const currentProjectEnumerators = {
  role: [
    'chief_investigator',
    'project_team_member',
    'associated_investigator',
    'consultant',
    'student',
    'advisor',
    'support/coordinator',
    'project_manager',
    'other_specify',
  ],
  ageGroup: [
    'pre_conception',
    'pregnancy_childbirth',
    'maternal_newborn',
    'babies_1',
    'babies_2',
    'toddlers',
    'preschoolers',
    'small_children',
    'early_adolescence',
    'late_adolescence',
    'young_adult',
    'midlife',
    'mature_adulthood',
    'late_aduldhood',
  ],
  status: ['draft', 'published', 'archived'],
  recordType: ['Academic', 'Professional'],
}

export default currentProjectEnumerators

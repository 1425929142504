import importerActions from 'src/modules/shared/importer/importerActions'
import selectors from 'src/modules/intellectualProperty/importer/intellectualPropertyImporterSelectors'
import IntellectualPropertyService from 'src/modules/intellectualProperty/intellectualPropertyService'
import fields from 'src/modules/intellectualProperty/importer/intellectualPropertyImporterFields'
import { i18n } from 'src/i18n'

const intellectualPropertyImporterActions = importerActions(
  'INTELLECTUALPROPERTY_IMPORTER',
  selectors,
  IntellectualPropertyService.import,
  fields,
  i18n('entities.intellectualProperty.importer.fileName'),
)

export default intellectualPropertyImporterActions

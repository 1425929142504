const providingExternalExpertiseEnumerators = {
  status: ['draft', 'published', 'archived'],
  recordType: ['Academic', 'Professional'],
  invited: ['invited_in_kind', 'invited_consultancy', 'unsolicited'],
  recipientType: [
    'government',
    'industry',
    'consumer_and_or_community',
    'non_government_organisation',
    'peak_body',
    'research_organisation',
    'education_organisation',
    'other_specify',
  ],
  expertiseType: [
    'position_statement',
    'evidence',
    'testimony',
    'consultancy',
    'submission',
    'comment',
    'paper',
    'white_paper',
    'position_paper',
    'brief',
    'ministerial_brief',
    'policy_brief',
    'opinion',
    'statement',
    'other_specify',
  ],
}

export default providingExternalExpertiseEnumerators

import React from 'react'
import ReactDOM from 'react-dom'
import { i18n, init as i18nInit } from 'src/i18n'
import { AuthToken } from './modules/auth/authToken'
import SettingsService from './modules/settings/settingsService'

;(async function () {
  AuthToken.applyFromLocationUrlIfExists()
  SettingsService.applyThemeFromTenant()
  await i18nInit()

  const App = require('./App').default
  document.title = i18n('app.titleBar')
  ReactDOM.render(<App />, document.getElementById('root'))
})()

import { i18n } from 'src/i18n'
import schemas from 'src/modules/shared/yup/yupImporterSchemas'

export default [
  {
    name: 'email',
    label: i18n('user.fields.email'),
    schema: schemas.email(i18n('user.fields.email'), {
      required: true,
    }),
  },
  {
    name: 'firstName',
    label: i18n('user.fields.firstName'),
    schema: schemas.string(i18n('user.fields.firstName'), {
      max: 80,
      required: true,
    }),
  },
  {
    name: 'lastName',
    label: i18n('user.fields.lastName'),
    schema: schemas.string(i18n('user.fields.lastName'), {
      max: 175,
      required: true,
    }),
  },
  {
    name: 'jobTitle',
    label: i18n('user.fields.jobTitle'),
    schema: schemas.string(i18n('user.fields.jobTitle'), {
      max: 100,
    }),
  },
  {
    name: 'employeeId',
    label: i18n('user.fields.employeeId'),
    schema: schemas.string(i18n('user.fields.employeeId'), {
      max: 100,
    }),
  },
  {
    name: 'ORCID',
    label: i18n('user.fields.ORCID'),
    schema: schemas.string(i18n('user.fields.ORCID'), {
      max: 100,
    }),
  },
]

import ToolsProductServiceService from 'src/modules/toolsProductService/toolsProductServiceService'
import selectors from 'src/modules/toolsProductService/list/toolsProductServiceListSelectors'
import exporterFields from 'src/modules/toolsProductService/list/toolsProductServiceListExporterFields'
import { generateListActions } from '../../listHelpers'

const prefix = 'TOOLSPRODUCTSERVICE_LIST'

const toolsProductServiceListActions = generateListActions(
  prefix,
  ToolsProductServiceService.list,
  selectors,
  exporterFields,
  'year',
)

export default toolsProductServiceListActions

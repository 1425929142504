import schemas from 'src/modules/shared/yup/yupImporterSchemas'
import { i18n } from 'src/i18n'
import educationTrainingMaterialEnumerators from 'src/modules/educationTrainingMaterial/educationTrainingMaterialEnumerators'

export default [
  {
    name: 'title',
    label: i18n('entities.educationTrainingMaterial.fields.title'),
    schema: schemas.string(
      i18n('entities.educationTrainingMaterial.fields.title'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'researcher',
    label: i18n('entities.educationTrainingMaterial.fields.researcher'),
    schema: schemas.relationToOne(
      i18n('entities.educationTrainingMaterial.fields.researcher'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'role',
    label: i18n('entities.educationTrainingMaterial.fields.role'),
    schema: schemas.enumerator(
      i18n('entities.educationTrainingMaterial.fields.role'),
      {
        required: true,
        options: educationTrainingMaterialEnumerators.role,
      },
    ),
  },
  {
    name: 'year',
    label: i18n('entities.educationTrainingMaterial.fields.year'),
    schema: schemas.string(
      i18n('entities.educationTrainingMaterial.fields.year'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'contribution',
    label: i18n('entities.educationTrainingMaterial.fields.contribution'),
    schema: schemas.string(
      i18n('entities.educationTrainingMaterial.fields.contribution'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'recipients',
    label: i18n('entities.educationTrainingMaterial.fields.recipients'),
    schema: schemas.string(
      i18n('entities.educationTrainingMaterial.fields.recipients'),
      {},
    ),
  },
  {
    name: 'organization',
    label: i18n('entities.educationTrainingMaterial.fields.organization'),
    schema: schemas.string(
      i18n('entities.educationTrainingMaterial.fields.organization'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'organizationType',
    label: i18n('entities.educationTrainingMaterial.fields.organizationType'),
    schema: schemas.string(
      i18n('entities.educationTrainingMaterial.fields.organizationType'),
      {},
    ),
  },
  {
    name: 'impact',
    label: i18n('entities.educationTrainingMaterial.fields.impact'),
    schema: schemas.string(
      i18n('entities.educationTrainingMaterial.fields.impact'),
      {},
    ),
  },
  {
    name: 'files',
    label: i18n('entities.educationTrainingMaterial.fields.files'),
    schema: schemas.files(
      i18n('entities.educationTrainingMaterial.fields.files'),
      {},
    ),
  },
  {
    name: 'images',
    label: i18n('entities.educationTrainingMaterial.fields.images'),
    schema: schemas.images(
      i18n('entities.educationTrainingMaterial.fields.images'),
      {},
    ),
  },
  {
    name: 'status',
    label: i18n('entities.educationTrainingMaterial.fields.status'),
    schema: schemas.enumerator(
      i18n('entities.educationTrainingMaterial.fields.status'),
      {
        required: true,
        options: educationTrainingMaterialEnumerators.status,
      },
    ),
  },
  {
    name: 'recordType',
    label: i18n('entities.educationTrainingMaterial.fields.recordType'),
    schema: schemas.enumerator(
      i18n('entities.educationTrainingMaterial.fields.recordType'),
      {
        options: educationTrainingMaterialEnumerators.recordType,
      },
    ),
  },
  {
    name: 'purpose',
    label: i18n('entities.educationTrainingMaterial.fields.purpose'),
    schema: schemas.boolean(
      i18n('entities.educationTrainingMaterial.fields.purpose'),
    ),
  },
]

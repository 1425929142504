import { i18n } from 'src/i18n'
import exporterRenders from 'src/modules/shared/exporter/exporterRenders'

export default [
  {
    name: 'id',
    label: i18n('entities.collaborativeNetwork.fields.id'),
  },
  {
    name: 'title',
    label: i18n('entities.collaborativeNetwork.fields.title'),
  },
  {
    name: 'researcher',
    label: i18n('entities.collaborativeNetwork.fields.researcher'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'role',
    label: i18n('entities.collaborativeNetwork.fields.role'),
  },
  {
    name: 'networkType',
    label: i18n('entities.collaborativeNetwork.fields.networkType'),
  },
  {
    name: 'scope',
    label: i18n('entities.collaborativeNetwork.fields.scope'),
  },
  {
    name: 'startDate',
    label: i18n('entities.collaborativeNetwork.fields.startDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'endDate',
    label: i18n('entities.collaborativeNetwork.fields.endDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'files',
    label: i18n('entities.collaborativeNetwork.fields.files'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'images',
    label: i18n('entities.collaborativeNetwork.fields.images'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'author',
    label: i18n('entities.collaborativeNetwork.fields.author'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'dateCreated',
    label: i18n('entities.collaborativeNetwork.fields.dateCreated'),
  },
  {
    name: 'status',
    label: i18n('entities.collaborativeNetwork.fields.status'),
  },
  {
    name: 'recordType',
    label: i18n('entities.collaborativeNetwork.fields.recordType'),
  },
  {
    name: 'year',
    label: i18n('entities.collaborativeNetwork.fields.year'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.collaborativeNetwork.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.collaborativeNetwork.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
]

import list from 'src/modules/admin/OrganisationStructure/HierarchyValues/list/HierarchyValuesListReducers'
import form from 'src/modules/admin/OrganisationStructure/HierarchyValues/form/HierarchyValuesFormReducers'
import view from 'src/modules/admin/OrganisationStructure/HierarchyValues/view/HierarchyValuesViewReducers'
import { combineReducers } from 'redux'

export default combineReducers({
  list,
  form,
  view,
})

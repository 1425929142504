const groups = () => (items) => {
  return items?.map((obj) => Object.values(obj)[0])
}

const orgStructure = () => (items) => {
  return items?.map((obj) => obj?.hierarchyValue?.name)?.join(' > ')
}

export default {
  groups,
  orgStructure,
}

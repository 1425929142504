import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import ReactTooltip from 'src/components/Tooltip'
import { i18n } from 'src/i18n'

export enum MenuOrdering {
  Alphabetical,
  AsIs,
}

interface MenuBlockProps {
  titleKey: string
  availableItems: any[]
  selectedKey: string
  ordering: MenuOrdering
}

function MenuBlock({
  titleKey,
  availableItems,
  selectedKey,
  ordering,
}: MenuBlockProps) {
  const sortedItems =
    ordering === MenuOrdering.AsIs
      ? availableItems
      : availableItems.sort((a, b) => a.label.localeCompare(b.label))

  return (
    <>
      <h3 className="header text-uppercase">{i18n(titleKey)}</h3>
      <ul className="menu-ul">
        {sortedItems.map((menu) => (
          <Fragment key={menu.path}>
            <li
              className={`menu-li text-nowrap ${
                selectedKey === menu.path ? 'active' : ''
              }`}
            >
              <Link to={menu.path}>
                {menu.indent && <i className="menu-icon fa fa-fw"></i>}
                <i className={`menu-icon ${menu.icon} fa-fw`}></i>
                <span data-tooltip-id={menu?.path}>{menu.label}</span>
              </Link>
            </li>
            <ReactTooltip
              id={menu?.path}
              content={menu?.label}
              style={{ padding: '20px 16px' }}
            />
          </Fragment>
        ))}
      </ul>
    </>
  )
}

export default MenuBlock

import schemas from 'src/modules/shared/yup/yupImporterSchemas'
import { i18n } from 'src/i18n'
import collaborativeNetworkEnumerators from 'src/modules/collaborativeNetwork/collaborativeNetworkEnumerators'

export default [
  {
    name: 'title',
    label: i18n('entities.collaborativeNetwork.fields.title'),
    schema: schemas.string(i18n('entities.collaborativeNetwork.fields.title'), {
      required: true,
    }),
  },
  {
    name: 'researcher',
    label: i18n('entities.collaborativeNetwork.fields.researcher'),
    schema: schemas.relationToOne(
      i18n('entities.collaborativeNetwork.fields.researcher'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'role',
    label: i18n('entities.collaborativeNetwork.fields.role'),
    schema: schemas.enumerator(
      i18n('entities.collaborativeNetwork.fields.role'),
      {
        options: collaborativeNetworkEnumerators.role,
      },
    ),
  },
  {
    name: 'networkType',
    label: i18n('entities.collaborativeNetwork.fields.networkType'),
    schema: schemas.string(
      i18n('entities.collaborativeNetwork.fields.networkType'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'scope',
    label: i18n('entities.collaborativeNetwork.fields.scope'),
    schema: schemas.enumerator(
      i18n('entities.collaborativeNetwork.fields.scope'),
      {
        options: collaborativeNetworkEnumerators.scope,
      },
    ),
  },
  {
    name: 'startDate',
    label: i18n('entities.collaborativeNetwork.fields.startDate'),
    schema: schemas.datetime(
      i18n('entities.collaborativeNetwork.fields.startDate'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'endDate',
    label: i18n('entities.collaborativeNetwork.fields.endDate'),
    schema: schemas.datetime(
      i18n('entities.collaborativeNetwork.fields.endDate'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'files',
    label: i18n('entities.collaborativeNetwork.fields.files'),
    schema: schemas.files(
      i18n('entities.collaborativeNetwork.fields.files'),
      {},
    ),
  },
  {
    name: 'images',
    label: i18n('entities.collaborativeNetwork.fields.images'),
    schema: schemas.images(
      i18n('entities.collaborativeNetwork.fields.images'),
      {},
    ),
  },
  {
    name: 'status',
    label: i18n('entities.collaborativeNetwork.fields.status'),
    schema: schemas.enumerator(
      i18n('entities.collaborativeNetwork.fields.status'),
      {
        required: true,
        options: collaborativeNetworkEnumerators.status,
      },
    ),
  },
  {
    name: 'recordType',
    label: i18n('entities.collaborativeNetwork.fields.recordType'),
    schema: schemas.enumerator(
      i18n('entities.collaborativeNetwork.fields.recordType'),
      {
        options: collaborativeNetworkEnumerators.recordType,
      },
    ),
  },
  {
    name: 'year',
    label: i18n('entities.collaborativeNetwork.fields.year'),
    schema: schemas.integer(
      i18n('entities.collaborativeNetwork.fields.year'),
      {},
    ),
  },
]

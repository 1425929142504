const isOtherOption = function (
  array: Array<any>,
  otherValue: string,
  watchValue: any,
) {
  return Array.isArray(watchValue)
    ? watchValue.some((watchValue) =>
        array.some(
          (option) => option.id === watchValue && option.label === otherValue,
        ),
      )
    : array.some(
        (option) => option.id === watchValue && option.label === otherValue,
      )
}

const flattenArray = function (array, flatArray = []) {
  array.forEach((item) => {
    flatArray.push(item)
    if (item.Childern && item.Childern.length > 0) {
      flattenArray(item.Childern, flatArray)
    }
  })
  return flatArray
}

const getFilteredLinkageArray = (impactCategory) => {
  const array = ['goal', 'funding', 'currentProject', 'record']
  return !['currentProject', 'funding', 'goal'].includes(impactCategory)
    ? array.filter((item) => item !== 'record')
    : ['currentProject'].includes(impactCategory)
    ? array.filter((item) => item !== 'currentProject')
    : ['funding'].includes(impactCategory)
    ? array.filter((item) => item !== 'funding')
    : ['goal'].includes(impactCategory) &&
      array.filter((item) => item !== 'goal')
}

const getSelectedRecordKey = (impactCategory) => {
  if (['currentProject', 'funding', 'goal'].includes(impactCategory)) {
    return impactCategory
  } else {
    return 'record'
  }
}

const getDeleteLinkageType = (type) => {
  if (type === 'indicators') {
    return 'record'
  }

  if (type === 'projects') {
    return 'currentProject'
  }

  if (type === 'goals') {
    return 'goal'
  }

  return type
}

const getAssociationId = (type) => {
  if (type === 'indicators' || type === 'engagements') {
    return 'record'
  }

  if (type === 'goals') {
    return 'goal'
  }

  if (type === 'projects') {
    return 'currentProject'
  }

  return type
}

const mapOrcidRecordToPublication = (
  orcidRecord,
  publicationTypeOptions,
  currentUser,
) => {
  const citation = orcidRecord?.citation?.['citation-data']
  const entryTags =
    (citation?.entryType === 'article' && citation?.entryTags) || {}

  const publicationDate = orcidRecord['publication-date']

  const peerReviewedJournalArticle = publicationTypeOptions.find(
    (o) => o.label === 'Peer Reviewed Journal Article',
  )

  const dois = orcidRecord['external-ids']['external-id']?.filter(
    (r) => r['external-id-type'] === 'doi',
  )

  const doiArray = dois?.map((d) => {
    return {
      doi: d['external-id-value'],
      url: d['external-id-url']?.value,
    }
  })

  const pages = (entryTags?.pages && entryTags.pages.split('-')) || []

  return {
    title: orcidRecord.title.title.value,
    year: publicationDate?.year?.value || entryTags?.year,
    month: publicationDate?.month?.value || entryTags?.month,
    day: publicationDate?.day?.value,
    PublicationType: peerReviewedJournalArticle,
    publicationType: 'Peer Reviewed Journal Article',
    publication: orcidRecord?.['journal-title']?.value,
    doiArray: doiArray,
    doi: doiArray?.[0]?.doi,
    url: doiArray?.[0]?.url,
    putCode: orcidRecord['put-code'],
    orcidId: orcidRecord?.source?.['source-orcid']?.path,
    orcidRecord: true,
    blob: JSON.stringify(orcidRecord),
    researchers: [
      {
        id: currentUser.id,
        value: currentUser.id,
        label: currentUser.fullName,
        fullName: currentUser.fullName,
      },
    ],
    allAuthors: entryTags?.author,
    publisher: entryTags?.publisher,
    volume: entryTags?.volume,
    pageNumber: pages[0],
    endPageNumber: pages[1],
    edition: entryTags?.edition,
    editor: entryTags?.editor,
    isbn: entryTags?.isbn,
    chapterNumber: entryTags?.chapter,
    issue: entryTags?.number,
  }
}

export {
  isOtherOption,
  flattenArray,
  getFilteredLinkageArray,
  getSelectedRecordKey,
  getDeleteLinkageType,
  getAssociationId,
  mapOrcidRecordToPublication,
}

import ResearchTeamService from 'src/modules/researchTeam/researchTeamService'
import Errors from 'src/modules/shared/error/errors'
import { getHistory } from 'src/modules/store'

const prefix = 'RESEARCHTEAM_VIEW'

const researchTeamViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: researchTeamViewActions.FIND_STARTED,
      })

      const record = await ResearchTeamService.find(id)

      dispatch({
        type: researchTeamViewActions.FIND_SUCCESS,
        payload: record,
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: researchTeamViewActions.FIND_ERROR,
      })

      getHistory().push('/research-team')
    }
  },
}

export default researchTeamViewActions

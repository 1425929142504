import { createBrowserHistory } from 'history'
import initializers from 'src/modules/initializers'
import createRootReducer from 'src/modules/reducers'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import thunkMiddleware from 'redux-thunk'
import { createReduxHistoryContext } from 'redux-first-history'

let store
let browserHistory

export function configureStore(preloadedState?) {
  const { createReduxHistory, routerMiddleware, routerReducer } =
    createReduxHistoryContext({
      history: createBrowserHistory(),
    })

  const middlewares = [thunkMiddleware, routerMiddleware].filter(Boolean)

  store = createStore(
    createRootReducer(routerReducer),
    preloadedState,
    composeWithDevTools(applyMiddleware(...middlewares)),
  )

  for (const initializer of initializers) {
    initializer(store)
  }

  browserHistory = createReduxHistory(store)

  return store
}

export function getHistory() {
  return browserHistory
}

export default function getStore() {
  return store
}

import list from 'src/modules/communityConsumerLiterature/list/communityConsumerLiteratureListReducers'
import form from 'src/modules/communityConsumerLiterature/form/communityConsumerLiteratureFormReducers'
import view from 'src/modules/communityConsumerLiterature/view/communityConsumerLiteratureViewReducers'
import destroy from 'src/modules/communityConsumerLiterature/destroy/communityConsumerLiteratureDestroyReducers'
import importerReducer from 'src/modules/communityConsumerLiterature/importer/communityConsumerLiteratureImporterReducers'
import { combineReducers } from 'redux'

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
})

import { i18n } from 'src/i18n'
import exporterRenders from 'src/modules/shared/exporter/exporterRenders'

export default [
  {
    name: 'id',
    label: i18n('entities.caseStudy.fields.id'),
  },
  {
    name: 'title',
    label: i18n('entities.caseStudy.fields.title'),
  },
  {
    name: 'caseStudyType',
    label: i18n('entities.caseStudy.fields.caseStudyType'),
  },
  {
    name: 'impactType',
    label: i18n('entities.caseStudy.fields.impactType'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'projectId',
    label: i18n('entities.caseStudy.fields.projectId'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'files',
    label: i18n('entities.caseStudy.fields.files'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'images',
    label: i18n('entities.caseStudy.fields.images'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'author',
    label: i18n('entities.caseStudy.fields.author'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'dateCreated',
    label: i18n('entities.caseStudy.fields.dateCreated'),
  },
  {
    name: 'status',
    label: i18n('entities.caseStudy.fields.status'),
  },
  {
    name: 'researchers',
    label: i18n('entities.caseStudy.fields.researchers'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'recordType',
    label: i18n('entities.caseStudy.fields.recordType'),
  },
  {
    name: 'year',
    label: i18n('entities.caseStudy.fields.year'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.caseStudy.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.caseStudy.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
]

import schemas from 'src/modules/shared/yup/yupImporterSchemas'
import { i18n } from 'src/i18n'
import currentProjectEnumerators from 'src/modules/currentProject/currentProjectEnumerators'

export default [
  {
    name: 'researcher',
    label: i18n('entities.currentProject.fields.researcher'),
    schema: schemas.relationToOne(
      i18n('entities.currentProject.fields.researcher'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'role',
    label: i18n('entities.currentProject.fields.role'),
    schema: schemas.enumerator(i18n('entities.currentProject.fields.role'), {
      options: currentProjectEnumerators.role,
    }),
  },
  {
    name: 'plainLanguageSummary',
    label: i18n('entities.currentProject.fields.plainLanguageSummary'),
    schema: schemas.string(
      i18n('entities.currentProject.fields.plainLanguageSummary'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'title',
    label: i18n('entities.currentProject.fields.title'),
    schema: schemas.string(i18n('entities.currentProject.fields.title'), {
      required: true,
    }),
  },
  {
    name: 'ageGroup',
    label: i18n('entities.currentProject.fields.ageGroup'),
    schema: schemas.string(i18n('entities.currentProject.fields.ageGroup'), {}),
  },
  {
    name: 'description',
    label: i18n('entities.currentProject.fields.description'),
    schema: schemas.string(i18n('entities.currentProject.fields.description'), {
      required: true,
    }),
  },
  {
    name: 'startDate',
    label: i18n('entities.currentProject.fields.startDate'),
    schema: schemas.date(i18n('entities.currentProject.fields.startDate'), {
      required: true,
    }),
  },
  {
    name: 'endDate',
    label: i18n('entities.currentProject.fields.endDate'),
    schema: schemas.date(i18n('entities.currentProject.fields.endDate'), {
      required: true,
    }),
  },
  {
    name: 'privateProject',
    label: i18n('entities.currentProject.fields.privateProject'),
    schema: schemas.boolean(
      i18n('entities.currentProject.fields.privateProject'),
    ),
  },
  {
    name: 'suitable',
    label: i18n('entities.currentProject.fields.suitable'),
    schema: schemas.boolean(i18n('entities.currentProject.fields.suitable')),
  },
  {
    name: 'files',
    label: i18n('entities.currentProject.fields.files'),
    schema: schemas.files(i18n('entities.currentProject.fields.files'), {}),
  },
  {
    name: 'images',
    label: i18n('entities.currentProject.fields.images'),
    schema: schemas.images(i18n('entities.currentProject.fields.images'), {}),
  },
  {
    name: 'status',
    label: i18n('entities.currentProject.fields.status'),
    schema: schemas.enumerator(i18n('entities.currentProject.fields.status'), {
      required: true,
      options: currentProjectEnumerators.status,
    }),
  },
  {
    name: 'recordType',
    label: i18n('entities.currentProject.fields.recordType'),
    schema: schemas.enumerator(
      i18n('entities.currentProject.fields.recordType'),
      {
        options: currentProjectEnumerators.recordType,
      },
    ),
  },
  {
    name: 'year',
    label: i18n('entities.currentProject.fields.year'),
    schema: schemas.integer(i18n('entities.currentProject.fields.year'), {}),
  },
]

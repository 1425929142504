import TestimonialService from 'src/modules/testimonial/testimonialService'
import selectors from 'src/modules/testimonial/list/testimonialListSelectors'
import exporterFields from 'src/modules/testimonial/list/testimonialListExporterFields'
import { generateListActions } from '../../listHelpers'

const prefix = 'TESTIMONIAL_LIST'

const testimonialListActions = generateListActions(
  prefix,
  TestimonialService.list,
  selectors,
  exporterFields,
  'year',
)

export default testimonialListActions

import CaseStudyService from 'src/modules/caseStudy/caseStudyService'
import selectors from 'src/modules/caseStudy/list/caseStudyListSelectors'
import exporterFields from 'src/modules/caseStudy/list/caseStudyListExporterFields'
import { generateListActions } from '../../listHelpers'

const prefix = 'CASESTUDY_LIST'

const caseStudyListActions = generateListActions(
  prefix,
  CaseStudyService.list,
  selectors,
  exporterFields,
  'year',
)

export default caseStudyListActions

import list from 'src/modules/providingExternalExpertise/list/providingExternalExpertiseListReducers'
import form from 'src/modules/providingExternalExpertise/form/providingExternalExpertiseFormReducers'
import view from 'src/modules/providingExternalExpertise/view/providingExternalExpertiseViewReducers'
import destroy from 'src/modules/providingExternalExpertise/destroy/providingExternalExpertiseDestroyReducers'
import importerReducer from 'src/modules/providingExternalExpertise/importer/providingExternalExpertiseImporterReducers'
import { combineReducers } from 'redux'

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
})

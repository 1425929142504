import ToolsProductServiceService from 'src/modules/toolsProductService/toolsProductServiceService'
import Errors from 'src/modules/shared/error/errors'
import { getHistory } from 'src/modules/store'

const prefix = 'TOOLSPRODUCTSERVICE_VIEW'

const toolsProductServiceViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: toolsProductServiceViewActions.FIND_STARTED,
      })

      const record = await ToolsProductServiceService.find(id)

      dispatch({
        type: toolsProductServiceViewActions.FIND_SUCCESS,
        payload: record,
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: toolsProductServiceViewActions.FIND_ERROR,
      })

      getHistory().push('/tools-product-service')
    }
  },
}

export default toolsProductServiceViewActions

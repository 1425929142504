import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import GlobalFilterWrapper from 'src/components/globalFilter/styles/Wrapper'
import GlobalFilterHeader from 'src/components/globalFilter/Header'
import GlobalFilterEdit from 'src/components/globalFilter/Edit'
import selectors from 'src/modules/admin/OrganisationStructure/list/OrganisationStructureListSelectors'
import OrganisationStructureRestrictionActions from 'src/modules/admin/OrganisationStructure/access/OrganisationStructureAccessActions'
import authSelectors from 'src/modules/auth/authSelectors'

export default function () {
  const dispatch = useDispatch()
  const [displayList, setDisplayList] = useState(false)
  const [displayFilter, setDisplayFilter] = useState(true)
  const flatHierarchies = useSelector(selectors.selectFlatHierarchies)
  const currentUser = useSelector(authSelectors.selectCurrentUser)

  const toggleList = () => {
    setDisplayList(!displayList)
  }

  const onReset = () => {
    dispatch(OrganisationStructureRestrictionActions.doReset([]))
  }

  useEffect(() => {
    const { pathname } = window.location
    if (
      pathname.includes('admin') ||
      pathname.includes('report') ||
      pathname.includes('map/pathways')
    ) {
      setDisplayFilter(false)
    }
  }, [window.location, dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    displayFilter && (
      <GlobalFilterWrapper
        style={{ background: displayList ? '#830B3F' : '#A81662' }}
      >
        <GlobalFilterHeader toggleList={toggleList} />
        {displayList && currentUser && (
          <GlobalFilterEdit
            toggleEdit={toggleList}
            hierarchies={flatHierarchies}
            user={currentUser}
            onReset={onReset}
            displayList={displayList}
          />
        )}
      </GlobalFilterWrapper>
    )
  )
}

import { i18n } from 'src/i18n'
import exporterRenders from 'src/modules/shared/exporter/exporterRenders'

export default [
  {
    name: 'id',
    label: i18n('entities.funding.fields.id'),
  },
  {
    name: 'title',
    label: i18n('entities.funding.fields.title'),
  },
  {
    name: 'researcher',
    label: i18n('entities.funding.fields.researcher'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'role',
    label: i18n('entities.funding.fields.role'),
  },
  {
    name: 'fundingType',
    label: i18n('entities.funding.fields.fundingType'),
  },
  {
    name: 'scheme',
    label: i18n('entities.funding.fields.scheme'),
  },
  {
    name: 'schemeType',
    label: i18n('entities.funding.fields.schemeType'),
  },
  {
    name: 'organization',
    label: i18n('entities.funding.fields.organization'),
  },
  {
    name: 'organizaionType',
    label: i18n('entities.funding.fields.organizaionType'),
  },
  {
    name: 'value',
    label: i18n('entities.funding.fields.value'),
  },
  {
    name: 'commencementYear',
    label: i18n('entities.funding.fields.commencementYear'),
  },
  {
    name: 'startDate',
    label: i18n('entities.funding.fields.startDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'endDate',
    label: i18n('entities.funding.fields.endDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'projectId',
    label: i18n('entities.funding.fields.projectId'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'isPrivate',
    label: i18n('entities.funding.fields.isPrivate'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'files',
    label: i18n('entities.funding.fields.files'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'images',
    label: i18n('entities.funding.fields.images'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'author',
    label: i18n('entities.funding.fields.author'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'dateCreated',
    label: i18n('entities.funding.fields.dateCreated'),
  },
  {
    name: 'status',
    label: i18n('entities.funding.fields.status'),
  },
  {
    name: 'recordType',
    label: i18n('entities.funding.fields.recordType'),
  },
  {
    name: 'year',
    label: i18n('entities.funding.fields.year'),
  },
  {
    name: 'collaborationPartnerId',
    label: i18n('entities.funding.fields.collaborationPartnerId'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'potential',
    label: i18n('entities.funding.fields.potential'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.funding.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.funding.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
]

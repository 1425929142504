import { i18n } from 'src/i18n'
import exporterRenders from 'src/modules/shared/exporter/exporterRenders'

export default [
  {
    name: 'id',
    label: i18n('entities.editorialReviewerResponsibility.fields.id'),
  },
  {
    name: 'title',
    label: i18n('entities.editorialReviewerResponsibility.fields.title'),
  },
  {
    name: 'researcher',
    label: i18n('entities.editorialReviewerResponsibility.fields.researcher'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'role',
    label: i18n('entities.editorialReviewerResponsibility.fields.role'),
  },
  {
    name: 'publicationType',
    label: i18n(
      'entities.editorialReviewerResponsibility.fields.publicationType',
    ),
  },
  {
    name: 'startDate',
    label: i18n('entities.editorialReviewerResponsibility.fields.startDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'endDate',
    label: i18n('entities.editorialReviewerResponsibility.fields.endDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'number',
    label: i18n('entities.editorialReviewerResponsibility.fields.number'),
  },
  {
    name: 'files',
    label: i18n('entities.editorialReviewerResponsibility.fields.files'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'images',
    label: i18n('entities.editorialReviewerResponsibility.fields.images'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'author',
    label: i18n('entities.editorialReviewerResponsibility.fields.author'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'dateCreated',
    label: i18n('entities.editorialReviewerResponsibility.fields.dateCreated'),
  },
  {
    name: 'status',
    label: i18n('entities.editorialReviewerResponsibility.fields.status'),
  },
  {
    name: 'recordType',
    label: i18n('entities.editorialReviewerResponsibility.fields.recordType'),
  },
  {
    name: 'year',
    label: i18n('entities.editorialReviewerResponsibility.fields.year'),
  },
  {
    name: 'publisher',
    label: i18n('entities.editorialReviewerResponsibility.fields.publisher'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.editorialReviewerResponsibility.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.editorialReviewerResponsibility.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
]

import organisationStructureService from 'src/modules/admin/OrganisationStructure/OrganisationStructureService'
import Errors from 'src/modules/shared/error/errors'
import Message from 'src/components/message'
import { i18n } from 'src/i18n'

const prefix = 'OrganisationStructure_LIST'

const OrganisationStructureListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  FETCH_FLAT_STARTED: `${prefix}_FETCH_FLAT_STARTED`,
  FETCH_FLAT_SUCCESS: `${prefix}_FETCH_FLAT_SUCCESS`,
  FETCH_FLAT_ERROR: `${prefix}_FETCH_FLAT_SUCCESS`,

  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  VALUES_FETCH_STARTED: `${prefix}_VALUES_FETCH_STARTED`,
  VALUES_FETCH_SUCCESS: `${prefix}_VALUES_FETCH_SUCCESS`,
  VALUES_FETCH_ERROR: `${prefix}_VALUE_FETCH_ERROR`,

  CLEAR_ALL: `${prefix}_CLEAR_ALL`,

  doFetch: (archived) => async (dispatch) => {
    try {
      dispatch({
        type: OrganisationStructureListActions.FETCH_STARTED,
      })

      const response = await organisationStructureService.getAll(archived)

      dispatch({
        type: OrganisationStructureListActions.FETCH_SUCCESS,
        payload: {
          response: response,
        },
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: OrganisationStructureListActions.FETCH_ERROR,
      })
    }
  },

  doFetchFlat:
    (archived = false) =>
    async (dispatch) => {
      try {
        dispatch({
          type: OrganisationStructureListActions.FETCH_FLAT_STARTED,
        })

        const hierarchies =
          await organisationStructureService.getFlatHierarchies(archived)
        dispatch({
          type: OrganisationStructureListActions.FETCH_FLAT_SUCCESS,
          payload: {
            response: hierarchies,
          },
        })
      } catch (error) {
        Errors.handle(error)

        dispatch({
          type: OrganisationStructureListActions.FETCH_FLAT_ERROR,
        })
      }
    },

  doFetchValues:
    (id, archived = false) =>
    async (dispatch) => {
      try {
        dispatch({
          type: OrganisationStructureListActions.VALUES_FETCH_STARTED,
        })

        const response = await organisationStructureService.getValues(
          id,
          archived,
        )

        dispatch({
          type: OrganisationStructureListActions.VALUES_FETCH_SUCCESS,
          payload: {
            [id]: response,
          },
        })
      } catch (error) {
        Errors.handle(error)

        dispatch({
          type: OrganisationStructureListActions.VALUES_FETCH_ERROR,
        })
      }
    },

  doDestroy: (id) => async (dispatch) => {
    try {
      dispatch({
        type: OrganisationStructureListActions.DESTROY_STARTED,
      })

      await organisationStructureService.destroy(id)

      dispatch({
        type: OrganisationStructureListActions.DESTROY_SUCCESS,
      })

      Message.success(i18n('user.doDestroySuccess'))
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: OrganisationStructureListActions.DESTROY_ERROR,
      })
    }
  },
}

export default OrganisationStructureListActions

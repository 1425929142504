import { useEffect } from 'react'
import actions from 'src/modules/admin/imactCategories/list/impactCategoriesListActions'
import selectors from 'src/modules/admin/imactCategories/list/impactCategoriesListSelectors'
import { useDispatch, useSelector } from 'react-redux'

function useMenu(): MenuItem[] {
  const dispatch = useDispatch()
  const menuItems = useSelector(selectors.selectMenuItems)

  useEffect(() => {
    dispatch(actions.doFetchMenuItems())
  }, [dispatch])

  return menuItems
}

export interface MenuItem {
  label: string
  exact: boolean
  path: string
  area: string
  type: string
  enabled: boolean
}

export default useMenu

import list from 'src/modules/guidelinePolicyPractice/list/guidelinePolicyPracticeListReducers'
import form from 'src/modules/guidelinePolicyPractice/form/guidelinePolicyPracticeFormReducers'
import view from 'src/modules/guidelinePolicyPractice/view/guidelinePolicyPracticeViewReducers'
import destroy from 'src/modules/guidelinePolicyPractice/destroy/guidelinePolicyPracticeDestroyReducers'
import importerReducer from 'src/modules/guidelinePolicyPractice/importer/guidelinePolicyPracticeImporterReducers'
import { combineReducers } from 'redux'

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
})

import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import authSelectors from 'src/modules/auth/authSelectors'
import PermissionChecker from 'src/modules/auth/permissionChecker'
import actions from 'src/modules/layout/layoutActions'
import layoutSelectors from 'src/modules/layout/layoutSelectors'
import MenuWrapper from 'src/components/layout/styles/MenuWrapper'
import useMenu from 'src/hooks/useMenu'
import AdminMenu from './menus/admin'
import IndicatorMenu from './menus/indicator'
import ResearchMenu from './menus/researchMenu'

function Menu(props) {
  const dispatch = useDispatch()
  const location = useLocation()

  const currentTenant = useSelector(authSelectors.selectCurrentTenant)
  const currentUser = useSelector(authSelectors.selectCurrentUser)
  const menuVisible = useSelector(layoutSelectors.selectMenuVisible)
  const menus = useMenu()
  const menuRef = useRef(null)
  const handleRef = useRef(null)
  const [menuToggle] = useState(false)

  const permissionChecker = new PermissionChecker(currentTenant, currentUser)
  const reportPath = location.pathname === '/report'
  const showOnReportPath = reportPath && menuToggle

  const handleMenuResize = (e) => {
    handleRef.current.style.left = e.clientX - 500 + 'px'
    handleRef.current.style.width = '100vw'
    menuRef.current.style.width = e.clientX + 'px'
  }

  const startResize = () => {
    handleRef.current.addEventListener('mousemove', handleMenuResize)
  }

  const stopResize = (e) => {
    handleRef.current.removeEventListener('mousemove', handleMenuResize)
    handleRef.current.style.left = e.clientX + 'px'
    handleRef.current.style.width = '24px'
    localStorage.setItem('menuWidth', menuRef.current.style.width.toString())
  }

  useLayoutEffect(() => {
    if (!reportPath || showOnReportPath) {
      if (menuRef && handleRef && localStorage.getItem('menuWidth')) {
        let menuWidth = localStorage.getItem('menuWidth')
        menuRef.current.style.width = menuWidth
        handleRef.current.style.left = menuWidth
      }
    }
  }, [menuToggle]) // eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(() => {
    if (!reportPath || showOnReportPath) {
      handleRef.current.addEventListener('mousedown', startResize)
      handleRef.current.addEventListener('mouseup', stopResize)
    }
  }, [menuToggle]) // eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(() => {
    const toggleMenuOnResize = () => {
      window.innerWidth < 576
        ? dispatch(actions.doHideMenu())
        : dispatch(actions.doShowMenu())
    }

    toggleMenuOnResize()

    window.addEventListener('resize', toggleMenuOnResize)

    return () => {
      window.removeEventListener('resize', toggleMenuOnResize)
    }
  }, [dispatch])

  useLayoutEffect(() => {
    if (!reportPath || showOnReportPath) {
      const handleScroll = () => {
        localStorage.setItem(
          'scrollPos',
          menuRef.current && menuRef.current.scrollTop.toString(),
        )
      }

      menuRef && menuRef.current.addEventListener('scroll', handleScroll)

      return () => {
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [menuToggle]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!reportPath || showOnReportPath) {
      const position = parseInt(localStorage.getItem('scrollPos'))
      menuRef && position && menuRef.current.scrollTo(0, position)
    }
  }, [menuToggle]) // eslint-disable-line react-hooks/exhaustive-deps

  const selectedKey = (): string => {
    const { url } = props

    const match = menus.find((option) => {
      if (option.exact) {
        return url === option.path
      }

      return url === option.path || url.startsWith(option.path + '/')
    })

    if (match) {
      return match.path
    }

    return ''
  }

  const match = (permission): boolean => {
    return permissionChecker.match(permission)
  }

  const showAdminMenu = props.url.startsWith('/admin/')

  if (!menuVisible || reportPath) {
    return <></>
  }

  return (
    <MenuWrapper>
      <div className="handle" ref={handleRef} />
      <div className="menu-nav border-right" ref={menuRef}>
        {showAdminMenu && (
          <AdminMenu
            menuItems={menus}
            selectedKey={selectedKey()}
            match={match}
          />
        )}

        {!showAdminMenu && (
          <>
            <ResearchMenu
              menuItems={menus}
              selectedKey={selectedKey()}
              match={match}
            />
            <IndicatorMenu menuItems={menus} selectedKey={selectedKey()} />
          </>
        )}
      </div>
    </MenuWrapper>
  )
}

export default Menu

import { i18n } from 'src/i18n'
import exporterRenders from 'src/modules/shared/exporter/exporterRenders'
import userDataTransformer from './userDataTransformer'

export default [
  {
    name: 'id',
    label: i18n('user.fields.id'),
  },
  {
    name: 'fullName',
    label: i18n('user.fields.fullName'),
  },
  {
    name: 'email',
    label: i18n('user.fields.email'),
  },
  {
    name: 'employeeId',
    label: i18n('user.fields.employeeId'),
  },
  {
    name: 'jobTitle',
    label: i18n('user.fields.jobTitle'),
  },
  {
    name: 'avatars',
    label: i18n('user.fields.avatars'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'roles',
    label: i18n('user.fields.role'),
    render: exporterRenders.stringArray(),
  },
  {
    name: 'groups',
    label: i18n('user.fields.group'),
    render: exporterRenders.stringArray(),
    transform: userDataTransformer.groups(),
  },
  {
    name: 'userHierarchies',
    label: i18n('user.fields.userHierarchies'),
    transform: userDataTransformer.orgStructure(),
  },
  {
    name: 'status',
    label: i18n('user.fields.status'),
  },
  {
    name: 'createdAt',
    label: i18n('user.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
]

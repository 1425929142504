import PropTypes from 'prop-types'
import { memo } from 'react'
import { useFormContext } from 'react-hook-form'
import FormErrors from 'src/helpers/formErrors'
import Label from '../../styles/Label'

export function InputFormItem(props) {
  const {
    label,
    name,
    hint,
    type,
    placeholder,
    autoFocus,
    autoComplete,
    required,
    externalErrorMessage,
    disabled,
    endAdornment,
    readOnly = false,
    customClassNames = [],
  } = props

  const {
    register,
    errors,
    formState: { touched, isSubmitted },
  } = useFormContext()

  const errorMessage = FormErrors.errorMessage(
    name,
    errors,
    touched,
    isSubmitted,
    externalErrorMessage,
  )
  const stringifiedClassNames = customClassNames.join(' ')
  return (
    <div className="form-group my-2 w-100">
      <div className={endAdornment ? 'input-group' : ''}>
        {Boolean(label) && (
          <Label
            className={`col-form-label form-label ${
              required ? 'required' : null
            }`}
            htmlFor={name}
          >
            {label}
          </Label>
        )}
        {Boolean(hint) && (
          <small className="form-text text-muted mb-1">{hint}</small>
        )}
        <input
          id={name}
          name={name}
          type={type}
          ref={register}
          onChange={(event) => {
            props.onChange && props.onChange(event.target.value)
          }}
          onKeyUp={(event) => {
            props.onKeyUp && props.onKeyUp(event)
          }}
          onBlur={(event) => {
            props.onBlur && props.onBlur(event)
          }}
          placeholder={placeholder || undefined}
          autoFocus={autoFocus || undefined}
          autoComplete={autoComplete || undefined}
          disabled={disabled}
          readOnly={readOnly}
          className={`form-control ${stringifiedClassNames} ${
            errorMessage ? 'is-invalid' : ''
          }`}
        />
        {endAdornment && (
          <div className="input-group-append">
            <span className="input-group-text">{endAdornment}</span>
          </div>
        )}
      </div>
      <div className="invalid-feedback">{errorMessage}</div>
    </div>
  )
}

InputFormItem.defaultProps = {
  type: 'text',
  required: false,
}

InputFormItem.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  type: PropTypes.string,
  label: PropTypes.string,
  hint: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  prefix: PropTypes.string,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  externalErrorMessage: PropTypes.string,
  endAdornment: PropTypes.any,
  onChange: PropTypes.any,
  onKeyUp: PropTypes.any,
  customClassNames: PropTypes.array,
}

export default memo(InputFormItem)

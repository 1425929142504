import schemas from 'src/modules/shared/yup/yupImporterSchemas'
import { i18n } from 'src/i18n'
import guidelinePolicyPracticeEnumerators from 'src/modules/guidelinePolicyPractice/guidelinePolicyPracticeEnumerators'

export default [
  {
    name: 'title',
    label: i18n('entities.guidelinePolicyPractice.fields.title'),
    schema: schemas.string(
      i18n('entities.guidelinePolicyPractice.fields.title'),
      {},
    ),
  },
  {
    name: 'type',
    label: i18n('entities.guidelinePolicyPractice.fields.type'),
    schema: schemas.enumerator(
      i18n('entities.guidelinePolicyPractice.fields.type'),
      {
        required: true,
        options: guidelinePolicyPracticeEnumerators.type,
      },
    ),
  },
  {
    name: 'researcher',
    label: i18n('entities.guidelinePolicyPractice.fields.researcher'),
    schema: schemas.relationToMany(
      i18n('entities.guidelinePolicyPractice.fields.researcher'),
      {},
    ),
  },
  {
    name: 'role',
    label: i18n('entities.guidelinePolicyPractice.fields.role'),
    schema: schemas.enumerator(
      i18n('entities.guidelinePolicyPractice.fields.role'),
      {
        required: true,
        options: guidelinePolicyPracticeEnumerators.role,
      },
    ),
  },
  {
    name: 'contribution',
    label: i18n('entities.guidelinePolicyPractice.fields.contribution'),
    schema: schemas.enumerator(
      i18n('entities.guidelinePolicyPractice.fields.contribution'),
      {
        required: true,
        options: guidelinePolicyPracticeEnumerators.contribution,
      },
    ),
  },
  {
    name: 'organization',
    label: i18n('entities.guidelinePolicyPractice.fields.organization'),
    schema: schemas.string(
      i18n('entities.guidelinePolicyPractice.fields.organization'),
      {},
    ),
  },
  {
    name: 'impactType1',
    label: i18n('entities.guidelinePolicyPractice.fields.impactType1'),
    schema: schemas.enumerator(
      i18n('entities.guidelinePolicyPractice.fields.impactType1'),
      {
        options: guidelinePolicyPracticeEnumerators.impactType1,
      },
    ),
  },
  {
    name: 'sector',
    label: i18n('entities.guidelinePolicyPractice.fields.sector'),
    schema: schemas.enumerator(
      i18n('entities.guidelinePolicyPractice.fields.sector'),
      {
        required: true,
        options: guidelinePolicyPracticeEnumerators.sector,
      },
    ),
  },
  {
    name: 'scope',
    label: i18n('entities.guidelinePolicyPractice.fields.scope'),
    schema: schemas.enumerator(
      i18n('entities.guidelinePolicyPractice.fields.scope'),
      {
        required: true,
        options: guidelinePolicyPracticeEnumerators.scope,
      },
    ),
  },
  {
    name: 'impactType2',
    label: i18n('entities.guidelinePolicyPractice.fields.impactType2'),
    schema: schemas.enumerator(
      i18n('entities.guidelinePolicyPractice.fields.impactType2'),
      {
        required: true,
        options: guidelinePolicyPracticeEnumerators.impactType2,
      },
    ),
  },
  {
    name: 'impact',
    label: i18n('entities.guidelinePolicyPractice.fields.impact'),
    schema: schemas.string(
      i18n('entities.guidelinePolicyPractice.fields.impact'),
      {
        max: 1000,
      },
    ),
  },
  {
    name: 'files',
    label: i18n('entities.guidelinePolicyPractice.fields.files'),
    schema: schemas.files(
      i18n('entities.guidelinePolicyPractice.fields.files'),
      {},
    ),
  },
  {
    name: 'images',
    label: i18n('entities.guidelinePolicyPractice.fields.images'),
    schema: schemas.images(
      i18n('entities.guidelinePolicyPractice.fields.images'),
      {},
    ),
  },
  {
    name: 'status',
    label: i18n('entities.guidelinePolicyPractice.fields.status'),
    schema: schemas.enumerator(
      i18n('entities.guidelinePolicyPractice.fields.status'),
      {
        required: true,
        options: guidelinePolicyPracticeEnumerators.status,
      },
    ),
  },
  {
    name: 'researchers',
    label: i18n('entities.guidelinePolicyPractice.fields.researchers'),
    schema: schemas.relationToMany(
      i18n('entities.guidelinePolicyPractice.fields.researchers'),
      {},
    ),
  },
  {
    name: 'recordType',
    label: i18n('entities.guidelinePolicyPractice.fields.recordType'),
    schema: schemas.enumerator(
      i18n('entities.guidelinePolicyPractice.fields.recordType'),
      {
        options: guidelinePolicyPracticeEnumerators.recordType,
      },
    ),
  },
  {
    name: 'year',
    label: i18n('entities.guidelinePolicyPractice.fields.year'),
    schema: schemas.integer(
      i18n('entities.guidelinePolicyPractice.fields.year'),
      {},
    ),
  },
]

import importerActions from 'src/modules/shared/importer/importerActions'
import selectors from 'src/modules/collaborativeNetwork/importer/collaborativeNetworkImporterSelectors'
import CollaborativeNetworkService from 'src/modules/collaborativeNetwork/collaborativeNetworkService'
import fields from 'src/modules/collaborativeNetwork/importer/collaborativeNetworkImporterFields'
import { i18n } from 'src/i18n'

const collaborativeNetworkImporterActions = importerActions(
  'COLLABORATIVENETWORK_IMPORTER',
  selectors,
  CollaborativeNetworkService.import,
  fields,
  i18n('entities.collaborativeNetwork.importer.fileName'),
)

export default collaborativeNetworkImporterActions

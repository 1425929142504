import { createSelector } from 'reselect'

const selectRaw = (state) => state.publication.orcidImporter

const selectRows = createSelector([selectRaw], (raw) => {
  return raw.rows || []
})

const selectOrcidRecords = createSelector([selectRaw], (raw) => {
  return raw.orcidRecords || []
})

const selectOrcidPublications = createSelector([selectRaw], (raw) => {
  return raw.orcidPublications || []
})

const selectPublication = createSelector([selectRaw], (raw) => {
  return raw.selectedPublication
})

const selectId = createSelector([selectRaw], (raw) => {
  return raw.id
})

const selectLoading = createSelector([selectRaw], (raw) => raw.loading)

const selectFilter = createSelector([selectRaw], (raw) => {
  return raw.filter
})

const selectRawFilter = createSelector([selectRaw], (raw) => {
  return raw.rawFilter
})

const selectLimit = createSelector([selectRaw], (raw) => {
  const pagination = raw.pagination
  return pagination.pageSize
})

const selectOffset = createSelector([selectRaw], (raw) => {
  const pagination = raw.pagination

  if (!pagination || !pagination.pageSize) {
    return 0
  }

  const current = pagination.current || 1

  return (current - 1) * pagination.pageSize
})

const selectCount = createSelector([selectRaw], (raw) => raw.count)

const selectPagination = createSelector(
  [selectRaw, selectCount],
  (raw, count) => {
    return {
      ...raw.pagination,
      total: count,
    }
  },
)

const orcidImporterSelectors = {
  selectRows,
  selectOrcidRecords,
  selectOrcidPublications,
  selectPublication,
  selectFilter,
  selectRawFilter,
  selectCount,
  selectPagination,
  selectLimit,
  selectOffset,
  selectId,
  selectLoading,
}

export default orcidImporterSelectors

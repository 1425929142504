import list from 'src/modules/mediaMention/list/mediaMentionListReducers'
import form from 'src/modules/mediaMention/form/mediaMentionFormReducers'
import view from 'src/modules/mediaMention/view/mediaMentionViewReducers'
import destroy from 'src/modules/mediaMention/destroy/mediaMentionDestroyReducers'
import importerReducer from 'src/modules/mediaMention/importer/mediaMentionImporterReducers'
import { combineReducers } from 'redux'

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
})

const barriersSupportEnumerators = {
  status: ['draft', 'published', 'archived'],
  recordType: ['Academic', 'Professional'],
  assistanceType: [
    'dissemination_activities',
    'advocacy',
    'lobbying',
    'forums',
    'high_level_policy_maker_practitioners',
    'working_outside_policy_frameworks',
    'communicating_to_policy_makers_practitioners',
    'knowledge_transfer_training',
    'other',
  ],
}

export default barriersSupportEnumerators

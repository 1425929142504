import list from 'src/modules/admin/groups/list/groupsListReducers'
import form from 'src/modules/admin/groups/form/groupsFormReducers'
import view from 'src/modules/admin/groups/view/groupsViewReducers'
import importerReducer from 'src/modules/admin/user/importer/userImporterReducers'
import { combineReducers } from 'redux'

export default combineReducers({
  list,
  form,
  view,
  importer: importerReducer,
})

import ResearchThemesIntendedImpactService from 'src/modules/researchThemesIntendedImpact/researchThemesIntendedImpactService'
import selectors from 'src/modules/researchThemesIntendedImpact/list/researchThemesIntendedImpactListSelectors'
import exporterFields from 'src/modules/researchThemesIntendedImpact/list/researchThemesIntendedImpactListExporterFields'
import { generateListActions } from '../../listHelpers'

const prefix = 'RESEARCHTHEMESINTENDEDIMPACT_LIST'

const researchThemesIntendedImpactListActions = generateListActions(
  prefix,
  ResearchThemesIntendedImpactService.list,
  selectors,
  exporterFields,
  'startDate',
)
export default researchThemesIntendedImpactListActions

import GuidelinePolicyPracticeService from 'src/modules/guidelinePolicyPractice/guidelinePolicyPracticeService'
import selectors from 'src/modules/guidelinePolicyPractice/list/guidelinePolicyPracticeListSelectors'
import exporterFields from 'src/modules/guidelinePolicyPractice/list/guidelinePolicyPracticeListExporterFields'
import { generateListActions } from '../../listHelpers'

const prefix = 'GUIDELINEPOLICYPRACTICE_LIST'

const guidelinePolicyPracticeListActions = generateListActions(
  prefix,
  GuidelinePolicyPracticeService.list,
  selectors,
  exporterFields,
  'year',
)

export default guidelinePolicyPracticeListActions

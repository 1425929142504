import list from 'src/modules/caseStudy/list/caseStudyListReducers'
import form from 'src/modules/caseStudy/form/caseStudyFormReducers'
import view from 'src/modules/caseStudy/view/caseStudyViewReducers'
import destroy from 'src/modules/caseStudy/destroy/caseStudyDestroyReducers'
import importerReducer from 'src/modules/caseStudy/importer/caseStudyImporterReducers'
import { combineReducers } from 'redux'

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
})

const externalDecisionMakingAdvisoryGroupEnumerators = {
  role: [
    'leader',
    'participant',
    'chair_member',
    'secretary',
    'ex_officer',
    'co_chair',
    'representative',
    'other_specify',
  ],
  groupType: [
    'committee',
    'board',
    'board_of_directors',
    'advisory_board',
    'think_tank',
    'workshop',
    'summit',
    'working_group',
    'council',
    'forum',
    'taskforce',
    'roundtable',
    'tribunal',
    'commission',
    'assessment_panel',
    'evaluation_panel',
    'review_panel',
    'review_board',
    'reference_group',
    'advisory_group',
    'stakeholder_group',
    'governance_group',
    'standing_committee',
    'advisory_committee',
    'management_committee',
    'steering_committee',
    'clinical_guidelines_committee',
    'other_specify',
  ],
  organizationType: [
    'industry',
    'consumer_and_community',
    'government',
    'non_government',
    'academic_MRIs',
    'aboriginal_focused_organisation',
    'other_specify',
  ],
  invited: [
    'invited_(in kind)',
    'invited_(consultancy)',
    'unsolicited_created',
    'other_specify',
  ],
  status: ['draft', 'published', 'archived'],
  recordType: ['Academic', 'Professional'],
}

export default externalDecisionMakingAdvisoryGroupEnumerators

import { HistoryRouter as Router } from 'redux-first-history/rr6'
import { configureStore, getHistory } from 'src/modules/store'
import { Provider } from 'react-redux'
import RoutesComponent from 'src/routes'
import jQuery from 'jquery'
import 'bootstrap/dist/js/bootstrap'
import '@fortawesome/fontawesome-free/css/all.min.css'
import CustomMsalProvider from './CustomMsalProvider'
import { QueryClient, QueryClientProvider } from 'react-query'
import ProductFruitComponent from './ProductFruits'
;(window as any).$ = (window as any).jQuery = jQuery

const store = configureStore()
const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnMount: true, refetchOnWindowFocus: false },
  },
})

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <CustomMsalProvider>
          <Router history={getHistory()}>
            <ProductFruitComponent />
            <RoutesComponent />
          </Router>
        </CustomMsalProvider>
      </Provider>
    </QueryClientProvider>
  )
}

export default App

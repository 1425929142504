import ExternalDecisionMakingAdvisoryGroupService from 'src/modules/externalDecisionMakingAdvisoryGroup/externalDecisionMakingAdvisoryGroupService'
import selectors from 'src/modules/externalDecisionMakingAdvisoryGroup/list/externalDecisionMakingAdvisoryGroupListSelectors'
import exporterFields from 'src/modules/externalDecisionMakingAdvisoryGroup/list/externalDecisionMakingAdvisoryGroupListExporterFields'
import { generateListActions } from '../../listHelpers'

const prefix = 'EXTERNALDECISIONMAKINGADVISORYGROUP_LIST'

const externalDecisionMakingAdvisoryGroupListActions = generateListActions(
  prefix,
  ExternalDecisionMakingAdvisoryGroupService.list,
  selectors,
  exporterFields,
  'startDate',
)
export default externalDecisionMakingAdvisoryGroupListActions

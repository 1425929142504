const caseStudyEnumerators = {
  caseStudyType: [
    'translation',
    'paradigm_shift',
    'in_the_pipeline',
    'collaborating_for_impact',
  ],
  impactType: [
    'paradigm_shift',
    'health_development_impact',
    'change_in_environmental_health_development_determinants',
    'change_in_social_health_development_determinants',
    'modification_of_risk_factors',
    'modification_to_other_determinants_of_health_and_development',
    'economic_benefits',
    'other_specify',
  ],
  status: ['draft', 'published', 'archived'],
  recordType: ['Academic', 'Professional'],
}

export default caseStudyEnumerators

import schemas from 'src/modules/shared/yup/yupImporterSchemas'
import { i18n } from 'src/i18n'
import awardPrizesEnumerators from 'src/modules/awardPrizes/awardPrizesEnumerators'

export default [
  {
    name: 'title',
    label: i18n('entities.awardPrizes.fields.title'),
    schema: schemas.string(i18n('entities.awardPrizes.fields.title'), {
      required: true,
    }),
  },
  {
    name: 'researcher',
    label: i18n('entities.awardPrizes.fields.researcher'),
    schema: schemas.relationToOne(
      i18n('entities.awardPrizes.fields.researcher'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'type',
    label: i18n('entities.awardPrizes.fields.type'),
    schema: schemas.enumerator(i18n('entities.awardPrizes.fields.type'), {
      required: true,
      options: awardPrizesEnumerators.type,
    }),
  },
  {
    name: 'organization',
    label: i18n('entities.awardPrizes.fields.organization'),
    schema: schemas.string(i18n('entities.awardPrizes.fields.organization'), {
      required: true,
    }),
  },
  {
    name: 'value',
    label: i18n('entities.awardPrizes.fields.value'),
    schema: schemas.string(i18n('entities.awardPrizes.fields.value'), {}),
  },
  {
    name: 'files',
    label: i18n('entities.awardPrizes.fields.files'),
    schema: schemas.files(i18n('entities.awardPrizes.fields.files'), {}),
  },
  {
    name: 'images',
    label: i18n('entities.awardPrizes.fields.images'),
    schema: schemas.images(i18n('entities.awardPrizes.fields.images'), {}),
  },
  {
    name: 'status',
    label: i18n('entities.awardPrizes.fields.status'),
    schema: schemas.enumerator(i18n('entities.awardPrizes.fields.status'), {
      required: true,
      options: awardPrizesEnumerators.status,
    }),
  },
  {
    name: 'recordType',
    label: i18n('entities.awardPrizes.fields.recordType'),
    schema: schemas.enumerator(i18n('entities.awardPrizes.fields.recordType'), {
      options: awardPrizesEnumerators.recordType,
    }),
  },
  {
    name: 'year',
    label: i18n('entities.awardPrizes.fields.year'),
    schema: schemas.integer(i18n('entities.awardPrizes.fields.year'), {}),
  },
]

import { i18n } from 'src/i18n'
import exporterRenders from 'src/modules/shared/exporter/exporterRenders'

export default [
  {
    name: 'id',
    label: i18n('entities.guidelinePolicyPractice.fields.id'),
  },
  {
    name: 'title',
    label: i18n('entities.guidelinePolicyPractice.fields.title'),
  },
  {
    name: 'type',
    label: i18n('entities.guidelinePolicyPractice.fields.type'),
  },
  {
    name: 'researcher',
    label: i18n('entities.guidelinePolicyPractice.fields.researcher'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'role',
    label: i18n('entities.guidelinePolicyPractice.fields.role'),
  },
  {
    name: 'contribution',
    label: i18n('entities.guidelinePolicyPractice.fields.contribution'),
  },
  {
    name: 'organization',
    label: i18n('entities.guidelinePolicyPractice.fields.organization'),
  },
  {
    name: 'impactType1',
    label: i18n('entities.guidelinePolicyPractice.fields.impactType1'),
  },
  {
    name: 'sector',
    label: i18n('entities.guidelinePolicyPractice.fields.sector'),
  },
  {
    name: 'scope',
    label: i18n('entities.guidelinePolicyPractice.fields.scope'),
  },
  {
    name: 'impactType2',
    label: i18n('entities.guidelinePolicyPractice.fields.impactType2'),
  },
  {
    name: 'impact',
    label: i18n('entities.guidelinePolicyPractice.fields.impact'),
  },
  {
    name: 'files',
    label: i18n('entities.guidelinePolicyPractice.fields.files'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'images',
    label: i18n('entities.guidelinePolicyPractice.fields.images'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'author',
    label: i18n('entities.guidelinePolicyPractice.fields.author'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'dateCreated',
    label: i18n('entities.guidelinePolicyPractice.fields.dateCreated'),
  },
  {
    name: 'status',
    label: i18n('entities.guidelinePolicyPractice.fields.status'),
  },
  {
    name: 'researchers',
    label: i18n('entities.guidelinePolicyPractice.fields.researchers'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'recordType',
    label: i18n('entities.guidelinePolicyPractice.fields.recordType'),
  },
  {
    name: 'year',
    label: i18n('entities.guidelinePolicyPractice.fields.year'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.guidelinePolicyPractice.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.guidelinePolicyPractice.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
]

import GuidelinePolicyPracticeService from 'src/modules/guidelinePolicyPractice/guidelinePolicyPracticeService'
import Errors from 'src/modules/shared/error/errors'
import { getHistory } from 'src/modules/store'

const prefix = 'GUIDELINEPOLICYPRACTICE_VIEW'

const guidelinePolicyPracticeViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: guidelinePolicyPracticeViewActions.FIND_STARTED,
      })

      const record = await GuidelinePolicyPracticeService.find(id)

      dispatch({
        type: guidelinePolicyPracticeViewActions.FIND_SUCCESS,
        payload: record,
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: guidelinePolicyPracticeViewActions.FIND_ERROR,
      })

      getHistory().push('/guideline-policy-practice')
    }
  },
}

export default guidelinePolicyPracticeViewActions

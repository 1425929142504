import importerActions from 'src/modules/shared/importer/importerActions'
import selectors from 'src/modules/externalDecisionMakingAdvisoryGroup/importer/externalDecisionMakingAdvisoryGroupImporterSelectors'
import ExternalDecisionMakingAdvisoryGroupService from 'src/modules/externalDecisionMakingAdvisoryGroup/externalDecisionMakingAdvisoryGroupService'
import fields from 'src/modules/externalDecisionMakingAdvisoryGroup/importer/externalDecisionMakingAdvisoryGroupImporterFields'
import { i18n } from 'src/i18n'

const externalDecisionMakingAdvisoryGroupImporterActions = importerActions(
  'EXTERNALDECISIONMAKINGADVISORYGROUP_IMPORTER',
  selectors,
  ExternalDecisionMakingAdvisoryGroupService.import,
  fields,
  i18n('entities.externalDecisionMakingAdvisoryGroup.importer.fileName'),
)

export default externalDecisionMakingAdvisoryGroupImporterActions

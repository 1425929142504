import { i18n } from 'src/i18n'
import exporterRenders from 'src/modules/shared/exporter/exporterRenders'

export default [
  {
    name: 'id',
    label: i18n('entities.educationTrainingMaterial.fields.id'),
  },
  {
    name: 'title',
    label: i18n('entities.educationTrainingMaterial.fields.title'),
  },
  {
    name: 'researcher',
    label: i18n('entities.educationTrainingMaterial.fields.researcher'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'role',
    label: i18n('entities.educationTrainingMaterial.fields.role'),
  },
  {
    name: 'year',
    label: i18n('entities.educationTrainingMaterial.fields.year'),
  },
  {
    name: 'contribution',
    label: i18n('entities.educationTrainingMaterial.fields.contribution'),
  },
  {
    name: 'recipients',
    label: i18n('entities.educationTrainingMaterial.fields.recipients'),
  },
  {
    name: 'organization',
    label: i18n('entities.educationTrainingMaterial.fields.organization'),
  },
  {
    name: 'organizationType',
    label: i18n('entities.educationTrainingMaterial.fields.organizationType'),
  },
  {
    name: 'impact',
    label: i18n('entities.educationTrainingMaterial.fields.impact'),
  },
  {
    name: 'files',
    label: i18n('entities.educationTrainingMaterial.fields.files'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'images',
    label: i18n('entities.educationTrainingMaterial.fields.images'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'author',
    label: i18n('entities.educationTrainingMaterial.fields.author'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'dateCreated',
    label: i18n('entities.educationTrainingMaterial.fields.dateCreated'),
  },
  {
    name: 'status',
    label: i18n('entities.educationTrainingMaterial.fields.status'),
  },
  {
    name: 'recordType',
    label: i18n('entities.educationTrainingMaterial.fields.recordType'),
  },
  {
    name: 'purpose',
    label: i18n('entities.educationTrainingMaterial.fields.purpose'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.educationTrainingMaterial.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.educationTrainingMaterial.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
]

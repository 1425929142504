import schemas from 'src/modules/shared/yup/yupImporterSchemas'
import { i18n } from 'src/i18n'
import mentoringSupervisionEnumerators from 'src/modules/mentoringSupervision/mentoringSupervisionEnumerators'

export default [
  {
    name: 'status',
    label: i18n('entities.mentoringSupervision.fields.status'),
    schema: schemas.enumerator(
      i18n('entities.mentoringSupervision.fields.status'),
      {
        required: true,
        options: mentoringSupervisionEnumerators.status,
      },
    ),
  },
  {
    name: 'title',
    label: i18n('entities.mentoringSupervision.fields.title'),
    schema: schemas.string(
      i18n('entities.mentoringSupervision.fields.title'),
      {},
    ),
  },
  {
    name: 'recordType',
    label: i18n('entities.mentoringSupervision.fields.recordType'),
    schema: schemas.enumerator(
      i18n('entities.mentoringSupervision.fields.recordType'),
      {
        options: mentoringSupervisionEnumerators.recordType,
      },
    ),
  },
  {
    name: 'year',
    label: i18n('entities.mentoringSupervision.fields.year'),
    schema: schemas.integer(
      i18n('entities.mentoringSupervision.fields.year'),
      {},
    ),
  },
  {
    name: 'mentoringSupervision',
    label: i18n('entities.mentoringSupervision.fields.mentoringSupervision'),
    schema: schemas.enumerator(
      i18n('entities.mentoringSupervision.fields.mentoringSupervision'),
      {
        required: true,
        options: mentoringSupervisionEnumerators.mentoringSupervision,
      },
    ),
  },
  {
    name: 'menteeName',
    label: i18n('entities.mentoringSupervision.fields.menteeName'),
    schema: schemas.relationToOne(
      i18n('entities.mentoringSupervision.fields.menteeName'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'researcher',
    label: i18n('entities.mentoringSupervision.fields.researcher'),
    schema: schemas.relationToOne(
      i18n('entities.mentoringSupervision.fields.researcher'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'reponsibility',
    label: i18n('entities.mentoringSupervision.fields.reponsibility'),
    schema: schemas.string(
      i18n('entities.mentoringSupervision.fields.reponsibility'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'role',
    label: i18n('entities.mentoringSupervision.fields.role'),
    schema: schemas.enumerator(
      i18n('entities.mentoringSupervision.fields.role'),
      {
        required: true,
        options: mentoringSupervisionEnumerators.role,
      },
    ),
  },
  {
    name: 'allSupervisors',
    label: i18n('entities.mentoringSupervision.fields.allSupervisors'),
    schema: schemas.string(
      i18n('entities.mentoringSupervision.fields.allSupervisors'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'name',
    label: i18n('entities.mentoringSupervision.fields.name'),
    schema: schemas.string(i18n('entities.mentoringSupervision.fields.name'), {
      required: true,
    }),
  },
  {
    name: 'mentoringStatus',
    label: i18n('entities.mentoringSupervision.fields.mentoringStatus'),
    schema: schemas.enumerator(
      i18n('entities.mentoringSupervision.fields.mentoringStatus'),
      {
        required: true,
        options: mentoringSupervisionEnumerators.mentoringStatus,
      },
    ),
  },
  {
    name: 'degree',
    label: i18n('entities.mentoringSupervision.fields.degree'),
    schema: schemas.enumerator(
      i18n('entities.mentoringSupervision.fields.degree'),
      {
        required: true,
        options: mentoringSupervisionEnumerators.degree,
      },
    ),
  },
  {
    name: 'program',
    label: i18n('entities.mentoringSupervision.fields.program'),
    schema: schemas.string(
      i18n('entities.mentoringSupervision.fields.program'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'organization',
    label: i18n('entities.mentoringSupervision.fields.organization'),
    schema: schemas.string(
      i18n('entities.mentoringSupervision.fields.organization'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'mentorName',
    label: i18n('entities.mentoringSupervision.fields.mentorName'),
    schema: schemas.relationToOne(
      i18n('entities.mentoringSupervision.fields.mentorName'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'programType',
    label: i18n('entities.mentoringSupervision.fields.programType'),
    schema: schemas.enumerator(
      i18n('entities.mentoringSupervision.fields.programType'),
      {
        options: mentoringSupervisionEnumerators.programType,
      },
    ),
  },
  {
    name: 'menteeType',
    label: i18n('entities.mentoringSupervision.fields.menteeType'),
    schema: schemas.enumerator(
      i18n('entities.mentoringSupervision.fields.menteeType'),
      {
        required: true,
        options: mentoringSupervisionEnumerators.menteeType,
      },
    ),
  },
  {
    name: 'environment',
    label: i18n('entities.mentoringSupervision.fields.environment'),
    schema: schemas.enumerator(
      i18n('entities.mentoringSupervision.fields.environment'),
      {
        options: mentoringSupervisionEnumerators.environment,
      },
    ),
  },
  {
    name: 'startDate',
    label: i18n('entities.mentoringSupervision.fields.startDate'),
    schema: schemas.datetime(
      i18n('entities.mentoringSupervision.fields.startDate'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'endDate',
    label: i18n('entities.mentoringSupervision.fields.endDate'),
    schema: schemas.datetime(
      i18n('entities.mentoringSupervision.fields.endDate'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'files',
    label: i18n('entities.mentoringSupervision.fields.files'),
    schema: schemas.files(
      i18n('entities.mentoringSupervision.fields.files'),
      {},
    ),
  },
  {
    name: 'images',
    label: i18n('entities.mentoringSupervision.fields.images'),
    schema: schemas.images(
      i18n('entities.mentoringSupervision.fields.images'),
      {},
    ),
  },
]

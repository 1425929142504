import EducationTrainingMaterialService from 'src/modules/educationTrainingMaterial/educationTrainingMaterialService'
import Errors from 'src/modules/shared/error/errors'
import { getHistory } from 'src/modules/store'

const prefix = 'EDUCATIONTRAININGMATERIAL_VIEW'

const educationTrainingMaterialViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: educationTrainingMaterialViewActions.FIND_STARTED,
      })

      const record = await EducationTrainingMaterialService.find(id)

      dispatch({
        type: educationTrainingMaterialViewActions.FIND_SUCCESS,
        payload: record,
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: educationTrainingMaterialViewActions.FIND_ERROR,
      })

      getHistory().push('/education-training-material')
    }
  },
}

export default educationTrainingMaterialViewActions

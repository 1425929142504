import schemas from 'src/modules/shared/yup/yupImporterSchemas'
import { i18n } from 'src/i18n'
import toolsProductServiceEnumerators from 'src/modules/toolsProductService/toolsProductServiceEnumerators'

export default [
  {
    name: 'title',
    label: i18n('entities.toolsProductService.fields.title'),
    schema: schemas.string(i18n('entities.toolsProductService.fields.title'), {
      required: true,
    }),
  },
  {
    name: 'value',
    label: i18n('entities.toolsProductService.fields.value'),
    schema: schemas.enumerator(
      i18n('entities.toolsProductService.fields.value'),
      {
        required: true,
        options: toolsProductServiceEnumerators.value,
      },
    ),
  },
  {
    name: 'tool',
    label: i18n('entities.toolsProductService.fields.tool'),
    schema: schemas.enumerator(
      i18n('entities.toolsProductService.fields.tool'),
      {
        required: true,
        options: toolsProductServiceEnumerators.tool,
      },
    ),
  },
  {
    name: 'product',
    label: i18n('entities.toolsProductService.fields.product'),
    schema: schemas.enumerator(
      i18n('entities.toolsProductService.fields.product'),
      {
        required: true,
        options: toolsProductServiceEnumerators.product,
      },
    ),
  },
  {
    name: 'service',
    label: i18n('entities.toolsProductService.fields.service'),
    schema: schemas.enumerator(
      i18n('entities.toolsProductService.fields.service'),
      {
        required: true,
        options: toolsProductServiceEnumerators.service,
      },
    ),
  },
  {
    name: 'researcher',
    label: i18n('entities.toolsProductService.fields.researcher'),
    schema: schemas.relationToOne(
      i18n('entities.toolsProductService.fields.researcher'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'role',
    label: i18n('entities.toolsProductService.fields.role'),
    schema: schemas.boolean(i18n('entities.toolsProductService.fields.role')),
  },
  {
    name: 'recipientType',
    label: i18n('entities.toolsProductService.fields.recipientType'),
    schema: schemas.enumerator(
      i18n('entities.toolsProductService.fields.recipientType'),
      {
        options: toolsProductServiceEnumerators.recipientType,
      },
    ),
  },
  {
    name: 'description',
    label: i18n('entities.toolsProductService.fields.description'),
    schema: schemas.string(
      i18n('entities.toolsProductService.fields.description'),
      {
        max: 1000,
      },
    ),
  },
  {
    name: 'scope',
    label: i18n('entities.toolsProductService.fields.scope'),
    schema: schemas.enumerator(
      i18n('entities.toolsProductService.fields.scope'),
      {
        options: toolsProductServiceEnumerators.scope,
      },
    ),
  },
  {
    name: 'files',
    label: i18n('entities.toolsProductService.fields.files'),
    schema: schemas.files(
      i18n('entities.toolsProductService.fields.files'),
      {},
    ),
  },
  {
    name: 'images',
    label: i18n('entities.toolsProductService.fields.images'),
    schema: schemas.images(
      i18n('entities.toolsProductService.fields.images'),
      {},
    ),
  },
  {
    name: 'status',
    label: i18n('entities.toolsProductService.fields.status'),
    schema: schemas.enumerator(
      i18n('entities.toolsProductService.fields.status'),
      {
        required: true,
        options: toolsProductServiceEnumerators.status,
      },
    ),
  },
  {
    name: 'researchers',
    label: i18n('entities.toolsProductService.fields.researchers'),
    schema: schemas.relationToMany(
      i18n('entities.toolsProductService.fields.researchers'),
      {},
    ),
  },
  {
    name: 'recordType',
    label: i18n('entities.toolsProductService.fields.recordType'),
    schema: schemas.enumerator(
      i18n('entities.toolsProductService.fields.recordType'),
      {
        options: toolsProductServiceEnumerators.recordType,
      },
    ),
  },
  {
    name: 'year',
    label: i18n('entities.toolsProductService.fields.year'),
    schema: schemas.integer(
      i18n('entities.toolsProductService.fields.year'),
      {},
    ),
  },
]

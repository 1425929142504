import XLSX from 'xlsx'
import { i18n } from 'src/i18n'
import ImporterSchema from 'src/modules/shared/importer/importerSchema'
import { Excel } from 'src/modules/shared/excel/excel'
import getSampleData from './sampleDataGenerator'

export default class Importer {
  schema: ImporterSchema

  constructor(fields) {
    this.schema = new ImporterSchema(fields)
  }

  async downloadTemplate(templateFileName, prefix) {
    return Excel.exportAsExcelFile(
      getSampleData(this.schema, prefix),
      this.schema.labels,
      templateFileName,
      true,
    )
  }

  async castForImport(row) {
    return this.schema.castForImport(row)
  }

  async castForDisplay(row, index) {
    return this.schema.castForDisplay(row, index)
  }

  async convertExcelFileToJson(file, skipHeader = true) {
    const workbook = await this._convertExcelFileToWorkbook(file)

    return XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
      header: 1,
      blankrows: false,
      range: skipHeader ? 1 : undefined,
      raw: true,
    })
  }

  async _convertExcelFileToWorkbook(file) {
    try {
      const data = await this._readFile(file)
      return XLSX.read(data, {
        type: 'array',
      })
    } catch (error) {
      throw new Error(i18n('importer.errors.invalidFileUpload'))
    }
  }

  async _readFile(file) {
    if (!file) {
      return null
    }

    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = (e) => {
        try {
          if (!e || !e.target) {
            reject(new Error())
            return
          }

          const data = new Uint8Array(e.target.result as any)
          resolve(data)
        } catch (error) {
          reject(error)
        }
      }

      reader.onerror = () => {
        reject()
      }

      reader.readAsArrayBuffer(file)
    })
  }
}

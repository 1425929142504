import { createSelector } from 'reselect'

const selectRaw = (state) => state.search

const selectLoading = createSelector([selectRaw], (raw) => Boolean(raw.loading))
const selectExportLoading = createSelector([selectRaw], (raw) =>
  Boolean(raw.exportLoading),
)
const selectRows = createSelector([selectRaw], (raw) => raw.rows)
const selectFilters = createSelector([selectRaw], (raw) => raw.filters)
const selectReportsData = createSelector([selectRaw], (raw) => raw.reportsData)
const selectPagination = createSelector(
  [selectRaw],
  (raw) => raw.filters.pagination,
)
const selectSavedFilters = createSelector([selectRaw], (raw) => raw.form.filter)
const selectSavedReports = createSelector(
  [selectRaw],
  (raw) => raw.savedReports,
)

const selectSelectedSavedReport = createSelector(
  [selectRaw],
  (raw) => raw.selectedSavedReport,
)

const searchSelectors = {
  selectRaw,
  selectLoading,
  selectRows,
  selectFilters,
  selectPagination,
  selectSavedFilters,
  selectSavedReports,
  selectSelectedSavedReport,
  selectReportsData,
  selectExportLoading,
}

export default searchSelectors

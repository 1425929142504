import styled from 'styled-components'

const LayoutWrapper = styled.div`
  color: rgba(0, 0, 0, 0.65);
  display: flex;
  flex-direction: column;
  font-size: 13px;
  overflow-y: hidden;

  button {
    font-size: 13px;
  }

  .navbar-fixed-top {
    position: fixed; /* Set the navbar to fixed position */
    top: 0;
    width: 100%;
    z-index: 100;
  }

  .btn {
    font-size: 13px;
  }

  h1 {
    font-size: 1.75em;
  }

  .bg-primary-light {
    background-color: #e6f7ff;
  }

  .primary-light {
    color: #e6f7ff;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: rgba(0, 0, 0, 0.85);
  }

  .content {
    padding: 24px;
    background-color: #fff;
  }

  .form-group {
    margin-bottom: 0;
  }

  .form-control {
    font-size: inherit;
  }

  .menu-li.active a {
    background-color: #fbf3f7 !important;
    border-bottom: 1px solid var(--primary);
    border-right: unset;
    color: var(--primary);
    font-weight: 700;
  }

  .main {
    display: flex;
    flex: auto;
    flex-direction: column;
    background-color: #fff;
    overflow-x: hidden;
    margin-top: 72px;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .form-label {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 28px;
    color: #333;
  }

  label.required::before,
  .form-label.required::before {
    display: inline-block;
    margin-right: 4px;
    content: '*';
    line-height: 1;
    font-size: 13px;
  }
`

export default LayoutWrapper

import ResearchTeamService from 'src/modules/researchTeam/researchTeamService'
import selectors from 'src/modules/researchTeam/list/researchTeamListSelectors'
import exporterFields from 'src/modules/researchTeam/list/researchTeamListExporterFields'
import { generateListActions } from '../../listHelpers'

const prefix = 'RESEARCHTEAM_LIST'

const researchTeamListActions = generateListActions(
  prefix,
  ResearchTeamService.list,
  selectors,
  exporterFields,
  'year',
)

export default researchTeamListActions

import listActions from 'src/modules/barriersSupport/list/barriersSupportListActions'
import BarriersSupportService from 'src/modules/barriersSupport/barriersSupportService'
import Errors from 'src/modules/shared/error/errors'
import { i18n } from 'src/i18n'
import { getHistory } from 'src/modules/store'
import Message from 'src/components/message'

const prefix = 'BARRIERSSUPPORT_DESTROY'

const barriersSupportDestroyActions = {
  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  DESTROY_ALL_STARTED: `${prefix}_DESTROY_ALL_STARTED`,
  DESTROY_ALL_SUCCESS: `${prefix}_DESTROY_ALL_SUCCESS`,
  DESTROY_ALL_ERROR: `${prefix}_DESTROY_ALL_ERROR`,

  doDestroy:
    (id, impactCategoryAlias = null) =>
    async (dispatch) => {
      try {
        dispatch({
          type: barriersSupportDestroyActions.DESTROY_STARTED,
        })

        await BarriersSupportService.destroyAll([id])

        dispatch({
          type: barriersSupportDestroyActions.DESTROY_SUCCESS,
        })

        Message.success(
          impactCategoryAlias
            ? i18n(
                'impactCategories.messages.destroy.success',
                impactCategoryAlias,
              )
            : i18n('entities.barriersSupport.destroy.success'),
        )

        dispatch(listActions.doFetchCurrentFilter())

        getHistory().push('/barriers-support')
      } catch (error) {
        Errors.handle(error)

        dispatch(listActions.doFetchCurrentFilter())

        dispatch({
          type: barriersSupportDestroyActions.DESTROY_ERROR,
        })
      }
    },

  doDestroyAll: (ids) => async (dispatch) => {
    try {
      dispatch({
        type: barriersSupportDestroyActions.DESTROY_ALL_STARTED,
      })

      await BarriersSupportService.destroyAll(ids)

      dispatch({
        type: barriersSupportDestroyActions.DESTROY_ALL_SUCCESS,
      })

      if (listActions) {
        dispatch(listActions.doClearAllSelected())
        dispatch(listActions.doFetchCurrentFilter())
      }

      Message.success(i18n('entities.barriersSupport.destroyAll.success'))

      getHistory().push('/barriers-support')
    } catch (error) {
      Errors.handle(error)

      dispatch(listActions.doFetchCurrentFilter())

      dispatch({
        type: barriersSupportDestroyActions.DESTROY_ALL_ERROR,
      })
    }
  },
}

export default barriersSupportDestroyActions

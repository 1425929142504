import styled from 'styled-components'

const HeaderWrapper = styled.div`
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
`

export default HeaderWrapper

import EditorialReviewerResponsibilityService from 'src/modules/editorialReviewerResponsibility/editorialReviewerResponsibilityService'
import selectors from 'src/modules/editorialReviewerResponsibility/list/editorialReviewerResponsibilityListSelectors'
import exporterFields from 'src/modules/editorialReviewerResponsibility/list/editorialReviewerResponsibilityListExporterFields'
import { generateListActions } from '../../listHelpers'

const prefix = 'EDITORIALREVIEWERRESPONSIBILITY_LIST'

const editorialReviewerResponsibilityListActions = generateListActions(
  prefix,
  EditorialReviewerResponsibilityService.list,
  selectors,
  exporterFields,
  'startDate',
)

export default editorialReviewerResponsibilityListActions

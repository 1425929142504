import authAxios from 'src/modules/shared/axios/authAxios'
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant'

export default class ToolsProductServiceService {
  static async update(id, data) {
    const body = {
      id,
      data,
    }

    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.put(
      `/tenant/${tenantId}/tools-product-service/${id}`,
      body,
    )

    return response.data
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    }

    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.delete(
      `/tenant/${tenantId}/tools-product-service`,
      {
        params,
      },
    )

    return response.data
  }

  static async create(data) {
    const body = {
      data,
    }

    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.post(
      `/tenant/${tenantId}/tools-product-service`,
      body,
    )

    return response.data
  }

  static async import(values, importHash) {
    const body = {
      data: values,
      importHash,
    }

    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.post(
      `/tenant/${tenantId}/tools-product-service/import`,
      body,
    )

    return response.data
  }

  static async find(id) {
    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.get(
      `/tenant/${tenantId}/tools-product-service/${id}`,
    )

    return response.data
  }

  static async list(filter, orderBy, limit, offset) {
    const researcherId = filter?.involved?.id
    if (researcherId) {
      filter.involved = researcherId
    }

    const params = {
      filter,
      orderBy,
      limit,
      offset,
    }

    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.get(
      `/tenant/${tenantId}/tools-product-service`,
      {
        params,
      },
    )

    return response.data
  }

  static async listAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    }

    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.get(
      `/tenant/${tenantId}/tools-product-service/autocomplete`,
      {
        params,
      },
    )

    return response.data
  }
}

import styled from 'styled-components'

const NavWrapper = styled.div`
  position: relative;
  padding: 0 1.875rem;
  border-bottom: 1px solid #dee2e6;
  min-height: 74px;
  z-index: 1000;

  .logo {
    max-height: 55px;
    width: auto;
    vertical-align: middle;
  }

  @media (max-width: 576px) {
    .i18n-select {
      display: none;
    }
  }

  .underline {
    text-decoration: underline;
  }

  ul {
    & li {
      display: flex;
    }
    & li:not(:last-child) {
      padding-right: 3.813rem;
    }
  }

  .nav-link {
    font-size: 0.9rem;
  }

  .badge {
    align-self: center;
    font-size: 9px;
    line-height: 11px;
    border-radius: 0;
    background-color: #2c8f8f;
    color: #fff;
    padding: 4px 7px;
  }

  .search-text {
    font-size: 0.9rem;
    padding: 0 8px;
  }

  .search {
    cursor: pointer;
  }

  .active {
    color: #2c8f8f !important;
    font-weight: bold;
  }

  .user-dropdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 6px;
    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
    user-select: none;
  }

  .dropdown-menu-right {
    right: 0.09rem;
    left: auto;
  }

  .disabled {
    cursor: not-allowed;
    pointer-events: none;
  }

  .nav-link {
    user-select: none;
  }

  .menu-toggle-button {
    display: block;
    border: none;
    background-color: transparent;
    font-size: 20px;
    transition: color 0.3s;
    color: rgba(0, 0, 0, 0.85);

    &:hover {
      color: var(--primary-color);
    }
  }
`

export default NavWrapper

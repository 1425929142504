import IntellectualPropertyService from 'src/modules/intellectualProperty/intellectualPropertyService'
import selectors from 'src/modules/intellectualProperty/list/intellectualPropertyListSelectors'
import exporterFields from 'src/modules/intellectualProperty/list/intellectualPropertyListExporterFields'
import { generateListActions } from '../../listHelpers'

const prefix = 'INTELLECTUALPROPERTY_LIST'

const intellectualPropertyListActions = generateListActions(
  prefix,
  IntellectualPropertyService.list,
  selectors,
  exporterFields,
  'startDate',
)

export default intellectualPropertyListActions

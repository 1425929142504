let inMemoryToken = null

export class AuthToken {
  static get() {
    return inMemoryToken || localStorage.getItem('jwt') || null
  }

  static set(token: string, rememberMe: boolean) {
    if (rememberMe) {
      inMemoryToken = null
      localStorage.setItem('jwt', token)
    } else {
      inMemoryToken = token
      localStorage.removeItem('jwt')
    }
  }

  static clear() {
    inMemoryToken = null
    localStorage.removeItem('jwt')
  }

  static applyFromLocationUrlIfExists() {
    const urlParams = new URLSearchParams(window.location.search)
    const authToken = urlParams.get('authToken')

    if (!authToken) {
      return
    }

    this.set(authToken, true)
    window.history.replaceState({}, document.title, window.location.origin)
  }
}

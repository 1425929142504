const toolsProductServiceEnumerators = {
  role: [
    'project_leader',
    'chief_investigator',
    'project_team_member',
    'associated_investigator',
    'consultant',
    'student',
    'advisor',
    'co_ordinator',
    'project_manager',
    'other',
  ],
  value: ['tool', 'product', 'service'],
  tool: [
    'algorithm',
    'animal_model',
    'computer_model',
    'data_analysis_technique',
    'educational_aid',
    'instruments_and_equipment',
    'software_mobile_app',
    'software_desktop',
    'deposited_data_set',
    'survey_instrument',
    'other_specify',
  ],
  product: [
    'software-mobile_app',
    'software-desktop',
    'software',
    'device',
    'diagnostics',
    'therapeutic',
    'prophylactic',
    'other-specify',
  ],
  service: ['profit', 'not_for_profit', 'in_kind', 'other-specify'],
  recipientType: [
    'government',
    'industry',
    'consumer_community',
    'non-government_organisation',
    'peak_body',
    'research_organisation',
    'education_organisation',
    'other_specify',
  ],
  scope: [
    'Perth-metro',
    'WA-state_wide',
    'National',
    'International',
    'WA-Gascoyne',
    'WA-Goldfields-Esperance',
    'WA-Great_Southern',
    'WA-Kimberley',
    'WA-Mid_West',
    'WA-Peel',
    'WA-Pilbara',
    'WA-South',
    'West',
    'WA-Wheatbelt',
    'National-NSW',
    'National-NT',
    'National-QLD',
    'National-SA',
    'National-TAS National-VIC',
    'International-Africa',
    'International-Asia',
    'International-Central America',
    'International-Eastern_Europe',
    'International-European_Union',
    'International-Middle_East',
    'International-North_America',
    'International-Oceania',
    'International-South_America',
    'International-Caribbean',
  ],
  status: ['draft', 'published', 'archived'],
  devStatus: ['concept', 'development', 'launched', 'prototype', 'other'],
  recordType: ['Academic', 'Professional'],
}

export default toolsProductServiceEnumerators

import OralPresentationService from 'src/modules/oralPresentation/oralPresentationService'
import selectors from 'src/modules/oralPresentation/list/oralPresentationListSelectors'
import exporterFields from 'src/modules/oralPresentation/list/oralPresentationListExporterFields'
import { generateListActions } from '../../listHelpers'

const prefix = 'ORALPRESENTATION_LIST'

const oralPresentationListActions = generateListActions(
  prefix,
  OralPresentationService.list,
  selectors,
  exporterFields,
  'year',
)

export default oralPresentationListActions

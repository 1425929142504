import importerActions from 'src/modules/shared/importer/importerActions'
import selectors from 'src/modules/inKindContribution/importer/inKindContributionImporterSelectors'
import InKindContributionService from 'src/modules/inKindContribution/inKindContributionService'
import fields from 'src/modules/inKindContribution/importer/inKindContributionImporterFields'
import { i18n } from 'src/i18n'

const inKindContributionImporterActions = importerActions(
  'INKINDCONTRIBUTION_IMPORTER',
  selectors,
  InKindContributionService.import,
  fields,
  i18n('entities.inKindContribution.importer.fileName'),
)

export default inKindContributionImporterActions

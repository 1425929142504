import Header from 'src/components/layout/Header'
import { useLocation } from 'react-router-dom'
import LayoutWrapper from 'src/components/layout/styles/LayoutWrapper'
import MainWrapper from 'src/components/layout/styles/MainWrapper'

function ReportLayout(props) {
  const { pathname: url } = useLocation()

  return (
    <LayoutWrapper>
      <Header />
      <div className="d-flex flex-row">
        <MainWrapper className="main">
          <div
            className={
              url === '/report' ? 'h-100 report-layout' : 'h-100 map-layout'
            }
          >
            {props.children}
          </div>
        </MainWrapper>
      </div>
    </LayoutWrapper>
  )
}

export default ReportLayout

import CurrentProjectService from 'src/modules/currentProject/currentProjectService'
import selectors from 'src/modules/currentProject/list/currentProjectListSelectors'
import exporterFields from 'src/modules/currentProject/list/currentProjectListExporterFields'
import { generateListActions } from '../../listHelpers'

const prefix = 'CURRENTPROJECT_LIST'

const currentProjectListActions = generateListActions(
  prefix,
  CurrentProjectService.list,
  selectors,
  exporterFields,
  'startDate',
)

export default currentProjectListActions

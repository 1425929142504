import list from 'src/modules/educationTrainingMaterial/list/educationTrainingMaterialListReducers'
import form from 'src/modules/educationTrainingMaterial/form/educationTrainingMaterialFormReducers'
import view from 'src/modules/educationTrainingMaterial/view/educationTrainingMaterialViewReducers'
import destroy from 'src/modules/educationTrainingMaterial/destroy/educationTrainingMaterialDestroyReducers'
import importerReducer from 'src/modules/educationTrainingMaterial/importer/educationTrainingMaterialImporterReducers'
import { combineReducers } from 'redux'

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
})

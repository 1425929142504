import schemas from 'src/modules/shared/yup/yupImporterSchemas'
import { i18n } from 'src/i18n'
import fundingEnumerators from 'src/modules/funding/fundingEnumerators'

export default [
  {
    name: 'title',
    label: i18n('entities.funding.fields.title'),
    schema: schemas.string(i18n('entities.funding.fields.title'), {
      required: true,
    }),
  },
  {
    name: 'researcher',
    label: i18n('entities.funding.fields.researcher'),
    schema: schemas.relationToOne(i18n('entities.funding.fields.researcher'), {
      required: true,
    }),
  },
  {
    name: 'role',
    label: i18n('entities.funding.fields.role'),
    schema: schemas.enumerator(i18n('entities.funding.fields.role'), {
      options: fundingEnumerators.role,
    }),
  },
  {
    name: 'fundingType',
    label: i18n('entities.funding.fields.fundingType'),
    schema: schemas.string(i18n('entities.funding.fields.fundingType'), {
      required: true,
    }),
  },
  {
    name: 'scheme',
    label: i18n('entities.funding.fields.scheme'),
    schema: schemas.string(i18n('entities.funding.fields.scheme'), {
      required: true,
    }),
  },
  {
    name: 'schemeType',
    label: i18n('entities.funding.fields.schemeType'),
    schema: schemas.string(i18n('entities.funding.fields.schemeType'), {
      required: true,
    }),
  },
  {
    name: 'organization',
    label: i18n('entities.funding.fields.organization'),
    schema: schemas.string(i18n('entities.funding.fields.organization'), {}),
  },
  {
    name: 'organizaionType',
    label: i18n('entities.funding.fields.organizaionType'),
    schema: schemas.string(i18n('entities.funding.fields.organizaionType'), {}),
  },
  {
    name: 'value',
    label: i18n('entities.funding.fields.value'),
    schema: schemas.string(i18n('entities.funding.fields.value'), {
      required: true,
    }),
  },
  {
    name: 'commencementYear',
    label: i18n('entities.funding.fields.commencementYear'),
    schema: schemas.string(i18n('entities.funding.fields.commencementYear'), {
      required: true,
    }),
  },
  {
    name: 'startDate',
    label: i18n('entities.funding.fields.startDate'),
    schema: schemas.datetime(i18n('entities.funding.fields.startDate'), {
      required: true,
    }),
  },
  {
    name: 'endDate',
    label: i18n('entities.funding.fields.endDate'),
    schema: schemas.datetime(i18n('entities.funding.fields.endDate'), {}),
  },
  {
    name: 'projectId',
    label: i18n('entities.funding.fields.projectId'),
    schema: schemas.relationToMany(
      i18n('entities.funding.fields.projectId'),
      {},
    ),
  },
  {
    name: 'isPrivate',
    label: i18n('entities.funding.fields.isPrivate'),
    schema: schemas.boolean(i18n('entities.funding.fields.isPrivate')),
  },
  {
    name: 'files',
    label: i18n('entities.funding.fields.files'),
    schema: schemas.files(i18n('entities.funding.fields.files'), {}),
  },
  {
    name: 'images',
    label: i18n('entities.funding.fields.images'),
    schema: schemas.images(i18n('entities.funding.fields.images'), {}),
  },
  {
    name: 'status',
    label: i18n('entities.funding.fields.status'),
    schema: schemas.enumerator(i18n('entities.funding.fields.status'), {
      required: true,
      options: fundingEnumerators.status,
    }),
  },
  {
    name: 'recordType',
    label: i18n('entities.funding.fields.recordType'),
    schema: schemas.enumerator(i18n('entities.funding.fields.recordType'), {
      options: fundingEnumerators.recordType,
    }),
  },
  {
    name: 'year',
    label: i18n('entities.funding.fields.year'),
    schema: schemas.integer(i18n('entities.funding.fields.year'), {}),
  },
  {
    name: 'collaborationPartnerId',
    label: i18n('entities.funding.fields.collaborationPartnerId'),
    schema: schemas.relationToOne(
      i18n('entities.funding.fields.collaborationPartnerId'),
      {},
    ),
  },
  {
    name: 'potential',
    label: i18n('entities.funding.fields.potential'),
    schema: schemas.boolean(i18n('entities.funding.fields.potential')),
  },
]

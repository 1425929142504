import importerActions from 'src/modules/shared/importer/importerActions'
import selectors from 'src/modules/editorialReviewerResponsibility/importer/editorialReviewerResponsibilityImporterSelectors'
import EditorialReviewerResponsibilityService from 'src/modules/editorialReviewerResponsibility/editorialReviewerResponsibilityService'
import fields from 'src/modules/editorialReviewerResponsibility/importer/editorialReviewerResponsibilityImporterFields'
import { i18n } from 'src/i18n'

const editorialReviewerResponsibilityImporterActions = importerActions(
  'EDITORIALREVIEWERRESPONSIBILITY_IMPORTER',
  selectors,
  EditorialReviewerResponsibilityService.import,
  fields,
  i18n('entities.editorialReviewerResponsibility.importer.fileName'),
)

export default editorialReviewerResponsibilityImporterActions

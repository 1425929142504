import importerActions from 'src/modules/shared/importer/importerActions'
import selectors from 'src/modules/guidelinePolicyPractice/importer/guidelinePolicyPracticeImporterSelectors'
import GuidelinePolicyPracticeService from 'src/modules/guidelinePolicyPractice/guidelinePolicyPracticeService'
import fields from 'src/modules/guidelinePolicyPractice/importer/guidelinePolicyPracticeImporterFields'
import { i18n } from 'src/i18n'

const guidelinePolicyPracticeImporterActions = importerActions(
  'GUIDELINEPOLICYPRACTICE_IMPORTER',
  selectors,
  GuidelinePolicyPracticeService.import,
  fields,
  i18n('entities.guidelinePolicyPractice.importer.fileName'),
)

export default guidelinePolicyPracticeImporterActions

const reportEnumerators = {
  recipient: [
    'government',
    'industry',
    'consumer_community',
    'non_government_organisation',
    'peak_body',
    'research_organisation',
    'education_organisation',
    'other',
  ],
  commissioned: ['yes', 'no'],
  city: ['other_specify'],
  status: ['draft', 'published', 'archived'],
  recordType: ['Academic', 'Professional'],
  role: [
    'project_leader',
    'chief_investigator',
    'project_team_member',
    'associated_investigator',
    'consultant',
    'student',
    'advisor',
    'co_ordinator',
    'project_manager',
    'other',
  ],
}

export default reportEnumerators

import list from 'src/modules/currentProject/list/currentProjectListReducers'
import form from 'src/modules/currentProject/form/currentProjectFormReducers'
import view from 'src/modules/currentProject/view/currentProjectViewReducers'
import destroy from 'src/modules/currentProject/destroy/currentProjectDestroyReducers'
import importerReducer from 'src/modules/currentProject/importer/currentProjectImporterReducers'
import { combineReducers } from 'redux'

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
})

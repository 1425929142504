import { Navigate, useLocation } from 'react-router-dom'
import PermissionChecker from 'src/modules/auth/permissionChecker'
import Layout from 'src/components/layout/Layout'
import ReportLayout from 'src/components/layout/ReportLayout'
import { AuthToken } from '../../modules/auth/authToken'
import { useSelector } from 'react-redux'
import selectors from 'src/modules/admin/imactCategories/list/impactCategoriesListSelectors'

function getMenuItem(menuItems, location) {
  if (menuItems?.length) {
    return menuItems.find(
      (item) =>
        (item.type === 'indicator' || item.type === 'library') &&
        item.path === location,
    )
  }
  return null
}

function PrivateRoute(props: any) {
  const {
    component: Component,
    currentTenant,
    currentUser,
    logoutAction,
    permissionRequired,
    fullLayout,
    logoutPath,
    ...rest
  } = props

  const location = useLocation()
  const FullLayout = fullLayout ? ReportLayout : Layout
  const permissionChecker = new PermissionChecker(currentTenant, currentUser)
  const menuItems = useSelector(selectors.selectMenuItems)
  const menuItem = getMenuItem(
    menuItems,
    `/${location?.pathname?.split('/')[1]}`,
  )

  if (!permissionChecker.isAuthenticated) {
    if (AuthToken.get()) {
      return null
    }

    return (
      <Navigate
        to={{
          pathname: '/auth/signin',
          search: location.pathname ? '?redirect=' + location.pathname : '',
        }}
        state={{ from: location }}
      />
    )
  }

  if (permissionChecker.isEmptyPermissions) {
    return <Navigate to="/auth/empty-permissions" />
  }

  if (!permissionChecker.match(permissionRequired)) {
    return (
      <Navigate
        to={{
          pathname: '/error',
        }}
        state={{
          errorCode: 403,
        }}
      />
    )
  }

  if (menuItem && !menuItem?.enabled) {
    return (
      <Navigate
        to={{
          pathname: '/error',
        }}
        state={{
          errorCode: 403,
          notEnabled: true,
        }}
      />
    )
  }

  if (fullLayout === undefined) {
    return <Component {...props} />
  }

  return (
    <FullLayout {...props}>
      <Component {...props} />
    </FullLayout>
  )
}

export default PrivateRoute

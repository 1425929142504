import * as toastr from 'toastr'
import 'toastr/build/toastr.css'

export default class Message {
  static success(arg) {
    // must be changed change because Bootstrap UI
    // css overrides the style
    toastr.options.toastClass = 'toastr'
    toastr.options.positionClass = 'toast-bottom-left'

    toastr.success(arg)
  }

  static error(arg) {
    // must be changed change because Bootstrap UI
    // css overrides the style
    toastr.options.toastClass = 'toastr'
    toastr.options.positionClass = 'toast-bottom-left'

    toastr.error(arg)
  }
  static info(arg) {
    // must be changed change because Bootstrap UI
    // css overrides the style
    toastr.options.toastClass = 'toastr'
    toastr.options.positionClass = 'toast-bottom-left'

    toastr.info(arg)
  }
  static warning(arg) {
    // must be changed change because Bootstrap UI
    // css overrides the style
    toastr.options.toastClass = 'toastr'
    toastr.options.positionClass = 'toast-bottom-left'
    toastr.warning(arg)
  }
}

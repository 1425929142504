import schemas from 'src/modules/shared/yup/yupImporterSchemas'
import { i18n } from 'src/i18n'
import communityConsumerLiteratureEnumerators from 'src/modules/communityConsumerLiterature/communityConsumerLiteratureEnumerators'

export default [
  {
    name: 'title',
    label: i18n('entities.communityConsumerLiterature.fields.title'),
    schema: schemas.string(
      i18n('entities.communityConsumerLiterature.fields.title'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'researcher',
    label: i18n('entities.communityConsumerLiterature.fields.researcher'),
    schema: schemas.relationToOne(
      i18n('entities.communityConsumerLiterature.fields.researcher'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'role',
    label: i18n('entities.communityConsumerLiterature.fields.role'),
    schema: schemas.enumerator(
      i18n('entities.communityConsumerLiterature.fields.role'),
      {
        options: communityConsumerLiteratureEnumerators.role,
      },
    ),
  },
  {
    name: 'year',
    label: i18n('entities.communityConsumerLiterature.fields.year'),
    schema: schemas.integer(
      i18n('entities.communityConsumerLiterature.fields.year'),
      {},
    ),
  },
  {
    name: 'publicationType',
    label: i18n('entities.communityConsumerLiterature.fields.publicationType'),
    schema: schemas.enumerator(
      i18n('entities.communityConsumerLiterature.fields.publicationType'),
      {
        required: true,
        options: communityConsumerLiteratureEnumerators.publicationType,
      },
    ),
  },
  {
    name: 'recipientType',
    label: i18n('entities.communityConsumerLiterature.fields.recipientType'),
    schema: schemas.enumerator(
      i18n('entities.communityConsumerLiterature.fields.recipientType'),
      {
        required: true,
        options: communityConsumerLiteratureEnumerators.recipientType,
      },
    ),
  },
  {
    name: 'description',
    label: i18n('entities.communityConsumerLiterature.fields.description'),
    schema: schemas.string(
      i18n('entities.communityConsumerLiterature.fields.description'),
      {
        max: 1000,
      },
    ),
  },
  {
    name: 'impact',
    label: i18n('entities.communityConsumerLiterature.fields.impact'),
    schema: schemas.string(
      i18n('entities.communityConsumerLiterature.fields.impact'),
      {},
    ),
  },
  {
    name: 'files',
    label: i18n('entities.communityConsumerLiterature.fields.files'),
    schema: schemas.files(
      i18n('entities.communityConsumerLiterature.fields.files'),
      {},
    ),
  },
  {
    name: 'images',
    label: i18n('entities.communityConsumerLiterature.fields.images'),
    schema: schemas.images(
      i18n('entities.communityConsumerLiterature.fields.images'),
      {},
    ),
  },
  {
    name: 'status',
    label: i18n('entities.communityConsumerLiterature.fields.status'),
    schema: schemas.enumerator(
      i18n('entities.communityConsumerLiterature.fields.status'),
      {
        required: true,
        options: communityConsumerLiteratureEnumerators.status,
      },
    ),
  },
  {
    name: 'recordType',
    label: i18n('entities.communityConsumerLiterature.fields.recordType'),
    schema: schemas.enumerator(
      i18n('entities.communityConsumerLiterature.fields.recordType'),
      {
        options: communityConsumerLiteratureEnumerators.recordType,
      },
    ),
  },
]

import importerActions from 'src/modules/shared/importer/importerActions'
import selectors from 'src/modules/researchTeam/importer/researchTeamImporterSelectors'
import ResearchTeamService from 'src/modules/researchTeam/researchTeamService'
import fields from 'src/modules/researchTeam/importer/researchTeamImporterFields'
import { i18n } from 'src/i18n'

const researchTeamImporterActions = importerActions(
  'RESEARCHTEAM_IMPORTER',
  selectors,
  ResearchTeamService.import,
  fields,
  i18n('entities.researchTeam.importer.fileName'),
)

export default researchTeamImporterActions

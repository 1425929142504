import { i18n } from 'src/i18n'
import exporterRenders from 'src/modules/shared/exporter/exporterRenders'

export default [
  {
    name: 'id',
    label: i18n('entities.collaborationPartnership.fields.id'),
  },
  {
    name: 'role',
    label: i18n('entities.collaborationPartnership.fields.role'),
  },
  {
    name: 'researcher',
    label: i18n('entities.collaborationPartnership.fields.researcher'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'organization',
    label: i18n('entities.collaborationPartnership.fields.organization'),
  },
  {
    name: 'organizationType',
    label: i18n('entities.collaborationPartnership.fields.organizationType'),
  },
  {
    name: 'department',
    label: i18n('entities.collaborationPartnership.fields.department'),
  },
  {
    name: 'country',
    label: i18n('entities.collaborationPartnership.fields.country'),
  },
  {
    name: 'city',
    label: i18n('entities.collaborationPartnership.fields.city'),
  },
  {
    name: 'startDate',
    label: i18n('entities.collaborationPartnership.fields.startDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'endDate',
    label: i18n('entities.collaborationPartnership.fields.endDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'files',
    label: i18n('entities.collaborationPartnership.fields.files'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'images',
    label: i18n('entities.collaborationPartnership.fields.images'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'author',
    label: i18n('entities.collaborationPartnership.fields.author'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'dateCreated',
    label: i18n('entities.collaborationPartnership.fields.dateCreated'),
  },
  {
    name: 'status',
    label: i18n('entities.collaborationPartnership.fields.status'),
  },
  {
    name: 'title',
    label: i18n('entities.collaborationPartnership.fields.title'),
  },
  {
    name: 'researchers',
    label: i18n('entities.collaborationPartnership.fields.researchers'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'recordType',
    label: i18n('entities.collaborationPartnership.fields.recordType'),
  },
  {
    name: 'year',
    label: i18n('entities.collaborationPartnership.fields.year'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.collaborationPartnership.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.collaborationPartnership.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
]

import MenuBlock, { MenuOrdering } from './menuBlock'

interface ResearchMenuProps {
  menuItems: any[]
  selectedKey: string
  match(any): boolean
}

function ResearchMenu({ menuItems, selectedKey, match }: ResearchMenuProps) {
  const availableItems = menuItems.filter(
    (menu) =>
      !menu.area &&
      menu.type === 'library' &&
      menu.enabled &&
      match(menu.permissionRequired),
  )

  return (
    <MenuBlock
      titleKey="impactCategories.libraries"
      availableItems={availableItems}
      selectedKey={selectedKey}
      ordering={MenuOrdering.Alphabetical}
    />
  )
}

export default ResearchMenu

import list from 'src/modules/intellectualProperty/list/intellectualPropertyListReducers'
import form from 'src/modules/intellectualProperty/form/intellectualPropertyFormReducers'
import view from 'src/modules/intellectualProperty/view/intellectualPropertyViewReducers'
import destroy from 'src/modules/intellectualProperty/destroy/intellectualPropertyDestroyReducers'
import importerReducer from 'src/modules/intellectualProperty/importer/intellectualPropertyImporterReducers'
import { combineReducers } from 'redux'

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
})

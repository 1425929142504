import { createSelector } from 'reselect'

const selectRaw = (state) => state.organisationStructure.list

const selectHierarchies = createSelector([selectRaw], (raw) => raw)

const selectHierarchyValues = (id) =>
  createSelector([selectRaw], (raw) => raw[id])

const selectLoading = createSelector([selectRaw], (raw) => raw.loading)

const selectResponse = createSelector([selectRaw], (raw) => raw.response)

const selectFlatHierarchies = createSelector([selectRaw], (raw) =>
  raw.flatHierarchies.response ? raw.flatHierarchies.response : [],
)

const organisationStructureListSelectors = {
  selectHierarchies,
  selectHierarchyValues,
  selectLoading,
  selectResponse,
  selectFlatHierarchies,
}

export default organisationStructureListSelectors

export default class FormErrors {
  static errorMessage(
    name,
    errors,
    touched,
    isSubmitted,
    externalErrorMessage = null,
  ) {
    if (externalErrorMessage) {
      return externalErrorMessage
    }

    if (name.split('.').length > 1) {
      const split = name.split('.')
      const group = split[0]
      const index = +split[1].charAt(1)
      const field = split[2]

      if (!isSubmitted && !touched[group]) {
        return null
      }

      const fieldErrors = errors[group]?.[index]?.[field]

      return fieldErrors?.message || fieldErrors || null
    }

    if (!isSubmitted && !touched[name]) {
      return null
    }

    const fieldErrors = errors[name]

    return (
      fieldErrors?.[0]?.message || fieldErrors?.message || fieldErrors || null
    )
  }
}

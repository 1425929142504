import schemas from 'src/modules/shared/yup/yupImporterSchemas'
import { i18n } from 'src/i18n'
import inKindContributionEnumerators from 'src/modules/inKindContribution/inKindContributionEnumerators'

export default [
  {
    name: 'title',
    label: i18n('entities.inKindContribution.fields.title'),
    schema: schemas.string(i18n('entities.inKindContribution.fields.title'), {
      required: true,
    }),
  },
  {
    name: 'organization',
    label: i18n('entities.inKindContribution.fields.organization'),
    schema: schemas.string(
      i18n('entities.inKindContribution.fields.organization'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'startDate',
    label: i18n('entities.inKindContribution.fields.startDate'),
    schema: schemas.datetime(
      i18n('entities.inKindContribution.fields.startDate'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'endDate',
    label: i18n('entities.inKindContribution.fields.endDate'),
    schema: schemas.datetime(
      i18n('entities.inKindContribution.fields.endDate'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'description',
    label: i18n('entities.inKindContribution.fields.description'),
    schema: schemas.string(
      i18n('entities.inKindContribution.fields.description'),
      {
        max: 1000,
      },
    ),
  },
  {
    name: 'value',
    label: i18n('entities.inKindContribution.fields.value'),
    schema: schemas.string(i18n('entities.inKindContribution.fields.value'), {
      required: true,
    }),
  },
  {
    name: 'files',
    label: i18n('entities.inKindContribution.fields.files'),
    schema: schemas.files(i18n('entities.inKindContribution.fields.files'), {}),
  },
  {
    name: 'images',
    label: i18n('entities.inKindContribution.fields.images'),
    schema: schemas.images(
      i18n('entities.inKindContribution.fields.images'),
      {},
    ),
  },
  {
    name: 'status',
    label: i18n('entities.inKindContribution.fields.status'),
    schema: schemas.enumerator(
      i18n('entities.inKindContribution.fields.status'),
      {
        required: true,
        options: inKindContributionEnumerators.status,
      },
    ),
  },
  {
    name: 'researchers',
    label: i18n('entities.inKindContribution.fields.researchers'),
    schema: schemas.relationToMany(
      i18n('entities.inKindContribution.fields.researchers'),
      {},
    ),
  },
  {
    name: 'recordType',
    label: i18n('entities.inKindContribution.fields.recordType'),
    schema: schemas.enumerator(
      i18n('entities.inKindContribution.fields.recordType'),
      {
        options: inKindContributionEnumerators.recordType,
      },
    ),
  },
  {
    name: 'year',
    label: i18n('entities.inKindContribution.fields.year'),
    schema: schemas.integer(
      i18n('entities.inKindContribution.fields.year'),
      {},
    ),
  },
]

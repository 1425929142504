import { useEffect, useState } from 'react'
import { i18n } from 'src/i18n'
import styled from 'styled-components'
import ButtonIcon from 'src/components/ButtonIcon'
import { useDispatch, useSelector } from 'react-redux'
import GlobalFilterEditWrapper from 'src/components/globalFilter/styles/Edit'
import GlobalFilterActions from 'src/modules/GlobalFilter/GlobalFilterActions'
import GlobalFilterSelectors from 'src/modules/GlobalFilter/GlobalFilterSelectors'
import OrganisationStructureListSelectors from 'src/modules/admin/OrganisationStructure/list/OrganisationStructureListSelectors'
import OrganisationStructureListActions from 'src/modules/admin/OrganisationStructure/list/OrganisationStructureListActions'
import { InputFormItem } from 'src/components/formFields/Input'
import { FormProvider, useForm } from 'react-hook-form'
import yupFormSchemas from 'src/modules/shared/yup/yupFormSchemas'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { flattenArray } from '../../helpers/functions'
import OrganisationStructureService from 'src/modules/admin/OrganisationStructure/OrganisationStructureService'

const HoverButtonFactory = (
  color,
  hoverColor,
  focusColor,
  textColor?,
  borderColor?,
) => {
  return styled.button`
    background: ${color};
    margin-right: 12px;
    transition: all 300ms;
    color: ${textColor && textColor.length > 0
      ? textColor
      : '#ffffff'} !important;
    border: 1px solid ${borderColor} !important;
    &:hover {
      background: ${hoverColor};
      color: ${textColor && textColor.length > 0 ? textColor : '#ffffff'};
      border-color: ${borderColor};
      cursor: pointer;
    }

    &:focus {
      background: ${focusColor};
    }

    & a {
      color: ${textColor};
    }

    & a:hover {
      text-decoration: none;
    }
  `
}

const schema = yup.object().shape({
  globalFilterInput: yupFormSchemas.string('Global Filter Input'),
})

const initialValues = { globalFilterInput: '' }

const SaveButton = HoverButtonFactory(
  '#A81662',
  '#62002A',
  '#62002A',
  '#ffffff',
)

const CancelButton = HoverButtonFactory(
  '#f2f2f2',
  '#f2f2f2',
  '#f2f2f2',
  '#333',
  '#f2f2f2',
)

export default function (props) {
  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: initialValues,
  })

  const dispatch = useDispatch()
  const [userOrgStructure, setUserOrgStructure] = useState(null)
  const [units, setUnits] = useState([])

  useEffect(() => {
    if (
      props.user &&
      props.user.orgStructure &&
      props.user.orgStructure.length > 0
    ) {
      const flatOrgStructure = flattenArray(props.user.orgStructure)
      const idsArray = flatOrgStructure.map((value) => value.id)
      setUserOrgStructure(idsArray)
    } else {
      setUserOrgStructure(null)
    }
  }, [props.user])

  useEffect(() => {
    if (props.displayList) {
      dispatch(OrganisationStructureListActions.doFetchFlat(false))
    }
    return () => {
      dispatch(GlobalFilterActions.doSelectActiveHierarchy(null))
      dispatch(GlobalFilterActions.doSelectActiveHierarchyValue(null))
    }
  }, [dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    async function fetchUnits() {
      let units = await OrganisationStructureService.getFlatHierarchyUnits()
      setUnits(units.rows)
    }

    fetchUnits().catch((error) => console.error(error))
  }, [])

  const onReset = () => {
    dispatch(GlobalFilterActions.doReset([]))
  }

  const activeHierarchy = useSelector(
    GlobalFilterSelectors.selectActiveHierarchy,
  )

  const activeHierarchyValue = useSelector(
    GlobalFilterSelectors.selectActiveHierarchyValue,
  )
  const activeHierarchyValues = useSelector(
    GlobalFilterSelectors.selectActiveHierarchyValues,
  )

  const selectedHierarchyValues = useSelector(
    GlobalFilterSelectors.selectSelectedHierarchyValues,
  )

  const activeSelectedHierarchyValue = useSelector(
    GlobalFilterSelectors.selectActiveSelectedHierarchyValue,
  )

  const getActiveHierarchy = () => {
    let active = ''
    let parent = null
    if (activeHierarchyValue || activeSelectedHierarchyValue) {
      let activeHierarchy = activeHierarchyValue
        ? activeHierarchyValue
        : activeSelectedHierarchyValue
      activeHierarchy = activeHierarchy.hierarchyValue
      active = activeHierarchy.name
      if (activeHierarchy.parentId) {
        parent = activeHierarchy.parent
        active = `${activeHierarchy.parent.name} > ${active}`
        if (parent.parentId) {
          while (parent) {
            let parentId = parent.parentId
            parent = units.find((o) => {
              if (o.id === parentId) {
                return true
              } else {
                return null
              }
            })
            active = `${parent.name} > ${active}`
            if (!parent.parentId) {
              parent = null
            }
          }
        }
      }
    }

    return active
  }

  const [filteredUnitValues, setFilteredUnitValues] = useState(
    activeHierarchyValues,
  )

  const hierarchyValuesPayload = useSelector(
    OrganisationStructureListSelectors.selectHierarchyValues(
      activeHierarchy && activeHierarchy.id,
    ),
  )

  const selectHierarchy = (hierarchy) => {
    dispatch(GlobalFilterActions.doSelectActiveHierarchy(hierarchy))
    dispatch(OrganisationStructureListActions.doFetchValues(hierarchy.id))
  }

  const selectHierarchyValue = (hierarchyValue, e) => {
    if (e.detail === 1) {
      dispatch(GlobalFilterActions.doSelectActiveHierarchyValue(hierarchyValue))
    } else {
      if (activeHierarchy) {
        dispatch(
          GlobalFilterActions.doAddHierarchyValues([activeHierarchyValue]),
        )
      }
    }
  }

  const selectSelectedHierarchyValue = (hierarchyValue, e) => {
    if (e.detail === 1) {
      dispatch(
        GlobalFilterActions.doSelectActiveSelectedHierarchyValue(
          hierarchyValue,
        ),
      )
    } else {
      if (activeSelectedHierarchyValue)
        dispatch(
          GlobalFilterActions.doRemoveHierarchyValue(
            activeSelectedHierarchyValue,
          ),
        )
    }
  }

  useEffect(() => {
    activeHierarchyValues &&
      activeHierarchyValues.length > 0 &&
      setFilteredUnitValues(activeHierarchyValues)
  }, [activeHierarchyValues])

  const onBulkAdd = () => {
    if (activeHierarchy) {
      const updatedActiveHierarchyValues = activeHierarchyValues.map(
        (value) => ({ ...value, id: null }),
      )
      dispatch(
        GlobalFilterActions.doAddHierarchyValues(updatedActiveHierarchyValues),
      )
    }
  }

  const onAdd = () => {
    if (activeHierarchyValue) {
      activeHierarchyValue.id = null
      dispatch(GlobalFilterActions.doAddHierarchyValues([activeHierarchyValue]))
    }
  }

  const onRemove = () => {
    if (activeSelectedHierarchyValue)
      dispatch(
        GlobalFilterActions.doRemoveHierarchyValue(
          activeSelectedHierarchyValue,
        ),
      )
  }

  const doFilter = () => {
    dispatch(GlobalFilterActions.doFilter())
    props.toggleEdit()
  }

  const doFilterUnit = (e) => {
    if (activeHierarchyValues && activeHierarchyValues.length > 0 && e) {
      const regularExpression = new RegExp(`.*${e.trim()}.*`, 'i')
      setFilteredUnitValues(
        activeHierarchyValues.filter((item) =>
          regularExpression.test(item.hierarchyValue.name),
        ),
      )
    } else {
      setFilteredUnitValues(activeHierarchyValues)
    }
  }

  useEffect(() => {
    if (
      activeHierarchy &&
      hierarchyValuesPayload &&
      hierarchyValuesPayload.rows
    ) {
      const restrictedHierarchyValues = hierarchyValuesPayload.rows.filter(
        (hierarchyValue) => {
          return Boolean(
            userOrgStructure.find(
              (orgStructure) => orgStructure === hierarchyValue.id,
            ),
          )
        },
      )
      dispatch(
        GlobalFilterActions.doInitializeHierarchyValues(
          restrictedHierarchyValues
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((hierarchyValue) => ({
              hierarchy: activeHierarchy,
              hierarchyValue,
            })),
        ),
      )
    }
  }, [dispatch, hierarchyValuesPayload]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <GlobalFilterEditWrapper>
      <div className="content">
        <div className="box-container">
          <div className="container-title">Level</div>
          <div className="box">
            {props.hierarchies.map((hierarchy) => (
              <div
                className={`d-flex align-items-center justify-content-between item-container ${
                  activeHierarchy &&
                  activeHierarchy.id === hierarchy.id &&
                  'active-selection'
                }`}
                onClick={() => selectHierarchy(hierarchy)}
                key={hierarchy.uuid}
              >
                <span>{hierarchy.name}</span>
                {activeHierarchy && activeHierarchy.id === hierarchy.id && (
                  <i className="fas fa-angle-right pr-2 arrow-right" />
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="box-container box-container-unit">
          <div className="container-title">Unit</div>
          <div className="input-container">
            <FormProvider {...form}>
              <form onSubmit={() => {}}>
                <InputFormItem
                  name="globalFilterInput"
                  onChange={(e) => doFilterUnit(e)}
                />
                <i className="fas fa-search search-icon" />
              </form>
            </FormProvider>
          </div>
          <div className="box">
            {activeHierarchyValues.length > 0 &&
              filteredUnitValues.map((h) => (
                <div
                  className={`d-flex align-items-center justify-content-between item-container ${
                    activeHierarchyValue &&
                    activeHierarchyValue.hierarchyValue.id ===
                      h.hierarchyValue.id &&
                    'active-selection'
                  }`}
                  onClick={(e) => selectHierarchyValue(h, e)}
                  key={h.hierarchyValue.uuid}
                >
                  <span>{h.hierarchyValue.name}</span>
                  {activeHierarchyValue &&
                    activeHierarchyValue.hierarchyValue.id ===
                      h.hierarchyValue.id && (
                      <i className="fas fa-angle-right pr-2 arrow-right" />
                    )}
                </div>
              ))}
          </div>
        </div>
        <div className="button-column-container">
          <ButtonIcon
            iconClass="fas fa-angle-double-right"
            color={`${!activeHierarchy ? '#C6CBCB' : '#a81662'}`}
            onClick={onBulkAdd}
          />
          <ButtonIcon
            iconClass="fas fa-angle-right"
            onClick={onAdd}
            color={`${!activeHierarchyValue ? '#C6CBCB' : '#a81662'}`}
          />
          <ButtonIcon
            iconClass="fas fa-angle-left"
            onClick={onRemove}
            color={`${!activeSelectedHierarchyValue ? '#C6CBCB' : '#a81662'}`}
          />
        </div>
        <div className="box-container box-container-selection">
          <div className="container-title">Selections</div>
          <div className="box">
            {selectedHierarchyValues.length > 0 &&
              selectedHierarchyValues.map((h) => (
                <div
                  className={`d-flex align-items-center justify-content-between item-container ${
                    activeSelectedHierarchyValue &&
                    activeSelectedHierarchyValue.hierarchyValue.id ===
                      h.hierarchyValue.id &&
                    'active-selection'
                  }`}
                  onClick={(e) => selectSelectedHierarchyValue(h, e)}
                  key={h.hierarchyValue.uuid}
                >
                  {`${h.hierarchy.name} > ${h.hierarchyValue.name}`}
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="active-hierarchy">{getActiveHierarchy()}</div>
      <div className="footer">
        <div className="button-group">
          <CancelButton
            type="button"
            className="btn btn-light"
            onClick={onReset}
          >
            <ButtonIcon color="#333" iconClass="fas fa-redo-alt" />
            {i18n(`common.reset`)}
          </CancelButton>
          <SaveButton
            type="button"
            className="btn btn-light"
            onClick={doFilter}
          >
            <ButtonIcon color="#ffffff" iconClass="fas fa-bookmark" />
            {'Filter'}
          </SaveButton>
        </div>
      </div>
    </GlobalFilterEditWrapper>
  )
}

import { i18n } from 'src/i18n'
import exporterRenders from 'src/modules/shared/exporter/exporterRenders'

export default [
  {
    name: 'id',
    label: i18n('entities.intellectualProperty.fields.id'),
  },
  {
    name: 'title',
    label: i18n('entities.intellectualProperty.fields.title'),
  },
  {
    name: 'ipType',
    label: i18n('entities.intellectualProperty.fields.ipType'),
  },
  {
    name: 'authors',
    label: i18n('entities.intellectualProperty.fields.authors'),
  },
  {
    name: 'researcher',
    label: i18n('entities.intellectualProperty.fields.researcher'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'contribution',
    label: i18n('entities.intellectualProperty.fields.contribution'),
  },
  {
    name: 'identifier',
    label: i18n('entities.intellectualProperty.fields.identifier'),
  },
  {
    name: 'organization',
    label: i18n('entities.intellectualProperty.fields.organization'),
  },
  {
    name: 'organizationLead',
    label: i18n('entities.intellectualProperty.fields.organizationLead'),
  },
  {
    name: 'stage',
    label: i18n('entities.intellectualProperty.fields.stage'),
  },
  {
    name: 'status2',
    label: i18n('entities.intellectualProperty.fields.status2'),
  },
  {
    name: 'jurisdiction',
    label: i18n('entities.intellectualProperty.fields.jurisdiction'),
  },
  {
    name: 'startDate',
    label: i18n('entities.intellectualProperty.fields.startDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'filedDate',
    label: i18n('entities.intellectualProperty.fields.filedDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'grantedDate',
    label: i18n('entities.intellectualProperty.fields.grantedDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'licenseOrganisation',
    label: i18n('entities.intellectualProperty.fields.licenseOrganisation'),
  },
  {
    name: 'assignedOrganisation',
    label: i18n('entities.intellectualProperty.fields.assignedOrganisation'),
  },
  {
    name: 'description',
    label: i18n('entities.intellectualProperty.fields.description'),
  },
  {
    name: 'impact',
    label: i18n('entities.intellectualProperty.fields.impact'),
  },
  {
    name: 'year',
    label: i18n('entities.intellectualProperty.fields.year'),
  },
  {
    name: 'files',
    label: i18n('entities.intellectualProperty.fields.files'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'images',
    label: i18n('entities.intellectualProperty.fields.images'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'author',
    label: i18n('entities.intellectualProperty.fields.author'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'dateCreated',
    label: i18n('entities.intellectualProperty.fields.dateCreated'),
  },
  {
    name: 'status',
    label: i18n('entities.intellectualProperty.fields.status'),
  },
  {
    name: 'recordType',
    label: i18n('entities.intellectualProperty.fields.recordType'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.intellectualProperty.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.intellectualProperty.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
]

import ExportHistoryService from '../service'
import selectors from 'src/modules/search/exportHistory/list/selectors'
import { generateListActions } from '../../../listHelpers'

const prefix = 'REPORT_EXPORT_HISTORY'

const actions = generateListActions(
  prefix,
  ExportHistoryService.list,
  selectors,
  null,
  'createdAt',
)

export default actions

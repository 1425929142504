const publicationEnumerators = {
  status: ['draft', 'published', 'archived'],
  recordType: ['Academic', 'Professional'],
  publicationType: [
    'book',
    'book_chapter',
    'peer_reviewed',
    'journal_article',
    'journal_editorial',
    'journal_letter',
    'journal_case_study',
    'journal_comment',
    'non_peer_reviewed_journal_article',
    'review',
    'conference_proceedings',
    'other_specify',
  ],
}

export default publicationEnumerators

const educationTrainingMaterialEnumerators = {
  role: ['editor', 'guest_editor', 'reviewer'],
  status: ['draft', 'published', 'archived'],
  recordType: ['Academic', 'Professional'],
  purpose: [
    'academic',
    'technical_technology',
    'service_delivery',
    'other_specify',
  ],
  materialType: [
    'instructional_video',
    'manual',
    'product_guide',
    'professional_practice_guide',
    'online_training_course',
    'syllabus',
    'tutorial',
    'networking_learning_tool',
    'other_specify',
  ],
  organisationType: [
    'industry',
    'consumer_community',
    'government',
    'non_government',
    'academic_MRIs',
    'aboriginal_focused_organisation',
  ],
}

export default educationTrainingMaterialEnumerators

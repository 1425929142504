import schemas from 'src/modules/shared/yup/yupImporterSchemas'
import { i18n } from 'src/i18n'
import consumerCommunityInvolvementEnumerators from 'src/modules/consumerCommunityInvolvement/consumerCommunityInvolvementEnumerators'

export default [
  {
    name: 'status',
    label: i18n('entities.consumerCommunityInvolvement.fields.status'),
    schema: schemas.enumerator(
      i18n('entities.consumerCommunityInvolvement.fields.status'),
      {
        required: true,
        options: consumerCommunityInvolvementEnumerators.status,
      },
    ),
  },
  {
    name: 'title',
    label: i18n('entities.consumerCommunityInvolvement.fields.title'),
    schema: schemas.string(
      i18n('entities.consumerCommunityInvolvement.fields.title'),
      {},
    ),
  },
  {
    name: 'recordType',
    label: i18n('entities.consumerCommunityInvolvement.fields.recordType'),
    schema: schemas.enumerator(
      i18n('entities.consumerCommunityInvolvement.fields.recordType'),
      {
        options: consumerCommunityInvolvementEnumerators.recordType,
      },
    ),
  },
  {
    name: 'year',
    label: i18n('entities.consumerCommunityInvolvement.fields.year'),
    schema: schemas.integer(
      i18n('entities.consumerCommunityInvolvement.fields.year'),
      {},
    ),
  },
  {
    name: 'startDate',
    label: i18n('entities.consumerCommunityInvolvement.fields.startDate'),
    schema: schemas.datetime(
      i18n('entities.consumerCommunityInvolvement.fields.startDate'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'description',
    label: i18n('entities.consumerCommunityInvolvement.fields.description'),
    schema: schemas.string(
      i18n('entities.consumerCommunityInvolvement.fields.description'),
      {
        max: 1000,
      },
    ),
  },
  {
    name: 'impact',
    label: i18n('entities.consumerCommunityInvolvement.fields.impact'),
    schema: schemas.string(
      i18n('entities.consumerCommunityInvolvement.fields.impact'),
      {
        max: 1000,
      },
    ),
  },
  {
    name: 'endDate',
    label: i18n('entities.consumerCommunityInvolvement.fields.endDate'),
    schema: schemas.datetime(
      i18n('entities.consumerCommunityInvolvement.fields.endDate'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'frequency',
    label: i18n('entities.consumerCommunityInvolvement.fields.frequency'),
    schema: schemas.enumerator(
      i18n('entities.consumerCommunityInvolvement.fields.frequency'),
      {
        required: true,
        options: consumerCommunityInvolvementEnumerators.frequency,
      },
    ),
  },
  {
    name: 'groupSize',
    label: i18n('entities.consumerCommunityInvolvement.fields.groupSize'),
    schema: schemas.string(
      i18n('entities.consumerCommunityInvolvement.fields.groupSize'),
      {},
    ),
  },
  {
    name: 'involvementType',
    label: i18n('entities.consumerCommunityInvolvement.fields.involvementType'),
    schema: schemas.boolean(
      i18n('entities.consumerCommunityInvolvement.fields.involvementType'),
    ),
  },
  {
    name: 'organization',
    label: i18n('entities.consumerCommunityInvolvement.fields.organization'),
    schema: schemas.string(
      i18n('entities.consumerCommunityInvolvement.fields.organization'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'researcher',
    label: i18n('entities.consumerCommunityInvolvement.fields.researcher'),
    schema: schemas.relationToOne(
      i18n('entities.consumerCommunityInvolvement.fields.researcher'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'files',
    label: i18n('entities.consumerCommunityInvolvement.fields.files'),
    schema: schemas.files(
      i18n('entities.consumerCommunityInvolvement.fields.files'),
      {},
    ),
  },
  {
    name: 'images',
    label: i18n('entities.consumerCommunityInvolvement.fields.images'),
    schema: schemas.images(
      i18n('entities.consumerCommunityInvolvement.fields.images'),
      {},
    ),
  },
]

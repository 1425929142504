import styled from 'styled-components'

const GlobalFilterHeaderWrapper = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1020px;
  padding: 10px 15px;

  & h5 {
    color: #fff !important;
    overflow: hidden;
    font-size: 1rem;
    white-space: nowrap;
    line-height: 1.6;
    text-overflow: ellipsis;
    margin-bottom: 0;
    margin-right: 1rem;
  }
`

export default GlobalFilterHeaderWrapper

import { useSelector } from 'react-redux'
import { TenantMode } from 'src/helpers/types/TenantModes'
import authSelectors from 'src/modules/auth/authSelectors'
import Permissions from 'src/security/permissions'
const permissions = Permissions.values

const usePrivateRoutes = () => {
  const currentTenant = useSelector(authSelectors.selectCurrentTenant)
  const mode =
    currentTenant?.mode === TenantMode.Research ? currentTenant?.mode : 'map'
  const privateRoutes = [
    {
      path: '/profile',
      loader: () => import('src/pages/auth/profile'),
      permissionRequired: null,
      exact: true,
    },

    {
      path: '/password-change',
      loader: () => import('src/pages/auth/passwordChange'),
      permissionRequired: null,
      exact: true,
    },

    {
      path: '/tenant',
      loader: () => import('src/pages/tenant'),
      permissionRequired: null,
      exact: true,
    },
    {
      path: '/tenant/new',
      loader: () => import('src/pages/tenant/form'),
      permissionRequired: null,
      exact: true,
    },
    {
      path: '/tenant/:id/edit',
      loader: () => import('src/pages/tenant/form'),
      permissionRequired: null,
      exact: true,
    },
    {
      path: '/admin/groups',
      loader: () => import('src/pages/admin/groups/list/GroupsListPage'),
      permissionRequired: [
        permissions.groupCreate,
        permissions.groupEdit,
        permissions.groupDestroy,
      ],
      exact: true,
    },

    {
      path: '/admin/groups/new',
      loader: () => import('src/pages/admin/groups/form/GroupsFormPage'),
      permissionRequired: permissions.groupCreate,
      exact: true,
    },

    {
      path: '/admin/groups/:id',
      loader: () => import('src/pages/admin/groups/view/GroupsViewPage'),
      permissionRequired: permissions.groupRead,
      exact: true,
    },

    {
      path: '/admin/groups/:id/edit',
      loader: () => import('src/pages/admin/groups/form/GroupsFormPage'),
      permissionRequired: permissions.groupEdit,
      exact: true,
    },

    {
      path: '/admin/user',
      loader: () => import('src/pages/admin/user/list/UserPage'),
      permissionRequired: [
        permissions.userCreate,
        permissions.userEdit,
        permissions.userDestroy,
        permissions,
      ],
      exact: true,
    },

    {
      path: '/admin/user/new',
      loader: () => import('src/pages/admin/user/new/UserNewPage'),
      permissionRequired: permissions.userCreate,
      exact: true,
    },

    {
      path: '/admin/user/importer',
      loader: () => import('src/pages/admin/user/importer/UserImporterPage'),
      permissionRequired: permissions.userImport,
      exact: true,
    },
    {
      path: '/admin/user/:id/edit',
      loader: () => import('src/pages/admin/user/edit/UserEditPage'),
      permissionRequired: permissions.userEdit,
      exact: true,
    },
    {
      path: '/admin/user/:id',
      loader: () => import('src/pages/admin/user/view/UserViewPage'),
      permissionRequired: permissions.userRead,
      exact: true,
    },

    {
      path: '/admin/orgstructure/',
      loader: () =>
        import(
          'src/pages/admin/organizationStructure/list/StructureHierarchyPage'
        ),
      permissionRequired: [
        permissions.hierarchyEdit,
        permissions.hierarchyDestroy,
      ],
      exact: true,
    },

    {
      path: '/admin/orgstructure/manageunits',
      loader: () =>
        import(
          'src/pages/admin/organizationStructure/list/OrgStructureListPage'
        ),
      permissionRequired: [
        permissions.hierarchyEdit,
        permissions.hierarchyDestroy,
      ],
      exact: true,
    },
    {
      path: '/admin/impactcategories/',
      loader: () =>
        import(
          'src/pages/admin/impactCategories/list/ImpactCategoriesListPage'
        ),
      permissionRequired: permissions.impactCategoryManage,
      exact: true,
    },

    {
      path: '/admin/impactcategories/edit',
      loader: () =>
        import(
          'src/pages/admin/impactCategories/edit/ImpactCategoriesEditPage'
        ),
      permissionRequired: permissions.impactCategoryManage,
      exact: true,
    },

    {
      path: '/admin/audit-logs',
      loader: () => import('src/pages/admin/auditLog/AuditLogPage'),
      permissionRequired: permissions.auditLogRead,
    },

    {
      path: '/admin/settings',
      loader: () => import('src/pages/admin/settings'),
      permissionRequired: permissions.settingsEdit,
    },

    {
      path: '/admin/integrations',
      loader: () =>
        import('src/pages/admin/integrations/list/IntegrationsListPage'),
      permissionRequired: [
        permissions.integrationCreate,
        permissions.integrationEdit,
        permissions.integrationDestroy,
      ],
      exact: true,
    },
    {
      path: '/admin/integrations/new',
      loader: () =>
        import('src/pages/admin/integrations/form/IntegrationsFormPage'),
      permissionRequired: permissions.integrationRead,
      exact: true,
    },
    {
      path: '/admin/integrations/:id/edit',
      loader: () =>
        import('src/pages/admin/integrations/form/IntegrationsFormPage'),
      permissionRequired: permissions.integrationEdit,
      exact: true,
    },
    {
      path: '/admin/integrations/:id',
      loader: () =>
        import('src/pages/admin/integrations/form/IntegrationsFormPage'),
      permissionRequired: permissions.integrationEdit,
      exact: true,
    },
    {
      path: '/research-team',
      loader: () => import('src/pages/collect/researchTeam'),
      permissionRequired: permissions.researchTeamRead,
      exact: true,
    },
    {
      path: '/research-team/new',
      loader: () => import('src/pages/collect/researchTeam/form'),
      permissionRequired: permissions.researchTeamCreate,
      exact: true,
    },
    {
      path: '/research-team/importer',
      loader: () => import('src/pages/collect/researchTeam/importer'),
      permissionRequired: permissions.researchTeamImport,
      exact: true,
    },
    {
      path: '/research-team/:id/edit',
      loader: () => import('src/pages/collect/researchTeam/form'),
      permissionRequired: permissions.researchTeamEdit,
      exact: true,
    },
    {
      path: '/research-team/:id',
      loader: () => import('src/pages/collect/researchTeam/view'),
      permissionRequired: permissions.researchTeamRead,
      exact: true,
    },

    {
      path: '/research-themes-intended-impact',
      loader: () => import('src/pages/collect/researchThemesIntendedImpact'),
      permissionRequired: permissions.researchThemesIntendedImpactRead,
      exact: true,
    },
    {
      path: '/research-themes-intended-impact/new',
      loader: () =>
        import('src/pages/collect/researchThemesIntendedImpact/form'),
      permissionRequired: permissions.researchThemesIntendedImpactCreate,
      exact: true,
    },
    {
      path: '/research-themes-intended-impact/importer',
      loader: () =>
        import('src/pages/collect/researchThemesIntendedImpact/importer'),
      permissionRequired: permissions.researchThemesIntendedImpactImport,
      exact: true,
    },
    {
      path: '/research-themes-intended-impact/:id/edit',
      loader: () =>
        import('src/pages/collect/researchThemesIntendedImpact/form'),
      permissionRequired: permissions.researchThemesIntendedImpactEdit,
      exact: true,
    },
    {
      path: '/research-themes-intended-impact/:id',
      loader: () =>
        import('src/pages/collect/researchThemesIntendedImpact/view'),
      permissionRequired: permissions.researchThemesIntendedImpactRead,
      exact: true,
    },

    {
      path: '/current-project',
      loader: () => import('src/pages/collect/currentProject'),
      permissionRequired: permissions.currentProjectRead,
      exact: true,
    },
    {
      path: '/current-project/new',
      loader: () => import('src/pages/collect/currentProject/form'),
      permissionRequired: permissions.currentProjectCreate,
      exact: true,
    },
    {
      path: '/current-project/importer',
      loader: () => import('src/pages/collect/currentProject/importer'),
      permissionRequired: permissions.currentProjectImport,
      exact: true,
    },
    {
      path: '/current-project/:id/edit',
      loader: () => import('src/pages/collect/currentProject/form'),
      permissionRequired: permissions.currentProjectEdit,
      exact: true,
    },
    {
      path: '/current-project/:id',
      loader: () => import('src/pages/collect/currentProject/view'),
      permissionRequired: permissions.currentProjectRead,
      exact: true,
    },

    {
      path: '/collaboration-partnership',
      loader: () => import('src/pages/collect/collaborationPartnership'),
      permissionRequired: permissions.collaborationPartnershipRead,
      exact: true,
    },
    {
      path: '/collaboration-partnership/new',
      loader: () => import('src/pages/collect/collaborationPartnership/form'),
      permissionRequired: permissions.collaborationPartnershipCreate,
      exact: true,
    },
    {
      path: '/collaboration-partnership/importer',
      loader: () =>
        import('src/pages/collect/collaborationPartnership/importer'),
      permissionRequired: permissions.collaborationPartnershipImport,
      exact: true,
    },
    {
      path: '/collaboration-partnership/:id/edit',
      loader: () => import('src/pages/collect/collaborationPartnership/form'),
      permissionRequired: permissions.collaborationPartnershipEdit,
      exact: true,
    },
    {
      path: '/collaboration-partnership/:id',
      loader: () => import('src/pages/collect/collaborationPartnership/view'),
      permissionRequired: permissions.collaborationPartnershipRead,
      exact: true,
    },

    {
      path: '/external-decision-making-advisory-group',
      loader: () =>
        import('src/pages/collect/externalDecisionMakingAdvisoryGroup'),
      permissionRequired: permissions.externalDecisionMakingAdvisoryGroupRead,
      exact: true,
    },
    {
      path: '/external-decision-making-advisory-group/new',
      loader: () =>
        import('src/pages/collect/externalDecisionMakingAdvisoryGroup/form'),
      permissionRequired: permissions.externalDecisionMakingAdvisoryGroupCreate,
      exact: true,
    },
    {
      path: '/external-decision-making-advisory-group/importer',
      loader: () =>
        import(
          'src/pages/collect/externalDecisionMakingAdvisoryGroup/importer'
        ),
      permissionRequired: permissions.externalDecisionMakingAdvisoryGroupImport,
      exact: true,
    },
    {
      path: '/external-decision-making-advisory-group/:id/edit',
      loader: () =>
        import('src/pages/collect/externalDecisionMakingAdvisoryGroup/form'),
      permissionRequired: permissions.externalDecisionMakingAdvisoryGroupEdit,
      exact: true,
    },
    {
      path: '/external-decision-making-advisory-group/:id',
      loader: () =>
        import('src/pages/collect/externalDecisionMakingAdvisoryGroup/view'),
      permissionRequired: permissions.externalDecisionMakingAdvisoryGroupRead,
      exact: true,
    },

    {
      path: '/providing-external-expertise',
      loader: () => import('src/pages/collect/providingExternalExpertise'),
      permissionRequired: permissions.providingExternalExpertiseRead,
      exact: true,
    },
    {
      path: '/providing-external-expertise/new',
      loader: () => import('src/pages/collect/providingExternalExpertise/form'),
      permissionRequired: permissions.providingExternalExpertiseCreate,
      exact: true,
    },
    {
      path: '/providing-external-expertise/importer',
      loader: () =>
        import('src/pages/collect/providingExternalExpertise/importer'),
      permissionRequired: permissions.providingExternalExpertiseImport,
      exact: true,
    },
    {
      path: '/providing-external-expertise/:id/edit',
      loader: () => import('src/pages/collect/providingExternalExpertise/form'),
      permissionRequired: permissions.providingExternalExpertiseEdit,
      exact: true,
    },
    {
      path: '/providing-external-expertise/:id',
      loader: () => import('src/pages/collect/providingExternalExpertise/view'),
      permissionRequired: permissions.providingExternalExpertiseRead,
      exact: true,
    },

    {
      path: '/collaborative-network',
      loader: () => import('src/pages/collect/collaborativeNetwork'),
      permissionRequired: permissions.collaborativeNetworkRead,
      exact: true,
    },
    {
      path: '/collaborative-network/new',
      loader: () => import('src/pages/collect/collaborativeNetwork/form'),
      permissionRequired: permissions.collaborativeNetworkCreate,
      exact: true,
    },
    {
      path: '/collaborative-network/importer',
      loader: () => import('src/pages/collect/collaborativeNetwork/importer'),
      permissionRequired: permissions.collaborativeNetworkImport,
      exact: true,
    },
    {
      path: '/collaborative-network/:id/edit',
      loader: () => import('src/pages/collect/collaborativeNetwork/form'),
      permissionRequired: permissions.collaborativeNetworkEdit,
      exact: true,
    },
    {
      path: '/collaborative-network/:id',
      loader: () => import('src/pages/collect/collaborativeNetwork/view'),
      permissionRequired: permissions.collaborativeNetworkRead,
      exact: true,
    },

    {
      path: '/guideline-policy-practice',
      loader: () => import('src/pages/collect/guidelinePolicyPractice'),
      permissionRequired: permissions.guidelinePolicyPracticeRead,
      exact: true,
    },
    {
      path: '/guideline-policy-practice/new',
      loader: () => import('src/pages/collect/guidelinePolicyPractice/form'),
      permissionRequired: permissions.guidelinePolicyPracticeCreate,
      exact: true,
    },
    {
      path: '/guideline-policy-practice/importer',
      loader: () =>
        import('src/pages/collect/guidelinePolicyPractice/importer'),
      permissionRequired: permissions.guidelinePolicyPracticeImport,
      exact: true,
    },
    {
      path: '/guideline-policy-practice/:id/edit',
      loader: () => import('src/pages/collect/guidelinePolicyPractice/form'),
      permissionRequired: permissions.guidelinePolicyPracticeEdit,
      exact: true,
    },
    {
      path: '/guideline-policy-practice/:id',
      loader: () => import('src/pages/collect/guidelinePolicyPractice/view'),
      permissionRequired: permissions.guidelinePolicyPracticeRead,
      exact: true,
    },

    {
      path: '/consumer-community-involvement',
      loader: () => import('src/pages/collect/consumerCommunityInvolvement'),
      permissionRequired: permissions.consumerCommunityInvolvementRead,
      exact: true,
    },
    {
      path: '/consumer-community-involvement/new',
      loader: () =>
        import('src/pages/collect/consumerCommunityInvolvement/form'),
      permissionRequired: permissions.consumerCommunityInvolvementCreate,
      exact: true,
    },
    {
      path: '/consumer-community-involvement/importer',
      loader: () =>
        import('src/pages/collect/consumerCommunityInvolvement/importer'),
      permissionRequired: permissions.consumerCommunityInvolvementImport,
      exact: true,
    },
    {
      path: '/consumer-community-involvement/:id/edit',
      loader: () =>
        import('src/pages/collect/consumerCommunityInvolvement/form'),
      permissionRequired: permissions.consumerCommunityInvolvementEdit,
      exact: true,
    },
    {
      path: '/consumer-community-involvement/:id',
      loader: () =>
        import('src/pages/collect/consumerCommunityInvolvement/view'),
      permissionRequired: permissions.consumerCommunityInvolvementRead,
      exact: true,
    },

    {
      path: '/oral-presentation',
      loader: () => import('src/pages/collect/oralPresentation'),
      permissionRequired: permissions.oralPresentationRead,
      exact: true,
    },
    {
      path: '/oral-presentation/new',
      loader: () => import('src/pages/collect/oralPresentation/form'),
      permissionRequired: permissions.oralPresentationCreate,
      exact: true,
    },
    {
      path: '/oral-presentation/importer',
      loader: () => import('src/pages/collect/oralPresentation/importer'),
      permissionRequired: permissions.oralPresentationImport,
      exact: true,
    },
    {
      path: '/oral-presentation/:id/edit',
      loader: () => import('src/pages/collect/oralPresentation/form'),
      permissionRequired: permissions.oralPresentationEdit,
      exact: true,
    },
    {
      path: '/oral-presentation/:id',
      loader: () => import('src/pages/collect/oralPresentation/view'),
      permissionRequired: permissions.oralPresentationRead,
      exact: true,
    },

    {
      path: '/report',
      loader: () => import('src/pages/search'),
      permissionRequired: permissions.reportRead,
      exact: true,
      fullLayout: true,
    },
    {
      path: '/report/history',
      loader: () => import('src/pages/search/exportHistory/ExportHistoryPage'),
      permissionRequired: permissions.reportRead,
      exact: true,
    },
    {
      path: '/map',
      loader: () => import(`src/pages/${mode}/pages/list`),
      permissionRequired: permissions.pathwayView,
      exact: true,
      fullLayout: true,
    },
    {
      path: '/map/pathways/new',
      loader: () => import(`src/pages/${mode}/pages/new`),
      permissionRequired: permissions.pathwayCreate,
      exact: true,
      fullLayout: true,
    },
    {
      path: '/map/pathways/:id/update',
      loader: () => import(`src/pages/${mode}/pages/edit`),
      permissionRequired: [
        permissions.pathwayView,
        permissions.pathwayEdit,
        permissions.pathwayDelete,
      ],
      exact: true,
      fullLayout: true,
    },
    {
      path: '/reports',
      loader: () => import('src/pages/collect/report'),
      permissionRequired: permissions.reportRead,
      exact: true,
    },
    {
      path: '/reports/new',
      loader: () => import('src/pages/collect/report/form'),
      permissionRequired: permissions.reportCreate,
      exact: true,
    },
    {
      path: '/reports/importer',
      loader: () => import('src/pages/collect/report/importer'),
      permissionRequired: permissions.reportImport,
      exact: true,
    },
    {
      path: '/report/:id/edit',
      loader: () => import('src/pages/collect/report/form'),
      permissionRequired: permissions.reportEdit,
      exact: true,
    },
    {
      path: '/report/:id',
      loader: () => import('src/pages/collect/report/view'),
      permissionRequired: permissions.reportRead,
      exact: true,
    },

    {
      path: '/tools-product-service',
      loader: () => import('src/pages/collect/toolsProductService'),
      permissionRequired: permissions.toolsProductServiceRead,
      exact: true,
    },
    {
      path: '/tools-product-service/new',
      loader: () => import('src/pages/collect/toolsProductService/form'),
      permissionRequired: permissions.toolsProductServiceCreate,
      exact: true,
    },
    {
      path: '/tools-product-service/importer',
      loader: () => import('src/pages/collect/toolsProductService/importer'),
      permissionRequired: permissions.toolsProductServiceImport,
      exact: true,
    },
    {
      path: '/tools-product-service/:id/edit',
      loader: () => import('src/pages/collect/toolsProductService/form'),
      permissionRequired: permissions.toolsProductServiceEdit,
      exact: true,
    },
    {
      path: '/tools-product-service/:id',
      loader: () => import('src/pages/collect/toolsProductService/view'),
      permissionRequired: permissions.toolsProductServiceRead,
      exact: true,
    },

    {
      path: '/award-prizes',
      loader: () => import('src/pages/collect/awardPrizes'),
      permissionRequired: permissions.awardPrizesRead,
      exact: true,
    },
    {
      path: '/award-prizes/new',
      loader: () => import('src/pages/collect/awardPrizes/form'),
      permissionRequired: permissions.awardPrizesCreate,
      exact: true,
    },
    {
      path: '/award-prizes/importer',
      loader: () => import('src/pages/collect/awardPrizes/importer'),
      permissionRequired: permissions.awardPrizesImport,
      exact: true,
    },
    {
      path: '/award-prizes/:id/edit',
      loader: () => import('src/pages/collect/awardPrizes/form'),
      permissionRequired: permissions.awardPrizesEdit,
      exact: true,
    },
    {
      path: '/award-prizes/:id',
      loader: () => import('src/pages/collect/awardPrizes/view'),
      permissionRequired: permissions.awardPrizesRead,
      exact: true,
    },

    {
      path: '/mentoring-supervision',
      loader: () => import('src/pages/collect/mentoringSupervision'),
      permissionRequired: permissions.mentoringSupervisionRead,
      exact: true,
    },
    {
      path: '/mentoring-supervision/new',
      loader: () => import('src/pages/collect/mentoringSupervision/form'),
      permissionRequired: permissions.mentoringSupervisionCreate,
      exact: true,
    },
    {
      path: '/mentoring-supervision/importer',
      loader: () => import('src/pages/collect/mentoringSupervision/importer'),
      permissionRequired: permissions.mentoringSupervisionImport,
      exact: true,
    },
    {
      path: '/mentoring-supervision/:id/edit',
      loader: () => import('src/pages/collect/mentoringSupervision/form'),
      permissionRequired: permissions.mentoringSupervisionEdit,
      exact: true,
    },
    {
      path: '/mentoring-supervision/:id',
      loader: () => import('src/pages/collect/mentoringSupervision/view'),
      permissionRequired: permissions.mentoringSupervisionRead,
      exact: true,
    },

    {
      path: '/case-study',
      loader: () => import('src/pages/collect/caseStudy'),
      permissionRequired: permissions.caseStudyRead,
      exact: true,
    },
    {
      path: '/case-study/new',
      loader: () => import('src/pages/collect/caseStudy/form/'),
      permissionRequired: permissions.caseStudyCreate,
      exact: true,
    },
    {
      path: '/case-study/importer',
      loader: () => import('src/pages/collect/caseStudy/importer'),
      permissionRequired: permissions.caseStudyImport,
      exact: true,
    },
    {
      path: '/case-study/:id/edit',
      loader: () => import('src/pages/collect/caseStudy/form'),
      permissionRequired: permissions.caseStudyEdit,
      exact: true,
    },
    {
      path: '/case-study/:id',
      loader: () => import('src/pages/collect/caseStudy/view'),
      permissionRequired: permissions.caseStudyRead,
      exact: true,
    },

    {
      path: '/community-consumer-literature',
      loader: () => import('src/pages/collect/communityConsumerLiterature'),
      permissionRequired: permissions.communityConsumerLiteratureRead,
      exact: true,
    },
    {
      path: '/community-consumer-literature/new',
      loader: () =>
        import('src/pages/collect/communityConsumerLiterature/form'),
      permissionRequired: permissions.communityConsumerLiteratureCreate,
      exact: true,
    },
    {
      path: '/community-consumer-literature/importer',
      loader: () =>
        import('src/pages/collect/communityConsumerLiterature/importer'),
      permissionRequired: permissions.communityConsumerLiteratureImport,
      exact: true,
    },
    {
      path: '/community-consumer-literature/:id/edit',
      loader: () =>
        import('src/pages/collect/communityConsumerLiterature/form'),
      permissionRequired: permissions.communityConsumerLiteratureEdit,
      exact: true,
    },
    {
      path: '/community-consumer-literature/:id',
      loader: () =>
        import('src/pages/collect/communityConsumerLiterature/view'),
      permissionRequired: permissions.communityConsumerLiteratureRead,
      exact: true,
    },

    {
      path: '/funding',
      loader: () => import('src/pages/collect/funding'),
      permissionRequired: permissions.fundingRead,
      exact: true,
    },
    {
      path: '/funding/new',
      loader: () => import('src/pages/collect/funding/form'),
      permissionRequired: permissions.fundingCreate,
      exact: true,
    },
    {
      path: '/funding/importer',
      loader: () => import('src/pages/collect/funding/importer'),
      permissionRequired: permissions.fundingImport,
      exact: true,
    },
    {
      path: '/funding/:id/edit',
      loader: () => import('src/pages/collect/funding/form'),
      permissionRequired: permissions.fundingEdit,
      exact: true,
    },
    {
      path: '/funding/:id',
      loader: () => import('src/pages/collect/funding/view'),
      permissionRequired: permissions.fundingRead,
      exact: true,
    },

    {
      path: '/barriers-support',
      loader: () => import('src/pages/collect/barriersSupport'),
      permissionRequired: permissions.barriersSupportRead,
      exact: true,
    },
    {
      path: '/barriers-support/new',
      loader: () => import('src/pages/collect/barriersSupport/form'),
      permissionRequired: permissions.barriersSupportCreate,
      exact: true,
    },
    {
      path: '/barriers-support/importer',
      loader: () => import('src/pages/collect/barriersSupport/importer'),
      permissionRequired: permissions.barriersSupportImport,
      exact: true,
    },
    {
      path: '/barriers-support/:id/edit',
      loader: () => import('src/pages/collect/barriersSupport/form'),
      permissionRequired: permissions.barriersSupportEdit,
      exact: true,
    },
    {
      path: '/barriers-support/:id',
      loader: () => import('src/pages/collect/barriersSupport/view'),
      permissionRequired: permissions.barriersSupportRead,
      exact: true,
    },

    {
      path: '/editorial-reviewer-responsibility',
      loader: () => import('src/pages/collect/editorialReviewerResponsibility'),
      permissionRequired: permissions.editorialReviewerResponsibilityRead,
      exact: true,
    },
    {
      path: '/editorial-reviewer-responsibility/new',
      loader: () =>
        import('src/pages/collect/editorialReviewerResponsibility/form'),
      permissionRequired: permissions.editorialReviewerResponsibilityCreate,
      exact: true,
    },
    {
      path: '/editorial-reviewer-responsibility/importer',
      loader: () =>
        import('src/pages/collect/editorialReviewerResponsibility/importer'),
      permissionRequired: permissions.editorialReviewerResponsibilityImport,
      exact: true,
    },
    {
      path: '/editorial-reviewer-responsibility/:id/edit',
      loader: () =>
        import('src/pages/collect/editorialReviewerResponsibility/form'),
      permissionRequired: permissions.editorialReviewerResponsibilityEdit,
      exact: true,
    },
    {
      path: '/editorial-reviewer-responsibility/:id',
      loader: () =>
        import('src/pages/collect/editorialReviewerResponsibility/view'),
      permissionRequired: permissions.editorialReviewerResponsibilityRead,
      exact: true,
    },

    {
      path: '/education-training-material',
      loader: () => import('src/pages/collect/educationTrainingMaterial'),
      permissionRequired: permissions.educationTrainingMaterialRead,
      exact: true,
    },
    {
      path: '/education-training-material/new',
      loader: () => import('src/pages/collect/educationTrainingMaterial/form'),
      permissionRequired: permissions.educationTrainingMaterialCreate,
      exact: true,
    },
    {
      path: '/education-training-material/importer',
      loader: () =>
        import('src/pages/collect/educationTrainingMaterial/importer'),
      permissionRequired: permissions.educationTrainingMaterialImport,
      exact: true,
    },
    {
      path: '/education-training-material/:id/edit',
      loader: () => import('src/pages/collect/educationTrainingMaterial/form'),
      permissionRequired: permissions.educationTrainingMaterialEdit,
      exact: true,
    },
    {
      path: '/education-training-material/:id',
      loader: () => import('src/pages/collect/educationTrainingMaterial/view'),
      permissionRequired: permissions.educationTrainingMaterialRead,
      exact: true,
    },

    {
      path: '/intervention-study',
      loader: () => import('src/pages/collect/interventionStudy'),
      permissionRequired: permissions.interventionStudyRead,
      exact: true,
    },
    {
      path: '/intervention-study/new',
      loader: () => import('src/pages/collect/interventionStudy/form'),
      permissionRequired: permissions.interventionStudyCreate,
      exact: true,
    },
    {
      path: '/intervention-study/importer',
      loader: () => import('src/pages/collect/interventionStudy/importer'),
      permissionRequired: permissions.interventionStudyImport,
      exact: true,
    },
    {
      path: '/intervention-study/:id/edit',
      loader: () => import('src/pages/collect/interventionStudy/form'),
      permissionRequired: permissions.interventionStudyEdit,
      exact: true,
    },
    {
      path: '/intervention-study/:id',
      loader: () => import('src/pages/collect/interventionStudy/view'),
      permissionRequired: permissions.interventionStudyRead,
      exact: true,
    },

    {
      path: '/in-kind-contribution',
      loader: () => import('src/pages/collect/inKindContribution'),
      permissionRequired: permissions.inKindContributionRead,
      exact: true,
    },
    {
      path: '/in-kind-contribution/new',
      loader: () => import('src/pages/collect/inKindContribution/form'),
      permissionRequired: permissions.inKindContributionCreate,
      exact: true,
    },
    {
      path: '/in-kind-contribution/importer',
      loader: () => import('src/pages/collect/inKindContribution/importer'),
      permissionRequired: permissions.inKindContributionImport,
      exact: true,
    },
    {
      path: '/in-kind-contribution/:id/edit',
      loader: () => import('src/pages/collect/inKindContribution/form'),
      permissionRequired: permissions.inKindContributionEdit,
      exact: true,
    },
    {
      path: '/in-kind-contribution/:id',
      loader: () => import('src/pages/collect/inKindContribution/view'),
      permissionRequired: permissions.inKindContributionRead,
      exact: true,
    },

    {
      path: '/media-mention',
      loader: () => import('src/pages/collect/mediaMention'),
      permissionRequired: permissions.mediaMentionRead,
      exact: true,
    },
    {
      path: '/media-mention/new',
      loader: () => import('src/pages/collect/mediaMention/form'),
      permissionRequired: permissions.mediaMentionCreate,
      exact: true,
    },
    {
      path: '/media-mention/importer',
      loader: () => import('src/pages/collect/mediaMention/importer'),
      permissionRequired: permissions.mediaMentionImport,
      exact: true,
    },
    {
      path: '/media-mention/:id/edit',
      loader: () => import('src/pages/collect/mediaMention/form'),
      permissionRequired: permissions.mediaMentionEdit,
      exact: true,
    },
    {
      path: '/media-mention/:id',
      loader: () => import('src/pages/collect/mediaMention/view'),
      permissionRequired: permissions.mediaMentionRead,
      exact: true,
    },
    {
      path: '/publication',
      loader: () => import('src/pages/collect/publication'),
      permissionRequired: permissions.publicationRead,
      exact: true,
    },
    {
      path: '/publication/new',
      loader: () => import('src/pages/collect/publication/form'),
      permissionRequired: permissions.publicationCreate,
      exact: true,
    },
    {
      path: '/publication/orcid/:orcidId/import',
      loader: () => import('src/pages/collect/publication/form'),
      permissionRequired: permissions.publicationCreate,
      exact: true,
    },
    {
      path: '/publication/importer',
      loader: () => import('src/pages/collect/publication/importer'),
      permissionRequired: permissions.publicationImport,
      exact: true,
    },
    {
      path: '/publication/orcid-importer',
      loader: () => import('src/pages/collect/publication/importer/orcid'),
      permissionRequired: permissions.publicationImport,
      exact: true,
    },
    {
      path: '/publication/:id/edit',
      loader: () => import('src/pages/collect/publication/form'),
      permissionRequired: permissions.publicationEdit,
      exact: true,
    },
    {
      path: '/publication/:id',
      loader: () => import('src/pages/collect/publication/view'),
      permissionRequired: permissions.publicationRead,
      exact: true,
    },

    {
      path: '/testimonial',
      loader: () => import('src/pages/collect/testimonial'),
      permissionRequired: permissions.testimonialRead,
      exact: true,
    },
    {
      path: '/testimonial/new',
      loader: () => import('src/pages/collect/testimonial/form'),
      permissionRequired: permissions.testimonialCreate,
      exact: true,
    },
    {
      path: '/testimonial/importer',
      loader: () => import('src/pages/collect/testimonial/importer'),
      permissionRequired: permissions.testimonialImport,
      exact: true,
    },
    {
      path: '/testimonial/:id/edit',
      loader: () => import('src/pages/collect/testimonial/form'),
      permissionRequired: permissions.testimonialEdit,
      exact: true,
    },
    {
      path: '/testimonial/:id',
      loader: () => import('src/pages/collect/testimonial/view'),
      permissionRequired: permissions.testimonialRead,
      exact: true,
    },

    {
      path: '/workshop-training-facilitated',
      loader: () => import('src/pages/collect/workshopTrainingFacilitated'),
      permissionRequired: permissions.workshopTrainingFacilitatedRead,
      exact: true,
    },
    {
      path: '/workshop-training-facilitated/new',
      loader: () =>
        import('src/pages/collect/workshopTrainingFacilitated/form'),
      permissionRequired: permissions.workshopTrainingFacilitatedCreate,
      exact: true,
    },
    {
      path: '/workshop-training-facilitated/importer',
      loader: () =>
        import('src/pages/collect/workshopTrainingFacilitated/importer'),
      permissionRequired: permissions.workshopTrainingFacilitatedImport,
      exact: true,
    },
    {
      path: '/workshop-training-facilitated/:id/edit',
      loader: () =>
        import('src/pages/collect/workshopTrainingFacilitated/form'),
      permissionRequired: permissions.workshopTrainingFacilitatedEdit,
      exact: true,
    },
    {
      path: '/workshop-training-facilitated/:id',
      loader: () =>
        import('src/pages/collect/workshopTrainingFacilitated/view'),
      permissionRequired: permissions.workshopTrainingFacilitatedRead,
      exact: true,
    },

    {
      path: '/intellectual-property',
      loader: () => import('src/pages/collect/intellectualProperty'),
      permissionRequired: permissions.intellectualPropertyRead,
      exact: true,
    },
    {
      path: '/intellectual-property/new',
      loader: () => import('src/pages/collect/intellectualProperty/form'),
      permissionRequired: permissions.intellectualPropertyCreate,
      exact: true,
    },
    {
      path: '/intellectual-property/importer',
      loader: () => import('src/pages/collect/intellectualProperty/importer'),
      permissionRequired: permissions.intellectualPropertyImport,
      exact: true,
    },
    {
      path: '/intellectual-property/:id/edit',
      loader: () => import('src/pages/collect/intellectualProperty/form'),
      permissionRequired: permissions.intellectualPropertyEdit,
      exact: true,
    },
    {
      path: '/intellectual-property/:id',
      loader: () => import('src/pages/collect/intellectualProperty/view'),
      permissionRequired: permissions.intellectualPropertyRead,
      exact: true,
    },
    {
      path: '/orcid/confirmation',
      loader: () => import('src/pages/orcid'),
      exact: true,
    },
  ].filter(Boolean)

  return privateRoutes
}

export default usePrivateRoutes

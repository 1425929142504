import { Navigate } from 'react-router-dom'

function AdminRoute(props: any) {
  const { currentUserRoles } = props

  if (currentUserRoles.includes('growImpact')) {
    return <Navigate to="/admin/user" />
  }

  if (
    currentUserRoles.includes('manageUser') ||
    currentUserRoles.includes('viewUser')
  ) {
    return <Navigate to="/admin/user" />
  }

  if (currentUserRoles.includes('manageGroup')) {
    return <Navigate to="/admin/groups" />
  }

  if (currentUserRoles.includes('manageOrganizationStructure')) {
    return <Navigate to="/admin/orgStructure" />
  }

  if (currentUserRoles.includes('manageGlobalSetting')) {
    return <Navigate to="/admin/settings" />
  }

  if (currentUserRoles.includes('viewAuditLog')) {
    return <Navigate to="/admin/audit-logs" />
  }

  return <Navigate to="/" />
}

export default AdminRoute

const prefix = `Global_Filter`

const GlobalFilterActions = {
  SELECT_ACTIVE_HIERARCHY: `${prefix}_SELECT_ACTIVE_HIERARCHY`,
  SELECT_ACTIVE_HIERARCHY_VALUE: `${prefix}_SELECT_ACTIVE_HIERARCHY_VALUE`,
  SELECT_ACTIVE_SELECTED_HIERARCHY_VALUE: `${prefix}_SELECT_ACTIVE_SELECTED_HIERARCHY_VALUE`,
  INITIALIZE_ACTIVE_HIERARCHY_VALUES: `${prefix}_INITIALIZE_ACTIVE_HIERARCHY_VALUES`,
  ADD_HIERARCHY_VALUE: `${prefix}_ADD_HIERARCHY_VALUE`,
  REMOVE_HIERARCHY_VALUE: `${prefix}_REMOVE_HIERARCHY_VALUE`,
  RESET: `${prefix}_RESET`,
  DO_FILTER: `${prefix}_DO_FILTER`,
  CLEAR_FILTER: `${prefix}_CLEAR_FILTER`,

  doSelectActiveHierarchy: (hierarchy) => async (dispatch) => {
    dispatch({
      type: GlobalFilterActions.SELECT_ACTIVE_HIERARCHY,
      payload: hierarchy,
    })
  },

  doSelectActiveHierarchyValue: (hierarchyValue) => async (dispatch) => {
    dispatch({
      type: GlobalFilterActions.SELECT_ACTIVE_HIERARCHY_VALUE,
      payload: hierarchyValue,
    })
  },

  doInitializeHierarchyValues: (hierarchyValues) => async (dispatch) => {
    dispatch({
      type: GlobalFilterActions.INITIALIZE_ACTIVE_HIERARCHY_VALUES,
      payload: hierarchyValues,
    })
  },

  doSelectActiveSelectedHierarchyValue: (value) => async (dispatch) => {
    dispatch({
      type: GlobalFilterActions.SELECT_ACTIVE_SELECTED_HIERARCHY_VALUE,
      payload: value,
    })
  },

  doAddHierarchyValues: (values) => async (dispatch) => {
    dispatch({
      type: GlobalFilterActions.ADD_HIERARCHY_VALUE,
      payload: values,
    })
  },

  doRemoveHierarchyValue: (value) => async (dispatch) => {
    dispatch({
      type: GlobalFilterActions.REMOVE_HIERARCHY_VALUE,
      payload: value,
    })
  },

  doReset: (values) => async (dispatch) => {
    dispatch({
      type: GlobalFilterActions.RESET,
      payload: values,
    })
  },

  doFilter: () => async (dispatch) => {
    dispatch({
      type: GlobalFilterActions.DO_FILTER,
    })
  },

  clearFilter: () => async (dispatch) => {
    dispatch({
      type: GlobalFilterActions.CLEAR_FILTER,
    })
  },
}

export default GlobalFilterActions

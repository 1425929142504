import IntellectualPropertyService from 'src/modules/intellectualProperty/intellectualPropertyService'
import Errors from 'src/modules/shared/error/errors'
import { getHistory } from 'src/modules/store'

const prefix = 'INTELLECTUALPROPERTY_VIEW'

const intellectualPropertyViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: intellectualPropertyViewActions.FIND_STARTED,
      })

      const record = await IntellectualPropertyService.find(id)

      dispatch({
        type: intellectualPropertyViewActions.FIND_SUCCESS,
        payload: record,
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: intellectualPropertyViewActions.FIND_ERROR,
      })

      getHistory().push('/intellectual-property')
    }
  },
}

export default intellectualPropertyViewActions

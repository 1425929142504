import list from 'src/modules/funding/list/fundingListReducers'
import form from 'src/modules/funding/form/fundingFormReducers'
import view from 'src/modules/funding/view/fundingViewReducers'
import destroy from 'src/modules/funding/destroy/fundingDestroyReducers'
import importerReducer from 'src/modules/funding/importer/fundingImporterReducers'
import { combineReducers } from 'redux'

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
})

import schemas from 'src/modules/shared/yup/yupImporterSchemas'
import { i18n } from 'src/i18n'
import researchThemesIntendedImpactEnumerators from 'src/modules/researchThemesIntendedImpact/researchThemesIntendedImpactEnumerators'

export default [
  {
    name: 'title',
    label: i18n('entities.researchThemesIntendedImpact.fields.title'),
    schema: schemas.string(
      i18n('entities.researchThemesIntendedImpact.fields.title'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'need',
    label: i18n('entities.researchThemesIntendedImpact.fields.need'),
    schema: schemas.string(
      i18n('entities.researchThemesIntendedImpact.fields.need'),
      {
        max: 1000,
        required: true,
      },
    ),
  },
  {
    name: 'description',
    label: i18n('entities.researchThemesIntendedImpact.fields.description'),
    schema: schemas.string(
      i18n('entities.researchThemesIntendedImpact.fields.description'),
      {
        max: 1000,
        required: true,
      },
    ),
  },
  {
    name: 'impact',
    label: i18n('entities.researchThemesIntendedImpact.fields.impact'),
    schema: schemas.string(
      i18n('entities.researchThemesIntendedImpact.fields.impact'),
      {
        max: 1000,
        required: true,
      },
    ),
  },
  {
    name: 'files',
    label: i18n('entities.researchThemesIntendedImpact.fields.files'),
    schema: schemas.files(
      i18n('entities.researchThemesIntendedImpact.fields.files'),
      {},
    ),
  },
  {
    name: 'images',
    label: i18n('entities.researchThemesIntendedImpact.fields.images'),
    schema: schemas.images(
      i18n('entities.researchThemesIntendedImpact.fields.images'),
      {},
    ),
  },
  {
    name: 'status',
    label: i18n('entities.researchThemesIntendedImpact.fields.status'),
    schema: schemas.enumerator(
      i18n('entities.researchThemesIntendedImpact.fields.status'),
      {
        required: true,
        options: researchThemesIntendedImpactEnumerators.status,
      },
    ),
  },
  {
    name: 'researchers',
    label: i18n('entities.researchThemesIntendedImpact.fields.researchers'),
    schema: schemas.relationToMany(
      i18n('entities.researchThemesIntendedImpact.fields.researchers'),
      {},
    ),
  },
  {
    name: 'recordType',
    label: i18n('entities.researchThemesIntendedImpact.fields.recordType'),
    schema: schemas.enumerator(
      i18n('entities.researchThemesIntendedImpact.fields.recordType'),
      {
        options: researchThemesIntendedImpactEnumerators.recordType,
      },
    ),
  },
  {
    name: 'year',
    label: i18n('entities.researchThemesIntendedImpact.fields.year'),
    schema: schemas.integer(
      i18n('entities.researchThemesIntendedImpact.fields.year'),
      {},
    ),
  },
]

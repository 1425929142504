import importerActions from 'src/modules/shared/importer/importerActions'
import selectors from 'src/modules/awardPrizes/importer/awardPrizesImporterSelectors'
import AwardPrizesService from 'src/modules/awardPrizes/awardPrizesService'
import fields from 'src/modules/awardPrizes/importer/awardPrizesImporterFields'
import { i18n } from 'src/i18n'

const awardPrizesImporterActions = importerActions(
  'AWARDPRIZES_IMPORTER',
  selectors,
  AwardPrizesService.import,
  fields,
  i18n('entities.awardPrizes.importer.fileName'),
)

export default awardPrizesImporterActions

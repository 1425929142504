import Errors from 'src/modules/shared/error/errors'
import { getHistory } from 'src/modules/store'
import UserService from 'src/modules/admin/user/userService'

const prefix = 'USER_VIEW'

const userViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  POST_STARTED: `${prefix}_POST_STARTED`,
  POST_SUCCESS: `${prefix}_POST_SUCCESS`,
  POST_ERROR: `${prefix}_POST_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: userViewActions.FIND_STARTED,
      })

      const user = await UserService.find(id)

      dispatch({
        type: userViewActions.FIND_SUCCESS,
        payload: user,
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: userViewActions.FIND_ERROR,
      })

      getHistory().push('/user')
    }
  },

  // doPost: (userPayload) => async (dispatch) => {
  //   try {
  //     dispatch({

  //     })
  //   }
  // }
}

export default userViewActions

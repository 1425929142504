import schemas from 'src/modules/shared/yup/yupImporterSchemas'
import { i18n } from 'src/i18n'
import publicationEnumerators from 'src/modules/publication/publicationEnumerators'

export default [
  {
    name: 'title',
    label: i18n('entities.publication.fields.title'),
    schema: schemas.string(i18n('entities.publication.fields.title'), {
      required: true,
    }),
  },
  {
    name: 'publicationType',
    label: i18n('entities.publication.fields.publicationType'),
    schema: schemas.string(
      i18n('entities.publication.fields.publicationType'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'researcher',
    label: i18n('entities.publication.fields.researcher'),
    schema: schemas.relationToOne(
      i18n('entities.publication.fields.researcher'),
      {},
    ),
  },
  {
    name: 'editor',
    label: i18n('entities.publication.fields.editor'),
    schema: schemas.string(i18n('entities.publication.fields.editor'), {}),
  },
  {
    name: 'chapterTitle',
    label: i18n('entities.publication.fields.chapterTitle'),
    schema: schemas.string(
      i18n('entities.publication.fields.chapterTitle'),
      {},
    ),
  },
  {
    name: 'files',
    label: i18n('entities.publication.fields.files'),
    schema: schemas.files(i18n('entities.publication.fields.files'), {}),
  },
  {
    name: 'images',
    label: i18n('entities.publication.fields.images'),
    schema: schemas.images(i18n('entities.publication.fields.images'), {}),
  },
  {
    name: 'status',
    label: i18n('entities.publication.fields.status'),
    schema: schemas.enumerator(i18n('entities.publication.fields.status'), {
      required: true,
      options: publicationEnumerators.status,
    }),
  },
  {
    name: 'recordType',
    label: i18n('entities.publication.fields.recordType'),
    schema: schemas.enumerator(i18n('entities.publication.fields.recordType'), {
      options: publicationEnumerators.recordType,
    }),
  },
  {
    name: 'year',
    label: i18n('entities.publication.fields.year'),
    schema: schemas.integer(i18n('entities.publication.fields.year'), {
      required: true,
    }),
  },
  {
    name: 'publication',
    label: i18n('entities.publication.fields.publication'),
    schema: schemas.string(i18n('entities.publication.fields.publication'), {}),
  },
  {
    name: 'publisher',
    label: i18n('entities.publication.fields.publisher'),
    schema: schemas.string(i18n('entities.publication.fields.publisher'), {
      required: true,
    }),
  },
  {
    name: 'country',
    label: i18n('entities.publication.fields.country'),
    schema: schemas.string(i18n('entities.publication.fields.country'), {}),
  },
  {
    name: 'city',
    label: i18n('entities.publication.fields.city'),
    schema: schemas.string(i18n('entities.publication.fields.city'), {}),
  },
  {
    name: 'state',
    label: i18n('entities.publication.fields.state'),
    schema: schemas.string(i18n('entities.publication.fields.state'), {}),
  },
  {
    name: 'chapterNumber',
    label: i18n('entities.publication.fields.chapterNumber'),
    schema: schemas.string(
      i18n('entities.publication.fields.chapterNumber'),
      {},
    ),
  },
  {
    name: 'edition',
    label: i18n('entities.publication.fields.edition'),
    schema: schemas.string(i18n('entities.publication.fields.edition'), {}),
  },
  {
    name: 'version',
    label: i18n('entities.publication.fields.version'),
    schema: schemas.string(i18n('entities.publication.fields.version'), {}),
  },
  {
    name: 'volume',
    label: i18n('entities.publication.fields.volume'),
    schema: schemas.string(i18n('entities.publication.fields.volume'), {}),
  },
  {
    name: 'issue',
    label: i18n('entities.publication.fields.issue'),
    schema: schemas.string(i18n('entities.publication.fields.issue'), {}),
  },
  {
    name: 'startDate',
    label: i18n('entities.publication.fields.startDate'),
    schema: schemas.datetime(i18n('entities.publication.fields.startDate'), {}),
  },
  {
    name: 'endDate',
    label: i18n('entities.publication.fields.endDate'),
    schema: schemas.datetime(i18n('entities.publication.fields.endDate'), {}),
  },
  {
    name: 'url',
    label: i18n('entities.publication.fields.url'),
    schema: schemas.string(i18n('entities.publication.fields.url'), {}),
  },
  {
    name: 'articleNumber',
    label: i18n('entities.publication.fields.articleNumber'),
    schema: schemas.string(
      i18n('entities.publication.fields.articleNumber'),
      {},
    ),
  },
  {
    name: 'isbn',
    label: i18n('entities.publication.fields.isbn'),
    schema: schemas.string(i18n('entities.publication.fields.isbn'), {}),
  },
  {
    name: 'doi',
    label: i18n('entities.publication.fields.doi'),
    schema: schemas.string(i18n('entities.publication.fields.doi'), {}),
  },
  {
    name: 'citations',
    label: i18n('entities.publication.fields.citations'),
    schema: schemas.string(i18n('entities.publication.fields.citations'), {}),
  },
  {
    name: 'impactFactor',
    label: i18n('entities.publication.fields.impactFactor'),
    schema: schemas.string(
      i18n('entities.publication.fields.impactFactor'),
      {},
    ),
  },
]

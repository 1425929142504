import InterventionStudyService from 'src/modules/interventionStudy/interventionStudyService'
import selectors from 'src/modules/interventionStudy/list/interventionStudyListSelectors'
import exporterFields from 'src/modules/interventionStudy/list/interventionStudyListExporterFields'
import { generateListActions } from '../../listHelpers'

const prefix = 'INTERVENTIONSTUDY_LIST'

const interventionStudyListActions = generateListActions(
  prefix,
  InterventionStudyService.list,
  selectors,
  exporterFields,
  'startDate',
)
export default interventionStudyListActions

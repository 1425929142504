import { i18n } from 'src/i18n'
import exporterRenders from 'src/modules/shared/exporter/exporterRenders'

export default [
  {
    name: 'id',
    label: i18n('entities.mediaMention.fields.id'),
  },
  {
    name: 'title',
    label: i18n('entities.mediaMention.fields.title'),
  },
  {
    name: 'researcher',
    label: i18n('entities.mediaMention.fields.researcher'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'media',
    label: i18n('entities.mediaMention.fields.media'),
  },
  {
    name: 'organization',
    label: i18n('entities.mediaMention.fields.organization'),
  },
  {
    name: 'country',
    label: i18n('entities.mediaMention.fields.country'),
  },
  {
    name: 'startDate',
    label: i18n('entities.mediaMention.fields.startDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'description',
    label: i18n('entities.mediaMention.fields.description'),
  },
  {
    name: 'files',
    label: i18n('entities.mediaMention.fields.files'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'images',
    label: i18n('entities.mediaMention.fields.images'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'author',
    label: i18n('entities.mediaMention.fields.author'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'dateCreated',
    label: i18n('entities.mediaMention.fields.dateCreated'),
  },
  {
    name: 'status',
    label: i18n('entities.mediaMention.fields.status'),
  },
  {
    name: 'recordType',
    label: i18n('entities.mediaMention.fields.recordType'),
  },
  {
    name: 'year',
    label: i18n('entities.mediaMention.fields.year'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.mediaMention.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.mediaMention.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
]

const prefix = 'JOB FIELD'

const jobFieldActions = {
  SET_ACTIVE_FIELD: `${prefix}_SET_ACTIVE_HIERARCHIES`,
  FILTER_FIELD: `${prefix}_FILTER_FIELD`,
  SELECT_FIELD: `${prefix}_SELECT_FIELD`,
  REMOVE_FIELD: `${prefix}_REMOVE_FIELD`,
  RESET: `${prefix}_RESET`,

  doSetActiveJobField: (mappings) => (dispatch) => {
    dispatch({
      type: jobFieldActions.SET_ACTIVE_FIELD,
      payload: mappings,
    })
  },

  doSelectJobField: (fieldMapping) => (dispatch) => {
    dispatch({ type: jobFieldActions.SELECT_FIELD, payload: fieldMapping })
  },

  doRemoveJobField: (id) => (dispatch) => {
    dispatch({ type: jobFieldActions.REMOVE_FIELD, payload: id })
  },

  doReset: () => (dispatch) => {
    dispatch({ type: jobFieldActions.RESET })
  },
}

export default jobFieldActions

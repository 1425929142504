import list from 'src/modules/collaborativeNetwork/list/collaborativeNetworkListReducers'
import form from 'src/modules/collaborativeNetwork/form/collaborativeNetworkFormReducers'
import view from 'src/modules/collaborativeNetwork/view/collaborativeNetworkViewReducers'
import destroy from 'src/modules/collaborativeNetwork/destroy/collaborativeNetworkDestroyReducers'
import importerReducer from 'src/modules/collaborativeNetwork/importer/collaborativeNetworkImporterReducers'
import { combineReducers } from 'redux'

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
})

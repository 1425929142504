import list from 'src/modules/mentoringSupervision/list/mentoringSupervisionListReducers'
import form from 'src/modules/mentoringSupervision/form/mentoringSupervisionFormReducers'
import view from 'src/modules/mentoringSupervision/view/mentoringSupervisionViewReducers'
import destroy from 'src/modules/mentoringSupervision/destroy/mentoringSupervisionDestroyReducers'
import importerReducer from 'src/modules/mentoringSupervision/importer/mentoringSupervisionImporterReducers'
import { combineReducers } from 'redux'

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
})

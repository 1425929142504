import importerActions from 'src/modules/shared/importer/importerActions'
import selectors from 'src/modules/mentoringSupervision/importer/mentoringSupervisionImporterSelectors'
import MentoringSupervisionService from 'src/modules/mentoringSupervision/mentoringSupervisionService'
import fields from 'src/modules/mentoringSupervision/importer/mentoringSupervisionImporterFields'
import { i18n } from 'src/i18n'

const mentoringSupervisionImporterActions = importerActions(
  'MENTORINGSUPERVISION_IMPORTER',
  selectors,
  MentoringSupervisionService.import,
  fields,
  i18n('entities.mentoringSupervision.importer.fileName'),
)

export default mentoringSupervisionImporterActions

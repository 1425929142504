import Errors from 'src/modules/shared/error/errors'
import authSelectors from 'src/modules/auth/authSelectors'
import Message from 'src/components/message'
import { i18n } from 'src/i18n'
import selectors from './selectors'
import SearchService from './service'

const prefix = 'SEARCH_VIEW'

const searchActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  EXPORT_STARTED: `${prefix}_EXPORT_STARTED`,
  EXPORT_COMPLETED: `${prefix}_EXPORT_SUCCESS`,

  CLEAR: `${prefix}_CLEAR`,
  CLEAR_FILTERED_REPORT: `${prefix}_CLEAR_FILTERED_REPORT`,

  SAVE_STARTED: `${prefix}_SAVE_STARTED`,
  SAVE_SUCCESS: `${prefix}_SAVE_SUCCESS`,
  SAVE_ERROR: `${prefix}_SAVE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  FETCH_SAVED_REPORTS_STARTED: `${prefix}_FETCH_SAVED_REPORTS_STARTED`,
  FETCH_SAVED_REPORTS_SUCCESS: `${prefix}_FETCH_SAVED_REPORTS_SUCCESS`,
  FETCH_SAVED_REPORTS_ERROR: `${prefix}_FETCH_SAVED_REPORTS_ERROR`,

  DELETE_SAVED_REPORTS_STARTED: `${prefix}_DELETE_SAVED_REPORTS_STARTED`,
  DELETE_SAVED_REPORTS_SUCCESS: `${prefix}_DELETE_SAVED_REPORTS_SUCCESS`,
  DELETE_SAVED_REPORTS_ERROR: `${prefix}_DELETE_SAVED_REPORTS_ERROR`,

  SELECT_SAVED_REPORT: `${prefix}_SELECT_SAVED_REPORT`,
  CLEAR_SELECTED_SAVED_REPORT: `${prefix}CLEAR_SELECTED_SAVED_REPORT`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,

  doFetchCurrentFilter: (category) => async (dispatch, getState) => {
    const filter = selectors.selectFilters(getState())
    const reportsDataState = selectors.selectReportsData(getState())
    const toPaginate = reportsDataState[category].toPaginate

    dispatch(
      searchActions.doFetch(
        filter.title,
        filter.startDate,
        filter.endDate,
        filter.categories,
        filter.lowestHierarchies,
        filter.status,
        filter.isExport,
        toPaginate,
        filter.paginatedCategory,
        filter.researchers,
        filter.url,
        filter.projectIds,
      ),
    )
  },

  doFetch:
    (
      title,
      startDate,
      endDate,
      categories,
      lowestHierarchies,
      status,
      isExport,
      pagination,
      paginatedCategory = [],
      researchers,
      url,
      projectIds,
    ) =>
    async (dispatch) => {
      try {
        if (isExport) {
          dispatch({ type: searchActions.EXPORT_STARTED })
          const response = await SearchService.searchList(
            title,
            startDate,
            endDate,
            paginatedCategory.length > 0 ? paginatedCategory : categories,
            lowestHierarchies,
            status,
            isExport,
            pagination,
            researchers,
            url,
            projectIds,
          )
          dispatch({ type: searchActions.EXPORT_COMPLETED })

          if (response?.result) {
            Message.success(i18n('report.exportProcessing'))
          }
          return
        }
        dispatch({
          type: searchActions.FETCH_STARTED,
          payload: {
            title,
            startDate,
            endDate,
            categories,
            lowestHierarchies,
            status,
            isExport,
            pagination,
            paginatedCategory,
            researchers,
            url,
            projectIds,
          },
        })

        const response = await SearchService.searchList(
          title,
          startDate,
          endDate,
          paginatedCategory.length > 0 ? paginatedCategory : categories,
          lowestHierarchies,
          status,
          isExport,
          pagination,
          researchers,
          url,
          projectIds,
        )

        dispatch({
          type: searchActions.FETCH_SUCCESS,
          payload: {
            rows: response,
            filters: {
              title,
              startDate,
              endDate,
              categories,
              lowestHierarchies,
              status,
              isExport,
              pagination,
              paginatedCategory,
              researchers,
              url,
              projectIds,
            },
          },
        })
      } catch (error) {
        Errors.handle(error)

        dispatch({
          type: searchActions.FETCH_ERROR,
        })
      }
    },

  doSaveFilter: (filter) => async (dispatch, getState) => {
    try {
      const currentUser = authSelectors.selectCurrentUser(getState())
      dispatch({
        type: searchActions.SAVE_STARTED,
        payload: { filter },
      })

      const filterData = {
        id: filter.id || null,
        searchUrl: filter.searchUrl,
        title: filter.title,
      }

      await SearchService.saveReport(currentUser.id, filterData)

      dispatch({
        type: searchActions.SAVE_SUCCESS,
        payload: { filter },
      })

      dispatch(searchActions.doFetchSavedReports())
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: searchActions.SAVE_ERROR,
      })
    }
  },

  doChangePagination: (pagination) => async (dispatch) => {
    dispatch({
      type: searchActions.PAGINATION_CHANGED,
      payload: pagination,
    })

    dispatch(searchActions.doFetchCurrentFilter(pagination.category))
  },

  doFetchSavedReports: () => async (dispatch, getState) => {
    const currentUser = authSelectors.selectCurrentUser(getState())
    try {
      dispatch({
        type: searchActions.FETCH_SAVED_REPORTS_STARTED,
      })

      const record = await SearchService.fetchSavedReports(currentUser.id)

      dispatch({
        type: searchActions.FETCH_SAVED_REPORTS_SUCCESS,
        payload: record,
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: searchActions.FETCH_SAVED_REPORTS_ERROR,
      })
    }
  },

  doDeleteSavedReport: (reportId) => async (dispatch, getState) => {
    try {
      const currentUser = authSelectors.selectCurrentUser(getState())

      await SearchService.deleteSavedReport(currentUser.id, reportId)

      dispatch({
        type: searchActions.DELETE_SAVED_REPORTS_SUCCESS,
      })

      dispatch(searchActions.doFetchSavedReports())
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: searchActions.SAVE_ERROR,
      })
    }
  },

  doSelectSavedReport: (report) => (dispatch) => {
    dispatch({
      type: searchActions.CLEAR_SELECTED_SAVED_REPORT,
    })
    dispatch({
      type: searchActions.SELECT_SAVED_REPORT,
      payload: report,
    })
  },

  doClearSelectedSavedReport: () => (dispatch) => {
    dispatch({
      type: searchActions.CLEAR_SELECTED_SAVED_REPORT,
    })
  },

  doClear: () => (dispatch) => {
    dispatch({
      type: searchActions.CLEAR,
    })
  },

  doClearFilteredReport: () => (dispatch) => {
    dispatch({
      type: searchActions.CLEAR_FILTERED_REPORT,
    })
  },
}

export default searchActions

import AwardPrizesService from 'src/modules/awardPrizes/awardPrizesService'
import selectors from 'src/modules/awardPrizes/list/awardPrizesListSelectors'
import exporterFields from 'src/modules/awardPrizes/list/awardPrizesListExporterFields'
import { generateListActions } from '../../listHelpers'

const prefix = 'AWARDPRIZES_LIST'

const awardPrizesListActions = generateListActions(
  prefix,
  AwardPrizesService.list,
  selectors,
  exporterFields,
  'year',
)
export default awardPrizesListActions

import list from 'src/modules/barriersSupport/list/barriersSupportListReducers'
import form from 'src/modules/barriersSupport/form/barriersSupportFormReducers'
import view from 'src/modules/barriersSupport/view/barriersSupportViewReducers'
import destroy from 'src/modules/barriersSupport/destroy/barriersSupportDestroyReducers'
import importerReducer from 'src/modules/barriersSupport/importer/barriersSupportImporterReducers'
import { combineReducers } from 'redux'

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
})

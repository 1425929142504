import schemas from 'src/modules/shared/yup/yupImporterSchemas'
import { i18n } from 'src/i18n'
import researchTeamEnumerators from 'src/modules/researchTeam/researchTeamEnumerators'

export default [
  {
    name: 'year',
    label: i18n('entities.researchTeam.fields.year'),
    schema: schemas.integer(i18n('entities.researchTeam.fields.year'), {}),
  },
  {
    name: 'researchTeam',
    label: i18n('entities.researchTeam.fields.researchTeam'),
    schema: schemas.enumerator(
      i18n('entities.researchTeam.fields.researchTeam'),
      {
        options: researchTeamEnumerators.researchTeam,
      },
    ),
  },
  {
    name: 'plainLanguageSummary',
    label: i18n('entities.researchTeam.fields.plainLanguageSummary'),
    schema: schemas.string(
      i18n('entities.researchTeam.fields.plainLanguageSummary'),
      {},
    ),
  },
  {
    name: 'teamHighlights',
    label: i18n('entities.researchTeam.fields.teamHighlights'),
    schema: schemas.string(
      i18n('entities.researchTeam.fields.teamHighlights'),
      {},
    ),
  },
  {
    name: 'notable',
    label: i18n('entities.researchTeam.fields.notable'),
    schema: schemas.string(i18n('entities.researchTeam.fields.notable'), {}),
  },
  {
    name: 'files',
    label: i18n('entities.researchTeam.fields.files'),
    schema: schemas.files(i18n('entities.researchTeam.fields.files'), {}),
  },
  {
    name: 'images',
    label: i18n('entities.researchTeam.fields.images'),
    schema: schemas.images(i18n('entities.researchTeam.fields.images'), {}),
  },
  {
    name: 'status',
    label: i18n('entities.researchTeam.fields.status'),
    schema: schemas.enumerator(i18n('entities.researchTeam.fields.status'), {
      required: true,
      options: researchTeamEnumerators.status,
    }),
  },
  {
    name: 'title',
    label: i18n('entities.researchTeam.fields.title'),
    schema: schemas.string(i18n('entities.researchTeam.fields.title'), {}),
  },
  {
    name: 'researchers',
    label: i18n('entities.researchTeam.fields.researchers'),
    schema: schemas.relationToMany(
      i18n('entities.researchTeam.fields.researchers'),
      {},
    ),
  },
  {
    name: 'recordType',
    label: i18n('entities.researchTeam.fields.recordType'),
    schema: schemas.enumerator(
      i18n('entities.researchTeam.fields.recordType'),
      {
        options: researchTeamEnumerators.recordType,
      },
    ),
  },
]

import listActions from 'src/modules/awardPrizes/list/awardPrizesListActions'
import AwardPrizesService from 'src/modules/awardPrizes/awardPrizesService'
import Errors from 'src/modules/shared/error/errors'
import { i18n } from 'src/i18n'
import { getHistory } from 'src/modules/store'
import Message from 'src/components/message'

const prefix = 'AWARDPRIZES_DESTROY'

const awardPrizesDestroyActions = {
  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  DESTROY_ALL_STARTED: `${prefix}_DESTROY_ALL_STARTED`,
  DESTROY_ALL_SUCCESS: `${prefix}_DESTROY_ALL_SUCCESS`,
  DESTROY_ALL_ERROR: `${prefix}_DESTROY_ALL_ERROR`,

  doDestroy:
    (id, impactCategoryAlias = null) =>
    async (dispatch) => {
      try {
        dispatch({
          type: awardPrizesDestroyActions.DESTROY_STARTED,
        })

        await AwardPrizesService.destroyAll([id])

        dispatch({
          type: awardPrizesDestroyActions.DESTROY_SUCCESS,
        })

        Message.success(
          impactCategoryAlias
            ? i18n(
                'impactCategories.messages.destroy.success',
                impactCategoryAlias,
              )
            : i18n('entities.awardPrizes.destroy.success'),
        )

        dispatch(listActions.doFetchCurrentFilter())

        getHistory().push('/award-prizes')
      } catch (error) {
        Errors.handle(error)

        dispatch(listActions.doFetchCurrentFilter())

        dispatch({
          type: awardPrizesDestroyActions.DESTROY_ERROR,
        })
      }
    },

  doDestroyAll: (ids) => async (dispatch) => {
    try {
      dispatch({
        type: awardPrizesDestroyActions.DESTROY_ALL_STARTED,
      })

      await AwardPrizesService.destroyAll(ids)

      dispatch({
        type: awardPrizesDestroyActions.DESTROY_ALL_SUCCESS,
      })

      if (listActions) {
        dispatch(listActions.doClearAllSelected())
        dispatch(listActions.doFetchCurrentFilter())
      }

      Message.success(i18n('entities.awardPrizes.destroyAll.success'))

      getHistory().push('/award-prizes')
    } catch (error) {
      Errors.handle(error)

      dispatch(listActions.doFetchCurrentFilter())

      dispatch({
        type: awardPrizesDestroyActions.DESTROY_ALL_ERROR,
      })
    }
  },
}

export default awardPrizesDestroyActions

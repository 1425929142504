import importerActions from 'src/modules/shared/importer/importerActions'
import selectors from 'src/modules/funding/importer/fundingImporterSelectors'
import FundingService from 'src/modules/funding/fundingService'
import fields from 'src/modules/funding/importer/fundingImporterFields'
import { i18n } from 'src/i18n'

const fundingImporterActions = importerActions(
  'FUNDING_IMPORTER',
  selectors,
  FundingService.import,
  fields,
  i18n('entities.funding.importer.fileName'),
)

export default fundingImporterActions

import AwardPrizesService from 'src/modules/awardPrizes/awardPrizesService'
import Errors from 'src/modules/shared/error/errors'
import { getHistory } from 'src/modules/store'

const prefix = 'AWARDPRIZES_VIEW'

const awardPrizesViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: awardPrizesViewActions.FIND_STARTED,
      })

      const record = await AwardPrizesService.find(id)

      dispatch({
        type: awardPrizesViewActions.FIND_SUCCESS,
        payload: record,
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: awardPrizesViewActions.FIND_ERROR,
      })

      getHistory().push('/award-prizes')
    }
  },
}

export default awardPrizesViewActions

import Errors from 'src/modules/shared/error/errors'
import Message from 'src/components/message'
import impactCategoriesService from 'src/modules/admin/imactCategories/impactCategoriesService'
import { getHistory } from 'src/modules/store'
import { i18n } from 'src/i18n'
import impactCategoriesListActions from '../list/impactCategoriesListActions'
import fieldActions from 'src/modules/admin/imactCategories/imactCategoriesFields/form/impactCategoriesFieldFormActions'

const prefix = 'IMPACTCATEGORIES_FORM'

const impactCategoriesFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: (id?) => async (dispatch) => {
    try {
      dispatch({
        type: impactCategoriesFormActions.INIT_STARTED,
      })

      const isEdit = Boolean(id)
      let record = {}

      if (isEdit) {
        record = await impactCategoriesService.find(id)
      }

      dispatch({
        type: impactCategoriesFormActions.INIT_SUCCESS,
        payload: record,
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: impactCategoriesFormActions.INIT_ERROR,
      })

      getHistory().push('/admin/impactCategories')
    }
  },

  doUpdate:
    (values, fieldValues, shouldRedirect = false) =>
    async (dispatch) => {
      try {
        dispatch({
          type: impactCategoriesFormActions.UPDATE_STARTED,
        })

        const record = await impactCategoriesService.update(values)

        await dispatch(fieldActions.doUpdate(record.id, fieldValues))

        await dispatch(impactCategoriesListActions.doFetchMenuItems())

        dispatch({
          type: impactCategoriesFormActions.UPDATE_SUCCESS,
        })
        Message.success(i18n('impactCategories.doUpdate.success'))
        if (shouldRedirect) {
          getHistory().push('/admin/impactCategories')
        }
        dispatch({
          type: impactCategoriesFormActions.INIT_SUCCESS,
          payload: record,
        })
      } catch (error) {
        Errors.handle(error)

        dispatch({
          type: impactCategoriesFormActions.UPDATE_ERROR,
        })
      }
    },
}

export default impactCategoriesFormActions

import ConsumerCommunityInvolvementService from 'src/modules/consumerCommunityInvolvement/consumerCommunityInvolvementService'
import selectors from 'src/modules/consumerCommunityInvolvement/list/consumerCommunityInvolvementListSelectors'
import exporterFields from 'src/modules/consumerCommunityInvolvement/list/consumerCommunityInvolvementListExporterFields'
import { generateListActions } from '../../listHelpers'

const prefix = 'CONSUMERCOMMUNITYINVOLVEMENT_LIST'

const consumerCommunityInvolvementListActions = generateListActions(
  prefix,
  ConsumerCommunityInvolvementService.list,
  selectors,
  exporterFields,
  'startDate',
)

export default consumerCommunityInvolvementListActions

import styled from 'styled-components'

const Label = styled('label')`
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 28px;
  color: #333;
`

export default Label

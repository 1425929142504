import Errors from 'src/modules/shared/error/errors'
import { getHistory } from 'src/modules/store'
import SettingsService from 'src/modules/settings/settingsService'
import Message from 'src/components/message'
import { i18n } from 'src/i18n'
import authSelectors from 'src/modules/auth/authSelectors'
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant'
import authActions from 'src/modules/auth/authActions'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTenantSetting } from './settingsSelectors'

const prefix = 'SETTINGS'

const settingsActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CLEAR: `${prefix}_INIT_CLEAR`,

  SAVE_STARTED: `${prefix}_SAVE_STARTED`,
  SAVE_SUCCESS: `${prefix}_SAVE_SUCCESS`,
  SAVE_ERROR: `${prefix}_SAVE_ERROR`,

  doInit: () => async (dispatch, getState) => {
    try {
      if (
        !authSelectors.selectSignedIn(getState()) ||
        !AuthCurrentTenant.get()
      ) {
        return
      }

      dispatch({
        type: settingsActions.INIT_STARTED,
      })

      const settings = await SettingsService.find()

      dispatch({
        type: settingsActions.INIT_SUCCESS,
        payload: settings,
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: settingsActions.INIT_ERROR,
      })

      getHistory().push('/')
    }
  },

  doSave: (values) => async (dispatch) => {
    try {
      dispatch({
        type: settingsActions.SAVE_STARTED,
      })

      const settings = await SettingsService.save(values)

      dispatch({
        type: settingsActions.SAVE_SUCCESS,
        payload: settings,
      })

      dispatch(authActions.doInit())
      Message.success(i18n('settings.save.success'))
      getHistory().push('/')
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: settingsActions.SAVE_ERROR,
      })
    }
  },

  doClear: () => (dispatch) => {
    dispatch({
      type: settingsActions.CLEAR,
    })
  },
}

export const useTenantSettingQuery = (tenant: string) => {
  const config = useTenantSetting()
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (tenant && !config) {
      setIsLoading(true)
      dispatch({
        type: settingsActions.INIT_STARTED,
      })
      SettingsService.findBySlug(tenant)
        .then((data) => {
          dispatch({
            type: settingsActions.INIT_SUCCESS,
            payload: data,
          })
        })
        .catch((e) => {
          dispatch({
            type: settingsActions.SAVE_ERROR,
          })
          console.error('err', e.message)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [tenant, config])

  return { isLoading, config }
}

export default settingsActions

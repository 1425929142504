import ImporterSchema from './importerSchema'

const getOption = (field) => {
  return {
    data: field?.options?.[0]?.label,
    hint: 'You must use one of the  values already defined in the system',
  }
}

const getMultiSelect = (field) => {
  return {
    data: `"${field?.options?.[0]?.label}","${field?.options?.[1]?.label}"`,
    hint: 'You must use one of the values already defined in the system. For a single selection type the value. For multiple selections place each value in inverted commas and separate them by a comma. ',
  }
}

const getRecordSecurity = () => {
  return {
    data: null,
    hint: null,
  }
}

const getYear = () => {
  return { data: new Date(Date.now()).getFullYear(), hint: 'Enter the year' }
}

const getNumber = (field, freetext?: boolean) => {
  let hint = 'Enter the number'

  if (freetext) {
    hint = 'Freetext'
  }

  if (field.name === 'potential' && !freetext) {
    hint += ' between 1 and 10 (inclusive)'
  }

  return { data: 10, hint }
}

const getDate = (field) => {
  if (field === 'startDate' || field === 'conferralDate')
    return {
      data: `${new Date(Date.now()).getFullYear()}-01`,
      hint: 'Enter date in the format YYYY-MM',
    }
  if (field === 'endDate' || field === 'proposedSubmissionDate') {
    let hint = 'Enter date in the format YYYY-MM'
    if (field === 'endDate') {
      hint = hint + ' or ongoing (if ongoing is enabled for this field)'
    }

    return { data: `${new Date(Date.now()).getFullYear()}-02`, hint }
  }
}

const getFullDate = () => {
  return {
    data: `${new Date(Date.now()).getFullYear()}-01-01`,
    hint: 'Enter date in the format YYYY-MM-DD',
  }
}

const getHostOrganisationResearchers = (field) => {
  if (field?.name === 'individualsInvolved')
    return {
      data: field?.users
        ?.slice(0, 2)
        .map((user, index) => {
          return `${user?.fullName}:${field?.options[index]?.label}:21`
        })
        .join(','),
      hint: 'Enter the full name of the person (they much exist as a user in the system) followed by a colon and then their role,followed by a colon and then their percentage. Where there are multiple people, separate the entries by a comma. You must use one of the roles already defined in the system',
    }
  if (field?.options?.length === 0)
    return {
      data: field?.users
        ?.slice(0, 2)
        .map((user) => {
          return user?.fullName
        })
        .join(','),
      hint: 'Enter the full name of the person (they much exist as a user in the system). Where there are multiple people, separate the entries by a comma.',
    }
  return {
    data: field?.users
      ?.slice(0, 2)
      .map((user, index) => {
        return `${user?.fullName}:${field?.options[index]?.label}`
      })
      .join(','),
    hint: 'Enter the full name of the person (they much exist as a user in the system) followed by a colon and then their role. Where there are multiple people, separate the entries by a comma. You must use one of the roles already defined in the system',
  }
}

const getSponsorOrganization = (field) => {
  return {
    data: `Organization name:${field?.options?.[0]?.label}`,
    hint: 'Enter sponsor organisation (freetext) followed by a colon and then  the sponsor type. Where there are multiple organisations, separate the entries by a comma. You must use one of the types already defined in the system for sponsor type.',
  }
}

const getOrganization = (field) => {
  return { data: null, hint: null }
}

const getCollaboratorPartnerLink = (field) => {
  const hint =
    'Enter collaboration and partnership information using the format: title::organization::department::start date::end date. To link multiple records, use a double pipe (||) as a separator. Ensure that each record is present in the "Collaborations and Partnerships" impact category. If any field—such as title, organization, department, start date, or end date—is empty, use "na" to indicate that the field is empty in the original record. For instance, if the department is empty for a record, represent it as title::organization::na::start date::end date.'
  if (field?.collaboratorRecords?.length > 0) {
    return {
      data: field?.collaboratorRecords
        ?.slice(0, 2)
        .map((record) => {
          return `${record?.title ?? 'na'}::${record?.organization ?? 'na'}::${
            record?.department ?? 'na'
          }::${record?.startDate?.substr(0, 7) ?? 'na'}::${
            record?.endDate?.substr(0, 7) ?? 'na'
          }`
        })
        .join('||'),
      hint,
    }
  }
  return null
}

const getWorkshopOrganization = (field) => {
  return {
    data: `Organisation name:${field?.options?.[0]?.label}`,
    hint: `Enter organisation name (freetext) followed by a colon and then  the recipient type. Where there are multiple organisations, separate the entries by a comma. You must use one of the types already defined in the system for recipient type.`,
  }
}

const getPatentNumber = () => {
  return { data: 'ABC-123-DE', hint: 'Freetext' }
}

const getISBN = () => {
  return { data: '1234-567', hint: 'Freetext' }
}

const getDOI = () => {
  return { data: '10.1234/abcd.5678', hint: 'Freetext' }
}

const getUrl = () => {
  return {
    data: 'https://example.com/abc',
    hint: 'Enter URL',
  }
}

const getCountry = () => {
  return {
    data: 'Australia',
    hint: 'Freetext',
  }
}

const getCity = () => {
  return { data: 'Perth', hint: 'Freetext' }
}

const getField = (field: any, impactCategory: string) => {
  const dateFields = [
    'startDate',
    'endDate',
    'grantedDate',
    'filedDate',
    'priorityDate',
    'proposedSubmissionDate',
    'conferralDate',
  ]

  const hostOrganisationResearchersField = [
    'individualsInvolved',
    'hostOrganisationResearchers',
    'involved',
    'researchers',
  ]
  if (
    field?.optionField &&
    !(
      field?.name === 'organization' &&
      impactCategory === 'WORKSHOPTRAININGFACILITATED'
    )
  ) {
    return getOption(field)
  }

  if (field?.recordSecurity) {
    return getRecordSecurity()
  }

  if (field?.multiSelectField) {
    return getMultiSelect(field)
  }

  if (!!field?.collaboratorPartnerLinkField) {
    return getCollaboratorPartnerLink(field)
  }

  if (field?.sponsorOrganizationField) {
    return getSponsorOrganization(field)
  }

  if (field?.milestonesField) {
    return {
      data: 'Milestone1,Milestone2,Milestone3',
      hint: 'Enter the milestones separated by a comma',
    }
  }

  if (field?.workshopOrganizationField) {
    return getWorkshopOrganization(field)
  }

  if (field?.name === 'country') {
    return getCountry()
  }

  if (field?.name === 'city') {
    return getCity()
  }

  if (
    field?.name === 'identifier' &&
    impactCategory === 'INTELLECTUALPROPERTY'
  ) {
    getPatentNumber()
  }

  if (
    impactCategory === 'PUBLICATION' &&
    [
      'volume',
      'issue',
      'version',
      'pageNumber',
      'endPageNumber',
      'edition',
      'articleNumber',
      'chapterNumber',
    ].includes(field?.name)
  ) {
    return getNumber(field, true)
  }

  if (impactCategory === 'PUBLICATION' && field?.name === 'isbn') {
    return getISBN()
  }

  if (impactCategory === 'PUBLICATION' && field?.name === 'doi') {
    return getDOI()
  }

  if (impactCategory === 'PUBLICATION' && field?.name === 'url') {
    return getUrl()
  }

  if (field?.name === 'value' && impactCategory === 'AWARDPRIZE') {
    getPatentNumber()
  }

  if (field?.schema?.type === 'boolean') {
    const hint = 'Enter yes or no'
    if (field?.name === 'orcidRecord') return { data: 'no', hint }
    return { data: 'yes', hint }
  }

  if (field?.schema?.type === 'number' && field?.name !== 'year') {
    return getNumber(field)
  }

  if (field?.name === 'year' || field?.name === 'yearAwarded') {
    return getYear()
  }

  if (
    field?.name === 'organization' &&
    impactCategory === 'WORKSHOPTRAININGFACILITATED' &&
    !field?.recordSecurity
  ) {
    return getOrganization(field)
  }

  if (dateFields?.includes(field?.name)) {
    const fullDateFields = [
      'startDate',
      'grantedDate',
      'filedDate',
      'priorityDate',
    ]
    const fullDateImpactCategories = ['INTELLECTUALPROPERTY', 'MEDIAMENTION']
    const fullDate =
      fullDateFields?.includes(field?.name) &&
      fullDateImpactCategories.includes(impactCategory)

    if (fullDate) {
      return getFullDate()
    }
    return getDate(field?.name)
  }

  if (field?.name?.startsWith('other')) {
    return {
      data: null,
      hint: "If you select value 'Other Specify' then you can enter freetext in this field",
    }
  }

  if (hostOrganisationResearchersField?.includes(field?.name)) {
    return getHostOrganisationResearchers(field)
  }

  return { data: 'Sample Data', hint: 'Freetext' }
}

const getSampleData = (schema: ImporterSchema, prefix: string) => {
  const impactCategory = prefix.split('_')?.[0]
  const fields = schema.fields?.filter((field) => !field?.hidden)

  const description = {
    [fields[0].label]:
      '***You must delete rows 2,3 and 4 before uploading the spreadsheet***. If your organisation has re-purposed any fields the example provided may not be relevant. ',
  }

  const finalData = fields.reduce(
    (sampleData, field) => {
      const fieldData = getField(field, impactCategory)
      sampleData['hint'][field?.label] = fieldData?.hint
      sampleData['data'][field?.label] = fieldData?.data
      return sampleData
    },
    { hint: {}, data: {} },
  )
  return [description, { ...finalData.hint }, { ...finalData.data }]
}

export default getSampleData

import list from 'src/modules/consumerCommunityInvolvement/list/consumerCommunityInvolvementListReducers'
import form from 'src/modules/consumerCommunityInvolvement/form/consumerCommunityInvolvementFormReducers'
import view from 'src/modules/consumerCommunityInvolvement/view/consumerCommunityInvolvementViewReducers'
import destroy from 'src/modules/consumerCommunityInvolvement/destroy/consumerCommunityInvolvementDestroyReducers'
import importerReducer from 'src/modules/consumerCommunityInvolvement/importer/consumerCommunityInvolvementImporterReducers'
import { combineReducers } from 'redux'

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
})

import MediaMentionService from 'src/modules/mediaMention/mediaMentionService'
import selectors from 'src/modules/mediaMention/list/mediaMentionListSelectors'
import exporterFields from 'src/modules/mediaMention/list/mediaMentionListExporterFields'
import { generateListActions } from '../../listHelpers'

const prefix = 'MEDIAMENTION_LIST'

const mediaMentionListActions = generateListActions(
  prefix,
  MediaMentionService.list,
  selectors,
  exporterFields,
  'startDate',
)

export default mediaMentionListActions

import { i18n } from '../i18n'
import Errors from './shared/error/errors'
import { createSelector } from 'reselect'
import Message from '../components/message'
import { getHistory } from './store'

export const generateFormActions = (prefix: string, service: any) => {
  const keys = {
    INIT_STARTED: `${prefix}_INIT_STARTED`,
    INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
    INIT_ERROR: `${prefix}_INIT_ERROR`,

    CREATE_STARTED: `${prefix}_CREATE_STARTED`,
    CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
    CREATE_ERROR: `${prefix}_CREATE_ERROR`,

    UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
    UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
    UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,
  }

  const doInit = (id) => async (dispatch) => {
    try {
      dispatch({
        type: keys.INIT_STARTED,
      })

      let record = {}

      const isEdit = Boolean(id)

      if (isEdit) {
        record = await service.find(id)
      }

      dispatch({
        type: keys.INIT_SUCCESS,
        payload: record,
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: keys.INIT_ERROR,
      })
    }
  }

  const doCreate =
    (values, impactCategoryDetails = null) =>
    async (dispatch) => {
      try {
        dispatch({
          type: keys.CREATE_STARTED,
        })

        const data = await service.create(values)

        dispatch({
          type: keys.CREATE_SUCCESS,
        })

        Message.success(
          i18n(
            'impactCategories.messages.create.success',
            impactCategoryDetails.alias,
          ),
        )

        getHistory().push(`/${impactCategoryDetails.basePath}/${data.id}/edit`)
      } catch (error) {
        Errors.handle(error)

        dispatch({
          type: keys.CREATE_ERROR,
        })
      }
    }

  const doUpdate =
    (id, values, impactCategoryAlias = null) =>
    async (dispatch) => {
      try {
        dispatch({
          type: keys.UPDATE_STARTED,
        })

        await service.update(id, values)

        dispatch({
          type: keys.UPDATE_SUCCESS,
        })

        Message.success(
          i18n(
            'impactCategories.messages.update.success',
            impactCategoryAlias?.alias,
          ),
        )
      } catch (error) {
        Errors.handle(error)

        dispatch({
          type: keys.UPDATE_ERROR,
        })
      }
    }

  return {
    ...keys,
    doInit,
    doCreate,
    doUpdate,
  }
}

export const generateFormReducers = (actions: any) => {
  const initialData = {
    initLoading: false,
    saveLoading: false,
    record: null,
  }

  return (state = initialData, { type, payload }) => {
    if (type === actions.INIT_STARTED) {
      return {
        ...state,
        record: null,
        initLoading: true,
      }
    }

    if (type === actions.INIT_SUCCESS) {
      return {
        ...state,
        record: payload,
        initLoading: false,
      }
    }

    if (type === actions.INIT_ERROR) {
      return {
        ...state,
        record: null,
        initLoading: false,
      }
    }

    if (type === actions.CREATE_STARTED) {
      return {
        ...state,
        saveLoading: true,
      }
    }

    if (type === actions.CREATE_SUCCESS) {
      return {
        ...state,
        saveLoading: false,
      }
    }

    if (type === actions.CREATE_ERROR) {
      return {
        ...state,
        saveLoading: false,
      }
    }

    if (type === actions.UPDATE_STARTED) {
      return {
        ...state,
        saveLoading: true,
      }
    }

    if (type === actions.UPDATE_SUCCESS) {
      return {
        ...state,
        saveLoading: false,
      }
    }

    if (type === actions.UPDATE_ERROR) {
      return {
        ...state,
        saveLoading: false,
      }
    }

    return state
  }
}

export const generateFormSelectors = (store: string) => {
  const selectRaw = (state) => state[store].form

  const selectRecord = createSelector([selectRaw], (raw) => raw.record)

  const selectInitLoading = createSelector([selectRaw], (raw) =>
    Boolean(raw.initLoading),
  )

  const selectSaveLoading = createSelector([selectRaw], (raw) =>
    Boolean(raw.saveLoading),
  )

  return {
    selectRaw,
    selectRecord,
    selectInitLoading,
    selectSaveLoading,
  }
}

import service from 'src/modules/auth/authService'
import Errors, { DEFAULT_ERROR_MESSAGE } from 'src/modules/shared/error/errors'
import Message from 'src/components/message'
import { i18n } from 'src/i18n'
import { getHistory } from 'src/modules/store'
import { AuthToken } from 'src/modules/auth/authToken'
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant'
import { tenantSubdomain } from '../tenant/tenantSubdomain'
import SettingsService from '../settings/settingsService'
import userListActions from '../admin/user/list/userListActions'
import settingsActions from '../settings/settingsActions'
import GlobalFilterActions from '../GlobalFilter/GlobalFilterActions'

const prefix = 'AUTH'

const authActions = {
  ERROR_MESSAGE_CLEARED: `${prefix}_ERROR_MESSAGE_CLEARED`,
  PASSWORD_RESET_EMAIL_SUCCESS_MESSAGE_CLEARED: `${prefix}_PASSWORD_RESET_EMAIL_SUCCESS_MESSAGE_CLEARED`,

  AUTH_INIT_START: `${prefix}_INIT_START`,
  AUTH_INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  AUTH_INIT_ERROR: `${prefix}_INIT_ERROR`,

  AUTH_START: `${prefix}_START`,
  AUTH_SUCCESS: `${prefix}_SUCCESS`,
  AUTH_ERROR: `${prefix}_ERROR`,

  UPDATE_PROFILE_START: `${prefix}_UPDATE_PROFILE_START`,
  UPDATE_PROFILE_SUCCESS: `${prefix}_UPDATE_PROFILE_SUCCESS`,
  UPDATE_PROFILE_ERROR: `${prefix}_UPDATE_PROFILE_ERROR`,

  PASSWORD_CHANGE_START: `${prefix}_PASSWORD_CHANGE_START`,
  PASSWORD_CHANGE_SUCCESS: `${prefix}_PASSWORD_CHANGE_SUCCESS`,
  PASSWORD_CHANGE_ERROR: `${prefix}_PASSWORD_CHANGE_ERROR`,

  CURRENT_USER_REFRESH_START: `${prefix}_CURRENT_USER_REFRESH_START`,
  CURRENT_USER_REFRESH_SUCCESS: `${prefix}_CURRENT_USER_REFRESH_SUCCESS`,
  CURRENT_USER_REFRESH_ERROR: `${prefix}_CURRENT_USER_REFRESH_ERROR`,

  PASSWORD_RESET_EMAIL_START: `${prefix}_PASSWORD_RESET_EMAIL_START`,
  PASSWORD_RESET_EMAIL_SUCCESS: `${prefix}_PASSWORD_RESET_EMAIL_SUCCESS`,
  PASSWORD_RESET_EMAIL_ERROR: `${prefix}_PASSWORD_RESET_EMAIL_ERROR`,

  PASSWORD_RESET_START: `${prefix}_PASSWORD_RESET_START`,
  PASSWORD_RESET_SUCCESS: `${prefix}_PASSWORD_RESET_SUCCESS`,
  PASSWORD_RESET_ERROR: `${prefix}_PASSWORD_RESET_ERROR`,

  EMAIL_CONFIRMATION_START: `${prefix}_EMAIL_CONFIRMATION_START`,
  EMAIL_CONFIRMATION_SUCCESS: `${prefix}_EMAIL_CONFIRMATION_SUCCESS`,
  EMAIL_CONFIRMATION_ERROR: `${prefix}_EMAIL_CONFIRMATION_ERROR`,

  doClearErrorMessage() {
    return {
      type: authActions.ERROR_MESSAGE_CLEARED,
    }
  },

  doSendEmailConfirmation: () => async (dispatch) => {
    try {
      dispatch({ type: authActions.EMAIL_CONFIRMATION_START })
      await service.sendEmailVerification()
      Message.success(i18n('auth.verificationEmailSuccess'))
      dispatch({
        type: authActions.EMAIL_CONFIRMATION_SUCCESS,
      })
    } catch (error) {
      Errors.handle(error)
      dispatch({ type: authActions.EMAIL_CONFIRMATION_ERROR })
    }
  },

  doSendPasswordResetEmail: (email) => async (dispatch) => {
    try {
      dispatch({
        type: authActions.PASSWORD_RESET_EMAIL_START,
      })
      const response = await service.sendPasswordResetEmail(email)
      dispatch({
        type: authActions.PASSWORD_RESET_EMAIL_SUCCESS,
      })
      if (response) {
        Message.success(i18n('auth.passwordResetEmailSuccess'))
      }
    } catch (error) {
      Errors.handle(error)
      dispatch({
        type: authActions.PASSWORD_RESET_EMAIL_ERROR,
      })
    }
  },

  doSignInWithEmailAndPassword:
    (email, password, rememberMe) => async (dispatch) => {
      try {
        dispatch({ type: authActions.AUTH_START })

        const token = await service.signInWithEmailAndPassword(email, password)

        AuthToken.set(token, rememberMe)
        const { currentUser, currentTenant } =
          await service.fetchCurrentUserAndTenant()
        AuthCurrentTenant.set(currentTenant)

        dispatch({
          type: authActions.AUTH_SUCCESS,
          payload: {
            currentUser,
            currentTenant,
          },
        })

        dispatch({
          type: settingsActions.INIT_SUCCESS,
          payload: currentTenant?.settings?.[0],
        })
      } catch (error) {
        AuthToken.clear()
        dispatch({
          type: authActions.AUTH_ERROR,
          payload:
            error?.response?.data?.message ||
            error?.message ||
            DEFAULT_ERROR_MESSAGE,
        })
      }
    },

  doSignout: (logoutPath?: string) => async (dispatch) => {
    dispatch(userListActions.doClearUserNames())
    dispatch(settingsActions.doClear())
    dispatch(GlobalFilterActions.doReset([]))
    localStorage.clear()
    try {
      dispatch({ type: authActions.AUTH_START })
      AuthToken.clear()
      dispatch({
        type: authActions.AUTH_SUCCESS,
        payload: {
          currentUser: null,
          currentTenant: null,
          logoutAction: true,
          logoutPath: logoutPath,
        },
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: authActions.AUTH_ERROR,
      })
    }
  },

  doInit: () => async (dispatch) => {
    const token = AuthToken.get()
    if (!token) {
      dispatch({
        type: authActions.AUTH_INIT_ERROR,
      })

      return null
    }

    try {
      dispatch({
        type: authActions.AUTH_INIT_START,
      })

      const payload = await service.fetchCurrentUserAndTenant()
      AuthCurrentTenant.set(payload.currentTenant)

      dispatch({
        type: authActions.AUTH_INIT_SUCCESS,
        payload,
      })

      dispatch({
        type: settingsActions.INIT_SUCCESS,
        payload: payload?.currentTenant?.settings?.[0],
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: authActions.AUTH_INIT_ERROR,
      })
    }
  },

  doRefreshCurrentUser: () => async (dispatch) => {
    try {
      dispatch({
        type: authActions.CURRENT_USER_REFRESH_START,
      })

      const payload = await service.fetchCurrentUserAndTenant()
      AuthCurrentTenant.set(payload.currentTenant)

      dispatch({
        type: authActions.CURRENT_USER_REFRESH_SUCCESS,
        payload,
      })
      dispatch({
        type: settingsActions.INIT_SUCCESS,
        payload: payload?.currentTenant?.settings?.[0],
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: authActions.CURRENT_USER_REFRESH_ERROR,
      })
    }
  },

  doUpdateProfile: (data) => async (dispatch) => {
    try {
      dispatch({
        type: authActions.UPDATE_PROFILE_START,
      })

      await service.updateProfile(data)

      dispatch({
        type: authActions.UPDATE_PROFILE_SUCCESS,
      })
      await dispatch(authActions.doRefreshCurrentUser())
      Message.success(i18n('auth.profile.success'))
      getHistory().push('/')
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: authActions.UPDATE_PROFILE_ERROR,
      })
    }
  },

  doChangePassword: (oldPassword, newPassword) => async (dispatch) => {
    try {
      dispatch({
        type: authActions.PASSWORD_CHANGE_START,
      })

      await service.changePassword(oldPassword, newPassword)

      dispatch({
        type: authActions.PASSWORD_CHANGE_SUCCESS,
      })
      await dispatch(authActions.doRefreshCurrentUser())
      Message.success(i18n('auth.passwordChange.success'))
      getHistory().push('/')
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: authActions.PASSWORD_CHANGE_ERROR,
      })
    }
  },

  doResetPassword:
    (token, password, setPassword = false) =>
    async (dispatch) => {
      try {
        dispatch({
          type: authActions.PASSWORD_RESET_START,
        })

        await service.passwordReset(token, password)

        setPassword
          ? Message.success(i18n('auth.setPasswordSuccess'))
          : Message.success(i18n('auth.passwordResetSuccess'))
        dispatch({
          type: authActions.PASSWORD_RESET_SUCCESS,
        })
        getHistory().push('/')
      } catch (error) {
        Errors.handle(error)

        dispatch({
          type: authActions.PASSWORD_RESET_ERROR,
        })
      }
    },

  doSelectTenant: (tenant) => async (dispatch) => {
    dispatch(userListActions.doClearUserNames())
    if (tenantSubdomain.isEnabled) {
      tenantSubdomain.redirectAuthenticatedTo(tenant.url)
      return
    }

    AuthCurrentTenant.set(tenant)

    await dispatch(authActions.doRefreshCurrentUser())
    SettingsService.applyThemeFromTenant()
    getHistory().push('/')
  },

  doSelectTenantInitial: (tenant) => async (dispatch) => {
    AuthCurrentTenant.set(tenant)
    await dispatch(authActions.doRefreshCurrentUser())
    SettingsService.applyThemeFromTenant()
    getHistory().push('/admin/impactcategories')
  },

  doCleaPasswordResetEmailSuccessMessage: () => async (dispatch) => {
    dispatch({
      type: authActions.PASSWORD_RESET_EMAIL_SUCCESS_MESSAGE_CLEARED,
    })
  },
}

export default authActions

import CollaborationPartnershipService from 'src/modules/collaborationPartnership/collaborationPartnershipService'
import Errors from 'src/modules/shared/error/errors'
import { getHistory } from 'src/modules/store'

const prefix = 'COLLABORATIONPARTNERSHIP_VIEW'

const collaborationPartnershipViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: collaborationPartnershipViewActions.FIND_STARTED,
      })

      const record = await CollaborationPartnershipService.find(id)

      dispatch({
        type: collaborationPartnershipViewActions.FIND_SUCCESS,
        payload: record,
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: collaborationPartnershipViewActions.FIND_ERROR,
      })

      getHistory().push('/collaboration-partnership')
    }
  },
}

export default collaborationPartnershipViewActions

import MentoringSupervisionService from 'src/modules/mentoringSupervision/mentoringSupervisionService'
import selectors from 'src/modules/mentoringSupervision/list/mentoringSupervisionListSelectors'
import exporterFields from 'src/modules/mentoringSupervision/list/mentoringSupervisionListExporterFields'
import { generateListActions } from '../../listHelpers'

const prefix = 'MENTORINGSUPERVISION_LIST'

const mentoringSupervisionListActions = generateListActions(
  prefix,
  MentoringSupervisionService.list,
  selectors,
  exporterFields,
  'startDate',
)

export default mentoringSupervisionListActions

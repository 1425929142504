import MenuBlock, { MenuOrdering } from './menuBlock'
import { MenuItem } from 'src/hooks/useMenu'

interface IndicatorMenuProps {
  menuItems: MenuItem[]
  selectedKey: string
}

function IndicatorMenu({ menuItems, selectedKey }: IndicatorMenuProps) {
  const availableItems = menuItems.filter(
    (menu) => !menu.area && menu.type === 'indicator' && menu.enabled,
  )

  return (
    <MenuBlock
      titleKey="impactCategories.indicators"
      availableItems={availableItems}
      selectedKey={selectedKey}
      ordering={MenuOrdering.Alphabetical}
    />
  )
}

export default IndicatorMenu

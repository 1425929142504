import schemas from 'src/modules/shared/yup/yupImporterSchemas'
import { i18n } from 'src/i18n'
import providingExternalExpertiseEnumerators from 'src/modules/providingExternalExpertise/providingExternalExpertiseEnumerators'

export default [
  {
    name: 'title',
    label: i18n('entities.providingExternalExpertise.fields.title'),
    schema: schemas.string(
      i18n('entities.providingExternalExpertise.fields.title'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'researcher',
    label: i18n('entities.providingExternalExpertise.fields.researcher'),
    schema: schemas.relationToMany(
      i18n('entities.providingExternalExpertise.fields.researcher'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'expertiseType',
    label: i18n('entities.providingExternalExpertise.fields.expertiseType'),
    schema: schemas.string(
      i18n('entities.providingExternalExpertise.fields.expertiseType'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'invited',
    label: i18n('entities.providingExternalExpertise.fields.invited'),
    schema: schemas.string(
      i18n('entities.providingExternalExpertise.fields.invited'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'organization',
    label: i18n('entities.providingExternalExpertise.fields.organization'),
    schema: schemas.string(
      i18n('entities.providingExternalExpertise.fields.organization'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'recipientType',
    label: i18n('entities.providingExternalExpertise.fields.recipientType'),
    schema: schemas.string(
      i18n('entities.providingExternalExpertise.fields.recipientType'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'description',
    label: i18n('entities.providingExternalExpertise.fields.description'),
    schema: schemas.string(
      i18n('entities.providingExternalExpertise.fields.description'),
      {
        max: 1000,
      },
    ),
  },
  {
    name: 'startDate',
    label: i18n('entities.providingExternalExpertise.fields.startDate'),
    schema: schemas.datetime(
      i18n('entities.providingExternalExpertise.fields.startDate'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'endDate',
    label: i18n('entities.providingExternalExpertise.fields.endDate'),
    schema: schemas.datetime(
      i18n('entities.providingExternalExpertise.fields.endDate'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'impact',
    label: i18n('entities.providingExternalExpertise.fields.impact'),
    schema: schemas.string(
      i18n('entities.providingExternalExpertise.fields.impact'),
      {},
    ),
  },
  {
    name: 'files',
    label: i18n('entities.providingExternalExpertise.fields.files'),
    schema: schemas.files(
      i18n('entities.providingExternalExpertise.fields.files'),
      {},
    ),
  },
  {
    name: 'images',
    label: i18n('entities.providingExternalExpertise.fields.images'),
    schema: schemas.images(
      i18n('entities.providingExternalExpertise.fields.images'),
      {},
    ),
  },
  {
    name: 'status',
    label: i18n('entities.providingExternalExpertise.fields.status'),
    schema: schemas.enumerator(
      i18n('entities.providingExternalExpertise.fields.status'),
      {
        required: true,
        options: providingExternalExpertiseEnumerators.status,
      },
    ),
  },
  {
    name: 'recordType',
    label: i18n('entities.providingExternalExpertise.fields.recordType'),
    schema: schemas.enumerator(
      i18n('entities.providingExternalExpertise.fields.recordType'),
      {
        options: providingExternalExpertiseEnumerators.recordType,
      },
    ),
  },
  {
    name: 'year',
    label: i18n('entities.providingExternalExpertise.fields.year'),
    schema: schemas.integer(
      i18n('entities.providingExternalExpertise.fields.year'),
      {},
    ),
  },
]

import authAxios from 'src/modules/shared/axios/authAxios'
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant'
import { i18n } from 'src/i18n'
import Permissions from 'src/security/permissions'

export default class ImpactCategoriesService {
  static async update(data) {
    const body = {
      data,
    }

    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.put(
      `/tenant/${tenantId}/tenantImpactCategories`,
      body,
    )

    return response.data
  }

  static async find(id) {
    const tenantId = AuthCurrentTenant.get()
    const response = await authAxios.get(
      `/tenant/${tenantId}/tenantImpactCategories/${id}`,
    )
    return response.data
  }

  static async fetchImpactCategories(enabled, orderBy = null) {
    const tenantId = AuthCurrentTenant.get()
    const params = {
      enabled,
      orderBy,
    }

    const response = await authAxios.get(
      `/tenant/${tenantId}/tenantImpactCategories`,
      { params },
    )
    return response.data
  }

  static async fetchImpactCategorySaveFilters(id) {
    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.get(
      `/tenant/${tenantId}/tenantImpactCategories/${id}/savedFilters`,
    )
    return response.data
  }

  static async createImpactCategorySaveFilters(id, data) {
    const tenantId = AuthCurrentTenant.get()
    const response = await authAxios.post(
      `/tenant/${tenantId}/tenantImpactCategories/${id}/savedFilters`,
      { data },
    )
    return response.data
  }

  static async updateImpactCategorySaveFilters(impactCategoryId, id, data) {
    const body = {
      ...data,
    }

    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.put(
      `/tenant/${tenantId}/tenantImpactCategories/${impactCategoryId}/savedFilters/${id}`,
      body,
    )

    return response.data
  }

  static async deleteImpactCategorySaveFilters(id, savedFilterId) {
    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.delete(
      `/tenant/${tenantId}/tenantImpactCategories/${id}/savedFilters/${savedFilterId}`,
    )

    return response.data
  }

  static async fetchImpactCategorySaveFilter(id, savedFilterId) {
    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.get(
      `/tenant/${tenantId}/tenantImpactCategories/${id}/savedFilters/${savedFilterId}`,
    )

    return response.data
  }

  static async fetchImpactCategoriesMenuItems() {
    const menuArray = []
    const idsArray = []
    const permissions = Permissions.values
    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.get(
      `/tenant/${tenantId}/tenantImpactCategories`,
    )

    response.data.rows.map((menuItem) => {
      menuArray[menuItem.impactCategory] = { ...menuItem }
      if (menuItem.enabled) idsArray[menuItem.impactCategory] = menuItem.id
      return idsArray
    })

    const menuConfig = [
      {
        path: '/admin/user',
        label: i18n('user.menu'),
        permissionRequired: [permissions.userCreate, permissions.userEdit],
        icon: 'fas fa-user',
        area: '/admin/',
      },

      {
        path: '/admin/groups',
        label: i18n('groups.menu'),
        permissionRequired: [permissions.groupCreate, permissions.groupEdit],
        icon: 'fas fa-users',
        area: '/admin/',
      },

      {
        path: '/admin/orgStructure',
        label: i18n('orgStructure.menu'),
        permissionRequired: [
          permissions.hierarchyCreate,
          permissions.hierarchyEdit,
        ],
        icon: 'fas fa-sitemap',
        area: '/admin/',
        exact: true,
      },
      {
        path: '/admin/orgStructure/manageunits',
        label: i18n('orgStructure.hierarchy.menu'),
        indent: true,
        icon: 'fas fa-sitemap',
        permissionRequired: [
          permissions.hierarchyCreate,
          permissions.hierarchyEdit,
        ],
        area: '/admin/',
        exact: true,
      },

      {
        path: '/admin/impactcategories',
        label: i18n('impactCategories.menu'),
        icon: 'fas fa-bars',
        permissionRequired: permissions.impactCategoryManage,
        area: '/admin/',
      },
      {
        path: '/admin/settings',
        icon: 'fas fa-cog',
        label: i18n('settings.menu'),
        permissionRequired: permissions.settingsEdit,
        area: '/admin/',
      },
      {
        path: '/admin/audit-logs',
        label: i18n('auditLog.menu'),
        icon: 'fas fa-clipboard-list',
        permissionRequired: permissions.auditLogRead,
        area: '/admin/',
      },
      {
        path: '/admin/integrations',
        icon: 'fas fa-cog',
        label: i18n('integrations.menu'),
        permissionRequired: permissions.integrationRead,
        area: '/admin/',
      },
      {
        path: '/research-team',
        permissionRequired: permissions.researchTeamRead,
        icon: 'fas fa-users',
        label: menuArray['researchTeam'].alias,
        enabled: menuArray['researchTeam'].enabled,
        type: menuArray['researchTeam'].categoryType,
        hint: menuArray['researchTeam'].hint,
        impactCategory: menuArray['researchTeam'].impactCategory,
        id: menuArray['researchTeam'].id,
      },

      {
        path: '/research-themes-intended-impact',
        permissionRequired: permissions.researchThemesIntendedImpactRead,
        icon: 'fas fa-lightbulb',
        label: menuArray['researchThemesIntendedImpact'].alias,
        enabled: menuArray['researchThemesIntendedImpact'].enabled,
        type: menuArray['researchThemesIntendedImpact'].categoryType,
        hint: menuArray['researchThemesIntendedImpact'].hint,
        impactCategory:
          menuArray['researchThemesIntendedImpact'].impactCategory,
        id: menuArray['researchThemesIntendedImpact'].id,
      },

      {
        path: '/current-project',
        permissionRequired: permissions.currentProjectRead,
        icon: 'fas fa-clipboard-list',
        label: menuArray['currentProject'].alias,
        enabled: menuArray['currentProject'].enabled,
        type: menuArray['currentProject'].categoryType,
        id: menuArray['currentProject'].id,
        hint: menuArray['currentProject'].hint,
        impactCategory: menuArray['currentProject'].impactCategory,
      },

      {
        path: '/collaboration-partnership',
        permissionRequired: permissions.collaborationPartnershipRead,
        icon: 'fas fa-hands-helping',
        label: menuArray['collaborationPartnership'].alias,
        enabled: menuArray['collaborationPartnership'].enabled,
        type: menuArray['collaborationPartnership'].categoryType,
        hint: menuArray['collaborationPartnership'].hint,
        impactCategory: menuArray['collaborationPartnership'].impactCategory,
        id: menuArray['collaborationPartnership'].id,
      },

      {
        path: '/external-decision-making-advisory-group',
        permissionRequired: permissions.externalDecisionMakingAdvisoryGroupRead,
        icon: 'fas fa-users',
        label: menuArray['externalDecisionMakingAdvisoryGroup'].alias,
        enabled: menuArray['externalDecisionMakingAdvisoryGroup'].enabled,
        type: menuArray['externalDecisionMakingAdvisoryGroup'].categoryType,
        id: menuArray['externalDecisionMakingAdvisoryGroup'].id,
        hint: menuArray['externalDecisionMakingAdvisoryGroup'].hint,
        impactCategory:
          menuArray['externalDecisionMakingAdvisoryGroup'].impactCategory,
      },

      {
        path: '/providing-external-expertise',
        permissionRequired: permissions.providingExternalExpertiseRead,
        icon: 'fas fa-graduation-cap',
        label: menuArray['providingExternalExpertise'].alias,
        enabled: menuArray['providingExternalExpertise'].enabled,
        type: menuArray['providingExternalExpertise'].categoryType,
        id: menuArray['providingExternalExpertise'].id,
        hint: menuArray['providingExternalExpertise'].hint,
        impactCategory: menuArray['providingExternalExpertise'].impactCategory,
      },

      {
        path: '/collaborative-network',
        permissionRequired: permissions.collaborativeNetworkRead,
        icon: 'fas fa-project-diagram',
        label: menuArray['collaborativeNetwork'].alias,
        enabled: menuArray['collaborativeNetwork'].enabled,
        type: menuArray['collaborativeNetwork'].categoryType,
        id: menuArray['collaborativeNetwork'].id,
        hint: menuArray['collaborativeNetwork'].hint,
        impactCategory: menuArray['collaborativeNetwork'].impactCategory,
      },

      {
        path: '/guideline-policy-practice',
        permissionRequired: permissions.guidelinePolicyPracticeRead,
        icon: 'fas fa-sticky-note',
        label: menuArray['guidelinePolicyPractice'].alias,
        enabled: menuArray['guidelinePolicyPractice'].enabled,
        type: menuArray['guidelinePolicyPractice'].categoryType,
        id: menuArray['guidelinePolicyPractice'].id,
        hint: menuArray['guidelinePolicyPractice'].hint,
        impactCategory: menuArray['guidelinePolicyPractice'].impactCategory,
      },

      {
        path: '/consumer-community-involvement',
        permissionRequired: permissions.consumerCommunityInvolvementRead,
        icon: 'fas fa-house-user',
        label: menuArray['consumerCommunityInvolvement'].alias,
        enabled: menuArray['consumerCommunityInvolvement'].enabled,
        type: menuArray['consumerCommunityInvolvement'].categoryType,
        id: menuArray['consumerCommunityInvolvement'].id,
        hint: menuArray['consumerCommunityInvolvement'].hint,
        impactCategory:
          menuArray['consumerCommunityInvolvement'].impactCategory,
      },

      {
        path: '/oral-presentation',
        permissionRequired: permissions.oralPresentationRead,
        icon: 'fas fa-desktop',
        label: menuArray['oralPresentation'].alias,
        enabled: menuArray['oralPresentation'].enabled,
        type: menuArray['oralPresentation'].categoryType,
        id: menuArray['oralPresentation'].id,
        hint: menuArray['oralPresentation'].hint,
        impactCategory: menuArray['oralPresentation'].impactCategory,
      },

      {
        path: '/reports',
        permissionRequired: permissions.reportRead,
        icon: 'fas fa-clipboard',
        label: menuArray['report'].alias,
        enabled: menuArray['report'].enabled,
        type: menuArray['report'].categoryType,
        id: menuArray['report'].id,
        hint: menuArray['report'].hint,
        impactCategory: menuArray['report'].impactCategory,
      },

      {
        path: '/tools-product-service',
        permissionRequired: permissions.toolsProductServiceRead,
        icon: 'fas fa-wrench',
        label: menuArray['toolsProductService'].alias,
        enabled: menuArray['toolsProductService'].enabled,
        type: menuArray['toolsProductService'].categoryType,
        id: menuArray['toolsProductService'].id,
        hint: menuArray['toolsProductService'].hint,
        impactCategory: menuArray['toolsProductService'].impactCategory,
      },
      {
        path: '/award-prizes',
        permissionRequired: permissions.awardPrizesRead,
        icon: 'fas fa-award',
        label: menuArray['awardPrizes'].alias,
        enabled: menuArray['awardPrizes'].enabled,
        type: menuArray['awardPrizes'].categoryType,
        hint: menuArray['awardPrizes'].hint,
        impactCategory: menuArray['awardPrizes'].impactCategory,
        id: menuArray['awardPrizes'].id,
      },

      {
        path: '/mentoring-supervision',
        permissionRequired: permissions.mentoringSupervisionRead,
        icon: 'fas fa-user-friends',
        label: menuArray['mentoringSupervision'].alias,
        enabled: menuArray['mentoringSupervision'].enabled,
        type: menuArray['mentoringSupervision'].categoryType,
        id: menuArray['mentoringSupervision'].id,
        hint: menuArray['mentoringSupervision'].hint,
        impactCategory: menuArray['mentoringSupervision'].impactCategory,
      },

      {
        path: '/case-study',
        permissionRequired: permissions.caseStudyRead,
        icon: 'fas fa-user-check',
        label: menuArray['caseStudy'].alias,
        enabled: menuArray['caseStudy'].enabled,
        type: menuArray['caseStudy'].categoryType,
        id: menuArray['caseStudy'].id,
        hint: menuArray['caseStudy'].hint,
        impactCategory: menuArray['caseStudy'].impactCategory,
      },

      {
        path: '/community-consumer-literature',
        permissionRequired: permissions.communityConsumerLiteratureRead,
        icon: 'fas fa-book-open',
        label: menuArray['communityConsumerLiterature'].alias,
        enabled: menuArray['communityConsumerLiterature'].enabled,
        type: menuArray['communityConsumerLiterature'].categoryType,
        id: menuArray['communityConsumerLiterature'].id,
        hint: menuArray['communityConsumerLiterature'].hint,
        impactCategory: menuArray['communityConsumerLiterature'].impactCategory,
      },

      {
        path: '/funding',
        permissionRequired: permissions.fundingRead,
        icon: 'fas fa-dollar-sign',
        label: menuArray['funding'].alias,
        enabled: menuArray['funding'].enabled,
        type: menuArray['funding'].categoryType,
        hint: menuArray['funding'].hint,
        impactCategory: menuArray['funding'].impactCategory,
        id: menuArray['funding'].id,
      },

      {
        path: '/barriers-support',
        permissionRequired: permissions.barriersSupportRead,
        icon: 'fas fa-times-circle',
        label: menuArray['barriersSupport'].alias,
        enabled: menuArray['barriersSupport'].enabled,
        type: menuArray['barriersSupport'].categoryType,
        id: menuArray['barriersSupport'].id,
        hint: menuArray['barriersSupport'].hint,
        impactCategory: menuArray['barriersSupport'].impactCategory,
      },

      {
        path: '/editorial-reviewer-responsibility',
        permissionRequired: permissions.editorialReviewerResponsibilityRead,
        icon: 'fas fa-tasks',
        label: menuArray['editorialReviewerResponsibility'].alias,
        enabled: menuArray['editorialReviewerResponsibility'].enabled,
        type: menuArray['editorialReviewerResponsibility'].categoryType,
        id: menuArray['editorialReviewerResponsibility'].id,
        hint: menuArray['editorialReviewerResponsibility'].hint,
        impactCategory:
          menuArray['editorialReviewerResponsibility'].impactCategory,
      },

      {
        path: '/education-training-material',
        permissionRequired: permissions.educationTrainingMaterialRead,
        icon: 'fas fa-user-graduate',
        label: menuArray['educationTrainingMaterial'].alias,
        enabled: menuArray['educationTrainingMaterial'].enabled,
        type: menuArray['educationTrainingMaterial'].categoryType,
        hint: menuArray['educationTrainingMaterial'].hint,
        impactCategory: menuArray['educationTrainingMaterial'].impactCategory,
        id: menuArray['educationTrainingMaterial'].id,
      },

      {
        path: '/intervention-study',
        permissionRequired: permissions.interventionStudyRead,
        icon: 'fab fa-buffer',
        label: menuArray['interventionStudy'].alias,
        enabled: menuArray['interventionStudy'].enabled,
        type: menuArray['interventionStudy'].categoryType,
        id: menuArray['interventionStudy'].id,
        hint: menuArray['interventionStudy'].hint,
        impactCategory: menuArray['interventionStudy'].impactCategory,
      },

      {
        path: '/in-kind-contribution',
        permissionRequired: permissions.inKindContributionRead,
        icon: 'fas fa-handshake',
        label: menuArray['inKindContribution'].alias,
        enabled: menuArray['inKindContribution'].enabled,
        type: menuArray['inKindContribution'].categoryType,
        id: menuArray['inKindContribution'].id,
        hint: menuArray['inKindContribution'].hint,
        impactCategory: menuArray['inKindContribution'].impactCategory,
      },

      {
        path: '/media-mention',
        permissionRequired: permissions.mediaMentionRead,
        icon: 'fas fa-hashtag',
        label: menuArray['mediaMention'].alias,
        enabled: menuArray['mediaMention'].enabled,
        type: menuArray['mediaMention'].categoryType,
        hint: menuArray['mediaMention'].hint,
        impactCategory: menuArray['mediaMention'].impactCategory,
        id: menuArray['mediaMention'].id,
      },
      {
        path: '/publication',
        permissionRequired: permissions.publicationRead,
        icon: 'fas fa-book',
        label: menuArray['publication'].alias,
        enabled: menuArray['publication'].enabled,
        type: menuArray['publication'].categoryType,
        id: menuArray['publication'].id,
        hint: menuArray['publication'].hint,
        impactCategory: menuArray['publication'].impactCategory,
      },

      {
        path: '/testimonial',
        permissionRequired: permissions.testimonialRead,
        icon: 'fas fa-comment',
        label: menuArray['testimonial'].alias,
        enabled: menuArray['testimonial'].enabled,
        type: menuArray['testimonial'].categoryType,
        id: menuArray['testimonial'].id,
        hint: menuArray['testimonial'].hint,
        impactCategory: menuArray['testimonial'].impactCategory,
      },

      {
        path: '/workshop-training-facilitated',
        permissionRequired: permissions.workshopTrainingFacilitatedRead,
        icon: 'fas fa-graduation-cap',
        label: menuArray['workshopTrainingFacilitated'].alias,
        enabled: menuArray['workshopTrainingFacilitated'].enabled,
        type: menuArray['workshopTrainingFacilitated'].categoryType,
        id: menuArray['workshopTrainingFacilitated'].id,
        hint: menuArray['workshopTrainingFacilitated'].hint,
        impactCategory: menuArray['workshopTrainingFacilitated'].impactCategory,
      },

      {
        path: '/intellectual-property',
        permissionRequired: permissions.intellectualPropertyRead,
        icon: 'fas fa-copyright',
        label: menuArray['intellectualProperty'].alias,
        enabled: menuArray['intellectualProperty'].enabled,
        type: menuArray['intellectualProperty'].categoryType,
        id: menuArray['intellectualProperty'].id,
        hint: menuArray['intellectualProperty'].hint,
        impactCategory: menuArray['intellectualProperty'].impactCategory,
      },
    ]
    return { menuConfig, idsArray }
  }
}

import list from 'src/modules/awardPrizes/list/awardPrizesListReducers'
import form from 'src/modules/awardPrizes/form/awardPrizesFormReducers'
import view from 'src/modules/awardPrizes/view/awardPrizesViewReducers'
import destroy from 'src/modules/awardPrizes/destroy/awardPrizesDestroyReducers'
import importerReducer from 'src/modules/awardPrizes/importer/awardPrizesImporterReducers'
import { combineReducers } from 'redux'

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
})

import MenuBlock, { MenuOrdering } from './menuBlock'

interface AdminMenuProps {
  menuItems: any[]
  selectedKey: string
  match(any): boolean
}

function AdminMenu({ menuItems, selectedKey, match }: AdminMenuProps) {
  const availableItems = menuItems.filter(
    (menu) => menu?.area === '/admin/' && match(menu.permissionRequired),
  )

  return (
    <MenuBlock
      titleKey="header.navItems.admin.label"
      availableItems={availableItems}
      selectedKey={selectedKey}
      ordering={MenuOrdering.AsIs}
    />
  )
}

export default AdminMenu

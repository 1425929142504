import BarriersSupportService from 'src/modules/barriersSupport/barriersSupportService'
import selectors from 'src/modules/barriersSupport/list/barriersSupportListSelectors'
import exporterFields from 'src/modules/barriersSupport/list/barriersSupportListExporterFields'
import { generateListActions } from '../../listHelpers'

const prefix = 'BARRIERSSUPPORT_LIST'

const barriersSupportListActions = generateListActions(
  prefix,
  BarriersSupportService.list,
  selectors,
  exporterFields,
  'year',
)

export default barriersSupportListActions

import schemas from 'src/modules/shared/yup/yupImporterSchemas'
import { i18n } from 'src/i18n'
import caseStudyEnumerators from 'src/modules/caseStudy/caseStudyEnumerators'

export default [
  {
    name: 'title',
    label: i18n('entities.caseStudy.fields.title'),
    schema: schemas.string(i18n('entities.caseStudy.fields.title'), {
      required: true,
    }),
  },
  {
    name: 'caseStudyType',
    label: i18n('entities.caseStudy.fields.caseStudyType'),
    schema: schemas.enumerator(
      i18n('entities.caseStudy.fields.caseStudyType'),
      {
        required: true,
        options: caseStudyEnumerators.caseStudyType,
      },
    ),
  },
  {
    name: 'impactType',
    label: i18n('entities.caseStudy.fields.impactType'),
    schema: schemas.boolean(i18n('entities.caseStudy.fields.impactType')),
  },
  {
    name: 'projectId',
    label: i18n('entities.caseStudy.fields.projectId'),
    schema: schemas.relationToMany(
      i18n('entities.caseStudy.fields.projectId'),
      {},
    ),
  },
  {
    name: 'files',
    label: i18n('entities.caseStudy.fields.files'),
    schema: schemas.files(i18n('entities.caseStudy.fields.files'), {}),
  },
  {
    name: 'images',
    label: i18n('entities.caseStudy.fields.images'),
    schema: schemas.images(i18n('entities.caseStudy.fields.images'), {}),
  },
  {
    name: 'status',
    label: i18n('entities.caseStudy.fields.status'),
    schema: schemas.enumerator(i18n('entities.caseStudy.fields.status'), {
      required: true,
      options: caseStudyEnumerators.status,
    }),
  },
  {
    name: 'researchers',
    label: i18n('entities.caseStudy.fields.researchers'),
    schema: schemas.relationToMany(
      i18n('entities.caseStudy.fields.researchers'),
      {},
    ),
  },
  {
    name: 'recordType',
    label: i18n('entities.caseStudy.fields.recordType'),
    schema: schemas.enumerator(i18n('entities.caseStudy.fields.recordType'), {
      options: caseStudyEnumerators.recordType,
    }),
  },
  {
    name: 'year',
    label: i18n('entities.caseStudy.fields.year'),
    schema: schemas.integer(i18n('entities.caseStudy.fields.year'), {
      required: true,
    }),
  },
]

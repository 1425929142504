const guidelinePolicyPracticeEnumerators = {
  type: ['guideline', 'policy', 'practice'],
  role: [
    'chief_investigator',
    'project_team_member',
    'associated_investigator',
    'consultant',
    'student',
    'advisor',
    'support/coordinator',
    'project_manager',
    'other_specify',
  ],
  contribution: ['low', 'medium', 'high'],
  impactType1: [
    'cited_in_clinical/public_health_guidelines',
    'cited_in_health_policy_documents',
    'cited_in_other_policy_documents',
    'cited_in_health_advocacy_documents_used_in_education',
    'training_or_professional development_to_improve_child_health_and_development_outcomes',
    'changed_practice_in_a_clinic',
    'hospital_or_other_community_care_environment',
    'other_specify',
  ],
  sector: [
    'health_clinical',
    'health_other',
    'justice',
    'education',
    'community_services',
    'standards',
    'other-specify',
  ],
  scope: [
    'Perth-metro',
    'WA-state_wide',
    'National',
    'International',
    'WA-Gascoyne',
    'WA-Goldfields-Esperance',
    'WA-Great_Southern',
    'WA-Kimberley',
    'WA-Mid_West',
    'WA-Peel',
    'WA-Pilbara',
    'WA-South',
    'West',
    'WA-Wheatbelt',
    'National-NSW',
    'National-NT',
    'National-QLD',
    'National-SA',
    'National-TAS National-VIC',
    'International-Africa',
    'International-Asia',
    'International-Central America',
    'International-Eastern_Europe',
    'International-European_Union',
    'International-Middle_East',
    'International-North_America',
    'International-Oceania',
    'International-South_America',
    'International-Caribbean',
  ],
  impactType2: [
    'paradigm_shift_in_prevention',
    'diagnosis',
    'treatment_or_cure_of_health_or_developmental_problems',
    'health_and/or_development_impact',
    'change_in_environmental',
    'health_and/or_development_determinants',
    'change_in_social_health_or_development_determinants',
    'modification_of_risk_factors',
    'modification_to_other',
    'determinants_of health_and/or_development',
    'economic_benefits',
    'other_specify',
  ],
  status: ['draft', 'published', 'archived'],
  recordType: ['Academic', 'Professional'],
}

export default guidelinePolicyPracticeEnumerators

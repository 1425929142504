import PermissionChecker from 'src/modules/auth/permissionChecker'
import React from 'react'
import { Navigate, Route } from 'react-router-dom'

function EmptyPermissionsRoute(props) {
  const { component: Component, currentTenant, currentUser, ...rest } = props

  const permissionChecker = new PermissionChecker(currentTenant, currentUser)

  if (!permissionChecker.isAuthenticated) {
    return (
      <Navigate
        to={{
          pathname: '/auth/signin',
        }}
      />
    )
  }

  if (!permissionChecker.isEmptyPermissions) {
    return <Navigate to="/" />
  }

  return <Component {...props} />
}

export default EmptyPermissionsRoute

import authAxios from 'src/modules/shared/axios/authAxios'
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant'
import AuthInvitationToken from 'src/modules/auth/authInvitationToken'
import { tenantSubdomain } from '../tenant/tenantSubdomain'

export default class AuthService {
  static async sendEmailVerification() {
    const response = await authAxios.post(
      '/auth/send-email-address-verification-email',
      {
        tenantId: tenantSubdomain.isSubdomain
          ? AuthCurrentTenant.get()
          : undefined,
      },
    )

    return response.data
  }

  static async sendPasswordResetEmail(email) {
    const response = await authAxios.post('/auth/send-password-reset-email', {
      email,
      tenantId: tenantSubdomain.isSubdomain
        ? AuthCurrentTenant.get()
        : undefined,
    })

    return response.data
  }

  static async registerWithEmailAndPassword(email, password) {
    const invitationToken = AuthInvitationToken.get()

    const response = await authAxios.post('/auth/sign-up', {
      email,
      password,
      invitationToken,
      tenantId: tenantSubdomain.isSubdomain
        ? AuthCurrentTenant.get()
        : undefined,
    })

    AuthInvitationToken.clear()

    return response.data
  }

  static async signInWithEmailAndPassword(email, password) {
    const invitationToken = AuthInvitationToken.get()

    const response = await authAxios.post('/auth/sign-in', {
      email,
      password,
      invitationToken,
      tenantId: tenantSubdomain.isSubdomain
        ? AuthCurrentTenant.get()
        : undefined,
    })

    AuthInvitationToken.clear()

    return response.data
  }

  static async fetchMe(tenantId) {
    const response = await authAxios.get(`/auth/${tenantId}/me`)
    return response.data
  }

  static async fetchORCIDUser(tenantId) {
    const response = await authAxios.get(`/tenant/${tenantId}/orcid/person`)
    return response.data
  }

  static async fetchCurrentUserAndTenant() {
    const currentTenantId = AuthCurrentTenant.get()

    const currentUser = await this.fetchMe(currentTenantId)
    const tenants = currentUser?.tenants.filter(
      (item) => item.status === 'active',
    )
    const currentTenant =
      tenants.find((item) => item.tenantId === currentTenantId)?.tenant ||
      tenants?.[0]?.tenant ||
      null

    if (currentTenant?.orcid?.enabled && currentTenant?.orcid?.settingEnabled) {
      const ocidUser = await this.fetchORCIDUser(currentTenant?.id)
      currentTenant.orcid.user = ocidUser
    }

    return { currentUser, currentTenant }
  }

  static async updateProfile(data) {
    const body = {
      data,
    }

    const response = await authAxios.put('/auth/profile', body)

    return response.data
  }

  static async changePassword(oldPassword, newPassword) {
    const body = {
      oldPassword,
      newPassword,
    }

    const response = await authAxios.put('/auth/change-password', body)

    return response.data
  }

  static async passwordReset(token, password) {
    const response = await authAxios.put('/auth/password-reset', {
      token,
      password,
      tenantId: tenantSubdomain.isSubdomain
        ? AuthCurrentTenant.get()
        : undefined,
    })

    return response.data
  }

  static async verifyEmail(token) {
    const response = await authAxios.put('/auth/verify-email', {
      token,
      tenantId: tenantSubdomain.isSubdomain
        ? AuthCurrentTenant.get()
        : undefined,
    })

    return response.data
  }
}

import schemas from 'src/modules/shared/yup/yupImporterSchemas'
import { i18n } from 'src/i18n'
import mediaMentionEnumerators from 'src/modules/mediaMention/mediaMentionEnumerators'

export default [
  {
    name: 'title',
    label: i18n('entities.mediaMention.fields.title'),
    schema: schemas.string(i18n('entities.mediaMention.fields.title'), {
      required: true,
    }),
  },
  {
    name: 'researcher',
    label: i18n('entities.mediaMention.fields.researcher'),
    schema: schemas.relationToOne(
      i18n('entities.mediaMention.fields.researcher'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'media',
    label: i18n('entities.mediaMention.fields.media'),
    schema: schemas.string(i18n('entities.mediaMention.fields.media'), {
      required: true,
    }),
  },
  {
    name: 'organization',
    label: i18n('entities.mediaMention.fields.organization'),
    schema: schemas.string(i18n('entities.mediaMention.fields.organization'), {
      required: true,
    }),
  },
  {
    name: 'country',
    label: i18n('entities.mediaMention.fields.country'),
    schema: schemas.string(i18n('entities.mediaMention.fields.country'), {}),
  },
  {
    name: 'startDate',
    label: i18n('entities.mediaMention.fields.startDate'),
    schema: schemas.datetime(i18n('entities.mediaMention.fields.startDate'), {
      required: true,
    }),
  },
  {
    name: 'description',
    label: i18n('entities.mediaMention.fields.description'),
    schema: schemas.string(i18n('entities.mediaMention.fields.description'), {
      required: true,
      max: 1000,
    }),
  },
  {
    name: 'files',
    label: i18n('entities.mediaMention.fields.files'),
    schema: schemas.files(i18n('entities.mediaMention.fields.files'), {}),
  },
  {
    name: 'images',
    label: i18n('entities.mediaMention.fields.images'),
    schema: schemas.images(i18n('entities.mediaMention.fields.images'), {}),
  },
  {
    name: 'status',
    label: i18n('entities.mediaMention.fields.status'),
    schema: schemas.enumerator(i18n('entities.mediaMention.fields.status'), {
      required: true,
      options: mediaMentionEnumerators.status,
    }),
  },
  {
    name: 'recordType',
    label: i18n('entities.mediaMention.fields.recordType'),
    schema: schemas.enumerator(
      i18n('entities.mediaMention.fields.recordType'),
      {
        options: mediaMentionEnumerators.recordType,
      },
    ),
  },
  {
    name: 'year',
    label: i18n('entities.mediaMention.fields.year'),
    schema: schemas.integer(i18n('entities.mediaMention.fields.year'), {}),
  },
]

import authAxios from 'src/modules/shared/axios/authAxios'
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant'

export default class HierarchyValuesService {
  static async edit(id, data) {
    const body = {
      data,
    }

    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.put(
      `/tenant/${tenantId}/hierarchies/${id}`,
      body,
    )
    return response.data
  }

  static async destroy(hierarchyId, id) {
    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.delete(
      `/tenant/${tenantId}/hierarchies/${hierarchyId}/values/${id}`,
    )

    return response.data
  }

  static async create(data) {
    const body = {
      data,
    }

    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.put(
      `/tenant/${tenantId}/hierarchies/${data.hierarchyId}/values`,
      body,
    )

    return response.data
  }

  static async get(id) {
    const tenantId = AuthCurrentTenant.get()
    const response = await authAxios.get(
      `/tenant/${tenantId}/hierarchies/${id}/values`,
    )
    return response.data
  }

  static async getAll(archived: boolean) {
    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.get(
      `/tenant/${tenantId}/hierarchies-values?archived=${archived}`,
    )
    return response.data
  }

  static async archive(hierarchyId, id, data) {
    const tenantId = AuthCurrentTenant.get()
    const body = {
      data,
    }
    const response = await authAxios.put(
      `/tenant/${tenantId}/values/${id}/archived`,
      body,
    )

    return response.data
  }
}

import { i18n } from 'src/i18n'
import exporterRenders from 'src/modules/shared/exporter/exporterRenders'

export default [
  {
    name: 'id',
    label: i18n('entities.barriersSupport.fields.id'),
  },
  {
    name: 'title',
    label: i18n('entities.barriersSupport.fields.title'),
  },
  {
    name: 'description',
    label: i18n('entities.barriersSupport.fields.description'),
  },
  {
    name: 'assistance',
    label: i18n('entities.barriersSupport.fields.assistance'),
  },
  {
    name: 'assistanceType',
    label: i18n('entities.barriersSupport.fields.assistanceType'),
  },
  {
    name: 'files',
    label: i18n('entities.barriersSupport.fields.files'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'images',
    label: i18n('entities.barriersSupport.fields.images'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'author',
    label: i18n('entities.barriersSupport.fields.author'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'dateCreated',
    label: i18n('entities.barriersSupport.fields.dateCreated'),
  },
  {
    name: 'status',
    label: i18n('entities.barriersSupport.fields.status'),
  },
  {
    name: 'researchers',
    label: i18n('entities.barriersSupport.fields.researchers'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'recordType',
    label: i18n('entities.barriersSupport.fields.recordType'),
  },
  {
    name: 'year',
    label: i18n('entities.barriersSupport.fields.year'),
  },
  {
    name: 'projectId',
    label: i18n('entities.barriersSupport.fields.projectId'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.barriersSupport.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.barriersSupport.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
]

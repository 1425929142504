import authAxios from 'src/modules/shared/axios/authAxios'
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant'

export default class GroupsService {
  static async edit(id, data) {
    const body = {
      data,
    }

    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.put(
      `/tenant/${tenantId}/groups/${id}`,
      body,
    )

    return response.data
  }

  static async destroy(id) {
    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.delete(`/tenant/${tenantId}/groups/${id}`)

    return response.data
  }

  static async create(data) {
    const body = {
      data,
    }

    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.post(`/tenant/${tenantId}/groups`, body)

    return response.data
  }

  static async find(id) {
    const tenantId = AuthCurrentTenant.get()
    const response = await authAxios.get(`/tenant/${tenantId}/groups/${id}`)
    return response.data
  }

  static async fetchGroups(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    }

    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.get(`/tenant/${tenantId}/groups`, {
      params,
    })

    return response.data
  }

  static async fetchAllGroups() {
    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.get(`/tenant/${tenantId}/groups`)
    return response.data
  }

  static async fetchGroupsAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    }

    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.get(
      `/tenant/${tenantId}/user/autocomplete`,
      {
        params,
      },
    )
    return response.data
  }
}

import importerActions from 'src/modules/shared/importer/importerActions'
import selectors from 'src/modules/mediaMention/importer/mediaMentionImporterSelectors'
import MediaMentionService from 'src/modules/mediaMention/mediaMentionService'
import fields from 'src/modules/mediaMention/importer/mediaMentionImporterFields'
import { i18n } from 'src/i18n'

const mediaMentionImporterActions = importerActions(
  'MEDIAMENTION_IMPORTER',
  selectors,
  MediaMentionService.import,
  fields,
  i18n('entities.mediaMention.importer.fileName'),
)

export default mediaMentionImporterActions

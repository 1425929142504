import importerActions from 'src/modules/shared/importer/importerActions'
import selectors from 'src/modules/testimonial/importer/testimonialImporterSelectors'
import TestimonialService from 'src/modules/testimonial/testimonialService'
import fields from 'src/modules/testimonial/importer/testimonialImporterFields'
import { i18n } from 'src/i18n'

const testimonialImporterActions = importerActions(
  'TESTIMONIAL_IMPORTER',
  selectors,
  TestimonialService.import,
  fields,
  i18n('entities.testimonial.importer.fileName'),
)

export default testimonialImporterActions

import importerActions from 'src/modules/shared/importer/importerActions'
import selectors from 'src/modules/providingExternalExpertise/importer/providingExternalExpertiseImporterSelectors'
import ProvidingExternalExpertiseService from 'src/modules/providingExternalExpertise/providingExternalExpertiseService'
import fields from 'src/modules/providingExternalExpertise/importer/providingExternalExpertiseImporterFields'
import { i18n } from 'src/i18n'

const providingExternalExpertiseImporterActions = importerActions(
  'PROVIDINGEXTERNALEXPERTISE_IMPORTER',
  selectors,
  ProvidingExternalExpertiseService.import,
  fields,
  i18n('entities.providingExternalExpertise.importer.fileName'),
)

export default providingExternalExpertiseImporterActions

import list from 'src/modules/admin/imactCategories/list/impactCategoriesListReducers'
import form from 'src/modules/admin/imactCategories/form/impactCategoriesFormReducers'
import view from 'src/modules/admin/imactCategories/view/impactCategoriesViewReducers'
import { combineReducers } from 'redux'

export default combineReducers({
  list,
  form,
  view,
})

import schemas from 'src/modules/shared/yup/yupImporterSchemas'
import { i18n } from 'src/i18n'
import testimonialEnumerators from 'src/modules/testimonial/testimonialEnumerators'

export default [
  {
    name: 'researcher',
    label: i18n('entities.testimonial.fields.researcher'),
    schema: schemas.relationToOne(
      i18n('entities.testimonial.fields.researcher'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'organization',
    label: i18n('entities.testimonial.fields.organization'),
    schema: schemas.string(
      i18n('entities.testimonial.fields.organization'),
      {},
    ),
  },
  {
    name: 'description',
    label: i18n('entities.testimonial.fields.description'),
    schema: schemas.string(i18n('entities.testimonial.fields.description'), {}),
  },
  {
    name: 'projectId',
    label: i18n('entities.testimonial.fields.projectId'),
    schema: schemas.relationToMany(
      i18n('entities.testimonial.fields.projectId'),
      {},
    ),
  },
  {
    name: 'files',
    label: i18n('entities.testimonial.fields.files'),
    schema: schemas.files(i18n('entities.testimonial.fields.files'), {}),
  },
  {
    name: 'images',
    label: i18n('entities.testimonial.fields.images'),
    schema: schemas.images(i18n('entities.testimonial.fields.images'), {}),
  },
  {
    name: 'status',
    label: i18n('entities.testimonial.fields.status'),
    schema: schemas.enumerator(i18n('entities.testimonial.fields.status'), {
      required: true,
      options: testimonialEnumerators.status,
    }),
  },
  {
    name: 'title',
    label: i18n('entities.testimonial.fields.title'),
    schema: schemas.string(i18n('entities.testimonial.fields.title'), {}),
  },
  {
    name: 'recordType',
    label: i18n('entities.testimonial.fields.recordType'),
    schema: schemas.enumerator(i18n('entities.testimonial.fields.recordType'), {
      options: testimonialEnumerators.recordType,
    }),
  },
  {
    name: 'permission',
    label: i18n('entities.testimonial.fields.permission'),
    schema: schemas.boolean(i18n('entities.testimonial.fields.permission')),
  },
]

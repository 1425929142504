import { i18n } from 'src/i18n'
import exporterRenders from 'src/modules/shared/exporter/exporterRenders'

export default [
  {
    name: 'id',
    label: i18n('entities.publication.fields.id'),
  },
  {
    name: 'title',
    label: i18n('entities.publication.fields.title'),
  },
  {
    name: 'publicationType',
    label: i18n('entities.publication.fields.publicationType'),
  },
  {
    name: 'researcher',
    label: i18n('entities.publication.fields.researcher'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'editor',
    label: i18n('entities.publication.fields.editor'),
  },
  {
    name: 'chapterTitle',
    label: i18n('entities.publication.fields.chapterTitle'),
  },
  {
    name: 'files',
    label: i18n('entities.publication.fields.files'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'images',
    label: i18n('entities.publication.fields.images'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'author',
    label: i18n('entities.publication.fields.author'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'dateCreated',
    label: i18n('entities.publication.fields.dateCreated'),
  },
  {
    name: 'status',
    label: i18n('entities.publication.fields.status'),
  },
  {
    name: 'recordType',
    label: i18n('entities.publication.fields.recordType'),
  },
  {
    name: 'year',
    label: i18n('entities.publication.fields.year'),
  },
  {
    name: 'publication',
    label: i18n('entities.publication.fields.publication'),
  },
  {
    name: 'publisher',
    label: i18n('entities.publication.fields.publisher'),
  },
  {
    name: 'country',
    label: i18n('entities.publication.fields.country'),
  },
  {
    name: 'city',
    label: i18n('entities.publication.fields.city'),
  },
  {
    name: 'state',
    label: i18n('entities.publication.fields.state'),
  },
  {
    name: 'chapterNumber',
    label: i18n('entities.publication.fields.chapterNumber'),
  },
  {
    name: 'edition',
    label: i18n('entities.publication.fields.edition'),
  },
  {
    name: 'version',
    label: i18n('entities.publication.fields.version'),
  },
  {
    name: 'volume',
    label: i18n('entities.publication.fields.volume'),
  },
  {
    name: 'issue',
    label: i18n('entities.publication.fields.issue'),
  },
  {
    name: 'startDate',
    label: i18n('entities.publication.fields.startDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'endDate',
    label: i18n('entities.publication.fields.endDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'url',
    label: i18n('entities.publication.fields.url'),
  },
  {
    name: 'articleNumber',
    label: i18n('entities.publication.fields.articleNumber'),
  },
  {
    name: 'isbn',
    label: i18n('entities.publication.fields.isbn'),
  },
  {
    name: 'doi',
    label: i18n('entities.publication.fields.doi'),
  },
  {
    name: 'citations',
    label: i18n('entities.publication.fields.citations'),
  },
  {
    name: 'impactFactor',
    label: i18n('entities.publication.fields.impactFactor'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.publication.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.publication.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
]

import Avatar from 'src/components/Avatar'
import Roles from 'src/security/roles'
import { i18n } from 'src/i18n'
import config from 'src/config'
import authSelectors from 'src/modules/auth/authSelectors'
import { useDispatch, useSelector } from 'react-redux'
import { getHistory } from 'src/modules/store'
import authActions from 'src/modules/auth/authActions'
import { useMsal } from '@azure/msal-react'

const useSignOut = () => {
  const dispatch = useDispatch()
  const { instance, accounts } = useMsal()

  return async () => {
    if (accounts.length > 0) {
      sessionStorage.removeItem('tenant-setting')
      instance.logoutRedirect({
        onRedirectNavigate: () => {
          return false
        },
      })
    } else {
      await dispatch(authActions.doSignout())
    }
  }
}

export default ({ currentUser }) => {
  const signOut = useSignOut()

  const userText = useSelector(authSelectors.selectCurrentUserNameOrEmailPrefix)
  const userAvatar = useSelector(authSelectors.selectCurrentUserAvatar)

  const doNavigateToProfile = () => {
    getHistory().push('/profile')
  }

  const doNavigateToPasswordChange = () => {
    getHistory().push('/password-change')
  }

  const doNavigateToTenants = () => {
    getHistory().push('/tenant')
  }

  const doNavigateToTestPage = () => {
    window.open('https://kb.growimpact.com.au', '_blank')
  }

  return (
    <>
      <div className="user-dropdown" data-toggle="dropdown">
        <span className="user-dropdown-avatar">
          <Avatar size="large" src={userAvatar || undefined} alt="avatar" />
        </span>
        <span className="user-dropdown-text">
          <span>{userText}</span>
        </span>
      </div>
      <div className="dropdown-menu dropdown-menu-right">
        <button
          onClick={doNavigateToProfile}
          className="dropdown-item"
          type="button"
        >
          <i className="fas fa-user" /> {i18n('auth.profile.title')}
        </button>
        <button
          onClick={doNavigateToPasswordChange}
          className="dropdown-item"
          type="button"
        >
          <i className="fas fa-lock" /> {i18n('auth.passwordChange.title')}
        </button>
        {['multi', 'multi-with-subdomain'].includes(config.tenantMode) &&
          currentUser.tenants[0].roles.includes(Roles.values.admin) && (
            <button
              onClick={doNavigateToTenants}
              className="dropdown-item"
              type="button"
            >
              <i className="fas fa-th-large" /> {i18n('auth.tenants')}
            </button>
          )}
        <button
          className="dropdown-item"
          type="button"
          onClick={doNavigateToTestPage}
        >
          <i className="fas fa-info-circle" /> {i18n('auth.help')}
        </button>
        <button onClick={signOut} className="dropdown-item" type="button">
          <i className="fas fa-sign-out-alt" /> {i18n('auth.signout')}
        </button>
      </div>
    </>
  )
}

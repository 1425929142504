import actions from 'src/modules/admin/user/view/userViewActions'
import impactCategoriesViewActions from 'src/modules/admin/imactCategories/view/impactCategoriesViewActions'

const initialData = {
  loading: false,
  impactCategory: null,
  savedFilter: null,
  selectedFilter: null,
}

export default (state = initialData, { type, payload }) => {
  if (type === actions.FIND_STARTED) {
    return {
      ...state,
      impactCategory: null,
      loading: true,
    }
  }

  if (type === actions.FIND_SUCCESS) {
    return {
      ...state,
      impactCategory: payload,
      loading: false,
    }
  }

  if (type === actions.FIND_ERROR) {
    return {
      ...state,
      impactCategory: null,
      loading: false,
    }
  }

  // Saved filters
  if (type === impactCategoriesViewActions.FETCH_STARTED) {
    return {
      ...state,
      loading: true,
    }
  }

  if (type === impactCategoriesViewActions.FETCH_SUCCESS) {
    return {
      ...state,
      savedFilter: payload,
      loading: false,
    }
  }

  if (type === impactCategoriesViewActions.FETCH_ERROR) {
    return {
      ...state,
      savedFilter: null,
      loading: false,
    }
  }

  if (type === impactCategoriesViewActions.CREATE_STARTED) {
    return {
      ...state,
      loading: true,
    }
  }

  if (type === impactCategoriesViewActions.CREATE_SUCCESS) {
    return {
      ...state,
      loading: false,
    }
  }

  if (type === impactCategoriesViewActions.CREATE_ERROR) {
    return {
      ...state,
      savedFilter: null,
      loading: false,
    }
  }

  if (type === impactCategoriesViewActions.FETCH_ONE_STARTED) {
    return {
      ...state,
      selectedFilter: null,
      loading: true,
    }
  }

  if (type === impactCategoriesViewActions.FETCH_ONE_SUCCESS) {
    return {
      ...state,
      selectedFilter: payload,
      loading: false,
    }
  }

  if (type === impactCategoriesViewActions.FETCH_ONE_ERROR) {
    return {
      ...state,
      selectedFilter: null,
      loading: false,
    }
  }

  if (type === impactCategoriesViewActions.RESET) {
    return {
      ...state,
      selectedFilter: null,
    }
  }
  return state
}

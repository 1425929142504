const oralPresentationEnumerators = {
  role: [
    'invited_keynote',
    'invited_plenary',
    'speaker_selected_from_abstract',
    'other_invited_speaker',
    'panel_chair',
    'panel_member',
    'session_chair',
    'conference_convenor',
    'lecturer',
    'other_specify',
  ],
  meetingType: [
    'community_event',
    'conference',
    'forum',
    'lecture',
    'seminar_series',
    'stakeholder meeting',
    'symposium',
    'other_specify',
  ],
  recipientType: [
    'government',
    'industry',
    'consumer_and/or_community',
    'non-government_organisation',
    'peak_body',
    'research_organisation',
    'education_organisation',
    'other_specify',
  ],
  presenterPurpose: [
    'to_share_academic_knowledge',
    'to_share_non-academic_knowledge',
    'to_advocate',
    'to_report',
    'other_specify',
  ],
  scope: [
    'Perth-metro',
    'WA-state_wide',
    'National',
    'International',
    'WA-Gascoyne',
    'WA-Goldfields-Esperance',
    'WA-Great_Southern',
    'WA-Kimberley',
    'WA-Mid_West',
    'WA-Peel',
    'WA-Pilbara',
    'WA-South',
    'West',
    'WA-Wheatbelt',
    'National-NSW',
    'National-NT',
    'National-QLD',
    'National-SA',
    'National-TAS National-VIC',
    'International-Africa',
    'International-Asia',
    'International-Central America',
    'International-Eastern_Europe',
    'International-European_Union',
    'International-Middle_East',
    'International-North_America',
    'International-Oceania',
    'International-South_America',
    'International-Caribbean',
  ],
  status: ['draft', 'published', 'archived'],
  recordType: ['Academic', 'Professional'],
}

export default oralPresentationEnumerators

import React from 'react'

const ButtonIcon = (props) => {
  return props.loading ? (
    <i className="spinner-border spinner-border-sm mr-1" />
  ) : props.iconClass ? (
    <i
      onClick={props.onClick}
      style={{
        color: props.color ? props.color : '#333',
        fontSize: props.fontSize ? props.fontSize : '',
        cursor: 'pointer',
        paddingBottom: '3.88px',
        paddingRight: '7px',
      }}
      className={`${props.iconClass}`}
    />
  ) : null
}

export default ButtonIcon

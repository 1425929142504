import actions from './actions'

const initialData = {
  loading: false,
  exportLoading: false,
  reportsData: {},
  form: {
    filter: [],
  },
  filters: {
    categories: [],
    paginatedCategory: [],
  },
  savedReports: null,
  selectedSavedReport: null,
}

export default (state = initialData, { type, payload }) => {
  if (type === actions.FETCH_STARTED) {
    return {
      ...state,
      loading: payload.paginatedCategory.length === 0,
      selectedKeys: [],
      filter: payload ? payload.filter : {},
      rawFilter: payload ? payload.rawFilter : {},
    }
  }

  if (type === actions.FETCH_SUCCESS) {
    const data = {}
    for (const category in payload.rows) {
      data[category] = {
        loading: false,
        rows: payload.rows[category].rows.map((item) => {
          return item
        }),
        pagination: {
          total: payload.rows[category].total,
          pageSize: payload.rows[category].pageSize,
          current: payload.rows[category].current,
          category: category,
          oldValue: null,
        },
      }
    }
    return {
      ...state,
      loading: false,
      reportsData: { ...state.reportsData, ...data },
      filters: { ...payload.filters },
    }
  }

  if (type === actions.FETCH_ERROR) {
    return {
      ...state,
      loading: false,
      exportLoading: false,
    }
  }

  if (type === actions.SAVE_STARTED) {
    return {
      ...state,
    }
  }

  if (type === actions.SAVE_SUCCESS) {
    return {
      ...state,
      form: {
        filter: [
          ...state.form.filter,
          {
            title: payload.filter.title,
            reportFilters: payload.filter.reportFilters,
          },
        ],
      },
    }
  }

  if (type === actions.SAVE_ERROR) {
    return {
      ...state,
    }
  }

  if (type === actions.UPDATE_STARTED) {
    return {
      ...state,
    }
  }

  if (type === actions.UPDATE_SUCCESS) {
    let selected = state.form.filter[payload.index]
    selected.title = payload.title
    selected.reportFiters = {
      ...state.form.filter[payload.index].reportFilters,
    }

    return {
      ...state,
      form: {
        filter: [...state.form.filter],
      },
    }
  }

  if (type === actions.PAGINATION_CHANGED) {
    const data = {}
    state.filters.paginatedCategory = [payload.category]
    data[payload.category] = {
      limit: payload.pageSize,
      page: payload.current,
      oldValue: null,
    }
    return {
      ...state,
      filters: { ...state.filters },
      reportsData: {
        ...state.reportsData,
        [payload.category]: {
          ...state.reportsData[payload.category],
          loading: true,
          toPaginate: [data],
        },
      },
    }
  }

  if (type === actions.UPDATE_ERROR) {
    return {
      ...state,
    }
  }

  if (type === actions.FETCH_SAVED_REPORTS_STARTED) {
    return {
      ...state,
      savedReports: null,
    }
  }

  if (type === actions.FETCH_SAVED_REPORTS_SUCCESS) {
    return {
      ...state,
      savedReports: payload,
    }
  }

  if (type === actions.FETCH_SAVED_REPORTS_ERROR) {
    return {
      ...state,
      savedReports: null,
    }
  }

  if (type === actions.SELECT_SAVED_REPORT) {
    return {
      ...state,
      selectedSavedReport: payload,
    }
  }

  if (type === actions.CLEAR_SELECTED_SAVED_REPORT) {
    return {
      ...state,
      selectedSavedReport: null,
    }
  }

  if (type === actions.CLEAR) {
    return {
      ...state,
      loading: false,
      rows: null,
      count: 0,
    }
  }

  if (type === actions.EXPORT_STARTED) {
    return {
      ...state,
      exportLoading: true,
    }
  }

  if (type === actions.EXPORT_COMPLETED) {
    return {
      ...state,
      exportLoading: false,
    }
  }

  if (type === actions.CLEAR_FILTERED_REPORT) {
    return {
      ...state,
      loading: false,
      reportsData: {},
    }
  }

  return state
}

import list from 'src/modules/collaborationPartnership/list/collaborationPartnershipListReducers'
import form from 'src/modules/collaborationPartnership/form/collaborationPartnershipFormReducers'
import view from 'src/modules/collaborationPartnership/view/collaborationPartnershipViewReducers'
import destroy from 'src/modules/collaborationPartnership/destroy/collaborationPartnershipDestroyReducers'
import importerReducer from 'src/modules/collaborationPartnership/importer/collaborationPartnershipImporterReducers'
import { combineReducers } from 'redux'

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
})

import Errors from 'src/modules/shared/error/errors'
import Message from 'src/components/message'
import { getHistory } from 'src/modules/store'
import { i18n } from 'src/i18n'
import authSelectors from 'src/modules/auth/authSelectors'
import authActions from 'src/modules/auth/authActions'
import integrationService from 'src/modules/integrations/integrationService'

const prefix = 'INTEGRATION_FORM'

const integrationFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  ADD_STARTED: `${prefix}_ADD_STARTED`,
  ADD_SUCCESS: `${prefix}_ADD_SUCCESS`,
  ADD_ERROR: `${prefix}_ADD_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: (id?) => async (dispatch) => {
    try {
      dispatch({
        type: integrationFormActions.INIT_STARTED,
      })

      const isEdit = Boolean(id)
      let record = {}

      if (isEdit) {
        record = await integrationService.find(id)
      }

      dispatch({
        type: integrationFormActions.INIT_SUCCESS,
        payload: record,
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: integrationFormActions.INIT_ERROR,
      })

      getHistory().push('/admin/integrations')
    }
  },

  doAdd: (values) => async (dispatch) => {
    try {
      dispatch({
        type: integrationFormActions.ADD_STARTED,
      })

      await integrationService.create(values)

      dispatch({
        type: integrationFormActions.ADD_SUCCESS,
      })

      Message.success(i18n('integrations.save.success'))

      getHistory().push('/admin/integrations')
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: integrationFormActions.ADD_ERROR,
      })
    }
  },

  doUpdate: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: integrationFormActions.UPDATE_STARTED,
      })

      await integrationService.edit(id, values)

      dispatch({
        type: integrationFormActions.UPDATE_SUCCESS,
      })

      const currentUser = authSelectors.selectCurrentUser(getState())

      if (currentUser.id === values.id) {
        await dispatch(authActions.doRefreshCurrentUser())
      }

      Message.success(i18n('integrations.save.update'))

      getHistory().push('/admin/integrations')
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: integrationFormActions.UPDATE_ERROR,
      })
    }
  },
}

export default integrationFormActions

import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import GlobalFilterSelectors from 'src/modules/GlobalFilter/GlobalFilterSelectors'
import ButtonIcon from 'src/components/ButtonIcon'
import GlobalFilterHeaderWrapper from 'src/components/globalFilter/styles/Header'

export default function GlobalFilterHeader(props) {
  const selectedHierarchyValues = useSelector(
    GlobalFilterSelectors.selectSelectedHierarchyValues,
  )

  const { toggleList } = props

  return (
    <GlobalFilterHeaderWrapper onClick={toggleList}>
      {selectedHierarchyValues && selectedHierarchyValues.length > 0 ? (
        selectedHierarchyValues.map((value) => (
          <h5 key={value.hierarchyValue.uuid}>{value.hierarchyValue.name}</h5>
        ))
      ) : (
        <h5>All</h5>
      )}
      <ButtonIcon
        iconClass="fas fa-angle-down"
        color="#fff"
        fontSize="1.5rem"
      />
    </GlobalFilterHeaderWrapper>
  )
}

GlobalFilterHeader.propTypes = {
  toggleList: PropTypes.func,
}

import importerActions from 'src/modules/shared/importer/importerActions'
import selectors from 'src/modules/consumerCommunityInvolvement/importer/consumerCommunityInvolvementImporterSelectors'
import ConsumerCommunityInvolvementService from 'src/modules/consumerCommunityInvolvement/consumerCommunityInvolvementService'
import fields from 'src/modules/consumerCommunityInvolvement/importer/consumerCommunityInvolvementImporterFields'
import { i18n } from 'src/i18n'

const consumerCommunityInvolvementImporterActions = importerActions(
  'CONSUMERCOMMUNITYINVOLVEMENT_IMPORTER',
  selectors,
  ConsumerCommunityInvolvementService.import,
  fields,
  i18n('entities.consumerCommunityInvolvement.importer.fileName'),
)

export default consumerCommunityInvolvementImporterActions

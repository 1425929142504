import schemas from 'src/modules/shared/yup/yupImporterSchemas'
import { i18n } from 'src/i18n'
import reportEnumerators from 'src/modules/report/reportEnumerators'

export default [
  {
    name: 'title',
    label: i18n('entities.report.fields.title'),
    schema: schemas.string(i18n('entities.report.fields.title'), {
      required: true,
    }),
  },
  {
    name: 'researcher',
    label: i18n('entities.report.fields.researcher'),
    schema: schemas.relationToOne(i18n('entities.report.fields.researcher'), {
      required: true,
    }),
  },
  {
    name: 'role',
    label: i18n('entities.report.fields.role'),
    schema: schemas.boolean(i18n('entities.report.fields.role')),
  },
  {
    name: 'seriesNumber',
    label: i18n('entities.report.fields.seriesNumber'),
    schema: schemas.string(i18n('entities.report.fields.seriesNumber'), {}),
  },
  {
    name: 'organization',
    label: i18n('entities.report.fields.organization'),
    schema: schemas.string(i18n('entities.report.fields.organization'), {}),
  },
  {
    name: 'recipient',
    label: i18n('entities.report.fields.recipient'),
    schema: schemas.enumerator(i18n('entities.report.fields.recipient'), {
      required: true,
      options: reportEnumerators.recipient,
    }),
  },
  {
    name: 'commissioned',
    label: i18n('entities.report.fields.commissioned'),
    schema: schemas.enumerator(i18n('entities.report.fields.commissioned'), {
      required: true,
      options: reportEnumerators.commissioned,
    }),
  },
  {
    name: 'publisher',
    label: i18n('entities.report.fields.publisher'),
    schema: schemas.string(i18n('entities.report.fields.publisher'), {}),
  },
  {
    name: 'country',
    label: i18n('entities.report.fields.country'),
    schema: schemas.string(i18n('entities.report.fields.country'), {}),
  },
  {
    name: 'city',
    label: i18n('entities.report.fields.city'),
    schema: schemas.enumerator(i18n('entities.report.fields.city'), {
      options: reportEnumerators.city,
    }),
  },
  {
    name: 'state',
    label: i18n('entities.report.fields.state'),
    schema: schemas.string(i18n('entities.report.fields.state'), {}),
  },
  {
    name: 'files',
    label: i18n('entities.report.fields.files'),
    schema: schemas.files(i18n('entities.report.fields.files'), {}),
  },
  {
    name: 'images',
    label: i18n('entities.report.fields.images'),
    schema: schemas.images(i18n('entities.report.fields.images'), {}),
  },
  {
    name: 'status',
    label: i18n('entities.report.fields.status'),
    schema: schemas.enumerator(i18n('entities.report.fields.status'), {
      required: true,
      options: reportEnumerators.status,
    }),
  },
  {
    name: 'researchers',
    label: i18n('entities.report.fields.researchers'),
    schema: schemas.relationToMany(
      i18n('entities.report.fields.researchers'),
      {},
    ),
  },
  {
    name: 'recordType',
    label: i18n('entities.report.fields.recordType'),
    schema: schemas.enumerator(i18n('entities.report.fields.recordType'), {
      options: reportEnumerators.recordType,
    }),
  },
  {
    name: 'year',
    label: i18n('entities.report.fields.year'),
    schema: schemas.integer(i18n('entities.report.fields.year'), {}),
  },
]

import Errors from 'src/modules/shared/error/errors'
import Message from 'src/components/message'
import groupService from 'src/modules/admin/groups/groupsService'
import { getHistory } from 'src/modules/store'
import { i18n } from 'src/i18n'
import authSelectors from 'src/modules/auth/authSelectors'
import authActions from 'src/modules/auth/authActions'

const prefix = 'GROUPS_FORM'

const groupFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  ADD_STARTED: `${prefix}_ADD_STARTED`,
  ADD_SUCCESS: `${prefix}_ADD_SUCCESS`,
  ADD_ERROR: `${prefix}_ADD_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: (id?) => async (dispatch) => {
    try {
      dispatch({
        type: groupFormActions.INIT_STARTED,
      })

      const isEdit = Boolean(id)
      let record = {}

      if (isEdit) {
        record = await groupService.find(id)
      }

      dispatch({
        type: groupFormActions.INIT_SUCCESS,
        payload: record,
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: groupFormActions.INIT_ERROR,
      })

      getHistory().push('/admin/groups')
    }
  },

  doAdd: (values) => async (dispatch) => {
    try {
      dispatch({
        type: groupFormActions.ADD_STARTED,
      })

      await groupService.create(values)

      dispatch({
        type: groupFormActions.ADD_SUCCESS,
      })

      Message.success(i18n('user.doAddSuccess'))

      getHistory().push('/admin/groups')
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: groupFormActions.ADD_ERROR,
      })
    }
  },

  doUpdate: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: groupFormActions.UPDATE_STARTED,
      })

      await groupService.edit(id, values)

      dispatch({
        type: groupFormActions.UPDATE_SUCCESS,
      })

      const currentUser = authSelectors.selectCurrentUser(getState())

      if (currentUser.id === values.id) {
        await dispatch(authActions.doRefreshCurrentUser())
      }

      Message.success(i18n('user.doUpdateSuccess'))

      getHistory().push('/admin/groups')
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: groupFormActions.UPDATE_ERROR,
      })
    }
  },
}

export default groupFormActions

import importerActions from 'src/modules/shared/importer/importerActions'
import selectors from 'src/modules/researchThemesIntendedImpact/importer/researchThemesIntendedImpactImporterSelectors'
import ResearchThemesIntendedImpactService from 'src/modules/researchThemesIntendedImpact/researchThemesIntendedImpactService'
import fields from 'src/modules/researchThemesIntendedImpact/importer/researchThemesIntendedImpactImporterFields'
import { i18n } from 'src/i18n'

const researchThemesIntendedImpactImporterActions = importerActions(
  'RESEARCHTHEMESINTENDEDIMPACT_IMPORTER',
  selectors,
  ResearchThemesIntendedImpactService.import,
  fields,
  i18n('entities.researchThemesIntendedImpact.importer.fileName'),
)

export default researchThemesIntendedImpactImporterActions

import schemas from 'src/modules/shared/yup/yupImporterSchemas'
import { i18n } from 'src/i18n'
import collaborationPartnershipEnumerators from 'src/modules/collaborationPartnership/collaborationPartnershipEnumerators'

export default [
  {
    name: 'role',
    label: i18n('entities.collaborationPartnership.fields.role'),
    schema: schemas.enumerator(
      i18n('entities.collaborationPartnership.fields.role'),
      {
        required: true,
        options: collaborationPartnershipEnumerators.role,
      },
    ),
  },
  {
    name: 'researcher',
    label: i18n('entities.collaborationPartnership.fields.researcher'),
    schema: schemas.relationToOne(
      i18n('entities.collaborationPartnership.fields.researcher'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'organization',
    label: i18n('entities.collaborationPartnership.fields.organization'),
    schema: schemas.string(
      i18n('entities.collaborationPartnership.fields.organization'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'organizationType',
    label: i18n('entities.collaborationPartnership.fields.organizationType'),
    schema: schemas.string(
      i18n('entities.collaborationPartnership.fields.organizationType'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'department',
    label: i18n('entities.collaborationPartnership.fields.department'),
    schema: schemas.string(
      i18n('entities.collaborationPartnership.fields.department'),
      {},
    ),
  },
  {
    name: 'country',
    label: i18n('entities.collaborationPartnership.fields.country'),
    schema: schemas.string(
      i18n('entities.collaborationPartnership.fields.country'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'city',
    label: i18n('entities.collaborationPartnership.fields.city'),
    schema: schemas.string(
      i18n('entities.collaborationPartnership.fields.city'),
      {},
    ),
  },
  {
    name: 'startDate',
    label: i18n('entities.collaborationPartnership.fields.startDate'),
    schema: schemas.datetime(
      i18n('entities.collaborationPartnership.fields.startDate'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'endDate',
    label: i18n('entities.collaborationPartnership.fields.endDate'),
    schema: schemas.datetime(
      i18n('entities.collaborationPartnership.fields.endDate'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'files',
    label: i18n('entities.collaborationPartnership.fields.files'),
    schema: schemas.files(
      i18n('entities.collaborationPartnership.fields.files'),
      {},
    ),
  },
  {
    name: 'images',
    label: i18n('entities.collaborationPartnership.fields.images'),
    schema: schemas.images(
      i18n('entities.collaborationPartnership.fields.images'),
      {},
    ),
  },
  {
    name: 'status',
    label: i18n('entities.collaborationPartnership.fields.status'),
    schema: schemas.enumerator(
      i18n('entities.collaborationPartnership.fields.status'),
      {
        required: true,
        options: collaborationPartnershipEnumerators.status,
      },
    ),
  },
  {
    name: 'title',
    label: i18n('entities.collaborationPartnership.fields.title'),
    schema: schemas.string(
      i18n('entities.collaborationPartnership.fields.title'),
      {},
    ),
  },
  {
    name: 'researchers',
    label: i18n('entities.collaborationPartnership.fields.researchers'),
    schema: schemas.relationToMany(
      i18n('entities.collaborationPartnership.fields.researchers'),
      {},
    ),
  },
  {
    name: 'recordType',
    label: i18n('entities.collaborationPartnership.fields.recordType'),
    schema: schemas.enumerator(
      i18n('entities.collaborationPartnership.fields.recordType'),
      {
        options: collaborationPartnershipEnumerators.recordType,
      },
    ),
  },
  {
    name: 'year',
    label: i18n('entities.collaborationPartnership.fields.year'),
    schema: schemas.integer(
      i18n('entities.collaborationPartnership.fields.year'),
      {},
    ),
  },
]

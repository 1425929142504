import importerActions from 'src/modules/shared/importer/importerActions'
import selectors from 'src/modules/currentProject/importer/currentProjectImporterSelectors'
import CurrentProjectService from 'src/modules/currentProject/currentProjectService'
import fields from 'src/modules/currentProject/importer/currentProjectImporterFields'
import { i18n } from 'src/i18n'

const currentProjectImporterActions = importerActions(
  'CURRENTPROJECT_IMPORTER',
  selectors,
  CurrentProjectService.import,
  fields,
  i18n('entities.currentProject.importer.fileName'),
)

export default currentProjectImporterActions

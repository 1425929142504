import ReportService from 'src/modules/report/reportService'
import selectors from 'src/modules/report/list/reportListSelectors'
import exporterFields from 'src/modules/report/list/reportListExporterFields'
import { generateListActions } from '../../listHelpers'

const prefix = 'REPORT_LIST'

const reportListActions = generateListActions(
  prefix,
  ReportService.list,
  selectors,
  exporterFields,
  'year',
)

export default reportListActions

import actions from 'src/modules/GlobalFilter/GlobalFilterActions'

interface GlobalFilterState {
  activeHierarchy: any
  activeHierarchyValue: any
  activeSelectedHierarchyValue: any
  activeHierarchyValues: any[]
  selectedHierarchyValues: any[]
  doFilter: Boolean
}

const initialState: GlobalFilterState = {
  activeHierarchy: null,
  activeHierarchyValue: null,
  activeHierarchyValues: [],
  activeSelectedHierarchyValue: null,
  selectedHierarchyValues: [],
  doFilter: false,
}

export default function (state = initialState, { type, payload }) {
  if (type === actions.SELECT_ACTIVE_HIERARCHY) {
    return {
      ...state,
      activeHierarchy: payload,
      activeHierarchyValue: null,
      activeHierarchyValues: [],
    }
  }

  if (type === actions.SELECT_ACTIVE_HIERARCHY_VALUE) {
    return {
      ...state,
      activeHierarchyValue: payload,
      activeSelectedHierarchyValue: null,
    }
  }

  if (type === actions.SELECT_ACTIVE_SELECTED_HIERARCHY_VALUE) {
    return {
      ...state,
      activeSelectedHierarchyValue: payload,
      activeHierarchyValue: null,
    }
  }

  if (type === actions.INITIALIZE_ACTIVE_HIERARCHY_VALUES) {
    const selectedHierarchyValues = [...state.selectedHierarchyValues]
    const ids = selectedHierarchyValues.map((p) => p.hierarchyValue.id)
    const activeHierarchyValues = payload.filter(
      (value) => !ids.includes(value.hierarchyValue.id),
    )
    return { ...state, activeHierarchyValues: activeHierarchyValues }
  }

  if (type === actions.ADD_HIERARCHY_VALUE) {
    const ids = payload.map((p) => p.hierarchyValue.id)
    const selectedHierarchyValues = [...state.selectedHierarchyValues]
    const activeHierarchyValues = state.activeHierarchyValues.filter(
      (value) => !ids.includes(value.hierarchyValue.id),
    )

    payload.forEach((value) => {
      if (
        !state.selectedHierarchyValues.find(
          (v) => value.hierarchyValue.id === v.hierarchyValue.id,
        )
      ) {
        selectedHierarchyValues.push(value)
      }
    })
    return {
      ...state,
      activeHierarchyValues,
      selectedHierarchyValues,
      activeHierarchyValue: null,
    }
  }

  if (type === actions.REMOVE_HIERARCHY_VALUE) {
    return {
      ...state,
      selectedHierarchyValues: state.selectedHierarchyValues.filter(
        (h) => h.hierarchyValue.id !== payload.hierarchyValue.id,
      ),
      activeHierarchyValues:
        state.activeHierarchy &&
        state.activeHierarchy.uuid === payload.hierarchy.uuid
          ? [...state.activeHierarchyValues, payload]
          : [...state.activeHierarchyValues],
      activeSelectedHierarchyValue: null,
    }
  }

  if (type === actions.RESET) {
    return {
      ...state,
      activeHierarchy: null,
      activeHierarchyValue: null,
      activeHierarchyValues: [],
      activeSelectedHierarchyValue: null,
      selectedHierarchyValues: payload,
    }
  }

  if (type === actions.DO_FILTER) {
    return {
      ...state,
      doFilter: true,
    }
  }

  if (type === actions.CLEAR_FILTER) {
    return {
      ...state,
      doFilter: false,
    }
  }
  return state
}

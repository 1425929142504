import Exporter from './shared/exporter/exporter'
import { i18n } from '../i18n'
import Errors from './shared/error/errors'
import { createSelector } from 'reselect'

export const generateListActions = (
  prefix: string,
  getList: any,
  selectors: any,
  exporterFields: any,
  recentListSorterField: string,
) => {
  const keys = {
    FETCH_STARTED: `${prefix}_FETCH_STARTED`,
    FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
    FETCH_ERROR: `${prefix}_FETCH_ERROR`,

    RESET: `${prefix}_RESET`,
    TOGGLE_ONE_SELECTED: `${prefix}_TOGGLE_ONE_SELECTED`,
    TOGGLE_ALL_SELECTED: `${prefix}_TOGGLE_ALL_SELECTED`,
    CLEAR_ALL_SELECTED: `${prefix}_CLEAR_ALL_SELECTED`,

    PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
    SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,
    QUERY_CHANGED: `${prefix}_QUERY_CHANGED`,

    EXPORT_STARTED: `${prefix}_EXPORT_STARTED`,
    EXPORT_SUCCESS: `${prefix}_EXPORT_SUCCESS`,
    EXPORT_ERROR: `${prefix}_EXPORT_ERROR`,
  }

  const doClearAllSelected = () => {
    return {
      type: keys.CLEAR_ALL_SELECTED,
    }
  }

  const doToggleAllSelected = () => {
    return {
      type: keys.TOGGLE_ALL_SELECTED,
    }
  }

  const doToggleOneSelected = (id) => {
    return {
      type: keys.TOGGLE_ONE_SELECTED,
      payload: id,
    }
  }

  const doReset = () => async (dispatch) => {
    dispatch({
      type: keys.RESET,
    })
  }

  const doExport = () => async (dispatch, getState) => {
    try {
      if (!exporterFields || !exporterFields.length) {
        throw new Error('exporterFields is required')
      }

      dispatch({
        type: keys.EXPORT_STARTED,
      })

      const response = await getList(
        selectors.selectFilter(getState()),
        selectors.selectIncludeAsWell(getState()),
        selectors.selectOrderBy(getState()),
        null,
        null,
      )

      new Exporter(
        exporterFields,
        i18n('entities.currentProject.exporterFileName'),
      ).transformAndExportAsExcelFile(response.rows)

      dispatch({
        type: keys.EXPORT_SUCCESS,
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: keys.EXPORT_ERROR,
      })
    }
  }

  const doFetchCurrentFilter = () => async (dispatch, getState) => {
    try {
      dispatch({
        type: keys.FETCH_STARTED,
      })

      const response = await getList(
        selectors.selectFilter(getState()),
        selectors.selectOrderBy(getState()),
        selectors.selectLimit(getState()),
        selectors.selectOffset(getState()),
        selectors.selectIncludeAsWell(getState()),
      )

      dispatch({
        type: keys.FETCH_SUCCESS,
        payload: {
          rows: response.rows,
          count: response.count,
        },
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: keys.FETCH_ERROR,
      })
    }
  }

  const doFetch =
    (filter?, rawFilter?, includeAsWell?, sorter?, pagination?) =>
    async (dispatch) => {
      dispatch({
        type: keys.QUERY_CHANGED,
        payload: { filter, rawFilter, includeAsWell, sorter, pagination },
      })
      await dispatch(doFetchCurrentFilter())
    }

  const doChangePagination = (pagination, filter?) =>
    doFetch(filter, undefined, undefined, undefined, pagination)

  const doChangeSort = (sorter) =>
    doFetch(undefined, undefined, undefined, sorter, undefined)

  const getRecentListSorter = () => {
    return { field: recentListSorterField, order: 'descend' }
  }

  return {
    ...keys,
    doClearAllSelected,
    doToggleAllSelected,
    doToggleOneSelected,
    doReset,
    doExport,
    doFetchCurrentFilter,
    doFetch,
    doChangePagination,
    doChangeSort,
    getRecentListSorter,
  }
}

export const generateListReducers = (actions: any) => {
  const INITIAL_PAGE_SIZE = 10

  const initialData = {
    rows: [] as Array<any>,
    count: 0,
    loading: false,
    filter: {},
    rawFilter: {},
    pagination: {
      current: 1,
      pageSize: INITIAL_PAGE_SIZE,
    },
    sorter: {},
    selectedKeys: [] as Array<string>,
    includeAsWell: [],
  }

  return (state = initialData, { type, payload }) => {
    if (type === actions.RESET) {
      return {
        ...initialData,
      }
    }

    if (type === actions.TOGGLE_ONE_SELECTED) {
      let selectedKeys = state.selectedKeys

      const exists = selectedKeys.includes(payload)

      if (exists) {
        selectedKeys = selectedKeys.filter((key) => key !== payload)
      } else {
        selectedKeys = [payload, ...selectedKeys]
      }

      return {
        ...state,
        selectedKeys,
      }
    }

    if (type === actions.TOGGLE_ALL_SELECTED) {
      const isAllSelected =
        (state.rows || []).length === (state.selectedKeys || []).length

      return {
        ...state,
        selectedKeys: isAllSelected ? [] : state.rows.map((row) => row.id),
      }
    }

    if (type === actions.CLEAR_ALL_SELECTED) {
      return {
        ...state,
        selectedKeys: [],
      }
    }

    if (type === actions.PAGINATION_CHANGED) {
      return {
        ...state,
        pagination: payload || {
          current: 1,
          pageSize: INITIAL_PAGE_SIZE,
        },
      }
    }

    if (type === actions.SORTER_CHANGED) {
      return {
        ...state,
        sorter: payload || {},
      }
    }

    if (type === actions.QUERY_CHANGED) {
      const newState = {
        ...state,
      }

      if (payload?.filter) {
        newState.filter = payload.filter
      }

      if (payload?.rawFilter) {
        newState.rawFilter = payload.rawFilter
      }

      if (payload?.includeAsWell) {
        newState.includeAsWell = payload.includeAsWell
      }

      if (payload?.sorter) {
        newState.sorter = payload.sorter
      }

      if (payload?.pagination) {
        newState.pagination =
          Object.keys(payload.pagination).length > 0
            ? payload.pagination
            : {
                current: 1,
                pageSize: INITIAL_PAGE_SIZE,
              }
      }

      return newState
    }

    if (type === actions.FETCH_STARTED) {
      return {
        ...state,
        loading: true,
      }
    }

    if (type === actions.FETCH_SUCCESS) {
      return {
        ...state,
        loading: false,
        rows: payload.rows,
        count: payload.count,
      }
    }

    if (type === actions.FETCH_ERROR) {
      return {
        ...state,
        loading: false,
        rows: [],
        count: 0,
      }
    }

    if (type === actions.EXPORT_STARTED) {
      return {
        ...state,
        exportLoading: true,
      }
    }

    if (type === actions.EXPORT_SUCCESS) {
      return {
        ...state,
        exportLoading: false,
      }
    }

    if (type === actions.EXPORT_ERROR) {
      return {
        ...state,
        exportLoading: false,
      }
    }

    return state
  }
}

export const generateListSelectors = (store: string) => {
  const selectRaw = (state) => state[store].list

  const selectLoading = createSelector([selectRaw], (raw) => raw.loading)

  const selectExportLoading = createSelector(
    [selectRaw],
    (raw) => raw.exportLoading,
  )

  const selectRows = createSelector([selectRaw], (raw) => raw.rows)

  const selectCount = createSelector([selectRaw], (raw) => raw.count)

  const selectHasRows = createSelector([selectCount], (count) => count > 0)

  const selectSorter = createSelector([selectRaw], (raw) => raw.sorter || {})

  const selectOrderBy = createSelector([selectRaw], (raw) => {
    const sorter = raw.sorter

    if (!sorter) {
      return null
    }

    if (!sorter.field) {
      return null
    }

    let direction = sorter.order === 'descend' ? 'DESC' : 'ASC'

    return `${sorter.field}_${direction}`
  })

  const selectFilter = createSelector([selectRaw], (raw) => {
    return raw.filter
  })

  const selectRawFilter = createSelector([selectRaw], (raw) => {
    return raw.rawFilter
  })

  const selectLimit = createSelector([selectRaw], (raw) => {
    const pagination = raw.pagination
    return pagination.pageSize
  })

  const selectOffset = createSelector([selectRaw], (raw) => {
    const pagination = raw.pagination

    if (!pagination || !pagination.pageSize) {
      return 0
    }

    const current = pagination.current || 1

    return (current - 1) * pagination.pageSize
  })

  const selectPagination = createSelector(
    [selectRaw, selectCount],
    (raw, count) => {
      return {
        ...raw.pagination,
        total: count,
      }
    },
  )

  const selectSelectedKeys = createSelector([selectRaw], (raw) => {
    return raw.selectedKeys
  })

  const selectSelectedRows = createSelector(
    [selectRaw, selectRows],
    (raw, rows) => {
      return rows.filter((row) => raw.selectedKeys.includes(row.id))
    },
  )

  const selectIsAllSelected = createSelector(
    [selectRows, selectSelectedKeys],
    (rows, selectedKeys) => {
      return rows.length === selectedKeys.length
    },
  )

  const selectIncludeAsWell = createSelector([selectRaw], (raw) => {
    return raw.includeAsWell
  })

  return {
    selectLoading,
    selectRows,
    selectCount,
    selectOrderBy,
    selectLimit,
    selectFilter,
    selectOffset,
    selectPagination,
    selectSelectedKeys,
    selectSelectedRows,
    selectHasRows,
    selectExportLoading,
    selectRawFilter,
    selectIsAllSelected,
    selectSorter,
    selectIncludeAsWell,
  }
}

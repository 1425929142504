import { i18n } from 'src/i18n'
import exporterRenders from 'src/modules/shared/exporter/exporterRenders'

export default [
  {
    name: 'id',
    label: i18n('entities.consumerCommunityInvolvement.fields.id'),
  },
  {
    name: 'author',
    label: i18n('entities.consumerCommunityInvolvement.fields.author'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'dateCreated',
    label: i18n('entities.consumerCommunityInvolvement.fields.dateCreated'),
  },
  {
    name: 'status',
    label: i18n('entities.consumerCommunityInvolvement.fields.status'),
  },
  {
    name: 'title',
    label: i18n('entities.consumerCommunityInvolvement.fields.title'),
  },
  {
    name: 'recordType',
    label: i18n('entities.consumerCommunityInvolvement.fields.recordType'),
  },
  {
    name: 'year',
    label: i18n('entities.consumerCommunityInvolvement.fields.year'),
  },
  {
    name: 'startDate',
    label: i18n('entities.consumerCommunityInvolvement.fields.startDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'description',
    label: i18n('entities.consumerCommunityInvolvement.fields.description'),
  },
  {
    name: 'impact',
    label: i18n('entities.consumerCommunityInvolvement.fields.impact'),
  },
  {
    name: 'endDate',
    label: i18n('entities.consumerCommunityInvolvement.fields.endDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'frequency',
    label: i18n('entities.consumerCommunityInvolvement.fields.frequency'),
  },
  {
    name: 'groupSize',
    label: i18n('entities.consumerCommunityInvolvement.fields.groupSize'),
  },
  {
    name: 'involvementType',
    label: i18n('entities.consumerCommunityInvolvement.fields.involvementType'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'organization',
    label: i18n('entities.consumerCommunityInvolvement.fields.organization'),
  },
  {
    name: 'researcher',
    label: i18n('entities.consumerCommunityInvolvement.fields.researcher'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'files',
    label: i18n('entities.consumerCommunityInvolvement.fields.files'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'images',
    label: i18n('entities.consumerCommunityInvolvement.fields.images'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.consumerCommunityInvolvement.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.consumerCommunityInvolvement.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
]

import UserService from 'src/modules/admin/user/userService'
import selectors from 'src/modules/admin/user/list/userListSelectors'
import Errors from 'src/modules/shared/error/errors'
import Message from 'src/components/message'
import { i18n } from 'src/i18n'
import exporterFields from 'src/modules/admin/user/list/userListExporterFields'
import Exporter from 'src/modules/shared/exporter/exporter'
import { getHistory } from 'src/modules/store'

const prefix = 'USER_LIST'

const userListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  FETCH_USER_NAMES: `${prefix}_FETCH_USER_NAMES`,
  CLEAR_USER_NAMES: `${prefix}_CLEAR_USER_NAMES`,

  RESET: `${prefix}_RESET`,
  TOGGLE_ONE_SELECTED: `${prefix}_TOGGLE_ONE_SELECTED`,
  TOGGLE_ALL_SELECTED: `${prefix}_TOGGLE_ALL_SELECTED`,
  CLEAR_ALL_SELECTED: `${prefix}_CLEAR_ALL_SELECTED`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  EXPORT_STARTED: `${prefix}_EXPORT_STARTED`,
  EXPORT_SUCCESS: `${prefix}_EXPORT_SUCCESS`,
  EXPORT_ERROR: `${prefix}_EXPORT_ERROR`,

  DESTROY_ALL_SELECTED_STARTED: `${prefix}_DESTROY_ALL_SELECTED_STARTED`,
  DESTROY_ALL_SELECTED_SUCCESS: `${prefix}_DESTROY_ALL_SELECTED_SUCCESS`,
  DESTROY_ALL_SELECTED_ERROR: `${prefix}_DESTROY_ALL_SELECTED_ERROR`,

  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  doClearAllSelected() {
    return {
      type: userListActions.CLEAR_ALL_SELECTED,
    }
  },

  doToggleAllSelected() {
    return {
      type: userListActions.TOGGLE_ALL_SELECTED,
    }
  },

  doToggleOneSelected(id) {
    return {
      type: userListActions.TOGGLE_ONE_SELECTED,
      payload: id,
    }
  },

  doReset: () => async (dispatch) => {
    dispatch({
      type: userListActions.RESET,
    })

    dispatch(userListActions.doFetch())
  },

  doExport: () => async (dispatch, getState) => {
    try {
      if (!exporterFields || !exporterFields.length) {
        throw new Error('exporterFields is required')
      }

      dispatch({
        type: userListActions.EXPORT_STARTED,
      })

      const filter = selectors.selectFilter(getState())
      const response = await UserService.fetchUsers(
        filter,
        selectors.selectOrderBy(getState()),
        null,
        null,
      )

      new Exporter(
        exporterFields,
        i18n('user.exporterFileName'),
      ).transformAndExportAsExcelFile(response.rows)

      dispatch({
        type: userListActions.EXPORT_SUCCESS,
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: userListActions.EXPORT_ERROR,
      })
    }
  },

  doChangePagination: (pagination, archived) => async (dispatch) => {
    dispatch({
      type: userListActions.PAGINATION_CHANGED,
      payload: pagination,
    })

    dispatch(userListActions.doFetchCurrentFilter(archived))
  },

  doChangeSort: (sorter) => async (dispatch) => {
    dispatch({
      type: userListActions.SORTER_CHANGED,
      payload: sorter,
    })

    dispatch(userListActions.doFetchCurrentFilter())
  },

  doFetchCurrentFilter: (archived?) => async (dispatch, getState) => {
    const filter = selectors.selectFilter(getState())
    const rawFilter = selectors.selectRawFilter(getState())
    dispatch(userListActions.doFetch(filter, rawFilter, true, archived))
  },

  doFetch:
    (filter?, rawFilter?, keepPagination?, archived?) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: userListActions.FETCH_STARTED,
          payload: { filter, rawFilter, keepPagination },
        })

        const response = await UserService.fetchUsers(
          filter,
          selectors.selectOrderBy(getState()),
          selectors.selectLimit(getState()),
          keepPagination ? selectors.selectOffset(getState()) : 0,
          archived,
        )

        dispatch({
          type: userListActions.FETCH_SUCCESS,
          payload: {
            rows: response.rows,
            count: response.count,
          },
        })
      } catch (error) {
        Errors.handle(error)

        dispatch({
          type: userListActions.FETCH_ERROR,
        })
      }
    },

  doDestroy: (id) => async (dispatch) => {
    try {
      dispatch({
        type: userListActions.DESTROY_STARTED,
      })

      await UserService.destroy([id])

      dispatch({
        type: userListActions.DESTROY_SUCCESS,
      })

      dispatch({
        type: userListActions.CLEAR_USER_NAMES,
      })

      Message.success(i18n('user.doDestroySuccess'))
      getHistory().push('/admin/user')

      dispatch(userListActions.doFetchCurrentFilter())
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: userListActions.DESTROY_ERROR,
      })

      dispatch(userListActions.doFetchCurrentFilter())
    }
  },

  doDestroyAllSelected: () => async (dispatch, getState) => {
    try {
      const selectedRows = selectors.selectSelectedRows(getState())

      dispatch({
        type: userListActions.DESTROY_ALL_SELECTED_STARTED,
      })

      await UserService.destroy(selectedRows.map((row) => row.id))

      dispatch({
        type: userListActions.DESTROY_ALL_SELECTED_SUCCESS,
      })

      dispatch({
        type: userListActions.CLEAR_USER_NAMES,
      })

      Message.success(i18n('user.doDestroyAllSelectedSuccess'))

      dispatch(userListActions.doFetchCurrentFilter())
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: userListActions.DESTROY_ALL_SELECTED_ERROR,
      })

      dispatch(userListActions.doFetchCurrentFilter())
    }
  },

  doClearUserNames: () => async (dispatch) => {
    dispatch({
      type: userListActions.CLEAR_USER_NAMES,
    })
  },

  doFetchUserNames: () => async (dispatch) => {
    try {
      const response = await UserService.fetchUserNames()

      dispatch({
        type: userListActions.FETCH_USER_NAMES,
        payload: response,
      })
    } catch (error) {
      dispatch({
        type: userListActions.CLEAR_USER_NAMES,
      })
      Errors.handle(error)
    }
  },
}

export default userListActions

import schemas from 'src/modules/shared/yup/yupImporterSchemas'
import { i18n } from 'src/i18n'
import workshopTrainingFacilitatedEnumerators from 'src/modules/workshopTrainingFacilitated/workshopTrainingFacilitatedEnumerators'

export default [
  {
    name: 'researcher',
    label: i18n('entities.workshopTrainingFacilitated.fields.researcher'),
    schema: schemas.relationToMany(
      i18n('entities.workshopTrainingFacilitated.fields.researcher'),
      {},
    ),
  },
  {
    name: 'description',
    label: i18n('entities.workshopTrainingFacilitated.fields.description'),
    schema: schemas.string(
      i18n('entities.workshopTrainingFacilitated.fields.description'),
      {
        max: 1000,
      },
    ),
  },
  {
    name: 'startDate',
    label: i18n('entities.workshopTrainingFacilitated.fields.startDate'),
    schema: schemas.date(
      i18n('entities.workshopTrainingFacilitated.fields.startDate'),
      {},
    ),
  },
  {
    name: 'recipient',
    label: i18n('entities.workshopTrainingFacilitated.fields.recipient'),
    schema: schemas.string(
      i18n('entities.workshopTrainingFacilitated.fields.recipient'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'recipientType',
    label: i18n('entities.workshopTrainingFacilitated.fields.recipientType'),
    schema: schemas.enumerator(
      i18n('entities.workshopTrainingFacilitated.fields.recipientType'),
      {
        options: workshopTrainingFacilitatedEnumerators.recipientType,
      },
    ),
  },
  {
    name: 'country',
    label: i18n('entities.workshopTrainingFacilitated.fields.country'),
    schema: schemas.string(
      i18n('entities.workshopTrainingFacilitated.fields.country'),
      {},
    ),
  },
  {
    name: 'educationTrainingMaterialId',
    label: i18n(
      'entities.workshopTrainingFacilitated.fields.educationTrainingMaterialId',
    ),
    schema: schemas.relationToOne(
      i18n(
        'entities.workshopTrainingFacilitated.fields.educationTrainingMaterialId',
      ),
      {},
    ),
  },
  {
    name: 'city',
    label: i18n('entities.workshopTrainingFacilitated.fields.city'),
    schema: schemas.string(
      i18n('entities.workshopTrainingFacilitated.fields.city'),
      {},
    ),
  },
  {
    name: 'role',
    label: i18n('entities.workshopTrainingFacilitated.fields.role'),
    schema: schemas.enumerator(
      i18n('entities.workshopTrainingFacilitated.fields.role'),
      {
        options: workshopTrainingFacilitatedEnumerators.role,
      },
    ),
  },
  {
    name: 'files',
    label: i18n('entities.workshopTrainingFacilitated.fields.files'),
    schema: schemas.files(
      i18n('entities.workshopTrainingFacilitated.fields.files'),
      {},
    ),
  },
  {
    name: 'images',
    label: i18n('entities.workshopTrainingFacilitated.fields.images'),
    schema: schemas.images(
      i18n('entities.workshopTrainingFacilitated.fields.images'),
      {},
    ),
  },
  {
    name: 'status',
    label: i18n('entities.workshopTrainingFacilitated.fields.status'),
    schema: schemas.enumerator(
      i18n('entities.workshopTrainingFacilitated.fields.status'),
      {
        required: true,
        options: workshopTrainingFacilitatedEnumerators.status,
      },
    ),
  },
  {
    name: 'title',
    label: i18n('entities.workshopTrainingFacilitated.fields.title'),
    schema: schemas.string(
      i18n('entities.workshopTrainingFacilitated.fields.title'),
      {},
    ),
  },
  {
    name: 'recordType',
    label: i18n('entities.workshopTrainingFacilitated.fields.recordType'),
    schema: schemas.enumerator(
      i18n('entities.workshopTrainingFacilitated.fields.recordType'),
      {
        options: workshopTrainingFacilitatedEnumerators.recordType,
      },
    ),
  },
  {
    name: 'year',
    label: i18n('entities.workshopTrainingFacilitated.fields.year'),
    schema: schemas.integer(
      i18n('entities.workshopTrainingFacilitated.fields.year'),
      {},
    ),
  },
  {
    name: 'organization',
    label: i18n('entities.workshopTrainingFacilitated.fields.organization'),
    schema: schemas.string(
      i18n('entities.workshopTrainingFacilitated.fields.organization'),
      {},
    ),
  },
]

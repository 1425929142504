import importerActions from 'src/modules/shared/importer/importerActions'
import selectors from 'src/modules/toolsProductService/importer/toolsProductServiceImporterSelectors'
import ToolsProductServiceService from 'src/modules/toolsProductService/toolsProductServiceService'
import fields from 'src/modules/toolsProductService/importer/toolsProductServiceImporterFields'
import { i18n } from 'src/i18n'

const toolsProductServiceImporterActions = importerActions(
  'TOOLSPRODUCTSERVICE_IMPORTER',
  selectors,
  ToolsProductServiceService.import,
  fields,
  i18n('entities.toolsProductService.importer.fileName'),
)

export default toolsProductServiceImporterActions

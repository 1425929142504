import styled from 'styled-components'

const GlobalFilterWrapper = styled.div`
  position: absolute;
  top: 100%;
  right: 1.5%;
  min-height: 40px;
  background: #a81662;
  transition: all 200ms;
  cursor: pointer;
  user-select: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  animation: popIn 200ms forwards;

  &:hover {
    background: #830b3f;
  }

  @keyframes popIn {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }
`

export default GlobalFilterWrapper

import actions from 'src/modules/admin/imactCategories/list/impactCategoriesListActions'

const initialData = {
  rows: [] as Array<any>,
  count: 0,
  loading: false,
  menuItems: [] as Array<any>,
  impactCategoryIds: [] as Array<any>,
}

export default (state = initialData, { type, payload }) => {
  if (type === actions.FETCH_STARTED) {
    return {
      ...state,
      loading: true,
      rows: [],
      count: 0,
    }
  }

  if (type === actions.FETCH_SUCCESS) {
    return {
      ...state,
      loading: false,
      rows: payload.rows,
      count: payload.count,
    }
  }

  if (type === actions.MENU_FETCH_SUCCESS) {
    return {
      ...state,
      loading: false,
      menuItems: payload.rows,
      impactCategoryIds: payload.idsArray,
    }
  }

  if (type === actions.FETCH_ERROR) {
    return {
      ...state,
      loading: false,
      rows: [],
      count: 0,
    }
  }

  if (type === actions.MENU_FETCH_ERROR) {
    return {
      ...state,
      loading: false,
      menuItems: [],
      impactCategoryIds: [],
    }
  }

  if (type === actions.RESET) {
    return {
      ...state,
      rows: [],
      count: 0,
      loading: false,
      impactCategoryIds: [],
    }
  }

  return state
}

import { createSelector } from 'reselect'

const selectRaw = (state) => state.GlobalFilter

const selectActiveHierarchy = createSelector(
  [selectRaw],
  (globalFilter) => globalFilter.activeHierarchy,
)
const selectActiveHierarchyValue = createSelector(
  [selectRaw],
  (globalFilter) => globalFilter.activeHierarchyValue,
)
const selectActiveSelectedHierarchyValue = createSelector(
  [selectRaw],
  (globalFilter) => globalFilter.activeSelectedHierarchyValue,
)
const selectActiveHierarchyValues = createSelector(
  [selectRaw],
  (globalFilter) => globalFilter.activeHierarchyValues,
)
const selectSelectedHierarchyValues = createSelector(
  [selectRaw],
  (globalFilter) => globalFilter.selectedHierarchyValues,
)

const selectDoFilter = createSelector(
  [selectRaw],
  (globalFilter) => globalFilter.doFilter,
)

export default {
  selectActiveHierarchy,
  selectActiveHierarchyValue,
  selectActiveSelectedHierarchyValue,
  selectSelectedHierarchyValues,
  selectActiveHierarchyValues,
  selectDoFilter,
}

import impactCategoriesService from 'src/modules/admin/imactCategories/impactCategoriesService'
import Errors from 'src/modules/shared/error/errors'

const prefix = 'IMPACTCATEGORIES_LIST'

const impactCategoriesListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,
  MENU_FETCH_SUCCESS: `${prefix}_MENU_FETCH_SUCCESS`,
  MENU_FETCH_ERROR: `${prefix}_MENU_FETCH_ERROR`,
  RESET: `${prefix}_RESET`,

  doFetch:
    (enabled, orderBy = null) =>
    async (dispatch) => {
      try {
        dispatch({
          type: impactCategoriesListActions.FETCH_STARTED,
        })

        const response = await impactCategoriesService.fetchImpactCategories(
          enabled,
          orderBy,
        )
        dispatch({
          type: impactCategoriesListActions.FETCH_SUCCESS,
          payload: {
            rows: response.rows,
            count: response.count,
          },
        })
      } catch (error) {
        Errors.handle(error)

        dispatch({
          type: impactCategoriesListActions.FETCH_ERROR,
        })
      }
    },

  doFetchMenuItems: () => async (dispatch) => {
    try {
      dispatch({
        type: impactCategoriesListActions.FETCH_STARTED,
      })

      const response =
        await impactCategoriesService.fetchImpactCategoriesMenuItems()

      dispatch({
        type: impactCategoriesListActions.MENU_FETCH_SUCCESS,
        payload: {
          rows: response.menuConfig,
          idsArray: response.idsArray,
        },
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: impactCategoriesListActions.MENU_FETCH_ERROR,
      })
    }
  },
}

export default impactCategoriesListActions

import authAxios from 'src/modules/shared/axios/authAxios'
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant'

export default class IntegrationService {
  static async create(data) {
    const body = {
      data,
    }

    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.post(`/tenant/${tenantId}/jobs`, body)
    return response.data
  }

  static async find(id) {
    const tenantId = AuthCurrentTenant.get()
    const response = await authAxios.get(`/tenant/${tenantId}/jobs/${id}`)
    return response.data
  }

  static async save(integrations) {
    const body = {
      data: integrations,
    }
    const tenantId = AuthCurrentTenant.get()
    const response = await authAxios.post(`/tenant/${tenantId}/jobs`, body)
    return response.data
  }

  static async edit(id, data) {
    const body = {
      data,
    }

    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.put(`/tenant/${tenantId}/jobs/${id}`, body)
    return response.data
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    }

    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.get(`/tenant/${tenantId}/jobs`, {
      params,
    })
    return response.data
  }

  static async destroy(id) {
    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.delete(`/tenant/${tenantId}/jobs/${id}`)

    return response.data
  }
}

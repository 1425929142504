import statuses from 'src/modules/shared/importer/importerStatuses'
import * as yup from 'yup'
import * as papa from 'papaparse'
import { isNumber } from 'lodash'

export default class ImporterSchema {
  fields: Array<any>
  yupSchema

  constructor(fields) {
    this.fields = fields
    this.yupSchema = this.buildSchema()
  }

  buildSchema() {
    const shape = {}

    this.fields.forEach((field) => {
      shape[field.name] = field.schema
    })

    return yup.object().shape(shape).noUnknown(true)
  }

  async castForDisplay(row, index) {
    const rowWithColumnNames = {} as any

    rowWithColumnNames._status = statuses.PENDING
    rowWithColumnNames._line = index + 2 /* 0 and header */

    this.fields
      .filter((field) => !field.hidden)
      .forEach((field, index) => {
        if (field.involvedField) {
          rowWithColumnNames[field.name] = row[index]

          const researchersWithRoles = row[index] ? row[index].split(',') : []
          if (
            researchersWithRoles &&
            researchersWithRoles.length > 0 &&
            field.options &&
            field.options.length > 0
          ) {
            const researchers = researchersWithRoles.map((researcherWithRole) =>
              researcherWithRole.split(':')[0]
                ? researcherWithRole.split(':')[0]
                : '',
            )
            const roles = researchersWithRoles.map((researcherWithRole) =>
              researcherWithRole.split(':')[1]
                ? researcherWithRole.split(':')[1]
                : '',
            )

            const otherRoles = researchersWithRoles.map((researcherWithRole) =>
              researcherWithRole.split(':')[2]
                ? researcherWithRole.split(':')[2]
                : 'null',
            )

            rowWithColumnNames['Involved Researchers'] = researchers
            rowWithColumnNames['Involved Role'] = roles
            rowWithColumnNames['Other Roles'] = otherRoles
          } else if (researchersWithRoles && researchersWithRoles.length > 0) {
            rowWithColumnNames['Involved Researchers'] = researchersWithRoles
          }
        } else if (field.individualsInvolvedField) {
          rowWithColumnNames[field.name] = row[index]

          const researchersWithRoles = row[index] ? row[index].split(',') : []
          if (
            researchersWithRoles &&
            researchersWithRoles.length > 0 &&
            field.options &&
            field.options.length > 0
          ) {
            const researchers = researchersWithRoles.map((researcherWithRole) =>
              researcherWithRole.split(':')[0]
                ? researcherWithRole.split(':')[0]
                : '',
            )
            const roles = researchersWithRoles.map((researcherWithRole) =>
              researcherWithRole.split(':')[1]
                ? researcherWithRole.split(':')[1]
                : '',
            )

            const otherRoles = researchersWithRoles.map((researcherWithRole) =>
              researcherWithRole.split(':')[2]?.length
                ? researcherWithRole.split(':')[2]
                : 'null',
            )

            const responsibilities = researchersWithRoles.map(
              (researcherWithRole) =>
                researcherWithRole.split(':')[3]?.length &&
                isNumber(Number(researcherWithRole.split(':')[3]))
                  ? researcherWithRole.split(':')[3]
                  : 'null',
            )

            rowWithColumnNames['Involved Researchers'] = researchers
            rowWithColumnNames['Involved Role'] = roles
            rowWithColumnNames['Other Roles'] = otherRoles
            rowWithColumnNames['Responsibilities'] = responsibilities
          } else if (researchersWithRoles && researchersWithRoles.length > 0) {
            rowWithColumnNames['Involved Researchers'] = researchersWithRoles
          }
        } else if (field.sponsorOrganizationField) {
          rowWithColumnNames[field.name] = row[index]
          const sponsorWithTypes = row[index] ? row[index].split(',') : []
          if (
            sponsorWithTypes &&
            sponsorWithTypes.length > 0 &&
            field.options &&
            field.options.length > 0
          ) {
            const sponsorOrganization = sponsorWithTypes.map(
              (sponsorWithType) =>
                sponsorWithType.split(':')[0]
                  ? sponsorWithType.split(':')[0]
                  : '',
            )
            const sponsorType = sponsorWithTypes.map((sonsorWithType) =>
              sonsorWithType.split(':')[1] ? sonsorWithType.split(':')[1] : '',
            )

            const otherSponsorType = sponsorWithTypes.map((sonsorWithType) =>
              sonsorWithType.split(':')[2] ? sonsorWithType.split(':')[2] : '',
            )

            rowWithColumnNames['Sponsor Organization'] = sponsorOrganization
            rowWithColumnNames['Sponsor Type'] = sponsorType
            rowWithColumnNames['Other Sponsor Type'] = otherSponsorType
          }
        } else if (field.keyCollaboratorsAndPartnerField) {
          rowWithColumnNames[field.name] = row[index]

          const keyCollaboratorsAndPartners = row[index]
            ? row[index].split(',')
            : []
          if (
            keyCollaboratorsAndPartners &&
            keyCollaboratorsAndPartners.length > 0
          ) {
            const individual = keyCollaboratorsAndPartners.map((individual) =>
              individual.split(':')[0] ? individual.split(':')[0] : '',
            )
            const organisation = keyCollaboratorsAndPartners.map(
              (organisation) =>
                organisation.split(':')[1] ? organisation.split(':')[1] : '',
            )

            rowWithColumnNames['Individual'] = individual
            rowWithColumnNames['Organisation'] = organisation
          }
        } else if (field?.workshopOrganizationField) {
          rowWithColumnNames[field.name] = row[index]

          const organizationWithRecipientType = row[index]
            ? row[index].split(',')
            : []
          if (
            organizationWithRecipientType?.length > 0 &&
            field?.options?.length > 0
          ) {
            const organizations = organizationWithRecipientType.map((item) =>
              item.split(':')[0] ? item.split(':')[0] : '',
            )
            const recipientTypes = organizationWithRecipientType.map((item) =>
              item.split(':')[1] ? item.split(':')[1] : '',
            )

            const otherRecipientTypes = organizationWithRecipientType.map(
              (item) =>
                item?.split(':')[2]?.length ? item.split(':')[2] : 'null',
            )

            rowWithColumnNames['Organizations'] = organizations
            rowWithColumnNames['Organization Recipient Types'] = recipientTypes
            rowWithColumnNames['Other Recipient Types'] = otherRecipientTypes
          }
        } else if (
          (field.multiSelectField || field.milestonesField) &&
          row[index]
        ) {
          const parsedValue = papa.parse(row[index])
          rowWithColumnNames[field.name] = JSON.stringify(
            parsedValue?.data?.[0],
          )
        } else {
          rowWithColumnNames[field.name] = row[index]
        }
      })

    try {
      const validatableRow = await this.castForValidation(rowWithColumnNames)
      await this.yupSchema.validate(validatableRow)
    } catch (error) {
      rowWithColumnNames._status = statuses.ERROR
      rowWithColumnNames._errorMessage = error.message
    }
    return rowWithColumnNames
  }

  async castForImport(row) {
    return this.yupSchema.cast(row)
  }

  async castForValidation(row) {
    return this.yupSchema.cast(row)
  }

  get labels() {
    return this.fields
      .filter((field) => !field.hidden)
      .map((field) => field.label)
  }
}

export default class AuthCurrentTenant {
  static get(all?: boolean) {
    const tenantASString = localStorage.getItem('tenant') || null

    if (tenantASString) {
      const tenant = JSON.parse(tenantASString)

      if (all) {
        return tenant
      }
      return tenant?.id
    }

    return null
  }

  static getSettings() {
    const tenant = this.get(true)

    if (tenant) {
      if (Array.isArray(tenant.settings)) {
        if (tenant.settings.length) {
          return tenant.settings[0]
        }
      } else {
        return tenant.settings
      }
    }

    return null
  }

  static set(tenant) {
    if (!tenant) {
      return this.clear()
    }

    localStorage.setItem('tenant', JSON.stringify(tenant))
  }

  static clear() {
    localStorage.removeItem('tenant')
  }
}

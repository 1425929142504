const communityConsumerLiteratureEnumerators = {
  role: ['lead', 'contributor'],
  publicationType: [
    'booklet',
    'brochure',
    'educational_flier',
    'guidelines',
    'web_content',
    'social_media_page',
    'video',
    'newsletter',
    'fact_sheet',
    'other_specify',
  ],
  recipientType: [
    'government',
    'industry',
    'consumer_community',
    'non-government_organisation',
    'peak_body',
    'research_organisation',
    'education_organisation',
    'other_specify',
  ],
  status: ['draft', 'published', 'archived'],
  recordType: ['Academic', 'Professional'],
}

export default communityConsumerLiteratureEnumerators

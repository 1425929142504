import list from 'src/modules/admin/OrganisationStructure/list/OrganisationStructureListReducers'
import form from 'src/modules/admin/OrganisationStructure/form/OrganisationStructureFormReducers'
import view from 'src/modules/admin/OrganisationStructure/view/OrganisationStructureViewReducers'
import access from 'src/modules/admin/OrganisationStructure/access/OrganisationStructureAccessReducers'
import security from 'src/modules/admin/OrganisationStructure/security/OrganisationStructureSecurityReducer'
import { combineReducers } from 'redux'

export default combineReducers({
  list,
  form,
  view,
  access,
  security,
})

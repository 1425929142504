import list from 'src/modules/toolsProductService/list/toolsProductServiceListReducers'
import form from 'src/modules/toolsProductService/form/toolsProductServiceFormReducers'
import view from 'src/modules/toolsProductService/view/toolsProductServiceViewReducers'
import destroy from 'src/modules/toolsProductService/destroy/toolsProductServiceDestroyReducers'
import importerReducer from 'src/modules/toolsProductService/importer/toolsProductServiceImporterReducers'
import { combineReducers } from 'redux'

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
})

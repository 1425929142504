import authSelectors from './modules/auth/authSelectors'
import { useMemo } from 'react'
import { ProductFruits } from 'react-product-fruits'
import { useSelector } from 'react-redux'

function ProductFruitComponent() {
  const currentUser = useSelector(authSelectors.selectCurrentUser)
  const currentTenant = useSelector(authSelectors.selectCurrentTenant)
  const currentUserRoles = useMemo(() => {
    if (currentTenant && currentUser) {
      return currentUser.tenants
        .find((item) => item.tenant.id === currentTenant.id)
        .roles.join(',')
    }
    return ''
  }, [currentTenant, currentUser])

  const user = {
    firstname: currentUser?.firstName,
    lastname: currentUser?.lastName,
    username: currentUser?.fullName || currentUser?.id,
    email: currentUser?.email,
    role: currentUserRoles,
  }
  return (
    currentUser && (
      <ProductFruits
        debug={true}
        workspaceCode="U20HfGnABCDwQxfZ"
        language="en"
        user={user}
      />
    )
  )
}
export default ProductFruitComponent

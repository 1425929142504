import FileSaver from 'file-saver'
import XLSX from '@sheet/core'

export const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
export const EXCEL_TYPE_WITH_CHARSET = `${EXCEL_TYPE};charset=UTF-8`
export const EXCEL_EXTENSION = '.xlsx'

export class Excel {
  static exportAsExcelFile(
    json,
    header,
    fileName,
    isImportTemplate: boolean = false,
  ) {
    let worksheet = XLSX.utils.json_to_sheet(json, {
      header,
      skipHeader: false,
    })

    if (isImportTemplate) {
      const mergeRange = {
        s: { r: 1, c: 0 },
        e: { r: 1, c: header?.length - 1 },
      }
      const styleRange = {
        s: { r: 1, c: 0 },
        e: { r: 3, c: header?.length - 1 },
      }

      const border: XLSX.Border = { style: 'thin', color: { rgb: 0x000000 } }

      worksheet['!merges'] = [mergeRange]
      XLSX.utils.sheet_set_range_style(worksheet, styleRange, {
        fgColor: { rgb: 0xffffb9 },
        top: border,
        bottom: border,
        left: border,
        right: border,
        incol: border,
        inrow: border,
      })
    }

    const wscols = []
    for (let i = 0; i < header.length; i++) {
      wscols.push({ wch: 20 })
    }
    worksheet['!cols'] = wscols
    XLSX.utils.sheet_set_range_style(worksheet, worksheet['!ref'], {
      alignment: { wrapText: true, horizontal: 'left', vertical: 'top' },
    })
    if (!worksheet['!cols']) worksheet['!cols'] = []
    for (let C = 0; C <= 16383; ++C) {
      // 0 = A is the first column, 16383 = XFD is the last column
      if (!worksheet['!cols'][C]) worksheet['!cols'][C] = { wch: 8 } // default width required
      worksheet['!cols'][C].s = {
        alignment: { wrapText: true, horizontal: 'left', vertical: 'top' },
      } // style for new cells
    }
    let workbook = {
      Sheets: { data: worksheet },
      SheetNames: ['data'],
    }
    let excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
      cellStyles: true,
    })
    this.saveAsExcelFile(excelBuffer, fileName)
  }

  static saveAsExcelFile(buffer, fileName) {
    const data = new Blob([buffer], {
      type: EXCEL_TYPE_WITH_CHARSET,
    })
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION)
  }
}

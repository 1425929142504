import importerActions from 'src/modules/shared/importer/importerActions'
import selectors from 'src/modules/collaborationPartnership/importer/collaborationPartnershipImporterSelectors'
import CollaborationPartnershipService from 'src/modules/collaborationPartnership/collaborationPartnershipService'
import fields from 'src/modules/collaborationPartnership/importer/collaborationPartnershipImporterFields'
import { i18n } from 'src/i18n'

const collaborationPartnershipImporterActions = importerActions(
  'COLLABORATIONPARTNERSHIP_IMPORTER',
  selectors,
  CollaborationPartnershipService.import,
  fields,
  i18n('entities.collaborationPartnership.importer.fileName'),
)

export default collaborationPartnershipImporterActions

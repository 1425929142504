import actions from 'src/modules/publication/orcidImporter/orcidImporterActions'

const INITIAL_PAGE_SIZE = 10

const initialData = {
  rows: [] as Array<any>,
  count: 0,
  orcidRecords: [],
  orcidPublications: [],
  errorMessage: null,
  loading: false,
  completed: false,
  pagination: {
    current: 1,
    pageSize: INITIAL_PAGE_SIZE,
  },
  sorter: {},
  selectedPublication: null,
  id: null,
}

export default (state = initialData, { type, payload }) => {
  if (type === actions.FETCH_STARTED) {
    return {
      ...state,
      loading: true,
      importing: true,
    }
  }

  if (type === actions.FETCH_SUCCESS) {
    return {
      ...state,
      loading: false,
      orcidRecords: payload.works,
      orcidPublications: payload.publications,
      count: payload.count,
      importing: false,
      completed: true,
    }
  }

  if (type === actions.FETCH_ERROR) {
    return {
      ...state,
      loading: false,
      importing: false,
    }
  }

  if (type === actions.SELECT_PUBLICATION) {
    return {
      ...state,
      selectedPublication: payload,
    }
  }

  if (type === actions.CLEAR_PUBLICATION) {
    return {
      ...state,
      selectedPublication: null,
    }
  }

  if (type === actions.SET_ID) {
    return {
      ...state,
      id: payload,
    }
  }

  if (type === actions.PAGINATION_CHANGED) {
    return {
      ...state,
      pagination: payload || {
        current: 1,
        pageSize: INITIAL_PAGE_SIZE,
      },
    }
  }

  return state
}

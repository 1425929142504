import CollaborationPartnershipService from 'src/modules/collaborationPartnership/collaborationPartnershipService'
import selectors from 'src/modules/collaborationPartnership/list/collaborationPartnershipListSelectors'
import exporterFields from 'src/modules/collaborationPartnership/list/collaborationPartnershipListExporterFields'
import { generateListActions } from '../../listHelpers'

const prefix = 'COLLABORATIONPARTNERSHIP_LIST'

const collaborationPartnershipListActions = generateListActions(
  prefix,
  CollaborationPartnershipService.list,
  selectors,
  exporterFields,
  'startDate',
)

export default collaborationPartnershipListActions

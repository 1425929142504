import { i18n } from 'src/i18n'
import exporterRenders from 'src/modules/shared/exporter/exporterRenders'

export default [
  {
    name: 'id',
    label: i18n('entities.workshopTrainingFacilitated.fields.id'),
  },
  {
    name: 'researcher',
    label: i18n('entities.workshopTrainingFacilitated.fields.researcher'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'description',
    label: i18n('entities.workshopTrainingFacilitated.fields.description'),
  },
  {
    name: 'startDate',
    label: i18n('entities.workshopTrainingFacilitated.fields.startDate'),
  },
  {
    name: 'recipient',
    label: i18n('entities.workshopTrainingFacilitated.fields.recipient'),
  },
  {
    name: 'recipientType',
    label: i18n('entities.workshopTrainingFacilitated.fields.recipientType'),
  },
  {
    name: 'country',
    label: i18n('entities.workshopTrainingFacilitated.fields.country'),
  },
  {
    name: 'educationTrainingMaterialId',
    label: i18n(
      'entities.workshopTrainingFacilitated.fields.educationTrainingMaterialId',
    ),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'city',
    label: i18n('entities.workshopTrainingFacilitated.fields.city'),
  },
  {
    name: 'role',
    label: i18n('entities.workshopTrainingFacilitated.fields.role'),
  },
  {
    name: 'files',
    label: i18n('entities.workshopTrainingFacilitated.fields.files'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'images',
    label: i18n('entities.workshopTrainingFacilitated.fields.images'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'author',
    label: i18n('entities.workshopTrainingFacilitated.fields.author'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'dateCreated',
    label: i18n('entities.workshopTrainingFacilitated.fields.dateCreated'),
  },
  {
    name: 'status',
    label: i18n('entities.workshopTrainingFacilitated.fields.status'),
  },
  {
    name: 'title',
    label: i18n('entities.workshopTrainingFacilitated.fields.title'),
  },
  {
    name: 'recordType',
    label: i18n('entities.workshopTrainingFacilitated.fields.recordType'),
  },
  {
    name: 'year',
    label: i18n('entities.workshopTrainingFacilitated.fields.year'),
  },
  {
    name: 'organization',
    label: i18n('entities.workshopTrainingFacilitated.fields.organization'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.workshopTrainingFacilitated.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.workshopTrainingFacilitated.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
]

import EducationTrainingMaterialService from 'src/modules/educationTrainingMaterial/educationTrainingMaterialService'
import selectors from 'src/modules/educationTrainingMaterial/list/educationTrainingMaterialListSelectors'
import exporterFields from 'src/modules/educationTrainingMaterial/list/educationTrainingMaterialListExporterFields'
import { generateListActions } from '../../listHelpers'

const prefix = 'EDUCATIONTRAININGMATERIAL_LIST'

const educationTrainingMaterialListActions = generateListActions(
  prefix,
  EducationTrainingMaterialService.list,
  selectors,
  exporterFields,
  'year',
)

export default educationTrainingMaterialListActions

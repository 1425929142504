import { i18n } from 'src/i18n'
import exporterRenders from 'src/modules/shared/exporter/exporterRenders'

export default [
  {
    name: 'id',
    label: i18n('entities.report.fields.id'),
  },
  {
    name: 'title',
    label: i18n('entities.report.fields.title'),
  },
  {
    name: 'researcher',
    label: i18n('entities.report.fields.researcher'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'role',
    label: i18n('entities.report.fields.role'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'seriesNumber',
    label: i18n('entities.report.fields.seriesNumber'),
  },
  {
    name: 'organization',
    label: i18n('entities.report.fields.organization'),
  },
  {
    name: 'recipient',
    label: i18n('entities.report.fields.recipient'),
  },
  {
    name: 'commissioned',
    label: i18n('entities.report.fields.commissioned'),
  },
  {
    name: 'publisher',
    label: i18n('entities.report.fields.publisher'),
  },
  {
    name: 'country',
    label: i18n('entities.report.fields.country'),
  },
  {
    name: 'city',
    label: i18n('entities.report.fields.city'),
  },
  {
    name: 'state',
    label: i18n('entities.report.fields.state'),
  },
  {
    name: 'files',
    label: i18n('entities.report.fields.files'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'images',
    label: i18n('entities.report.fields.images'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'author',
    label: i18n('entities.report.fields.author'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'dateCreated',
    label: i18n('entities.report.fields.dateCreated'),
  },
  {
    name: 'status',
    label: i18n('entities.report.fields.status'),
  },
  {
    name: 'researchers',
    label: i18n('entities.report.fields.researchers'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'recordType',
    label: i18n('entities.report.fields.recordType'),
  },
  {
    name: 'year',
    label: i18n('entities.report.fields.year'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.report.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.report.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
]

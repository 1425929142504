const prefix = `ORGANISATION_STRUCTURE_ACCESS`

const organisationStructureAccessActions = {
  SELECT_ACTIVE_HIERARCHY: `${prefix}_SELECT_ACTIVE_HIERARCHY`,
  SELECT_ACTIVE_HIERARCHY_VALUE: `${prefix}_SELECT_ACTIVE_HIERARCHY_VALUE`,
  SELECT_ACTIVE_SELECTED_HIERARCHY_VALUE: `${prefix}_SELECT_ACTIVE_SELECTED_HIERARCHY_VALUE`,
  INITIALIZE_ACTIVE_HIERARCHY_VALUES: `${prefix}_INITIALIZE_ACTIVE_HIERARCHY_VALUES`,
  ADD_HIERARCHY_VALUE: `${prefix}_ADD_HIERARCHY_VALUE`,
  REMOVE_HIERARCHY_VALUE: `${prefix}_REMOVE_HIERARCHY_VALUE`,
  RESET: `${prefix}_RESET`,

  doSelectActiveHierarchy: (hierarchy) => async (dispatch) => {
    dispatch({
      type: organisationStructureAccessActions.SELECT_ACTIVE_HIERARCHY,
      payload: hierarchy,
    })
  },

  doSelectActiveHierarchyValue: (hierarchyValue) => async (dispatch) => {
    dispatch({
      type: organisationStructureAccessActions.SELECT_ACTIVE_HIERARCHY_VALUE,
      payload: hierarchyValue,
    })
  },

  doInitializeHierarchyValues: (hierarchyValues) => async (dispatch) => {
    dispatch({
      type: organisationStructureAccessActions.INITIALIZE_ACTIVE_HIERARCHY_VALUES,
      payload: hierarchyValues,
    })
  },

  doSelectActiveSelectedHierarchyValue: (value) => async (dispatch) => {
    dispatch({
      type: organisationStructureAccessActions.SELECT_ACTIVE_SELECTED_HIERARCHY_VALUE,
      payload: value,
    })
  },

  doAddHierarchyValues: (values) => async (dispatch) => {
    dispatch({
      type: organisationStructureAccessActions.ADD_HIERARCHY_VALUE,
      payload: values,
    })
  },

  doRemoveHierarchyValue: (value) => async (dispatch) => {
    dispatch({
      type: organisationStructureAccessActions.REMOVE_HIERARCHY_VALUE,
      payload: value,
    })
  },

  doReset: (values) => async (dispatch) => {
    dispatch({
      type: organisationStructureAccessActions.RESET,
      payload: values,
    })
  },
}

export default organisationStructureAccessActions

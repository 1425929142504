import FundingService from 'src/modules/funding/fundingService'
import selectors from 'src/modules/funding/list/fundingListSelectors'
import exporterFields from 'src/modules/funding/list/fundingListExporterFields'
import { generateListActions } from '../../listHelpers'

const prefix = 'FUNDING_LIST'

const fundingListActions = generateListActions(
  prefix,
  FundingService.list,
  selectors,
  exporterFields,
  'startDate',
)

export default fundingListActions

import importerActions from 'src/modules/shared/importer/importerActions'
import selectors from 'src/modules/caseStudy/importer/caseStudyImporterSelectors'
import CaseStudyService from 'src/modules/caseStudy/caseStudyService'
import fields from 'src/modules/caseStudy/importer/caseStudyImporterFields'
import { i18n } from 'src/i18n'

const caseStudyImporterActions = importerActions(
  'CASESTUDY_IMPORTER',
  selectors,
  CaseStudyService.import,
  fields,
  i18n('entities.caseStudy.importer.fileName'),
)

export default caseStudyImporterActions

import importerActions from 'src/modules/shared/importer/importerActions'
import selectors from 'src/modules/oralPresentation/importer/oralPresentationImporterSelectors'
import OralPresentationService from 'src/modules/oralPresentation/oralPresentationService'
import fields from 'src/modules/oralPresentation/importer/oralPresentationImporterFields'
import { i18n } from 'src/i18n'

const oralPresentationImporterActions = importerActions(
  'ORALPRESENTATION_IMPORTER',
  selectors,
  OralPresentationService.import,
  fields,
  i18n('entities.oralPresentation.importer.fileName'),
)

export default oralPresentationImporterActions

import { i18n } from 'src/i18n'
import hierarchyValuesService from 'src/modules/admin/OrganisationStructure/HierarchyValues/HierarchyValuesService'
import Errors from 'src/modules/shared/error/errors'
import { getHistory } from 'src/modules/store'
import Message from 'src/components/message'

const prefix = 'HIERARCHYVALUES_FORM'

const hierarchyValuesFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  ADD_STARTED: `${prefix}_ADD_STARTED`,
  ADD_SUCCESS: `${prefix}_ADD_SUCCESS`,
  ADD_ERROR: `${prefix}_ADD_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: (id?) => async (dispatch) => {
    try {
      dispatch({
        type: hierarchyValuesFormActions.INIT_STARTED,
      })

      const isEdit = Boolean(id)
      let record = {}

      if (isEdit) {
        record = await hierarchyValuesService.get(id)
      }

      dispatch({
        type: hierarchyValuesFormActions.INIT_SUCCESS,
        payload: record,
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: hierarchyValuesFormActions.INIT_ERROR,
      })

      getHistory().push('/admin/organisationStructure')
    }
  },

  doAdd: (values) => async (dispatch) => {
    try {
      dispatch({
        type: hierarchyValuesFormActions.ADD_STARTED,
      })

      await hierarchyValuesService.create(values)

      dispatch({
        type: hierarchyValuesFormActions.ADD_SUCCESS,
      })

      Message.success(i18n('user.doAddSuccess'))

      getHistory().push('/admin/organisationStructure')
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: hierarchyValuesFormActions.ADD_ERROR,
      })
    }
  },

  doUpdate: (id, values) => async (dispatch) => {
    try {
      dispatch({
        type: hierarchyValuesFormActions.UPDATE_STARTED,
      })

      await hierarchyValuesService.edit(id, values)

      dispatch({
        type: hierarchyValuesFormActions.UPDATE_SUCCESS,
      })

      getHistory().push('/admin/orgStructure')
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: hierarchyValuesFormActions.UPDATE_ERROR,
      })
    }
  },
}

export default hierarchyValuesFormActions

import { i18n } from 'src/i18n'
import exporterRenders from 'src/modules/shared/exporter/exporterRenders'

export default [
  {
    name: 'id',
    label: i18n('entities.externalDecisionMakingAdvisoryGroup.fields.id'),
  },
  {
    name: 'title',
    label: i18n('entities.externalDecisionMakingAdvisoryGroup.fields.title'),
  },
  {
    name: 'researcher',
    label: i18n(
      'entities.externalDecisionMakingAdvisoryGroup.fields.researcher',
    ),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'role',
    label: i18n('entities.externalDecisionMakingAdvisoryGroup.fields.role'),
  },
  {
    name: 'organization',
    label: i18n(
      'entities.externalDecisionMakingAdvisoryGroup.fields.organization',
    ),
  },
  {
    name: 'organizationType',
    label: i18n(
      'entities.externalDecisionMakingAdvisoryGroup.fields.organizationType',
    ),
  },
  {
    name: 'groupType',
    label: i18n(
      'entities.externalDecisionMakingAdvisoryGroup.fields.groupType',
    ),
  },
  {
    name: 'scope',
    label: i18n('entities.externalDecisionMakingAdvisoryGroup.fields.scope'),
  },
  {
    name: 'purpose',
    label: i18n('entities.externalDecisionMakingAdvisoryGroup.fields.purpose'),
  },
  {
    name: 'invited',
    label: i18n('entities.externalDecisionMakingAdvisoryGroup.fields.invited'),
  },
  {
    name: 'startDate',
    label: i18n(
      'entities.externalDecisionMakingAdvisoryGroup.fields.startDate',
    ),
    render: exporterRenders.datetime(),
  },
  {
    name: 'endDate',
    label: i18n('entities.externalDecisionMakingAdvisoryGroup.fields.endDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'description',
    label: i18n(
      'entities.externalDecisionMakingAdvisoryGroup.fields.description',
    ),
  },
  {
    name: 'impact',
    label: i18n('entities.externalDecisionMakingAdvisoryGroup.fields.impact'),
  },
  {
    name: 'files',
    label: i18n('entities.externalDecisionMakingAdvisoryGroup.fields.files'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'images',
    label: i18n('entities.externalDecisionMakingAdvisoryGroup.fields.images'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'author',
    label: i18n('entities.externalDecisionMakingAdvisoryGroup.fields.author'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'dateCreated',
    label: i18n(
      'entities.externalDecisionMakingAdvisoryGroup.fields.dateCreated',
    ),
  },
  {
    name: 'status',
    label: i18n('entities.externalDecisionMakingAdvisoryGroup.fields.status'),
  },
  {
    name: 'recordType',
    label: i18n(
      'entities.externalDecisionMakingAdvisoryGroup.fields.recordType',
    ),
  },
  {
    name: 'year',
    label: i18n('entities.externalDecisionMakingAdvisoryGroup.fields.year'),
  },
  {
    name: 'createdAt',
    label: i18n(
      'entities.externalDecisionMakingAdvisoryGroup.fields.createdAt',
    ),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n(
      'entities.externalDecisionMakingAdvisoryGroup.fields.updatedAt',
    ),
    render: exporterRenders.datetime(),
  },
]

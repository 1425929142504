import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant'
import authAxios from 'src/modules/shared/axios/authAxios'
import { Excel } from '../shared/excel/excel'
import actions from './actions'

export default class SearchService {
  static async searchList(
    title,
    startDate,
    endDate,
    categories,
    lowestHierarchies,
    status,
    isExport,
    pagination: [],
    researchers,
    url,
    projectIds,
  ) {
    const params = {
      q: title,
      startDate,
      endDate,
      isExport,
      status,
      pagination: JSON.stringify(pagination),
      categories: JSON.stringify(categories),
      lowestHierarchies: JSON.stringify(lowestHierarchies),
      researchers: JSON.stringify(researchers),
      projectIds: JSON.stringify(projectIds),
      url,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    }

    if (!isExport) {
      actions.doFetch(
        title,
        startDate,
        endDate,
        categories,
        lowestHierarchies,
        status,
        isExport,
        pagination,
        [],
        researchers,
        url,
        projectIds,
      )
    }

    const tenantId = AuthCurrentTenant.get()

    if (!isExport) {
      const response = await authAxios.get(
        `/tenant/${tenantId}/search/reports`,
        { params },
      )

      return response.data
    } else {
      const response = await authAxios.get(
        `/tenant/${tenantId}/search/reports`,
        {
          params,
        },
      )

      if (response.data.exportUrl) {
        const excelBuffer = await authAxios.post(
          `/tenant/${tenantId}/${response.data.exportUrl}`,
          {
            pagination,
            categories,
            lowestHierarchies,
            url,
          },
          {
            responseType: 'arraybuffer',
            params,
          },
        )
        return Excel.saveAsExcelFile(excelBuffer.data, 'report')
      } else {
        return response.data
      }
    }
  }

  static async saveReport(userId, data) {
    const tenantId = AuthCurrentTenant.get()
    const body = {
      data,
    }

    const response = data.id
      ? await authAxios.put(
          `/tenant/${tenantId}/user/${userId}/savedReports/${data.id}`,
          body,
        )
      : await authAxios.post(
          `/tenant/${tenantId}/user/${userId}/savedReports`,
          body,
        )

    return response.data
  }

  static async fetchSavedReports(userId) {
    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.get(
      `/tenant/${tenantId}/user/${userId}/savedReports`,
    )

    return response.data.rows
  }

  static async deleteSavedReport(userId, reportId) {
    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.delete(
      `/tenant/${tenantId}/user/${userId}/savedReports/${reportId}`,
    )

    return response.data
  }
}

import { CSSProperties } from 'react'
import {
  ChildrenType,
  EventsType,
  IPosition,
  Middleware,
  PlacesType,
  PositionStrategy,
  Tooltip,
  VariantType,
  WrapperType,
} from 'react-tooltip'

interface TooltipProps {
  className?: string
  classNameArrow?: string
  maxWidth?: CSSProperties['maxWidth']
  zIndex?: CSSProperties['zIndex']
  content?: string
  html?: string
  render?: (render: {
    content: string | null
    activeAnchor: HTMLElement | null
  }) => ChildrenType
  place?: PlacesType
  offset?: number
  id?: string
  variant?: VariantType
  anchorId?: string
  anchorSelect?: string
  wrapper?: WrapperType
  children?: ChildrenType
  events?: EventsType[]
  openOnClick?: boolean
  positionStrategy?: PositionStrategy
  middlewares?: Middleware[]
  delayShow?: number
  delayHide?: number
  float?: boolean
  hidden?: boolean
  noArrow?: boolean
  clickable?: boolean
  closeOnEsc?: boolean
  closeOnScroll?: boolean
  closeOnResize?: boolean
  style?: CSSProperties
  position?: IPosition
  isOpen?: boolean
  disableStyleInjection?: boolean | 'core'
  border?: CSSProperties['border']
  opacity?: CSSProperties['opacity']
  setIsOpen?: (value: boolean) => void
  afterShow?: () => void
  afterHide?: () => void
}

const style = (style: CSSProperties): CSSProperties => {
  return {
    ...style,
    zIndex: style?.zIndex ?? '999',
    maxWidth: style?.maxWidth ?? '400px',
    whiteSpace: 'pre-wrap',
    fontSize: style?.fontSize ?? '13px',
  }
}

const ReactTooltip = (props: TooltipProps) => {
  return (
    <Tooltip
      {...props}
      border={props?.border ?? '1px solid #dee2e6'}
      place={props?.place ?? 'bottom'}
      variant={props?.variant ?? 'light'}
      style={{
        ...style({
          ...props.style,
          maxWidth: props?.style?.maxWidth ?? props?.maxWidth,
          zIndex: props?.style?.zIndex ?? props?.zIndex,
        }),
      }}
    >
      {props.children}
    </Tooltip>
  )
}

export default ReactTooltip

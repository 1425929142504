import importerActions from 'src/modules/shared/importer/importerActions'
import selectors from 'src/modules/barriersSupport/importer/barriersSupportImporterSelectors'
import BarriersSupportService from 'src/modules/barriersSupport/barriersSupportService'
import fields from 'src/modules/barriersSupport/importer/barriersSupportImporterFields'
import { i18n } from 'src/i18n'

const barriersSupportImporterActions = importerActions(
  'BARRIERSSUPPORT_IMPORTER',
  selectors,
  BarriersSupportService.import,
  fields,
  i18n('entities.barriersSupport.importer.fileName'),
)

export default barriersSupportImporterActions

const awardPrizesEnumerators = {
  type: [
    'award',
    'prize',
    'admission_to_prestigious_group/academy',
    'other_specify',
  ],
  status: ['draft', 'published', 'archived'],
  recordType: ['Academic', 'Professional'],
}

export default awardPrizesEnumerators

import WorkshopTrainingFacilitatedService from 'src/modules/workshopTrainingFacilitated/workshopTrainingFacilitatedService'
import selectors from 'src/modules/workshopTrainingFacilitated/list/workshopTrainingFacilitatedListSelectors'
import exporterFields from 'src/modules/workshopTrainingFacilitated/list/workshopTrainingFacilitatedListExporterFields'
import { generateListActions } from '../../listHelpers'

const prefix = 'WORKSHOPTRAININGFACILITATED_LIST'

const workshopTrainingFacilitatedListActions = generateListActions(
  prefix,
  WorkshopTrainingFacilitatedService.list,
  selectors,
  exporterFields,
  'startDate',
)

export default workshopTrainingFacilitatedListActions

import { i18n } from 'src/i18n'
import exporterRenders from 'src/modules/shared/exporter/exporterRenders'

export default [
  {
    name: 'id',
    label: i18n('entities.interventionStudy.fields.id'),
  },
  {
    name: 'title',
    label: i18n('entities.interventionStudy.fields.title'),
  },
  {
    name: 'studyType',
    label: i18n('entities.interventionStudy.fields.studyType'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'researchType',
    label: i18n('entities.interventionStudy.fields.researchType'),
  },
  {
    name: 'organization',
    label: i18n('entities.interventionStudy.fields.organization'),
  },
  {
    name: 'sponsorOrganization',
    label: i18n('entities.interventionStudy.fields.sponsorOrganization'),
  },
  {
    name: 'partyType',
    label: i18n('entities.interventionStudy.fields.partyType'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'country',
    label: i18n('entities.interventionStudy.fields.country'),
  },
  {
    name: 'city',
    label: i18n('entities.interventionStudy.fields.city'),
  },
  {
    name: 'sites',
    label: i18n('entities.interventionStudy.fields.sites'),
  },
  {
    name: 'participants',
    label: i18n('entities.interventionStudy.fields.participants'),
  },
  {
    name: 'contribution',
    label: i18n('entities.interventionStudy.fields.contribution'),
  },
  {
    name: 'startDate',
    label: i18n('entities.interventionStudy.fields.startDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'endDate',
    label: i18n('entities.interventionStudy.fields.endDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'impact',
    label: i18n('entities.interventionStudy.fields.impact'),
  },
  {
    name: 'trialId',
    label: i18n('entities.interventionStudy.fields.trialId'),
  },
  {
    name: 'projectId',
    label: i18n('entities.interventionStudy.fields.projectId'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'files',
    label: i18n('entities.interventionStudy.fields.files'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'images',
    label: i18n('entities.interventionStudy.fields.images'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'author',
    label: i18n('entities.interventionStudy.fields.author'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'dateCreated',
    label: i18n('entities.interventionStudy.fields.dateCreated'),
  },
  {
    name: 'status',
    label: i18n('entities.interventionStudy.fields.status'),
  },
  {
    name: 'researchers',
    label: i18n('entities.interventionStudy.fields.researchers'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'recordType',
    label: i18n('entities.interventionStudy.fields.recordType'),
  },
  {
    name: 'year',
    label: i18n('entities.interventionStudy.fields.year'),
  },
  {
    name: 'phase',
    label: i18n('entities.interventionStudy.fields.phase'),
  },
  {
    name: 'condition',
    label: i18n('entities.interventionStudy.fields.condition'),
  },
  {
    name: 'status3',
    label: i18n('entities.interventionStudy.fields.status3'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.interventionStudy.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.interventionStudy.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
]

import Errors from 'src/modules/shared/error/errors'
import { getHistory } from 'src/modules/store'
import groupsService from 'src/modules/admin/groups/groupsService'

const prefix = 'GROUP_VIEW'

const groupViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: groupViewActions.FIND_STARTED,
      })

      const group = await groupsService.find(id)

      dispatch({
        type: groupViewActions.FIND_SUCCESS,
        payload: group,
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: groupViewActions.FIND_ERROR,
      })

      getHistory().push('/admin/groups')
    }
  },
}

export default groupViewActions

import { i18n } from 'src/i18n'
import exporterFields from 'src/modules/inKindContribution/list/inKindContributionListExporterFields'
import Errors from 'src/modules/shared/error/errors'
import Exporter from 'src/modules/shared/exporter/exporter'

import IntegrationService from 'src/modules/integrations/integrationService'
import selectors from 'src/modules/integrations/list/integrationListSelectors'

import { getHistory } from 'src/modules/store'
import Message from 'src/components/message'

const prefix = 'INTEGRATION_LIST'

const integrationActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  RESET: `${prefix}_RESET`,
  TOGGLE_ONE_SELECTED: `${prefix}_TOGGLE_ONE_SELECTED`,
  TOGGLE_ALL_SELECTED: `${prefix}_TOGGLE_ALL_SELECTED`,
  CLEAR_ALL_SELECTED: `${prefix}_CLEAR_ALL_SELECTED`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  EXPORT_STARTED: `${prefix}_EXPORT_STARTED`,
  EXPORT_SUCCESS: `${prefix}_EXPORT_SUCCESS`,
  EXPORT_ERROR: `${prefix}_EXPORT_ERROR`,

  SAVE_STARTED: `${prefix}_SAVE_STARTED`,
  SAVE_SUCCESS: `${prefix}_SAVE_SUCCESS`,
  SAVE_ERROR: `${prefix}_SAVE_ERROR`,

  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  doClearAllSelected() {
    return {
      type: integrationActions.CLEAR_ALL_SELECTED,
    }
  },

  doToggleAllSelected() {
    return {
      type: integrationActions.TOGGLE_ALL_SELECTED,
    }
  },

  doToggleOneSelected(id) {
    return {
      type: integrationActions.TOGGLE_ONE_SELECTED,
      payload: id,
    }
  },

  doReset: () => async (dispatch) => {
    dispatch({
      type: integrationActions.RESET,
    })

    dispatch(integrationActions.doFetch())
  },

  doExport: () => async (dispatch, getState) => {
    try {
      if (!exporterFields || !exporterFields.length) {
        throw new Error('exporterFields is required')
      }

      dispatch({
        type: integrationActions.EXPORT_STARTED,
      })

      const filter = selectors.selectFilter(getState())
      const response = await IntegrationService.list(
        filter,
        selectors.selectOrderBy(getState()),
        null,
        null,
      )

      new Exporter(
        exporterFields,
        i18n('entities.inKindContribution.exporterFileName'),
      ).transformAndExportAsExcelFile(response.rows)

      dispatch({
        type: integrationActions.EXPORT_SUCCESS,
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: integrationActions.EXPORT_ERROR,
      })
    }
  },

  doChangePagination: (pagination) => async (dispatch) => {
    dispatch({
      type: integrationActions.PAGINATION_CHANGED,
      payload: pagination,
    })

    dispatch(integrationActions.doFetchCurrentFilter())
  },

  doChangeSort: (sorter) => async (dispatch) => {
    dispatch({
      type: integrationActions.SORTER_CHANGED,
      payload: sorter,
    })

    dispatch(integrationActions.doFetchCurrentFilter())
  },

  doFetchCurrentFilter: () => async (dispatch, getState) => {
    const filter = selectors.selectFilter(getState())
    const rawFilter = selectors.selectRawFilter(getState())
    dispatch(integrationActions.doFetch(filter, rawFilter, true))
  },

  doFetch:
    (filter?, rawFilter?, keepPagination = false) =>
    async (dispatch, getState) => {
      try {
        dispatch({
          type: integrationActions.FETCH_STARTED,
          payload: { filter, rawFilter, keepPagination },
        })

        const response = await IntegrationService.list(
          filter,
          selectors.selectOrderBy(getState()),
          selectors.selectLimit(getState()),
          selectors.selectOffset(getState()),
        )

        dispatch({
          type: integrationActions.FETCH_SUCCESS,
          payload: {
            rows: response.rows,
            count: response.count,
          },
        })
      } catch (error) {
        Errors.handle(error)

        dispatch({
          type: integrationActions.FETCH_ERROR,
        })
      }
    },

  doSave: (values) => async (dispatch) => {
    try {
      dispatch({
        type: integrationActions.SAVE_STARTED,
      })

      const settings = await IntegrationService.save(values)

      dispatch({
        type: integrationActions.SAVE_SUCCESS,
        payload: settings,
      })
      Message.success(i18n('integrations.save.success'))
      getHistory().push('/admin/integrations')
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: integrationActions.SAVE_ERROR,
      })
    }
  },

  doDestroy: (id) => async (dispatch) => {
    try {
      dispatch({
        type: integrationActions.DESTROY_STARTED,
      })

      await IntegrationService.destroy(id)

      dispatch({
        type: integrationActions.DESTROY_SUCCESS,
      })

      Message.success(i18n('integrations.doDestroySuccess'))

      dispatch(integrationActions.doFetchCurrentFilter())
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: integrationActions.DESTROY_ERROR,
      })

      dispatch(integrationActions.doFetchCurrentFilter())
    }
  },
}

export default integrationActions

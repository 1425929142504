import list from 'src/modules/editorialReviewerResponsibility/list/editorialReviewerResponsibilityListReducers'
import form from 'src/modules/editorialReviewerResponsibility/form/editorialReviewerResponsibilityFormReducers'
import view from 'src/modules/editorialReviewerResponsibility/view/editorialReviewerResponsibilityViewReducers'
import destroy from 'src/modules/editorialReviewerResponsibility/destroy/editorialReviewerResponsibilityDestroyReducers'
import importerReducer from 'src/modules/editorialReviewerResponsibility/importer/editorialReviewerResponsibilityImporterReducers'
import { combineReducers } from 'redux'

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
})

import _get from 'lodash/get'
import { setLocale as setYupLocale } from 'yup'

const languages: {
  [key: string]: {
    id: string
    label: string
    flag: string
    dateFns: any
    dictionary: any
  }
} = {
  en: {
    id: 'en',
    label: 'English',
    flag: '/images/flags/United-States.png',
    dateFns: null,
    dictionary: null,
  },
}

export async function init() {
  await initEn()
}

async function initEn() {
  const language = languages['en']

  language.dictionary = (await import('src/i18n/en')).default

  if (language.dictionary.validation) {
    setYupLocale(language.dictionary.validation)
  }

  return language
}

export function getLanguage() {
  return languages['en']
}

function format(message, args) {
  if (!message) {
    return null
  }

  try {
    return message.replace(/{(\d+)}/g, function (match, number) {
      return typeof args[number] != 'undefined' ? args[number] : match
    })
  } catch (error) {
    console.error(message, error)
    throw error
  }
}

export function i18nExists(key) {
  if (!getLanguage()) {
    return false
  }

  const message = _get(getLanguage().dictionary, key)
  return Boolean(message)
}

export function i18n(key, ...args) {
  if (!getLanguage()) {
    return key
  }

  const message = _get(getLanguage().dictionary, key)

  if (!message) {
    return key
  }

  return format(message, args)
}

export function i18nHtml(key, ...args) {
  return (
    <span
      dangerouslySetInnerHTML={{
        __html: i18n(key, ...args),
      }}
    />
  )
}

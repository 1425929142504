import actions from 'src/modules/admin/imactCategories/imactCategoriesFields/form/impactCategoriesFieldFormActions'

const initialData = {
  initLoading: false,
  saveLoading: false,
  impactCategoryField: null,
  historical: false,
  impactCategoryFieldConfig: {},
  collaborationPartnershipFieldsConfig: {},
}

export default (state = initialData, { type, payload }) => {
  if (type === actions.INIT_STARTED) {
    return {
      ...state,
      impactCategoryField: null,
      initLoading: true,
    }
  }

  if (type === actions.INIT_SUCCESS) {
    return {
      ...state,
      impactCategoryField: payload,
      initLoading: false,
    }
  }

  if (type === actions.INIT_ERROR) {
    return {
      ...state,
      impactCategoryField: null,
      initLoading: false,
    }
  }

  if (type === actions.UPDATE_STARTED) {
    return {
      ...state,
      saveLoading: true,
    }
  }

  if (type === actions.UPDATE_SUCCESS) {
    return {
      ...state,
      saveLoading: false,
    }
  }

  if (type === actions.UPDATE_ERROR) {
    return {
      ...state,
      saveLoading: false,
    }
  }

  if (type === actions.FIELDSCONFIG_FETCH_STARTED) {
    return {
      ...state,
      impactCategoryFieldConfig: {},
    }
  }

  if (type === actions.FIELDSCONFIG_FETCH_SUCCESS) {
    return {
      ...state,
      impactCategoryFieldConfig: payload,
    }
  }

  if (type === actions.COLLABORATION_PARTNERSHIP_FETCH_SUCCESS) {
    return {
      ...state,
      collaborationPartnershipFieldsConfig: payload,
    }
  }

  if (type === actions.FIELDSCONFIG_FETCH_ERROR) {
    return {
      ...state,
      impactCategoryFieldConfig: {},
    }
  }

  if (type === actions.UPDATE_HISTORICAL) {
    return {
      ...state,
      historical: payload,
    }
  }

  return state
}

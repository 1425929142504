import PermissionChecker from 'src/modules/auth/permissionChecker'
import { Navigate } from 'react-router-dom'

function PublicRoute(props) {
  const {
    component: Component,
    currentTenant,
    currentUser,
    redirectPath,
    ...rest
  } = props
  const permissionChecker = new PermissionChecker(currentTenant, currentUser)

  if (permissionChecker.isAuthenticated) {
    return <Navigate to={redirectPath} />
  }

  return <Component {...props} />
}

export default PublicRoute

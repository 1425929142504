const collaborativeNetworkEnumerators = {
  role: [
    'leader',
    'participant',
    'chair_member',
    'secretary',
    'ex_officer',
    'co_chair',
    'representative',
    'other_specify',
  ],
  scope: [
    'Perth-metro',
    'WA-state_wide',
    'National',
    'International',
    'WA-Gascoyne',
    'WA-Goldfields-Esperance',
    'WA-Great_Southern',
    'WA-Kimberley',
    'WA-Mid_West',
    'WA-Peel',
    'WA-Pilbara',
    'WA-South',
    'West',
    'WA-Wheatbelt',
    'National-NSW',
    'National-NT',
    'National-QLD',
    'National-SA',
    'National-TAS National-VIC',
    'International-Africa',
    'International-Asia',
    'International-Central America',
    'International-Eastern_Europe',
    'International-European_Union',
    'International-Middle_East',
    'International-North_America',
    'International-Oceania',
    'International-South_America',
    'International-Caribbean',
  ],
  status: ['draft', 'published', 'archived'],
  recordType: ['Academic', 'Professional'],
  networkType: [
    'research_network',
    'clinical_network',
    'policy_network',
    'disease_focused_network',
    'other_specify',
  ],
}

export default collaborativeNetworkEnumerators

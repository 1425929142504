import Message from 'src/components/message'
import { getHistory } from 'src/modules/store'
import { i18n, i18nExists } from 'src/i18n'
import { AuthToken } from '../../auth/authToken'

export const DEFAULT_ERROR_MESSAGE = i18n('errors.defaultErrorMessage')

function selectErrorKeyOrMessage(error) {
  if (error && error.response && error.response.data) {
    const data = error.response.data

    if (data.error && data.error.message) {
      return data.error.message
    }

    if (error?.message) {
      return error?.message
    }

    return String(data)
  }

  return error.message || DEFAULT_ERROR_MESSAGE
}

function selectErrorMessage(error) {
  const key = selectErrorKeyOrMessage(error)

  if (i18nExists(key)) {
    return i18n(key)
  }

  return key
}

function selectErrorCode(error) {
  if (error && error.response && error.response.status) {
    return error.response.status
  }

  return 500
}

export default class Errors {
  static handle(error) {
    const response = error?.response

    if (!response) {
      Message.error(error.message || DEFAULT_ERROR_MESSAGE)
    }

    const statusCode = response?.status

    if (statusCode === 401) {
      AuthToken.clear()
      window.location.reload()
      return
    }

    if (statusCode === 403) {
      getHistory().push(
        {
          pathname: '/error',
        },
        {
          errorCode: 403,
        },
      )
      return
    }

    Message.error(response?.data?.message || DEFAULT_ERROR_MESSAGE)
  }

  static errorCode(error) {
    return selectErrorCode(error)
  }

  static selectMessage(error) {
    return selectErrorMessage(error)
  }

  static showMessage(error) {
    Message.error(selectErrorMessage(error))
  }
}

import PublicationService from 'src/modules/publication/publicationService'
import selectors from 'src/modules/publication/list/publicationListSelectors'
import exporterFields from 'src/modules/publication/list/publicationListExporterFields'
import { generateListActions } from '../../listHelpers'

const prefix = 'PUBLICATION_LIST'

const publicationListActions = generateListActions(
  prefix,
  PublicationService.list,
  selectors,
  exporterFields,
  'year',
)
export default publicationListActions

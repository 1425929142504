import CollaborativeNetworkService from 'src/modules/collaborativeNetwork/collaborativeNetworkService'
import selectors from 'src/modules/collaborativeNetwork/list/collaborativeNetworkListSelectors'
import exporterFields from 'src/modules/collaborativeNetwork/list/collaborativeNetworkListExporterFields'
import { generateListActions } from '../../listHelpers'

const prefix = 'COLLABORATIVENETWORK_LIST'

const collaborativeNetworkListActions = generateListActions(
  prefix,
  CollaborativeNetworkService.list,
  selectors,
  exporterFields,
  'startDate',
)

export default collaborativeNetworkListActions

import styled from 'styled-components'

const MenuWrapper = styled.div`
  margin-top: 72px;

  .handle {
    position: absolute;
    z-index: 100;
    width: 24px;
    top: 74px;
    bottom: 0;
    left: 255px;
    cursor: col-resize;
  }

  .menu-nav:hover {
    overflow-y: scroll;
    padding-right: 0px;
  }

  .menu-nav {
    width: 255px;
    height: calc(100vh - 74px);
    padding-top: 34px;
    overflow: hidden;
    background-color: #f8f9fa;

    ::-webkit-scrollbar {
      width: 12px;
    }

    ul:not(:last-of-type) {
      padding-bottom: 34px;
    }

    ::-webkit-scrollbar-track {
      background: #e9ecef;
    }

    ::-webkit-scrollbar-button {
      display: none;
    }

    ::-webkit-scrollbar-thumb {
      background: #ced4da;
      border-radius: 4px;
    }
  }

  .header {
    color: rgb(168, 22, 97);
    padding-left: 24px;
    font-weight: 500;
    font-size: 20px;
    line-height: 32.81px;
    padding-bottom: 16px;
  }

  @media only screen and (max-width: 770px) {
    .menu-nav {
      height: calc(100vh - 74px);
    }
  }

  @media only screen and (min-width: 770px) and (max-width: 1600px) {
    .menu-nav {
      height: calc(100vh - 74px);
    }
  }

  .menu-ul {
    font-size: 13px;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: none;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    line-height: 0;
    transition: background 0.3s, width 0.2s;
    zoom: 1;
    border-top: 1px solid rgb(168, 22, 97);
  }

  .menu-li {
    border-top: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
  }

  .menu-li.active {
    border: unset;
  }

  .menu-li a,
  .menu-li .menu-li-locked {
    display: block;
    width: 100%;
    padding: 0 16px;
    padding-left: 24px;
    font-size: 16px;
    line-height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    background-color: white;
  }

  .menu-li a .menu-icon,
  .menu-li > .menu-li-locked .menu-icon {
    margin-right: 10px;
  }

  .menu-li .menu-li-locked {
    cursor: default;
  }

  .menu-li a:focus,
  .menu-li a:hover {
    text-decoration: none;
  }
`

export default MenuWrapper

const fundingEnumerators = {
  role: [
    'project_leader',
    'chief_investigator',
    'project_team_member',
    'associated_investigator',
    'consultant',
    'student',
    'advisor',
    'support/co-ordinator',
    'project_manager',
    'editor',
    'partner',
  ],
  status: ['draft', 'published', 'archived'],
  recordType: ['Academic', 'Professional'],
  fundingType: [
    'philanthropic',
    'not_for_profit',
    'non_government',
    'government',
    'industry',
    'crc',
    'rdc',
    'charity',
    'other',
  ],
  schemeType: [
    'contract',
    'competitive_grant',
    'contract_research',
    'internal_grant',
    'philanthropy',
    'other',
  ],

  partnerOrganizationType: [
    'industry',
    'government',
    'non_government',
    'philanthropy',
    'academic',
    'educational_institution',
    'research_organisation',
    'charity',
    'other',
  ],
  potential: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
}

export default fundingEnumerators

import Errors from 'src/modules/shared/error/errors'
import impactCategoriesFieldService from 'src/modules/admin/imactCategories/imactCategoriesFields/impactCategoriesFieldService'
import { getHistory } from 'src/modules/store'

const prefix = 'IMPACTCATEGORIESFIELD_FORM'

const impactCategoriesFieldFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  FIELDSCONFIG_FETCH_STARTED: `${prefix}_FIELDSCONFIG_FETCH_STARTED`,
  FIELDSCONFIG_FETCH_SUCCESS: `${prefix}_FIELDSCONFIG_FETCH_SUCCESS`,
  FIELDSCONFIG_FETCH_ERROR: `${prefix}_FIELDSCONFIG_FETCH_ERROR`,

  COLLABORATION_PARTNERSHIP_FETCH_SUCCESS: `${prefix}_COLLABORATION_PARTNERSHIP_FIELDSCONFIG_FETCH_SUCCESS`,

  UPDATE_HISTORICAL: `${prefix}_UPDATE_HISTORICAL`,

  doInit: (id?) => async (dispatch) => {
    try {
      dispatch({
        type: impactCategoriesFieldFormActions.INIT_STARTED,
      })

      const isEdit = Boolean(id)
      let record = {}

      if (isEdit) {
        record = await impactCategoriesFieldService.find(id)
      }

      dispatch({
        type: impactCategoriesFieldFormActions.INIT_SUCCESS,
        payload: record,
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: impactCategoriesFieldFormActions.INIT_ERROR,
      })

      getHistory().push('/admin/impactCategories')
    }
  },

  doUpdate: (id, values) => async (dispatch) => {
    try {
      dispatch({
        type: impactCategoriesFieldFormActions.UPDATE_STARTED,
      })

      await impactCategoriesFieldService.update(id, values)

      dispatch({
        type: impactCategoriesFieldFormActions.UPDATE_SUCCESS,
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: impactCategoriesFieldFormActions.UPDATE_ERROR,
      })
    }
  },

  fetchCollaborationPartnershipFieldsConfig: (id) => async (dispatch) => {
    try {
      const response = await impactCategoriesFieldService.findFieldConfig(id)
      dispatch({
        type: impactCategoriesFieldFormActions.COLLABORATION_PARTNERSHIP_FETCH_SUCCESS,
        payload: response,
      })
    } catch (error) {
      Errors.handle(error)
    }
  },

  fetchFieldsConfig: (id) => async (dispatch) => {
    try {
      dispatch({
        type: impactCategoriesFieldFormActions.FIELDSCONFIG_FETCH_STARTED,
      })

      if (id) {
        const response = await impactCategoriesFieldService.findFieldConfig(id)

        dispatch({
          type: impactCategoriesFieldFormActions.FIELDSCONFIG_FETCH_SUCCESS,
          payload: response,
        })
      }
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: impactCategoriesFieldFormActions.FIELDSCONFIG_FETCH_ERROR,
      })
    }
  },

  doUpdateHistorical: (historical) => (dispatch) => {
    dispatch({
      type: impactCategoriesFieldFormActions.UPDATE_HISTORICAL,
      payload: historical,
    })
  },
}

export default impactCategoriesFieldFormActions

const prefix = 'RESEARCHERS'

const researcherActions = {
  SET_ACTIVE_RESEARCHERS: `${prefix}_SET_ACTIVE_HIERARCHIES`,
  SELECT_RESEARCHER: `${prefix}_SELECT_RESEARCHER`,
  REMOVE_RESEARCHER: `${prefix}_REMOVE_RESEARCHER`,
  RESET: `${prefix}_RESET`,

  doSetActiveResearchers: (researchers) => (dispatch) => {
    dispatch({
      type: researcherActions.SET_ACTIVE_RESEARCHERS,
      payload: researchers,
    })
  },

  doSelectResearcher: (researcher) => (dispatch) => {
    dispatch({ type: researcherActions.SELECT_RESEARCHER, payload: researcher })
  },

  doRemoveResearcher: (id) => (dispatch) => {
    dispatch({ type: researcherActions.REMOVE_RESEARCHER, payload: id })
  },

  doReset: () => (dispatch) => {
    dispatch({ type: researcherActions.RESET })
  },
}

export default researcherActions

import hierarchyValuesService from 'src/modules/admin/OrganisationStructure/HierarchyValues/HierarchyValuesService'
import Errors from 'src/modules/shared/error/errors'
import Message from 'src/components/message'
import { i18n } from 'src/i18n'

const prefix = 'HierarchyValues_LIST'

const HierarchyValuesListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  doFetch: (archived) => async (dispatch) => {
    try {
      dispatch({
        type: HierarchyValuesListActions.FETCH_STARTED,
      })

      const response = await hierarchyValuesService.getAll(archived)

      dispatch({
        type: HierarchyValuesListActions.FETCH_SUCCESS,
        payload: {
          response: response,
        },
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: HierarchyValuesListActions.FETCH_ERROR,
      })
    }
  },

  doDestroy: (hierarchyId, id) => async (dispatch) => {
    try {
      dispatch({
        type: HierarchyValuesListActions.DESTROY_STARTED,
      })

      await hierarchyValuesService.destroy(hierarchyId, id)

      dispatch({
        type: HierarchyValuesListActions.DESTROY_SUCCESS,
      })

      Message.success(i18n('user.doDestroySuccess'))
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: HierarchyValuesListActions.DESTROY_ERROR,
      })
    }
  },
}

export default HierarchyValuesListActions

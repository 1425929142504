import { i18n } from 'src/i18n'
import exporterRenders from 'src/modules/shared/exporter/exporterRenders'

export default [
  {
    name: 'id',
    label: i18n('entities.mentoringSupervision.fields.id'),
  },
  {
    name: 'author',
    label: i18n('entities.mentoringSupervision.fields.author'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'dateCreated',
    label: i18n('entities.mentoringSupervision.fields.dateCreated'),
  },
  {
    name: 'status',
    label: i18n('entities.mentoringSupervision.fields.status'),
  },
  {
    name: 'title',
    label: i18n('entities.mentoringSupervision.fields.title'),
  },
  {
    name: 'recordType',
    label: i18n('entities.mentoringSupervision.fields.recordType'),
  },
  {
    name: 'year',
    label: i18n('entities.mentoringSupervision.fields.year'),
  },
  {
    name: 'mentoringSupervision',
    label: i18n('entities.mentoringSupervision.fields.mentoringSupervision'),
  },
  {
    name: 'menteeName',
    label: i18n('entities.mentoringSupervision.fields.menteeName'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'researcher',
    label: i18n('entities.mentoringSupervision.fields.researcher'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'reponsibility',
    label: i18n('entities.mentoringSupervision.fields.reponsibility'),
  },
  {
    name: 'role',
    label: i18n('entities.mentoringSupervision.fields.role'),
  },
  {
    name: 'allSupervisors',
    label: i18n('entities.mentoringSupervision.fields.allSupervisors'),
  },
  {
    name: 'name',
    label: i18n('entities.mentoringSupervision.fields.name'),
  },
  {
    name: 'mentoringStatus',
    label: i18n('entities.mentoringSupervision.fields.mentoringStatus'),
  },
  {
    name: 'degree',
    label: i18n('entities.mentoringSupervision.fields.degree'),
  },
  {
    name: 'program',
    label: i18n('entities.mentoringSupervision.fields.program'),
  },
  {
    name: 'organization',
    label: i18n('entities.mentoringSupervision.fields.organization'),
  },
  {
    name: 'mentorName',
    label: i18n('entities.mentoringSupervision.fields.mentorName'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'programType',
    label: i18n('entities.mentoringSupervision.fields.programType'),
  },
  {
    name: 'menteeType',
    label: i18n('entities.mentoringSupervision.fields.menteeType'),
  },
  {
    name: 'environment',
    label: i18n('entities.mentoringSupervision.fields.environment'),
  },
  {
    name: 'startDate',
    label: i18n('entities.mentoringSupervision.fields.startDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'endDate',
    label: i18n('entities.mentoringSupervision.fields.endDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'files',
    label: i18n('entities.mentoringSupervision.fields.files'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'images',
    label: i18n('entities.mentoringSupervision.fields.images'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.mentoringSupervision.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.mentoringSupervision.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
]

const consumerCommunityInvolvementEnumerators = {
  status: ['draft', 'published', 'archived'],
  recordType: ['Academic', 'Professional'],
  frequency: [
    'once',
    'weekly',
    'monthly',
    'quarterly',
    'biannual',
    'annual',
    'other_specify',
  ],
  involvementType: [
    'document_reviewer',
    'forum',
    'consumer_and_community_member_in_research_team',
    'research_buddy',
    'reference_group',
    'steering_group',
    'consumer_and_community_researcher',
    'consumer_and_community_advisory_council',
    'priority_setting_group',
    'yarning',
    'other_specify',
  ],
}

export default consumerCommunityInvolvementEnumerators

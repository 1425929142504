import authAxios from 'src/modules/shared/axios/authAxios'
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant'

export default class impactCategoriesFieldService {
  static async update(id, data) {
    const body = {
      data,
    }

    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.put(
      `/tenant/${tenantId}/tenantImpactCategories/${id}/fields`,
      body,
    )

    return response.data
  }

  static async find(id) {
    const tenantId = AuthCurrentTenant.get()
    const response = await authAxios.get(
      `/tenant/${tenantId}/tenantImpactCategories/${id}/fields`,
    )
    return response.data
  }

  static async findFieldConfig(id) {
    const fieldsConfig = {}
    const tenantId = AuthCurrentTenant.get()
    const response = await authAxios.get(
      `/tenant/${tenantId}/tenantImpactCategories/${id}/fields`,
    )

    if (response && response.data.rows.length > 0) {
      response.data.rows.map((field) => {
        let index =
          field.options.length > 0 &&
          field.options.indexOf(
            field.options.find((option) =>
              option.label.toLowerCase().includes('other specify'),
            ),
          )
        index >= 0 && field.options.push(field.options.splice(index, 1)[0])

        return (fieldsConfig[field.field] = {
          id: field.id,
          enabled: field.enabled,
          mandatory: field.mandatory,
          ongoingEnabled: field.ongoingEnabled,
          options: field.options || null,
          label: field.label,
          hint: field.hint,
        })
      })

      return fieldsConfig
    }
  }

  static async addFieldOption(fieldId, data) {
    const body = {
      data,
    }

    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.post(
      `/tenant/${tenantId}/fields/${fieldId}/options`,
      body,
    )

    return response.data
  }

  static async deleteFieldOption(fieldId, optionId) {
    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.delete(
      `/tenant/${tenantId}/fields/${fieldId}/options/${optionId}`,
    )

    return response.data
  }

  static async getFieldOptions(fieldId) {
    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.get(
      `/tenant/${tenantId}/fields/${fieldId}/options`,
    )

    return response.data.rows
  }
}

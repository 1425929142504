import actions from 'src/modules/admin/imactCategories/imactCategoriesFields/list/impactCategoriesFieldListActions'

const initialData = {
  rows: [] as Array<any>,
  count: 0,
  loading: false,
}

export default (state = initialData, { type, payload }) => {
  if (type === actions.FETCH_STARTED) {
    return {
      ...state,
      count: 0,
      rows: [],
      loading: true,
    }
  }

  if (type === actions.FETCH_SUCCESS) {
    return {
      ...state,
      loading: false,
      rows: payload.rows,
      count: payload.count,
    }
  }

  if (type === actions.FETCH_ERROR) {
    return {
      ...state,
      loading: false,
      rows: [],
      count: 0,
    }
  }

  if (type === actions.CLEAR) {
    return {
      ...state,
      count: 0,
      rows: [],
      loading: false,
    }
  }

  return state
}

import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'

const selectRaw = (state) => state.settings

const selectSettings = createSelector([selectRaw], (raw) => raw.settings)

const selectInitLoading = createSelector([selectRaw], (raw) =>
  Boolean(raw.initLoading),
)

const selectInitiated = createSelector([selectRaw], (raw) =>
  Boolean(raw.initiated),
)

const selectSaveLoading = createSelector([selectRaw], (raw) =>
  Boolean(raw.saveLoading),
)

const features = createSelector(
  [(state: any) => state.auth],
  (auth) => auth.features,
)

const settingsSelectors = {
  selectInitLoading,
  selectSaveLoading,
  selectSettings,
  selectRaw,
  features,
}

export const useTenantSetting = () => {
  return useSelector(selectSettings)
}

export const useIsTenantSettingInitiated = () => {
  return useSelector(selectInitiated)
}

export default settingsSelectors

import importerActions from 'src/modules/shared/importer/importerActions'
import selectors from 'src/modules/educationTrainingMaterial/importer/educationTrainingMaterialImporterSelectors'
import EducationTrainingMaterialService from 'src/modules/educationTrainingMaterial/educationTrainingMaterialService'
import fields from 'src/modules/educationTrainingMaterial/importer/educationTrainingMaterialImporterFields'
import { i18n } from 'src/i18n'

const educationTrainingMaterialImporterActions = importerActions(
  'EDUCATIONTRAININGMATERIAL_IMPORTER',
  selectors,
  EducationTrainingMaterialService.import,
  fields,
  i18n('entities.educationTrainingMaterial.importer.fileName'),
)

export default educationTrainingMaterialImporterActions

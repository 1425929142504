import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant'
import authAxios from 'src/modules/shared/axios/authAxios'

export default class UserService {
  static async edit(data) {
    const body = {
      data,
    }

    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.put(`/tenant/${tenantId}/user`, body)
    return response.data
  }

  static async destroy(ids) {
    const params = {
      ids,
    }

    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.delete(`/tenant/${tenantId}/user`, {
      params,
    })

    return response.data
  }

  static async create(data) {
    const body = {
      data,
    }
    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.post(`/tenant/${tenantId}/user`, body)

    return response.data
  }

  static async import(values, importHash) {
    const body = {
      data: {
        ...values,
      },
      importHash,
    }

    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.post(
      `/tenant/${tenantId}/user/import`,
      body,
    )

    return response.data
  }

  static async find(id) {
    const tenantId = AuthCurrentTenant.get()
    const response = await authAxios.get(`/tenant/${tenantId}/user/${id}`)
    return response.data
  }

  static async fetchUsers(filter, orderBy, limit, offset, archived = false) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
      archived,
    }

    if (filter) {
      filter.archived = archived
    }
    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.get(`/tenant/${tenantId}/user`, {
      params,
    })
    return response.data
  }

  static async fetchUserAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    }

    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.get(`/tenant/${tenantId}/user`, {
      params,
    })
    return response.data.rows
  }

  static async fetchUserNames() {
    const tenantId = AuthCurrentTenant.get()
    const response = await authAxios.get(`/tenant/${tenantId}/users`)
    return response.data.rows
  }

  static async searchUser(query) {
    const tenantId = AuthCurrentTenant.get()
    const response = await authAxios.get(
      `/tenant/${tenantId}/search/users?q=${query}`,
    )

    return response.data.rows
  }
}

import importerActions from 'src/modules/shared/importer/importerActions'
import selectors from 'src/modules/interventionStudy/importer/interventionStudyImporterSelectors'
import InterventionStudyService from 'src/modules/interventionStudy/interventionStudyService'
import fields from 'src/modules/interventionStudy/importer/interventionStudyImporterFields'
import { i18n } from 'src/i18n'

const interventionStudyImporterActions = importerActions(
  'INTERVENTIONSTUDY_IMPORTER',
  selectors,
  InterventionStudyService.import,
  fields,
  i18n('entities.interventionStudy.importer.fileName'),
)

export default interventionStudyImporterActions

import schemas from 'src/modules/shared/yup/yupImporterSchemas'
import { i18n } from 'src/i18n'
import interventionStudyEnumerators from 'src/modules/interventionStudy/interventionStudyEnumerators'

export default [
  {
    name: 'title',
    label: i18n('entities.interventionStudy.fields.title'),
    schema: schemas.string(i18n('entities.interventionStudy.fields.title'), {}),
  },
  {
    name: 'studyType',
    label: i18n('entities.interventionStudy.fields.studyType'),
    schema: schemas.boolean(
      i18n('entities.interventionStudy.fields.studyType'),
    ),
  },
  {
    name: 'researchType',
    label: i18n('entities.interventionStudy.fields.researchType'),
    schema: schemas.string(
      i18n('entities.interventionStudy.fields.researchType'),
      {},
    ),
  },
  {
    name: 'organization',
    label: i18n('entities.interventionStudy.fields.organization'),
    schema: schemas.string(
      i18n('entities.interventionStudy.fields.organization'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'sponsorOrganization',
    label: i18n('entities.interventionStudy.fields.sponsorOrganization'),
    schema: schemas.string(
      i18n('entities.interventionStudy.fields.sponsorOrganization'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'partyType',
    label: i18n('entities.interventionStudy.fields.partyType'),
    schema: schemas.boolean(
      i18n('entities.interventionStudy.fields.partyType'),
    ),
  },
  {
    name: 'country',
    label: i18n('entities.interventionStudy.fields.country'),
    schema: schemas.string(
      i18n('entities.interventionStudy.fields.country'),
      {},
    ),
  },
  {
    name: 'city',
    label: i18n('entities.interventionStudy.fields.city'),
    schema: schemas.string(i18n('entities.interventionStudy.fields.city'), {
      required: true,
    }),
  },
  {
    name: 'sites',
    label: i18n('entities.interventionStudy.fields.sites'),
    schema: schemas.string(i18n('entities.interventionStudy.fields.sites'), {}),
  },
  {
    name: 'participants',
    label: i18n('entities.interventionStudy.fields.participants'),
    schema: schemas.string(
      i18n('entities.interventionStudy.fields.participants'),
      {},
    ),
  },
  {
    name: 'contribution',
    label: i18n('entities.interventionStudy.fields.contribution'),
    schema: schemas.string(
      i18n('entities.interventionStudy.fields.contribution'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'startDate',
    label: i18n('entities.interventionStudy.fields.startDate'),
    schema: schemas.datetime(
      i18n('entities.interventionStudy.fields.startDate'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'endDate',
    label: i18n('entities.interventionStudy.fields.endDate'),
    schema: schemas.datetime(
      i18n('entities.interventionStudy.fields.endDate'),
      {},
    ),
  },
  {
    name: 'impact',
    label: i18n('entities.interventionStudy.fields.impact'),
    schema: schemas.string(
      i18n('entities.interventionStudy.fields.impact'),
      {},
    ),
  },
  {
    name: 'trialId',
    label: i18n('entities.interventionStudy.fields.trialId'),
    schema: schemas.string(
      i18n('entities.interventionStudy.fields.trialId'),
      {},
    ),
  },
  {
    name: 'projectId',
    label: i18n('entities.interventionStudy.fields.projectId'),
    schema: schemas.relationToMany(
      i18n('entities.interventionStudy.fields.projectId'),
      {},
    ),
  },
  {
    name: 'files',
    label: i18n('entities.interventionStudy.fields.files'),
    schema: schemas.files(i18n('entities.interventionStudy.fields.files'), {}),
  },
  {
    name: 'images',
    label: i18n('entities.interventionStudy.fields.images'),
    schema: schemas.images(
      i18n('entities.interventionStudy.fields.images'),
      {},
    ),
  },
  {
    name: 'status',
    label: i18n('entities.interventionStudy.fields.status'),
    schema: schemas.enumerator(
      i18n('entities.interventionStudy.fields.status'),
      {
        required: true,
        options: interventionStudyEnumerators.status,
      },
    ),
  },
  {
    name: 'researchers',
    label: i18n('entities.interventionStudy.fields.researchers'),
    schema: schemas.relationToMany(
      i18n('entities.interventionStudy.fields.researchers'),
      {},
    ),
  },
  {
    name: 'recordType',
    label: i18n('entities.interventionStudy.fields.recordType'),
    schema: schemas.enumerator(
      i18n('entities.interventionStudy.fields.recordType'),
      {
        options: interventionStudyEnumerators.recordType,
      },
    ),
  },
  {
    name: 'year',
    label: i18n('entities.interventionStudy.fields.year'),
    schema: schemas.integer(i18n('entities.interventionStudy.fields.year'), {}),
  },
  {
    name: 'phase',
    label: i18n('entities.interventionStudy.fields.phase'),
    schema: schemas.string(i18n('entities.interventionStudy.fields.phase'), {
      required: true,
    }),
  },
  {
    name: 'condition',
    label: i18n('entities.interventionStudy.fields.condition'),
    schema: schemas.string(
      i18n('entities.interventionStudy.fields.condition'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'status3',
    label: i18n('entities.interventionStudy.fields.status3'),
    schema: schemas.string(
      i18n('entities.interventionStudy.fields.status3'),
      {},
    ),
  },
]

import CommunityConsumerLiteratureService from 'src/modules/communityConsumerLiterature/communityConsumerLiteratureService'
import selectors from 'src/modules/communityConsumerLiterature/list/communityConsumerLiteratureListSelectors'
import exporterFields from 'src/modules/communityConsumerLiterature/list/communityConsumerLiteratureListExporterFields'
import { generateListActions } from '../../listHelpers'

const prefix = 'COMMUNITYCONSUMERLITERATURE_LIST'

const communityConsumerLiteratureListActions = generateListActions(
  prefix,
  CommunityConsumerLiteratureService.list,
  selectors,
  exporterFields,
  'year',
)

export default communityConsumerLiteratureListActions

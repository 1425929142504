import styled from 'styled-components'

const MainWrapper = styled.div`
  overflow-y: scroll;
  height: 93vh;

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background: #e9ecef;
  }

  ::-webkit-scrollbar-button {
    display: none;
  }

  ::-webkit-scrollbar-thumb {
    background: #ced4da;
    border-radius: 4px;
  }

  .report-layout {
    background-color: rgb(248, 249, 250);
  }
  .map-layout {
    background-color: white;
  }
`

export default MainWrapper

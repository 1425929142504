import list from 'src/modules/interventionStudy/list/interventionStudyListReducers'
import form from 'src/modules/interventionStudy/form/interventionStudyFormReducers'
import view from 'src/modules/interventionStudy/view/interventionStudyViewReducers'
import destroy from 'src/modules/interventionStudy/destroy/interventionStudyDestroyReducers'
import importerReducer from 'src/modules/interventionStudy/importer/interventionStudyImporterReducers'
import { combineReducers } from 'redux'

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
})

import listActions from 'src/modules/guidelinePolicyPractice/list/guidelinePolicyPracticeListActions'
import GuidelinePolicyPracticeService from 'src/modules/guidelinePolicyPractice/guidelinePolicyPracticeService'
import Errors from 'src/modules/shared/error/errors'
import { i18n } from 'src/i18n'
import { getHistory } from 'src/modules/store'
import Message from 'src/components/message'

const prefix = 'GUIDELINEPOLICYPRACTICE_DESTROY'

const guidelinePolicyPracticeDestroyActions = {
  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  DESTROY_ALL_STARTED: `${prefix}_DESTROY_ALL_STARTED`,
  DESTROY_ALL_SUCCESS: `${prefix}_DESTROY_ALL_SUCCESS`,
  DESTROY_ALL_ERROR: `${prefix}_DESTROY_ALL_ERROR`,

  doDestroy:
    (id, impactCategoryAlias = null) =>
    async (dispatch) => {
      try {
        dispatch({
          type: guidelinePolicyPracticeDestroyActions.DESTROY_STARTED,
        })

        await GuidelinePolicyPracticeService.destroyAll([id])

        dispatch({
          type: guidelinePolicyPracticeDestroyActions.DESTROY_SUCCESS,
        })

        Message.success(
          impactCategoryAlias
            ? i18n(
                'impactCategories.messages.destroy.success',
                impactCategoryAlias,
              )
            : i18n('entities.guidelinePolicyPractice.destroy.success'),
        )
        dispatch(listActions.doFetchCurrentFilter())

        getHistory().push('/guideline-policy-practice')
      } catch (error) {
        Errors.handle(error)

        dispatch(listActions.doFetchCurrentFilter())

        dispatch({
          type: guidelinePolicyPracticeDestroyActions.DESTROY_ERROR,
        })
      }
    },

  doDestroyAll: (ids) => async (dispatch) => {
    try {
      dispatch({
        type: guidelinePolicyPracticeDestroyActions.DESTROY_ALL_STARTED,
      })

      await GuidelinePolicyPracticeService.destroyAll(ids)

      dispatch({
        type: guidelinePolicyPracticeDestroyActions.DESTROY_ALL_SUCCESS,
      })

      if (listActions) {
        dispatch(listActions.doClearAllSelected())
        dispatch(listActions.doFetchCurrentFilter())
      }

      Message.success(
        i18n('entities.guidelinePolicyPractice.destroyAll.success'),
      )

      getHistory().push('/guideline-policy-practice')
    } catch (error) {
      Errors.handle(error)

      dispatch(listActions.doFetchCurrentFilter())

      dispatch({
        type: guidelinePolicyPracticeDestroyActions.DESTROY_ALL_ERROR,
      })
    }
  },
}

export default guidelinePolicyPracticeDestroyActions

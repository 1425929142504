import list from 'src/modules/testimonial/list/testimonialListReducers'
import form from 'src/modules/testimonial/form/testimonialFormReducers'
import view from 'src/modules/testimonial/view/testimonialViewReducers'
import destroy from 'src/modules/testimonial/destroy/testimonialDestroyReducers'
import importerReducer from 'src/modules/testimonial/importer/testimonialImporterReducers'
import { combineReducers } from 'redux'

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
})

import schemas from 'src/modules/shared/yup/yupImporterSchemas'
import { i18n } from 'src/i18n'
import editorialReviewerResponsibilityEnumerators from 'src/modules/editorialReviewerResponsibility/editorialReviewerResponsibilityEnumerators'

export default [
  {
    name: 'title',
    label: i18n('entities.editorialReviewerResponsibility.fields.title'),
    schema: schemas.string(
      i18n('entities.editorialReviewerResponsibility.fields.title'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'researcher',
    label: i18n('entities.editorialReviewerResponsibility.fields.researcher'),
    schema: schemas.relationToOne(
      i18n('entities.editorialReviewerResponsibility.fields.researcher'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'role',
    label: i18n('entities.editorialReviewerResponsibility.fields.role'),
    schema: schemas.enumerator(
      i18n('entities.editorialReviewerResponsibility.fields.role'),
      {
        options: editorialReviewerResponsibilityEnumerators.role,
      },
    ),
  },
  {
    name: 'publicationType',
    label: i18n(
      'entities.editorialReviewerResponsibility.fields.publicationType',
    ),
    schema: schemas.string(
      i18n('entities.editorialReviewerResponsibility.fields.publicationType'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'startDate',
    label: i18n('entities.editorialReviewerResponsibility.fields.startDate'),
    schema: schemas.datetime(
      i18n('entities.editorialReviewerResponsibility.fields.startDate'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'endDate',
    label: i18n('entities.editorialReviewerResponsibility.fields.endDate'),
    schema: schemas.datetime(
      i18n('entities.editorialReviewerResponsibility.fields.endDate'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'number',
    label: i18n('entities.editorialReviewerResponsibility.fields.number'),
    schema: schemas.string(
      i18n('entities.editorialReviewerResponsibility.fields.number'),
      {},
    ),
  },
  {
    name: 'files',
    label: i18n('entities.editorialReviewerResponsibility.fields.files'),
    schema: schemas.files(
      i18n('entities.editorialReviewerResponsibility.fields.files'),
      {},
    ),
  },
  {
    name: 'images',
    label: i18n('entities.editorialReviewerResponsibility.fields.images'),
    schema: schemas.images(
      i18n('entities.editorialReviewerResponsibility.fields.images'),
      {},
    ),
  },
  {
    name: 'status',
    label: i18n('entities.editorialReviewerResponsibility.fields.status'),
    schema: schemas.enumerator(
      i18n('entities.editorialReviewerResponsibility.fields.status'),
      {
        required: true,
        options: editorialReviewerResponsibilityEnumerators.status,
      },
    ),
  },
  {
    name: 'recordType',
    label: i18n('entities.editorialReviewerResponsibility.fields.recordType'),
    schema: schemas.enumerator(
      i18n('entities.editorialReviewerResponsibility.fields.recordType'),
      {
        options: editorialReviewerResponsibilityEnumerators.recordType,
      },
    ),
  },
  {
    name: 'year',
    label: i18n('entities.editorialReviewerResponsibility.fields.year'),
    schema: schemas.integer(
      i18n('entities.editorialReviewerResponsibility.fields.year'),
      {},
    ),
  },
  {
    name: 'publisher',
    label: i18n('entities.editorialReviewerResponsibility.fields.publisher'),
    schema: schemas.string(
      i18n('entities.editorialReviewerResponsibility.fields.publisher'),
      {
        required: true,
      },
    ),
  },
]

import schemas from 'src/modules/shared/yup/yupImporterSchemas'
import { i18n } from 'src/i18n'
import barriersSupportEnumerators from 'src/modules/barriersSupport/barriersSupportEnumerators'

export default [
  {
    name: 'title',
    label: i18n('entities.barriersSupport.fields.title'),
    schema: schemas.string(i18n('entities.barriersSupport.fields.title'), {
      required: true,
    }),
  },
  {
    name: 'description',
    label: i18n('entities.barriersSupport.fields.description'),
    schema: schemas.string(
      i18n('entities.barriersSupport.fields.description'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'assistance',
    label: i18n('entities.barriersSupport.fields.assistance'),
    schema: schemas.string(i18n('entities.barriersSupport.fields.assistance'), {
      required: true,
    }),
  },
  {
    name: 'assistanceType',
    label: i18n('entities.barriersSupport.fields.assistanceType'),
    schema: schemas.string(
      i18n('entities.barriersSupport.fields.assistanceType'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'files',
    label: i18n('entities.barriersSupport.fields.files'),
    schema: schemas.files(i18n('entities.barriersSupport.fields.files'), {}),
  },
  {
    name: 'images',
    label: i18n('entities.barriersSupport.fields.images'),
    schema: schemas.images(i18n('entities.barriersSupport.fields.images'), {}),
  },
  {
    name: 'status',
    label: i18n('entities.barriersSupport.fields.status'),
    schema: schemas.enumerator(i18n('entities.barriersSupport.fields.status'), {
      required: true,
      options: barriersSupportEnumerators.status,
    }),
  },
  {
    name: 'researchers',
    label: i18n('entities.barriersSupport.fields.researchers'),
    schema: schemas.relationToMany(
      i18n('entities.barriersSupport.fields.researchers'),
      {},
    ),
  },
  {
    name: 'recordType',
    label: i18n('entities.barriersSupport.fields.recordType'),
    schema: schemas.enumerator(
      i18n('entities.barriersSupport.fields.recordType'),
      {
        options: barriersSupportEnumerators.recordType,
      },
    ),
  },
  {
    name: 'year',
    label: i18n('entities.barriersSupport.fields.year'),
    schema: schemas.integer(i18n('entities.barriersSupport.fields.year'), {}),
  },
  {
    name: 'projectId',
    label: i18n('entities.barriersSupport.fields.projectId'),
    schema: schemas.relationToMany(
      i18n('entities.barriersSupport.fields.projectId'),
      {},
    ),
  },
]

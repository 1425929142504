import styled from 'styled-components'

const OrganisationStructureRestrictionEditWrapper = styled.div`
  position: initial;
  background: #fff;
  min-width: 1020px;
  min-height: 350px;
  overflow: auto;
  border: 1px solid #dedede;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  user-select: none;

  & .active-hierarchy {
    padding-left: 24px;
    margin-top: -10px;
    max-width: 1020px;
  }

  & .active-hierarchy:empty:after {
    content: '.';
    visibility: hidden;
  }

  & .active-selection {
    background: #d6f3f3;
  }

  & h5 {
    margin-bottom: 0;
    font-size: 16px;
  }

  & .header {
    padding: 12px 6px;
    background: #f2f2f2;
    border: 1px solid #dedede;
  }

  & .footer {
    padding: 12px 6px;
    background: #f2f2f2;
    border: 1px solid #dedede;
    display: flex;
    flex-direction: row-reverse;
    margin-top: 12px;
  }

  & .content {
    height: 100%;
    display: flex;
    flex-direction: row;
    padding: 24px;
  }

  & .content div {
    margin-right: 10px;
    max-width: 100%;
    overflow-x: hidden;
  }

  & .content .box-container {
    width: 30%;
  }

  & .content .box-container .container-title {
    /* font-weight: bold; */
    width: 100%;
    padding: 3px 7px;
    border: 1px solid #dedede;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  & .content .box-container .input-container .search-icon {
    position: absolute;
    left: 33%;
    top: 29%;
    color: #17a2b8;
  }

  & .content .box-container-selection {
    width: 36%;
  }

  & .content .box-container .input-container {
    padding-left: 7px;
    border: 1px solid #dedede;
    margin-right: 0;
  }

  & .content .box-container .input-container .form-group {
    padding-bottom: 0;
  }

  & .content .box-container .input-container .form-control {
    height: calc(1.5em + 0.75rem + 0px);
    padding-left: 25px;
    font-size: inherit;
  }

  & .content .box-container .input-container .form-control:focus {
    border: 1px solid #a81662 !important;
  }

  & .content .box-container .box {
    width: 100%;
    height: 200px;
    border: 1px solid #dedede;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    margin-bottom: 1px;

    ::-webkit-scrollbar {
      width: 12px;
    }

    ::-webkit-scrollbar {
      width: 12px;
    }

    ::-webkit-scrollbar-track {
      background: #e9ecef;
    }

    ::-webkit-scrollbar-button {
      display: none;
    }

    ::-webkit-scrollbar-thumb {
      background: #ced4da;
      border-radius: 4px;
    }
  }

  & .content .box-container-unit .box {
    height: 147px;
  }

  & .content .item-container {
    padding: 5px 4px;
    margin-bottom: 2px;
    margin-right: 0;
    cursor: pointer;
  }

  & .content .item-container .arrow-right {
    color: #a81662;
  }

  & .content .button-column-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  & .content .button-column-container i {
    margin-bottom: 12px;
    font-size: 15px;
  }

  @keyframes popIn {
    0% {
      transform: scale(0.4);
    }

    100% {
      transform: scale(1);
    }
  }
`

export default OrganisationStructureRestrictionEditWrapper

import layout from 'src/modules/layout/layoutReducers'
import auth from 'src/modules/auth/authReducers'
import tenant from 'src/modules/tenant/tenantReducers'
import user from 'src/modules/admin/user/userReducers'
import auditLog from 'src/modules/auditLog/auditLogReducers'
import settings from 'src/modules/settings/settingsReducers'
import researchTeam from 'src/modules/researchTeam/researchTeamReducers'
import researchThemesIntendedImpact from 'src/modules/researchThemesIntendedImpact/researchThemesIntendedImpactReducers'
import currentProject from 'src/modules/currentProject/currentProjectReducers'
import collaborationPartnership from 'src/modules/collaborationPartnership/collaborationPartnershipReducers'
import externalDecisionMakingAdvisoryGroup from 'src/modules/externalDecisionMakingAdvisoryGroup/externalDecisionMakingAdvisoryGroupReducers'
import providingExternalExpertise from 'src/modules/providingExternalExpertise/providingExternalExpertiseReducers'
import collaborativeNetwork from 'src/modules/collaborativeNetwork/collaborativeNetworkReducers'
import guidelinePolicyPractice from 'src/modules/guidelinePolicyPractice/guidelinePolicyPracticeReducers'
import consumerCommunityInvolvement from 'src/modules/consumerCommunityInvolvement/consumerCommunityInvolvementReducers'
import oralPresentation from 'src/modules/oralPresentation/oralPresentationReducers'
import report from 'src/modules/report/reportReducers'
import toolsProductService from 'src/modules/toolsProductService/toolsProductServiceReducers'
import awardPrizes from 'src/modules/awardPrizes/awardPrizesReducers'
import mentoringSupervision from 'src/modules/mentoringSupervision/mentoringSupervisionReducers'
import caseStudy from 'src/modules/caseStudy/caseStudyReducers'
import communityConsumerLiterature from 'src/modules/communityConsumerLiterature/communityConsumerLiteratureReducers'
import funding from 'src/modules/funding/fundingReducers'
import barriersSupport from 'src/modules/barriersSupport/barriersSupportReducers'
import editorialReviewerResponsibility from 'src/modules/editorialReviewerResponsibility/editorialReviewerResponsibilityReducers'
import educationTrainingMaterial from 'src/modules/educationTrainingMaterial/educationTrainingMaterialReducers'
import interventionStudy from 'src/modules/interventionStudy/interventionStudyReducers'
import inKindContribution from 'src/modules/inKindContribution/inKindContributionReducers'
import mediaMention from 'src/modules/mediaMention/mediaMentionReducers'
import publication from 'src/modules/publication/publicationReducers'
import testimonial from 'src/modules/testimonial/testimonialReducers'
import workshopTrainingFacilitated from 'src/modules/workshopTrainingFacilitated/workshopTrainingFacilitatedReducers'
import intellectualProperty from 'src/modules/intellectualProperty/intellectualPropertyReducers'
import organisationStructure from 'src/modules/admin/OrganisationStructure/OrganisationStructureReducers'
import groups from 'src/modules/admin/groups/groupsReducers'
import impactCategories from 'src/modules/admin/imactCategories/impactCategoriesReducers'
import impactCategoriesField from 'src/modules/admin/imactCategories/imactCategoriesFields/impactCategoriesFieldReducers'
import hierarchyValues from 'src/modules/admin/OrganisationStructure/HierarchyValues/HierarchyValuesReducers'
import GlobalFilter from 'src/modules/GlobalFilter/GlobalFilterReducers'
import recordSecurity from 'src/modules/security/recordSecurityReducer'
import individualsInvolved from 'src/modules/mentoringSupervision/IndividualsInvolved/IndividualsInvolvedReducer'
import integrations from 'src/modules/integrations/integrationsReducers'
import field from 'src/modules/JobFieldMapping/JobFieldReducer'
import search from 'src/modules/search/reducers'
import reportExportHistory from 'src/modules/search/exportHistory/exportHistoryReducers'

import { combineReducers } from 'redux'

export default (routerReducer) =>
  combineReducers({
    router: routerReducer,
    layout,
    auth,
    tenant,
    user,
    auditLog,
    settings,
    researchTeam,
    researchThemesIntendedImpact,
    currentProject,
    collaborationPartnership,
    externalDecisionMakingAdvisoryGroup,
    providingExternalExpertise,
    collaborativeNetwork,
    guidelinePolicyPractice,
    consumerCommunityInvolvement,
    oralPresentation,
    report,
    toolsProductService,
    awardPrizes,
    mentoringSupervision,
    caseStudy,
    communityConsumerLiterature,
    funding,
    barriersSupport,
    editorialReviewerResponsibility,
    educationTrainingMaterial,
    interventionStudy,
    inKindContribution,
    mediaMention,
    publication,
    testimonial,
    workshopTrainingFacilitated,
    intellectualProperty,
    organisationStructure,
    groups,
    impactCategories,
    impactCategoriesField,
    hierarchyValues,
    GlobalFilter,
    recordSecurity,
    individualsInvolved,
    integrations,
    field,
    search,
    reportExportHistory,
  })

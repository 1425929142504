import { i18n } from 'src/i18n'
import exporterRenders from 'src/modules/shared/exporter/exporterRenders'

export default [
  {
    name: 'id',
    label: i18n('entities.inKindContribution.fields.id'),
  },
  {
    name: 'title',
    label: i18n('entities.inKindContribution.fields.title'),
  },
  {
    name: 'organization',
    label: i18n('entities.inKindContribution.fields.organization'),
  },
  {
    name: 'startDate',
    label: i18n('entities.inKindContribution.fields.startDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'endDate',
    label: i18n('entities.inKindContribution.fields.endDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'description',
    label: i18n('entities.inKindContribution.fields.description'),
  },
  {
    name: 'value',
    label: i18n('entities.inKindContribution.fields.value'),
  },
  {
    name: 'files',
    label: i18n('entities.inKindContribution.fields.files'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'images',
    label: i18n('entities.inKindContribution.fields.images'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'author',
    label: i18n('entities.inKindContribution.fields.author'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'dateCreated',
    label: i18n('entities.inKindContribution.fields.dateCreated'),
  },
  {
    name: 'status',
    label: i18n('entities.inKindContribution.fields.status'),
  },
  {
    name: 'researchers',
    label: i18n('entities.inKindContribution.fields.researchers'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'recordType',
    label: i18n('entities.inKindContribution.fields.recordType'),
  },
  {
    name: 'year',
    label: i18n('entities.inKindContribution.fields.year'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.inKindContribution.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.inKindContribution.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
]

import Errors from 'src/modules/shared/error/errors'
import PublicationService from '../publicationService'
import selectors from 'src/modules/publication/orcidImporter/orcidImporterSelectors'

const prefix = 'PUBLICATION'

const orcidImporterActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,
  SELECT_PUBLICATION: `${prefix}_SELECT_PUBLICATION`,
  CLEAR_PUBLICATION: `${prefix}_CLEAR_PUBLICATION`,
  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SET_ID: `${prefix}_SET_ID`,

  doChangePagination: (pagination) => async (dispatch) => {
    dispatch({
      type: orcidImporterActions.PAGINATION_CHANGED,
      payload: pagination,
    })

    dispatch(orcidImporterActions.doFetchCurrentFilter())
  },

  doFetch: (id?, filter?) => async (dispatch, getState) => {
    try {
      dispatch({
        type: orcidImporterActions.FETCH_STARTED,
      })

      if (id) {
        dispatch({
          type: orcidImporterActions.SET_ID,
          payload: id,
        })
      }

      const response = await PublicationService.fetchPublications(
        selectors.selectId(getState()),
        filter,
        selectors.selectLimit(getState()),
        selectors.selectOffset(getState()),
      )

      dispatch({
        type: orcidImporterActions.FETCH_SUCCESS,
        payload: response,
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: orcidImporterActions.FETCH_ERROR,
      })
    }
  },

  doFetchCurrentFilter: () => async (dispatch, getState) => {
    const filter = selectors.selectFilter(getState())
    const rawFilter = selectors.selectRawFilter(getState())
    dispatch(orcidImporterActions.doFetch(filter, rawFilter))
  },

  doSelect: (publication) => (dispatch) => {
    dispatch({
      type: orcidImporterActions.SELECT_PUBLICATION,
      payload: publication,
    })
  },

  doClear: () => (dispatch) => {
    dispatch({
      type: orcidImporterActions.CLEAR_PUBLICATION,
    })
  },
}

export default orcidImporterActions

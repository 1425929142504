import list from 'src/modules/researchThemesIntendedImpact/list/researchThemesIntendedImpactListReducers'
import form from 'src/modules/researchThemesIntendedImpact/form/researchThemesIntendedImpactFormReducers'
import view from 'src/modules/researchThemesIntendedImpact/view/researchThemesIntendedImpactViewReducers'
import destroy from 'src/modules/researchThemesIntendedImpact/destroy/researchThemesIntendedImpactDestroyReducers'
import importerReducer from 'src/modules/researchThemesIntendedImpact/importer/researchThemesIntendedImpactImporterReducers'
import { combineReducers } from 'redux'

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
})

import importerActions from 'src/modules/shared/importer/importerActions'
import selectors from 'src/modules/communityConsumerLiterature/importer/communityConsumerLiteratureImporterSelectors'
import CommunityConsumerLiteratureService from 'src/modules/communityConsumerLiterature/communityConsumerLiteratureService'
import fields from 'src/modules/communityConsumerLiterature/importer/communityConsumerLiteratureImporterFields'
import { i18n } from 'src/i18n'

const communityConsumerLiteratureImporterActions = importerActions(
  'COMMUNITYCONSUMERLITERATURE_IMPORTER',
  selectors,
  CommunityConsumerLiteratureService.import,
  fields,
  i18n('entities.communityConsumerLiterature.importer.fileName'),
)

export default communityConsumerLiteratureImporterActions

import { v4 as uuid4 } from 'uuid'
import individualsInvolvedActions from 'src/modules/mentoringSupervision/IndividualsInvolved/IndividualsInvolvedActions'

const initialState = {
  activeResearchers: [],
  filteredResearchers: [],
}

export default function (state = initialState, { type, payload }) {
  if (type === individualsInvolvedActions.SET_ACTIVE_RESEARCHERS) {
    const activeResearchers = payload.map((researcher) => ({
      id: uuid4(),
      researcher,
      role: researcher.role,
      otherRole: researcher.otherRole,
      responsibility: researcher.responsibility,
    }))

    return {
      ...state,
      activeResearchers,
      filteredResearchers: activeResearchers,
    }
  }

  if (type === individualsInvolvedActions.SELECT_RESEARCHER) {
    return {
      ...state,
      activeResearchers: [
        ...state.activeResearchers,
        { ...payload, id: uuid4() },
      ],
      filteredResearchers: [
        ...state.activeResearchers,
        { ...payload, id: uuid4() },
      ],
    }
  }

  if (type === individualsInvolvedActions.REMOVE_RESEARCHER) {
    return {
      ...state,
      activeResearchers: state.activeResearchers.filter(
        (researcher) => researcher.id !== payload,
      ),
      filteredResearchers: state.activeResearchers.filter(
        (researcher) => researcher.id !== payload,
      ),
    }
  }

  if (type === individualsInvolvedActions.RESET) {
    return { ...state, activeResearchers: [], filteredResearchers: [] }
  }

  return state
}

import list from 'src/modules/inKindContribution/list/inKindContributionListReducers'
import form from 'src/modules/inKindContribution/form/inKindContributionFormReducers'
import view from 'src/modules/inKindContribution/view/inKindContributionViewReducers'
import destroy from 'src/modules/inKindContribution/destroy/inKindContributionDestroyReducers'
import importerReducer from 'src/modules/inKindContribution/importer/inKindContributionImporterReducers'
import { combineReducers } from 'redux'

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
})

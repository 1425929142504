import { MsalProvider, MsalProviderProps } from '@azure/msal-react'
import { PublicClientApplication } from '@azure/msal-browser'
import { useTenantSetting } from './modules/settings/settingsSelectors'

const getCachedTenantSetting = () => {
  const setting = sessionStorage.getItem('tenant-setting')

  if (setting) {
    return JSON.parse(setting)
  }

  return null
}

const CustomMsalProvider = (props: Omit<MsalProviderProps, 'instance'>) => {
  const tenantSetting = useTenantSetting()
  const tenantConfig = getCachedTenantSetting() || tenantSetting

  if (tenantConfig?.ssoEnabled) {
    const { clientId, authority, redirectUri } = tenantConfig.ssoConfig
    const msalInstance = new PublicClientApplication({
      auth: {
        clientId,
        authority,
        redirectUri,
      },
      cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
      },
    })

    return <MsalProvider instance={msalInstance} {...props} />
  }

  return <>{props.children}</>
}

export default CustomMsalProvider

const workshopTrainingFacilitatedEnumerators = {
  recipientType: [
    'government',
    'industry',
    'consumer_and/or_community',
    'non-government_organisation',
    'peak_body',
    'research_organisation',
    'education_organisation',
    'other_specify',
  ],
  role: ['lead', 'contributor'],
  status: ['draft', 'published', 'archived'],
  recordType: ['Academic', 'Professional'],
}

export default workshopTrainingFacilitatedEnumerators

import impactCategoriesFieldService from 'src/modules/admin/imactCategories/imactCategoriesFields/impactCategoriesFieldService'
import Errors from 'src/modules/shared/error/errors'

const prefix = 'IMPACTCATEGORIES_LIST_FIELD'

const impactCategoriesFieldListActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,
  CLEAR: `${prefix}_CLEAR`,

  doClearImpactCategoryFields: () => async (dispatch) => {
    dispatch({
      type: impactCategoriesFieldListActions.CLEAR,
    })
  },

  doFetchById: (id) => async (dispatch) => {
    try {
      dispatch({
        type: impactCategoriesFieldListActions.FETCH_STARTED,
      })

      const response = await impactCategoriesFieldService.find(id)

      dispatch({
        type: impactCategoriesFieldListActions.FETCH_SUCCESS,
        payload: {
          rows: response.rows,
          count: response.count,
        },
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: impactCategoriesFieldListActions.FETCH_ERROR,
      })
    }
  },
}

export default impactCategoriesFieldListActions

import InKindContributionService from 'src/modules/inKindContribution/inKindContributionService'
import selectors from 'src/modules/inKindContribution/list/inKindContributionListSelectors'
import exporterFields from 'src/modules/inKindContribution/list/inKindContributionListExporterFields'
import { generateListActions } from '../../listHelpers'

const prefix = 'INKINDCONTRIBUTION_LIST'

const inKindContributionListActions = generateListActions(
  prefix,
  InKindContributionService.list,
  selectors,
  exporterFields,
  'startDate',
)

export default inKindContributionListActions

import { useSelector } from 'react-redux'
import authSelectors from 'src/modules/auth/authSelectors'
import Roles from 'src/security/roles'
import { NavLink } from 'react-router-dom'
import { i18n } from 'src/i18n'
import permissions from 'src/pages/map/permissionSelectors'

const NavItem = (props) => (
  <li>
    {props.count ? <span className="badge">{props.count}+</span> : null}
    <NavLink
      to={props.link}
      className={`nav-link text-uppercase ${props.customStyle}`}
    >
      {i18n(`header.navItems.${props.name}.label`)}
    </NavLink>
  </li>
)

export default ({ currentUser }) => {
  const currentTenant = useSelector(authSelectors.selectCurrentTenant)

  const currentTenantUser = currentUser.tenants.find(
    (tenant) => tenant.tenantId === currentTenant.id,
  )

  const hasPermissionToViewPathway = useSelector(
    permissions.selectPermissionToViewPathway,
  )

  return (
    <ul className="navbar-nav mx-auto">
      {(currentTenantUser.roles.includes(Roles.values.admin) ||
        currentTenantUser.roles.includes(Roles.values.manageUser) ||
        currentTenantUser.roles.includes(Roles.values.manageGlobalSetting) ||
        currentTenantUser.roles.includes(
          Roles.values.manageOrganizationStructure,
        ) ||
        currentTenantUser.roles.includes(Roles.values.manageGroup) ||
        currentTenantUser.roles.includes(
          Roles.values.manageImpactCategory,
        )) && (
        <NavItem name="admin" link="/admin" customStyle="font-weight-bold" />
      )}
      {hasPermissionToViewPathway && (
        <NavItem name="map" link="/map" customStyle="font-weight-bold" />
      )}
      {!hasPermissionToViewPathway && (
        <NavItem name="map" link="/map" customStyle="disabled" />
      )}
      <NavItem
        name="collect"
        link="/research-team"
        customStyle="font-weight-bold"
      />
      <NavItem name="report" link="/report" customStyle="font-weight-bold" />
    </ul>
  )
}

import ProvidingExternalExpertiseService from 'src/modules/providingExternalExpertise/providingExternalExpertiseService'
import selectors from 'src/modules/providingExternalExpertise/list/providingExternalExpertiseListSelectors'
import exporterFields from 'src/modules/providingExternalExpertise/list/providingExternalExpertiseListExporterFields'
import { generateListActions } from '../../listHelpers'

const prefix = 'PROVIDINGEXTERNALEXPERTISE_LIST'

const providingExternalExpertiseListActions = generateListActions(
  prefix,
  ProvidingExternalExpertiseService.list,
  selectors,
  exporterFields,
  'startDate',
)

export default providingExternalExpertiseListActions

import { i18n } from 'src/i18n'
import exporterRenders from 'src/modules/shared/exporter/exporterRenders'

export default [
  {
    name: 'id',
    label: i18n('entities.testimonial.fields.id'),
  },
  {
    name: 'researcher',
    label: i18n('entities.testimonial.fields.researcher'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'organization',
    label: i18n('entities.testimonial.fields.organization'),
  },
  {
    name: 'description',
    label: i18n('entities.testimonial.fields.description'),
  },
  {
    name: 'projectId',
    label: i18n('entities.testimonial.fields.projectId'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'files',
    label: i18n('entities.testimonial.fields.files'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'images',
    label: i18n('entities.testimonial.fields.images'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'author',
    label: i18n('entities.testimonial.fields.author'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'dateCreated',
    label: i18n('entities.testimonial.fields.dateCreated'),
  },
  {
    name: 'status',
    label: i18n('entities.testimonial.fields.status'),
  },
  {
    name: 'title',
    label: i18n('entities.testimonial.fields.title'),
  },
  {
    name: 'recordType',
    label: i18n('entities.testimonial.fields.recordType'),
  },
  {
    name: 'permission',
    label: i18n('entities.testimonial.fields.permission'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.testimonial.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.testimonial.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
]

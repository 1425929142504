import importerActions from 'src/modules/shared/importer/importerActions'
import selectors from 'src/modules/workshopTrainingFacilitated/importer/workshopTrainingFacilitatedImporterSelectors'
import WorkshopTrainingFacilitatedService from 'src/modules/workshopTrainingFacilitated/workshopTrainingFacilitatedService'
import fields from 'src/modules/workshopTrainingFacilitated/importer/workshopTrainingFacilitatedImporterFields'
import { i18n } from 'src/i18n'

const workshopTrainingFacilitatedImporterActions = importerActions(
  'WORKSHOPTRAININGFACILITATED_IMPORTER',
  selectors,
  WorkshopTrainingFacilitatedService.import,
  fields,
  i18n('entities.workshopTrainingFacilitated.importer.fileName'),
)

export default workshopTrainingFacilitatedImporterActions

const mentoringSupervisionEnumerators = {
  status: ['draft', 'published', 'archived'],
  recordType: ['Academic', 'Professional'],
  mentoringSupervision: ['student_supervisor', 'mentor'],
  role: [
    'Co-ordinating',
    'principal',
    'co-originating_principal',
    'co-supervisor',
    'lead_supervisor',
    'other-specify',
  ],
  mentoringStatus: ['active', 'in_active'],
  degree: [
    'Honours',
    'Masters',
    'PhD_Work_Experience',
    'Merit_for_Coursework',
    'Merit_for_Specialty',
    'other-specify',
  ],
  programType: [
    'official_program_(internal)',
    'official_program_(external)',
    'informal_(internal)',
    'informal_(external)',
  ],
  menteeType: [
    'work_experience_student(s)',
    'HDR_student',
    'post-doctoral_researcher',
    'medical_student',
    'clinician',
    'volunteer',
  ],
  environment: [
    'clinical_environment',
    'laboratory_environment',
    'at_their_external_workplace',
  ],
}

export default mentoringSupervisionEnumerators

import list from 'src/modules/externalDecisionMakingAdvisoryGroup/list/externalDecisionMakingAdvisoryGroupListReducers'
import form from 'src/modules/externalDecisionMakingAdvisoryGroup/form/externalDecisionMakingAdvisoryGroupFormReducers'
import view from 'src/modules/externalDecisionMakingAdvisoryGroup/view/externalDecisionMakingAdvisoryGroupViewReducers'
import destroy from 'src/modules/externalDecisionMakingAdvisoryGroup/destroy/externalDecisionMakingAdvisoryGroupDestroyReducers'
import importerReducer from 'src/modules/externalDecisionMakingAdvisoryGroup/importer/externalDecisionMakingAdvisoryGroupImporterReducers'
import { combineReducers } from 'redux'

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
})

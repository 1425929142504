import actions from 'src/modules/admin/OrganisationStructure/HierarchyValues/list/HierarchyValuesListActions'

const initialData = {
  response: null,
  loading: false,
}

export default (state = initialData, { type, payload }) => {
  if (type === actions.FETCH_STARTED) {
    return {
      ...state,
      loading: true,
    }
  }

  if (type === actions.FETCH_SUCCESS) {
    return {
      ...state,
      loading: false,
      response: payload.response,
    }
  }

  if (type === actions.FETCH_ERROR) {
    return {
      ...state,
      loading: false,
      response: null,
    }
  }

  if (type === actions.DESTROY_STARTED) {
    return {
      ...state,
      loading: true,
    }
  }

  if (type === actions.DESTROY_ERROR) {
    return {
      ...state,
      loading: false,
    }
  }

  if (type === actions.DESTROY_SUCCESS) {
    return {
      ...state,
      selectedKeys: [],
    }
  }

  return state
}

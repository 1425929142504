import { useSelector } from 'react-redux'
import { i18n } from 'src/i18n'
import { default as authSelectors } from 'src/modules/auth/authSelectors'
import HeaderWrapper from 'src/components/layout/styles/HeaderWrapper'
import NavWrapper from 'src/components/layout/styles/NavWrapper'
import GlobalFilter from 'src/components/globalFilter'
import HeaderMenu from './HeaderMenu'
import { NavLink } from 'react-router-dom'
import HeaderUserMenu from './HeaderUserMenu'
import ReactTooltip from 'src/components/Tooltip'
import { TenantMode } from 'src/helpers/types/TenantModes'

function Header() {
  const currentUser = useSelector(authSelectors.selectCurrentUser)
  const currentTenant = useSelector(authSelectors.selectCurrentTenant)
  const logoUrl = useSelector(authSelectors.selectLogoUrl)
  const appTitle =
    currentTenant?.mode === TenantMode.Research
      ? i18n('app.titleResearch')
      : i18n('app.title')

  const NavBar = () => (
    <NavWrapper
      className="navbar navbar-fixed-top navbar-light bg-white navbar-expand-md"
      role="navigation"
    >
      <GlobalFilter />
      <div
        className="navbar-brand"
        data-tooltip-id="workspace-name"
        data-tooltip-content={currentTenant?.name}
      >
        <NavLink to="/">
          {logoUrl ? (
            <img src={logoUrl} alt={appTitle} className="logo" />
          ) : (
            <>{appTitle}</>
          )}
        </NavLink>
      </div>
      <ReactTooltip id="workspace-name" variant="dark" />

      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="navbar-collapse collapse" id="navbarContent">
        <HeaderMenu currentUser={currentUser} />
        <HeaderUserMenu currentUser={currentUser} />
      </div>
    </NavWrapper>
  )

  return (
    <HeaderWrapper>
      <NavBar />
    </HeaderWrapper>
  )
}

export default Header

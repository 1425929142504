import authAxios from 'src/modules/shared/axios/authAxios'
import AuthCurrentTenant from 'src/modules/auth/authCurrentTenant'

export default class PublicationService {
  static async update(id, data) {
    const body = {
      id,
      data,
    }

    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.put(
      `/tenant/${tenantId}/publication/${id}`,
      body,
    )

    return response.data
  }

  static async destroyAll(ids) {
    const params = {
      ids,
    }

    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.delete(`/tenant/${tenantId}/publication`, {
      params,
    })

    return response.data
  }

  static async create(data) {
    const body = {
      data,
    }

    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.post(
      `/tenant/${tenantId}/publication`,
      body,
    )

    return response.data
  }

  static async import(values, importHash) {
    const body = {
      data: values,
      importHash,
    }

    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.post(
      `/tenant/${tenantId}/publication/import`,
      body,
    )

    return response.data
  }

  static async find(id) {
    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.get(
      `/tenant/${tenantId}/publication/${id}`,
    )

    return response.data
  }

  static async list(filter, orderBy, limit, offset) {
    const iId = filter?.involved?.id
    if (iId) {
      filter.involved = iId
    }

    const params = {
      filter,
      orderBy,
      limit,
      offset,
    }

    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.get(`/tenant/${tenantId}/publication`, {
      params,
    })

    return response.data
  }

  static async listAutocomplete(query, limit) {
    const params = {
      query,
      limit,
    }

    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.get(
      `/tenant/${tenantId}/publication/autocomplete`,
      {
        params,
      },
    )

    return response.data
  }

  static async fetchPublications(id, filter, limit, offset) {
    const params = {
      id,
      filter,
      limit,
      offset,
    }

    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.get(`/tenant/${tenantId}/orcid/works`, {
      params,
    })

    return response.data
  }

  static async fetchOrcidPublications() {
    const tenantId = AuthCurrentTenant.get()

    const response = await authAxios.get(
      `/tenant/${tenantId}/publication/orcid-records`,
    )

    return response.data
  }

  static async bulkImportOrcidPublications(values, orcidId, options) {
    const body = {
      data: {
        values,
        options,
      },
    }

    const tenantId = AuthCurrentTenant.get()

    const params = buildParams(orcidId)

    const response = await authAxios.post(
      `/tenant/${tenantId}/publication/orcid-records/bulk-import`,
      body,
      {
        params,
      },
    )

    return response.data
  }
}

function buildParams(orcidId) {
  if (orcidId) {
    return { orcidId }
  }

  return {}
}

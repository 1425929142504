const intellectualPropertyEnumerators = {
  stage: [
    'continuation',
    'continuation in part',
    'divisional',
    'nationalized_pct',
    'patent_cooperation_treaty',
    'provisional',
    'other-specify',
  ],
  status2: [
    'expired',
    'granted',
    'lapsed',
    'pending',
    'published',
    'other-specify',
  ],
  status: ['draft', 'published', 'archived'],
  recordType: ['Academic', 'Professional'],
}

export default intellectualPropertyEnumerators

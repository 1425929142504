import { useDispatch, useSelector } from 'react-redux'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'
import { useEffect, useState } from 'react'
import { AuthToken } from './authToken'
import authAxios from '../shared/axios/authAxios'
import service from './authService'
import authActions from './authActions'
import AuthCurrentTenant from './authCurrentTenant'
import {
  useIsTenantSettingInitiated,
  useTenantSetting,
} from '../settings/settingsSelectors'
import authSelectors from './authSelectors'
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from '@azure/msal-browser'
import Message from '../../components/message'
import { DEFAULT_ERROR_MESSAGE } from '../shared/error/errors'

export const useSso = () => {
  const dispatch = useDispatch()
  const tenantSetting = useTenantSetting()
  const isTenantSettingInitiated = useIsTenantSettingInitiated()
  const isAuthenticated = useIsAuthenticated()
  const { instance, accounts, inProgress } = useMsal()
  const tenant = useSelector(authSelectors.selectCurrentTenant)

  const [isLoading, setIsLoading] = useState(true)

  const exchangeToken = async () => {
    const token = AuthToken.get()
    if (isAuthenticated) {
      if (!token) {
        try {
          const accessTokenRes = await instance.acquireTokenSilent({
            scopes: ['User.Read'],
            account: accounts[0],
          })

          const exchangeTokenRes = await authAxios.post(
            '/auth/exchange-token',
            {
              accessToken: accessTokenRes.accessToken,
            },
          )

          AuthToken.set(exchangeTokenRes.data.token, true)
          localStorage.setItem('sso', 'true')
          const { currentUser, currentTenant } =
            await service.fetchCurrentUserAndTenant()
          AuthCurrentTenant.set(currentTenant)

          dispatch({
            type: authActions.AUTH_SUCCESS,
            payload: {
              currentUser,
              currentTenant,
            },
          })
        } catch (e) {
          if (e instanceof InteractionRequiredAuthError) {
            return
          }
          localStorage.setItem(
            'sso-error-message',
            (e?.response?.data?.message &&
              'User credentials not recognised, please contact your Grow Impact administrator.') ||
              e?.message ||
              DEFAULT_ERROR_MESSAGE,
          )
          instance.logoutRedirect({
            onRedirectNavigate: () => {
              return false
            },
          })
        }
      }
    } else {
      const sso = localStorage.getItem('sso')
      if (token && sso && inProgress === InteractionStatus.None) {
        dispatch(authActions.doSignout(tenant?.slug))
      }
    }
  }

  useEffect(() => {
    if (
      !isTenantSettingInitiated ||
      !(tenantSetting && tenantSetting.ssoEnabled)
    ) {
      setIsLoading(false)
      return
    }

    setIsLoading(true)
    exchangeToken().finally(() => {
      setIsLoading(false)
    })
  }, [isTenantSettingInitiated, tenantSetting, isAuthenticated, inProgress])

  return { isLoading }
}

export const useSsoLogout = () => {
  return async () => {
    sessionStorage.clear()
  }
}

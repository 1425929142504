import actions from 'src/modules/admin/OrganisationStructure/list/OrganisationStructureListActions'

const initialData = {
  response: null,
  flatHierarchies: [],
  loading: false,
}

export default (state = initialData, { type, payload }) => {
  if (type === actions.FETCH_STARTED) {
    return {
      ...state,
      loading: true,
    }
  }

  if (type === actions.FETCH_SUCCESS) {
    return {
      ...state,
      loading: false,
      response: payload.response,
    }
  }

  if (type === actions.FETCH_ERROR) {
    return {
      ...state,
      loading: false,
      response: null,
    }
  }

  if (type === actions.FETCH_FLAT_STARTED) {
    return {
      ...state,
      loading: true,
    }
  }

  if (type === actions.FETCH_FLAT_SUCCESS) {
    return {
      ...state,
      loading: false,
      flatHierarchies: payload,
    }
  }

  if (type === actions.FETCH_FLAT_ERROR) {
    return {
      ...state,
      loading: false,
      flatHierarchies: [],
    }
  }

  if (type === actions.VALUES_FETCH_STARTED) {
    return {
      ...state,
      loading: true,
    }
  }

  if (type === actions.VALUES_FETCH_SUCCESS) {
    return {
      ...state,
      ...payload,
    }
  }

  if (type === actions.VALUES_FETCH_ERROR) {
    return {
      ...state,
      loading: false,
    }
  }

  if (type === actions.DESTROY_STARTED) {
    return {
      ...state,
      loading: true,
    }
  }

  if (type === actions.DESTROY_ERROR) {
    return {
      ...state,
      loading: false,
    }
  }

  if (type === actions.DESTROY_SUCCESS) {
    return {
      ...state,
      selectedKeys: [],
    }
  }

  if (type === actions.CLEAR_ALL) {
    return {
      ...state,
      response: null,
      flatHierarchies: [],
      loading: false,
    }
  }

  return state
}

import schemas from 'src/modules/shared/yup/yupImporterSchemas'
import { i18n } from 'src/i18n'
import oralPresentationEnumerators from 'src/modules/oralPresentation/oralPresentationEnumerators'

export default [
  {
    name: 'title',
    label: i18n('entities.oralPresentation.fields.title'),
    schema: schemas.string(i18n('entities.oralPresentation.fields.title'), {
      required: true,
    }),
  },
  {
    name: 'researcher',
    label: i18n('entities.oralPresentation.fields.researcher'),
    schema: schemas.relationToOne(
      i18n('entities.oralPresentation.fields.researcher'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'role',
    label: i18n('entities.oralPresentation.fields.role'),
    schema: schemas.enumerator(i18n('entities.oralPresentation.fields.role'), {
      options: oralPresentationEnumerators.role,
    }),
  },
  {
    name: 'meeting',
    label: i18n('entities.oralPresentation.fields.meeting'),
    schema: schemas.string(i18n('entities.oralPresentation.fields.meeting'), {
      required: true,
    }),
  },
  {
    name: 'meetingType',
    label: i18n('entities.oralPresentation.fields.meetingType'),
    schema: schemas.enumerator(
      i18n('entities.oralPresentation.fields.meetingType'),
      {
        options: oralPresentationEnumerators.meetingType,
      },
    ),
  },
  {
    name: 'recipientType',
    label: i18n('entities.oralPresentation.fields.recipientType'),
    schema: schemas.enumerator(
      i18n('entities.oralPresentation.fields.recipientType'),
      {
        required: true,
        options: oralPresentationEnumerators.recipientType,
      },
    ),
  },
  {
    name: 'presenterPurpose',
    label: i18n('entities.oralPresentation.fields.presenterPurpose'),
    schema: schemas.enumerator(
      i18n('entities.oralPresentation.fields.presenterPurpose'),
      {
        required: true,
        options: oralPresentationEnumerators.presenterPurpose,
      },
    ),
  },
  {
    name: 'scope',
    label: i18n('entities.oralPresentation.fields.scope'),
    schema: schemas.enumerator(i18n('entities.oralPresentation.fields.scope'), {
      required: true,
      options: oralPresentationEnumerators.scope,
    }),
  },
  {
    name: 'country',
    label: i18n('entities.oralPresentation.fields.country'),
    schema: schemas.string(
      i18n('entities.oralPresentation.fields.country'),
      {},
    ),
  },
  {
    name: 'city',
    label: i18n('entities.oralPresentation.fields.city'),
    schema: schemas.string(i18n('entities.oralPresentation.fields.city'), {
      required: true,
    }),
  },
  {
    name: 'description',
    label: i18n('entities.oralPresentation.fields.description'),
    schema: schemas.string(
      i18n('entities.oralPresentation.fields.description'),
      {
        max: 1000,
      },
    ),
  },
  {
    name: 'files',
    label: i18n('entities.oralPresentation.fields.files'),
    schema: schemas.files(i18n('entities.oralPresentation.fields.files'), {}),
  },
  {
    name: 'images',
    label: i18n('entities.oralPresentation.fields.images'),
    schema: schemas.images(i18n('entities.oralPresentation.fields.images'), {}),
  },
  {
    name: 'status',
    label: i18n('entities.oralPresentation.fields.status'),
    schema: schemas.enumerator(
      i18n('entities.oralPresentation.fields.status'),
      {
        required: true,
        options: oralPresentationEnumerators.status,
      },
    ),
  },
  {
    name: 'researchers',
    label: i18n('entities.oralPresentation.fields.researchers'),
    schema: schemas.relationToMany(
      i18n('entities.oralPresentation.fields.researchers'),
      {},
    ),
  },
  {
    name: 'recordType',
    label: i18n('entities.oralPresentation.fields.recordType'),
    schema: schemas.enumerator(
      i18n('entities.oralPresentation.fields.recordType'),
      {
        options: oralPresentationEnumerators.recordType,
      },
    ),
  },
  {
    name: 'year',
    label: i18n('entities.oralPresentation.fields.year'),
    schema: schemas.integer(i18n('entities.oralPresentation.fields.year'), {}),
  },
]

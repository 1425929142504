import Errors from 'src/modules/shared/error/errors'
import { getHistory } from 'src/modules/store'
import impactCategoriesService from 'src/modules/admin/imactCategories/impactCategoriesService'
import Message from 'src/components/message'
import { i18n } from 'src/i18n'

const prefix = 'IMPACTCATEGORY_VIEW'

const impactCategoriesViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  DELETE_STARTED: `${prefix}_DELETE_STARTED`,
  DELETE_SUCCESS: `${prefix}_DELETE_SUCCESS`,
  DELETE_ERROR: `${prefix}_DELETE_ERROR`,

  FETCH_ONE_STARTED: `${prefix}_FETCH_ONE_STARTED`,
  FETCH_ONE_SUCCESS: `${prefix}_FETCH_ONE_SUCCESS`,
  FETCH_ONE_ERROR: `${prefix}_FETCH_ONE_ERROR`,

  RESET: `${prefix}_RESET`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: impactCategoriesViewActions.FIND_STARTED,
      })

      const impactCategory = await impactCategoriesService.find(id)

      dispatch({
        type: impactCategoriesViewActions.FIND_SUCCESS,
        payload: impactCategory,
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: impactCategoriesViewActions.FIND_ERROR,
      })

      getHistory().push('/admin/impactcategories')
    }
  },

  doFetchSavedFilters: (id) => async (dispatch) => {
    try {
      dispatch({
        type: impactCategoriesViewActions.FETCH_STARTED,
      })
      const impactCategorySavedFilters =
        await impactCategoriesService.fetchImpactCategorySaveFilters(id)

      dispatch({
        type: impactCategoriesViewActions.FETCH_SUCCESS,
        payload: impactCategorySavedFilters,
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: impactCategoriesViewActions.FETCH_ERROR,
      })
    }
  },

  doCreateSavedFilters:
    (fieldsId, data, filterId?) => async (dispatch, getState) => {
      try {
        const {
          values,
          fields,
          config: {
            actions: { doFetch },
            selectors,
          },
        } = data

        await dispatch(doFetch(data.values))
        const recordCount = selectors.selectCount(getState())
        const dataToSubmit = { name: values?.filterName, recordCount, fields }

        if (filterId) {
          await dispatch(
            impactCategoriesViewActions.doUpdateSavedFilters(
              fieldsId,
              filterId,
              dataToSubmit,
            ),
          )
          return
        }

        dispatch({
          type: impactCategoriesViewActions.CREATE_STARTED,
        })

        const impactCategorySavedFilters =
          await impactCategoriesService.createImpactCategorySaveFilters(
            fieldsId,
            dataToSubmit,
          )

        dispatch({
          type: impactCategoriesViewActions.CREATE_SUCCESS,
          payload: impactCategorySavedFilters,
        })

        dispatch(impactCategoriesViewActions.doFetchSavedFilters(fieldsId))

        Message.success(i18n('savedFilter.create.success'))
      } catch (error) {
        Errors.handle(error)

        dispatch({
          type: impactCategoriesViewActions.CREATE_ERROR,
        })
      }
    },

  doFetchSavedFilter: (id, savedFilterId) => async (dispatch) => {
    try {
      dispatch({
        type: impactCategoriesViewActions.FETCH_ONE_STARTED,
      })

      const impactCategorySavedFilters =
        await impactCategoriesService.fetchImpactCategorySaveFilter(
          id,
          savedFilterId,
        )

      dispatch({
        type: impactCategoriesViewActions.FETCH_ONE_SUCCESS,
        payload: impactCategorySavedFilters,
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: impactCategoriesViewActions.FETCH_ONE_ERROR,
      })
    }
  },

  doDeleteSavedFilter: (id, savedFilterId) => async (dispatch) => {
    try {
      dispatch({
        type: impactCategoriesViewActions.DELETE_STARTED,
      })

      await impactCategoriesService.deleteImpactCategorySaveFilters(
        id,
        savedFilterId,
      )

      await dispatch(impactCategoriesViewActions.doFetchSavedFilters(id))

      dispatch({
        type: impactCategoriesViewActions.DELETE_SUCCESS,
      })
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: impactCategoriesViewActions.FETCH_ONE_ERROR,
      })
    }
  },

  doUpdateSavedFilters: (impactCategoryId, id, data) => async (dispatch) => {
    try {
      dispatch({
        type: impactCategoriesViewActions.UPDATE_STARTED,
      })

      const impactCategoryUpdatedSavedFilters =
        await impactCategoriesService.updateImpactCategorySaveFilters(
          impactCategoryId,
          id,
          { data },
        )

      dispatch({
        type: impactCategoriesViewActions.UPDATE_SUCCESS,
        payload: impactCategoryUpdatedSavedFilters,
      })
      dispatch(
        impactCategoriesViewActions.doFetchSavedFilters(impactCategoryId),
      )
      Message.success(i18n('savedFilter.update.success'))
    } catch (error) {
      Errors.handle(error)

      dispatch({
        type: impactCategoriesViewActions.UPDATE_ERROR,
      })
    }
  },
}

export default impactCategoriesViewActions

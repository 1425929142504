import schemas from 'src/modules/shared/yup/yupImporterSchemas'
import { i18n } from 'src/i18n'
import intellectualPropertyEnumerators from 'src/modules/intellectualProperty/intellectualPropertyEnumerators'

export default [
  {
    name: 'title',
    label: i18n('entities.intellectualProperty.fields.title'),
    schema: schemas.string(i18n('entities.intellectualProperty.fields.title'), {
      required: true,
    }),
  },
  {
    name: 'ipType',
    label: i18n('entities.intellectualProperty.fields.ipType'),
    schema: schemas.string(
      i18n('entities.intellectualProperty.fields.ipType'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'authors',
    label: i18n('entities.intellectualProperty.fields.authors'),
    schema: schemas.string(
      i18n('entities.intellectualProperty.fields.authors'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'researcher',
    label: i18n('entities.intellectualProperty.fields.researcher'),
    schema: schemas.relationToMany(
      i18n('entities.intellectualProperty.fields.researcher'),
      {},
    ),
  },
  {
    name: 'contribution',
    label: i18n('entities.intellectualProperty.fields.contribution'),
    schema: schemas.string(
      i18n('entities.intellectualProperty.fields.contribution'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'identifier',
    label: i18n('entities.intellectualProperty.fields.identifier'),
    schema: schemas.string(
      i18n('entities.intellectualProperty.fields.identifier'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'organization',
    label: i18n('entities.intellectualProperty.fields.organization'),
    schema: schemas.string(
      i18n('entities.intellectualProperty.fields.organization'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'organizationLead',
    label: i18n('entities.intellectualProperty.fields.organizationLead'),
    schema: schemas.string(
      i18n('entities.intellectualProperty.fields.organizationLead'),
      {},
    ),
  },
  {
    name: 'stage',
    label: i18n('entities.intellectualProperty.fields.stage'),
    schema: schemas.enumerator(
      i18n('entities.intellectualProperty.fields.stage'),
      {
        required: true,
        options: intellectualPropertyEnumerators.stage,
      },
    ),
  },
  {
    name: 'status2',
    label: i18n('entities.intellectualProperty.fields.status2'),
    schema: schemas.enumerator(
      i18n('entities.intellectualProperty.fields.status2'),
      {
        required: true,
        options: intellectualPropertyEnumerators.status2,
      },
    ),
  },
  {
    name: 'jurisdiction',
    label: i18n('entities.intellectualProperty.fields.jurisdiction'),
    schema: schemas.string(
      i18n('entities.intellectualProperty.fields.jurisdiction'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'startDate',
    label: i18n('entities.intellectualProperty.fields.startDate'),
    schema: schemas.datetime(
      i18n('entities.intellectualProperty.fields.startDate'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'filedDate',
    label: i18n('entities.intellectualProperty.fields.filedDate'),
    schema: schemas.datetime(
      i18n('entities.intellectualProperty.fields.filedDate'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'grantedDate',
    label: i18n('entities.intellectualProperty.fields.grantedDate'),
    schema: schemas.datetime(
      i18n('entities.intellectualProperty.fields.grantedDate'),
      {
        required: true,
      },
    ),
  },
  {
    name: 'licenseOrganisation',
    label: i18n('entities.intellectualProperty.fields.licenseOrganisation'),
    schema: schemas.string(
      i18n('entities.intellectualProperty.fields.licenseOrganisation'),
      {},
    ),
  },
  {
    name: 'assignedOrganisation',
    label: i18n('entities.intellectualProperty.fields.assignedOrganisation'),
    schema: schemas.string(
      i18n('entities.intellectualProperty.fields.assignedOrganisation'),
      {},
    ),
  },
  {
    name: 'description',
    label: i18n('entities.intellectualProperty.fields.description'),
    schema: schemas.string(
      i18n('entities.intellectualProperty.fields.description'),
      {
        max: 1000,
      },
    ),
  },
  {
    name: 'impact',
    label: i18n('entities.intellectualProperty.fields.impact'),
    schema: schemas.string(
      i18n('entities.intellectualProperty.fields.impact'),
      {},
    ),
  },
  {
    name: 'year',
    label: i18n('entities.intellectualProperty.fields.year'),
    schema: schemas.integer(
      i18n('entities.intellectualProperty.fields.year'),
      {},
    ),
  },
  {
    name: 'files',
    label: i18n('entities.intellectualProperty.fields.files'),
    schema: schemas.files(
      i18n('entities.intellectualProperty.fields.files'),
      {},
    ),
  },
  {
    name: 'images',
    label: i18n('entities.intellectualProperty.fields.images'),
    schema: schemas.images(
      i18n('entities.intellectualProperty.fields.images'),
      {},
    ),
  },
  {
    name: 'status',
    label: i18n('entities.intellectualProperty.fields.status'),
    schema: schemas.enumerator(
      i18n('entities.intellectualProperty.fields.status'),
      {
        required: true,
        options: intellectualPropertyEnumerators.status,
      },
    ),
  },
  {
    name: 'recordType',
    label: i18n('entities.intellectualProperty.fields.recordType'),
    schema: schemas.enumerator(
      i18n('entities.intellectualProperty.fields.recordType'),
      {
        options: intellectualPropertyEnumerators.recordType,
      },
    ),
  },
]

import Header from 'src/components/layout/Header'
import Menu from 'src/components/layout/Menu'
import { useLocation } from 'react-router-dom'
import LayoutWrapper from 'src/components/layout/styles/LayoutWrapper'
import MainWrapper from 'src/components/layout/styles/MainWrapper'

function Layout(props) {
  const location = useLocation()
  const url = location.pathname + location.search

  return (
    <LayoutWrapper>
      <Header />
      <div className="d-flex flex-row">
        <Menu url={url} />
        <MainWrapper className="main">
          <div className="content">{props.children}</div>
        </MainWrapper>
      </div>
    </LayoutWrapper>
  )
}

export default Layout

import React, { useEffect } from 'react'
import ProgressBar from 'src/routes/components/ProgressBar'

export default function LoadingComponent() {
  useEffect(() => {
    ProgressBar.start()
    return () => {
      ProgressBar.done()
    }
  }, [])

  return <div />
}

import { v4 as uuid4 } from 'uuid'
import jobFieldActions from 'src/modules/JobFieldMapping/JobFieldActions'

const initialState = {
  activeFields: [],
  filteredFields: [],
}

export default function (state = initialState, { type, payload }) {
  if (type === jobFieldActions.SET_ACTIVE_FIELD) {
    const activeFields =
      payload &&
      payload.map((field) => ({
        id: uuid4(),
        field: field.field,
        column: field.columnNo,
      }))

    return {
      ...state,
      activeFields,
      filteredFields: activeFields,
    }
  }

  if (type === jobFieldActions.SELECT_FIELD) {
    return {
      ...state,
      activeFields: [...state.activeFields, { ...payload, id: uuid4() }],
      filteredFields: [...state.activeFields, { ...payload, id: uuid4() }],
    }
  }

  if (type === jobFieldActions.FILTER_FIELD) {
    const filteredFields = state.activeFields.filter((activeField) =>
      activeField.field.toLowerCase().includes(payload.toLowerCase()),
    )

    return {
      ...state,
      filteredFields: payload.length > 0 ? filteredFields : state.activeFields,
    }
  }

  if (type === jobFieldActions.REMOVE_FIELD) {
    return {
      ...state,
      activeFields: state.activeFields.filter((field) => field.id !== payload),
      filteredFields: state.activeFields.filter(
        (field) => field.id !== payload,
      ),
    }
  }

  if (type === jobFieldActions.RESET) {
    return { ...state, activeFields: [], filteredFields: [] }
  }

  return state
}
